import React, { ReactElement } from 'react';
import cn from 'classnames';
import { TimesIcon } from 'components/icons';
import { FilePreview, isServerFile } from 'components/filePreview';
import { DropzoneFileProps } from './types';

const classes = {
  dropzoneFile: cn('dropzone-file odin-relative'),
  dropzoneFileImage: cn('odin-w-24 odin-h-24 odin-object-contain'),
  dropzoneFileDelete: cn(
    'odin-absolute odin-top-0.5 odin-right-0.5',
    '-odin-mr-2 -odin-mt-2',
    'odin-font-medium odin-rounded-xl',
    'odin-w-6 odin-h-6 odin-bg-black odin-text-white',
    'odin-opacity-80 hover:odin-opacity-100',
  ),
  dropzoneFileName: cn('odin-w-24 odin-text-xs odin-whitespace-nowrap odin-overflow-hidden odin-text-ellipsis'),
};

export function DropzoneFile({ file, onRemove }: DropzoneFileProps): ReactElement {
  const fileKey = (isServerFile(file) ? file.fileId : file?.name) ?? '';

  return (
    <div className={classes.dropzoneFile}>
      <FilePreview file={file} alt="dropzone-file" className={classes.dropzoneFileImage} />
      <button type="button" onClick={onRemove} className={classes.dropzoneFileDelete}>
        <TimesIcon />
      </button>
      <p className={classes.dropzoneFileName}>{fileKey}</p>
    </div>
  );
}
