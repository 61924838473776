import React from 'react';
import { useQuery } from '@apollo/client';
import { GetWorkerDataDocument, Worker, WorkerComment } from 'apollo/generated/client-operations';
import { Button, Table, TableContainer } from '@odin-labs/components';
import { PlusIcon } from 'components/icons';
import { LoadingError } from 'components/loadingError';
import { NewHeader } from 'components/header/NewHeader';
import { WorkerTabProps } from 'containers/worker/types';
import { AddWorkerCommentModal } from 'containers/worker/modals';
import { paginationSizePerPage as limit } from 'utils/constants';
import { GET_WORKER_COMMENTS } from 'containers/worker/helpers/queries';
import { useBoolean } from 'utils';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { getColumns } from './WorkerComments.tables';

const refetchQueries = [GET_WORKER_COMMENTS, GetWorkerDataDocument];

export function WorkerComments(props: WorkerTabProps): React.ReactElement {
  const { worker, jobsiteWorkers, jobsiteIds, isLocked, refetchWorkerData, onTabApiChange } = props;
  const { workerId } = worker;
  const { value: isAddModalOpen, setTrue: openAddModal, setFalse: closeAddModal } = useBoolean(false);
  const { page, updateUrl } = usePageQueryParams();

  const { data, error, loading } = useQuery(GET_WORKER_COMMENTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: isLocked,
    variables: {
      workerId,
      limit,
      offset: limit * page,
      includeArchived: worker?.isArchived,
    },
  });

  if (isLocked) {
    return <LockedWorkerAlert worker={worker as Worker} />;
  }

  const workerCommentsData = data?.getWorkerComments;
  const workerComments = workerCommentsData?.edges?.filter((workerComment: WorkerComment) =>
    jobsiteIds?.includes(workerComment?.jobsite?.jobsiteId),
  );
  const workerCommentsCount = workerCommentsData?.totalCount;
  const pageCount = Math.ceil(workerCommentsCount / limit);

  const jobsites = React.useMemo(() => jobsiteWorkers?.map((jw) => jw?.jobsiteContractor?.jobsite), [jobsiteWorkers]);
  const columns = React.useMemo(() => getColumns(), []);

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  return (
    <>
      <TableContainer>
        <NewHeader
          size="md"
          title="Comments"
          titleInfo={workerCommentsCount}
          actionsProps={{
            headerActions: null,
            children: (
              <Button
                icon={PlusIcon}
                text="Add Comment"
                hideTextOnMobile
                size={['base', 'md:sm']}
                onClick={openAddModal}
              />
            ),
          }}
        />
        <Table
          loading={loading}
          columns={columns}
          data={workerComments}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          onPageChange={onPageChangeHandler}
          cellClassName="!odin-pl-5"
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <AddWorkerCommentModal
        isOpen={isAddModalOpen}
        closeModal={closeAddModal}
        onConfirm={closeAddModal}
        refetchQueries={refetchQueries}
        worker={worker}
        jobsites={jobsites}
      />
    </>
  );
}
