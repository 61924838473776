import React, { ReactElement } from 'react';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { Avatar } from '@odin-labs/components';
import { useGetJobsiteWorkerQuery } from 'apollo/generated/client-operations';
import { getFullNameForUser, getInitialsForUser } from 'utils';
import { AuthContext } from 'auth';
import { SidebarContainerProps } from 'routes/types';
import { LoadingError } from 'components/loadingError';
import { SidebarItem, SidebarProps } from 'containers/sidebar/types';
import { DesktopSidebar, MobileSidebar, TabletSidebar } from 'containers/sidebar';
import { OnboardingStepKey, OnboardingUrlParams } from './types';
import { getVisibleSteps, getSidebarItems, isValidStep, getClasses } from './helpers/utils';

export function WorkerOnboardingSidebarContainer(props: SidebarContainerProps): ReactElement {
  const { currentRoute, pathname, isSidebarOpen, closeSidebar } = props;

  const currentRoutePath = currentRoute?.path as string;
  const { jobsiteWorkerId, step } = matchPath<OnboardingUrlParams>(pathname, { path: currentRoutePath })?.params ?? {};

  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);

  const { data: jobsiteWorkerData, error } = useGetJobsiteWorkerQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteWorkerId },
    skip: !jobsiteWorkerId,
  });

  const jobsiteWorker = jobsiteWorkerData?.getJobsiteWorker;
  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};
  const { workerId, user: workerUser, profilePictureCropped } = jobsiteWorker?.contractorWorker?.worker ?? {};

  const visibleSteps = React.useMemo(() => getVisibleSteps(jobsite), [jobsite]);
  const sidebarItems = React.useMemo((): SidebarItem[] => {
    return getSidebarItems(jobsiteWorker, visibleSteps, step) ?? [];
  }, [jobsiteWorker, visibleSteps]);

  React.useEffect(() => {
    if (!step || (visibleSteps && !visibleSteps.includes(step)) || !isValidStep(step)) {
      history.replace(`/onboarding/${jobsiteWorkerId}/${OnboardingStepKey.PersonalInfo}`);
    }
  }, [step, visibleSteps]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const getItemProps = (item: SidebarItem): { isLocked: boolean; isActive: boolean } => {
    const { locked, path } = item;
    return {
      isLocked: typeof locked === 'function' ? locked(user) : locked,
      isActive: !!matchPath(pathname, { path }),
    };
  };

  const sidebarHeader = <span className="odin-text-lg odin-font-medium">Worker onboarding</span>;
  const sidebarFooter = (
    <Link
      to={`/worker/${workerId}`}
      onClick={closeSidebar}
      className="odin-flex odin-items-center md:odin-flex-col lg:odin-flex-row"
    >
      <Avatar src={profilePictureCropped?.downloadUrl} size="md" placeholder={getInitialsForUser(workerUser)} />
      <div className="odin-ml-2 odin-text-odin-primary odin-text-center">{getFullNameForUser(workerUser)}</div>
    </Link>
  );

  const sidebarProps: SidebarProps = {
    isSidebarOpen,
    closeSidebar,
    items: sidebarItems,
    getItemProps,
    classes: getClasses(),
    header: sidebarHeader,
    footer: sidebarFooter,
  };

  return (
    <>
      <MobileSidebar {...sidebarProps} />
      <TabletSidebar {...sidebarProps} />
      <DesktopSidebar {...sidebarProps} />
    </>
  );
}
