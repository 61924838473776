import React, { ReactElement } from 'react';
import { Modal, Select, SelectOptionElement } from '@odin-labs/components';
import { RequiredField } from 'utils/validation';
import { SelectJobsiteModalProps } from './types';

interface SelectJobsiteModalState {
  selectedJobsite: SelectOptionElement | null;
  selectedJobsiteError?: string;
}

const initialState: SelectJobsiteModalState = {
  selectedJobsite: null,
};

export function SelectJobsiteModal(props: SelectJobsiteModalProps): ReactElement {
  const { isOpen = false, onCancel, onConfirm, jobsites } = props;

  const jobsiteOptions: SelectOptionElement[] = React.useMemo(
    () => jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
    [jobsites],
  );

  const [state, setState] = React.useState<SelectJobsiteModalState>(initialState);
  const { selectedJobsite, selectedJobsiteError } = state;

  const onActionHandler = (): void => {
    if (!selectedJobsite) {
      setState((prevState) => ({
        ...prevState,
        selectedJobsiteError: !selectedJobsite ? RequiredField`Jobsite` : '',
      }));
      return;
    }

    onConfirm({ jobsiteId: selectedJobsite.value });
    setState(initialState);
  };

  const onCancelHandler = (): void => {
    onCancel();
    setState(initialState);
  };

  return (
    <Modal
      open={isOpen}
      size="xs"
      title="Select a jobsite to assign the card to"
      onAction={onActionHandler}
      setOpen={onCancelHandler}
    >
      <div className="odin-space-y-5">
        <Select
          name="jobsite"
          label="Worker jobsites"
          value={selectedJobsite}
          options={jobsiteOptions ?? []}
          placeholder="Select a jobsite"
          onChange={(value): void => setState({ selectedJobsite: value, selectedJobsiteError: '' })}
          error={selectedJobsiteError}
        />
      </div>
    </Modal>
  );
}
