import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  MutationUploadSingleFileArgs,
  useCreateJobsiteWorkerDocumentMutation,
  useCreateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { InputFile, ServerFile } from 'types';
import { AlertService } from 'components/alertService';
import { FILE_UPLOAD } from 'containers/workerOnboarding/helpers/queries';
import {
  MedicalDocumentsUpload,
  MedicalConditionsDataType,
  UploadDocumentsArgs,
  UploadFileResponse,
  UseMedicalDocumentsUploadArgs,
} from 'containers/workerOnboarding/types';
import { getCurrentISOFormattedDate, getFullNameForUser, uploadCompressedFile, useIsMounted } from 'utils';
import { getCurrentISOFormattedDateTime, getISOFormattedDate, getISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey, WorkerAccessRevokingCategory } from 'containers/worker/utils';

export const NO_PASS = 'No pass';
export const CONDITIONAL_PASS = 'Conditional pass';

export function useMedicalDocumentsUpload(args: UseMedicalDocumentsUploadArgs): MedicalDocumentsUpload {
  const {
    user,
    jobsiteWorker,
    documentTypes,
    filteredDocumentTypeIds,
    documents,
    deferredSubmission,
    onUploadCompleted,
    refetchJobsiteWorkerDocuments,
  } = args;

  const { jobsiteWorkerId } = jobsiteWorker ?? {};
  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments({ jobsiteWorkerId, includePhi: true });
      onUploadCompleted?.();
    },
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker', 'GetWorkerDocuments'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useCreateJobsiteWorkerDocumentMutation({
    onError: (error) => {
      if (isMounted()) setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useCreateJobsiteWorkerDocumentVersionMutation();

  const isServerFile = (file: File | ServerFile): file is ServerFile => {
    return Boolean(file && (file as any).fileId);
  };

  const uploadDocuments = async ({
    data,
    dirtyFields,
  }: UploadDocumentsArgs<MedicalConditionsDataType>): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      const dirtyFieldValues = Object.keys(dirtyFields);

      const alcoholTestResult = data[`${DocumentKey.MedicalBreathAlcoholTest}-result`] as SelectOptionElement;
      const drugUrineTestResult = data[`${DocumentKey.MedicalUrineDrugTest}-result`] as SelectOptionElement;

      await Promise.all(
        documentTypes.map(async (documentType: string) => {
          const isDirty = dirtyFieldValues.some((val) => val.startsWith(documentType));
          const fileIds = [];
          const additionalFields = [];
          if (!isDirty) {
            return;
          }

          if (data[`${documentType}-front`]) {
            const file = data[`${documentType}-front`] as InputFile;
            if (isServerFile(file)) {
              fileIds.push(file.fileId);
            } else if (file) {
              const fileId = await uploadCompressedFile(file, uploadFile);
              if (fileId) {
                fileIds.push(fileId);
              }
            }
          }

          additionalFields.push({ key: 'test-taken-date', value: getCurrentISOFormattedDate() });

          const testResult = data[`${documentType}-result`] as SelectOptionElement;
          if (testResult) {
            additionalFields.push({ key: 'result', value: testResult.value });
          }

          const testReason = data[`${documentType}-test-reason`] as SelectOptionElement;
          if (testReason) {
            additionalFields.push({ key: 'test-reason', value: testReason.value });
          }

          const notesValue = data[`${documentType}-notes`] as string;
          if (notesValue) {
            additionalFields.push({ key: 'notes', value: notesValue });
          }

          const expirationDateRaw = data[`${documentType}-conditional-access-expiration-date`] as string;
          if (expirationDateRaw && testResult.value === CONDITIONAL_PASS) {
            additionalFields.push({
              key: 'conditional-access-expiration-date',
              value: getISOFormattedDate(expirationDateRaw),
            });
          }

          const dateSignedRaw = data[`${documentType}-date-signed`] as string;
          if (dateSignedRaw) {
            additionalFields.push({ key: 'date-signed', value: getISOFormattedDate(dateSignedRaw) });
          }

          additionalFields.push({ key: 'test-date', value: getCurrentISOFormattedDate() });

          const existingDocument = documents?.find(({ key }) => key === documentType);

          if (existingDocument) {
            createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId: existingDocument.id,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          } else {
            createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentType],
                  key: documentType,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: additionalFields,
                },
              },
            });
          }
        }),
      );

      if (alcoholTestResult?.value === NO_PASS || drugUrineTestResult?.value === NO_PASS) {
        await updateJobsiteWorker({
          variables: {
            jobsiteWorkerId,
            jobsiteWorkerInput: {
              isBanned: true,
              banCategory: WorkerAccessRevokingCategory.MedicalDrugTest,
              bannedReason: 'Unable to complete onboarding',
              bannedAt: getCurrentISOFormattedDateTime(),
              passedMedicalExamAt: null,
              medicalExamSkipReason: null,
              medicalNotes: data?.medicalNotes,
              siteAccessChangeApprovedBy: getFullNameForUser(user),
            },
          },
        });
        AlertService.alert('danger', 'User ban!', 'This user has been banned from the jobsite.');
        // `deferredSubmission` is not resolved here, so the route will not change
        return;
      }

      if (drugUrineTestResult?.value === CONDITIONAL_PASS) {
        const medicalExamSkipExpiresAtDate = data[
          `${DocumentKey.MedicalUrineDrugTest}-conditional-access-expiration-date`
        ] as string;
        await updateJobsiteWorker({
          variables: {
            jobsiteWorkerId,
            jobsiteWorkerInput: {
              isBanned: false,
              bannedReason: null,
              bannedAt: null,
              passedMedicalExamAt: getCurrentISOFormattedDateTime(),
              medicalNotes: data?.medicalNotes,
              urineTestConditionalPassExpiresAt: getISOFormattedDateTime(medicalExamSkipExpiresAtDate),
              medicalExamSkipExpiresAt: getISOFormattedDateTime(medicalExamSkipExpiresAtDate),
            },
          },
        });
        // `deferredSubmission` is not resolved here, so the route will not change
        return;
      }

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            passedMedicalExamAt: getCurrentISOFormattedDateTime(),
            medicalNotes: data?.medicalNotes,
            medicalExamSkipReason: null,
            medicalExamSkipExpiresAt: null,
            isBanned: false,
            bannedReason: null,
            bannedAt: null,
          },
        },
      });
      deferredSubmission?.resolve(true);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission?.reject(error);
    }
  };

  return {
    uploadDocuments,
    updateJobsiteWorker,
    fetching,
    setFetching,
  };
}
