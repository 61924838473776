import {
  JobsiteClearanceType,
  JobsiteWorkerClearanceAssignmentInput,
  useGetJobsiteDetailsQuery,
  useUpdateJobsiteWorkerV2Mutation,
} from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { FormOnSubmit, ModalForm } from 'components/form';
import React from 'react';
import { getFullNameForUser, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { useQueryParams } from 'utils/useQueryParams';
import { getDefaultValues, getFormInputs } from './EditJobsiteClearanceAssignmentsModal.forms';
import { EditJobsiteClearanceAssignmentsFormData, EditJobsiteClearanceAssignmentsModalProps } from './types';

export function EditJobsiteClearanceAssignmentsModal(
  props: EditJobsiteClearanceAssignmentsModalProps,
): React.ReactElement {
  const { isOpen, closeModal, onConfirm, refetchQueries, jobsiteWorker, worker } = props;
  const { user } = worker ?? {};
  const { jobsiteWorkerId, jobsiteContractor } = jobsiteWorker ?? {};
  const { jobsiteId } = jobsiteContractor?.jobsite ?? {};
  const urlSearchParams = useQueryParams();
  const skipACSSync = !!urlSearchParams.get('skipacssync');
  const workerFullName = getFullNameForUser(user);

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const {
    data: jobsiteData,
    loading,
    error,
  } = useGetJobsiteDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      jobsiteId,
    },
    skip: !jobsiteWorker,
  });
  const jobsite = jobsiteData?.getJobsite;

  const [updateJobsiteWorkerV2] = useUpdateJobsiteWorkerV2Mutation({ refetchQueries });

  const onSubmit: FormOnSubmit<EditJobsiteClearanceAssignmentsFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);
    const clearanceAssignments =
      data?.assignedClearances
        ?.filter((cs) => cs.selected)
        .map((cs) => {
          return {
            id: cs.clearanceAssignment?.id ?? undefined,
            clearanceId: cs.clearance.cCureClearanceId,
            jobsiteWorkerId,
            clearanceType: JobsiteClearanceType.CCure,
          } as JobsiteWorkerClearanceAssignmentInput;
        }) ?? [];
    try {
      await updateJobsiteWorkerV2({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            clearanceAssignments,
          },
          skipACSSync,
        },
      });

      onConfirm();
    } catch (ex) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(ex));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const defaultValues = React.useMemo(() => getDefaultValues(jobsiteWorker, jobsite), [jobsiteWorker, jobsite]);
  const formInputs = React.useMemo(() => getFormInputs(), []);

  const { assignedClearances } = defaultValues;
  const isSaveButtonEnabled = assignedClearances.length && !assignedClearances.some((cs) => cs.clearanceAssignment);

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title={`Select clearances for ${workerFullName}`}
      onIsDirtyChange={setIsFormDirty}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save"
      actionButtonEnabled={isFormDirty || isSaveButtonEnabled}
      actionButtonWithSpinner={isSaving}
      loading={loading}
      error={error}
    />
  );
}
