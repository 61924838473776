import React from 'react';
import moment from 'moment';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { CheckIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertService } from 'components/alertService';
import { GetWorkerCardsDocument, useAddCardToWorkerMutation, WorkerCardType } from 'apollo/generated/client-operations';
import { AssignBluetoothCardData } from './types';

export type ReassignBluetoothCardModalContentProps = {
  workerId: string;
  reassignData: AssignBluetoothCardData;
  setOpen: () => void;
  onAction: () => void;
};

export function ReassignBluetoothCardModalContent(props: ReassignBluetoothCardModalContentProps): React.ReactElement {
  const { workerId, reassignData, setOpen, onAction } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addCardToWorker] = useAddCardToWorkerMutation({
    refetchQueries: [GetWorkerCardsDocument],
  });

  const onSubmit: FormOnSubmit<unknown> = async (_data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { jobsiteId, cardNumber } = reassignData;

    try {
      const addProximityCardResponse = await addCardToWorker({
        variables: {
          jobsiteId: jobsiteId.value,
          workerId,
          forceCreate: true,
          workerCardInput: {
            cardNumber,
            type: WorkerCardType.Proximity,
          },
        },
      });

      const { workerCardId: matchingWorkerCardId } = addProximityCardResponse?.data?.addCardToWorker ?? {};
      if (matchingWorkerCardId) {
        const today = moment();
        await addCardToWorker({
          variables: {
            jobsiteId: jobsiteId.value,
            workerId,
            forceCreate: true,
            workerCardInput: {
              startDate: today.toDate(),
              endDate: today.add(2, 'years').endOf('day').toDate(),
              cardNumber,
              type: WorkerCardType.Bluetooth,
              matchingWorkerCardId,
            },
          },
        });
      }

      onAction();
      AlertService.alert('success', 'Success', 'Bluetooth card assigned');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  return (
    <ModalFormContent
      loading={isFetching}
      setOpen={setOpen}
      inputs={null}
      onSubmit={onSubmit}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Yes"
      actionIcon={CheckIcon}
      cancelText="No"
      actionButtonWithSpinner={isFetching}
    />
  );
}
