import React, { ReactElement, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import {
  GetWorkerDataDocument,
  MutationUploadSingleFileArgs,
  MutationUpdateWorkerArgs,
  Worker,
} from 'apollo/generated/client-operations';
import { InputFile } from 'types';
import { FILE_UPLOAD, UPDATE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { UploadFileResponse } from 'containers/workerOnboarding/types';
import { uploadCompressedFile, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { Dropzone } from 'components/dropzone';
import { AlertService } from 'components/alertService';
import { LoadingError } from 'components/loadingError';
import { UpdateProfilePictureModalContainerProps } from './types';

export function UpdateProfilePictureModalContainer({
  worker,
  isOpen = false,
  closeModal,
  onConfirm,
}: UpdateProfilePictureModalContainerProps): ReactElement {
  const isMounted = useIsMounted();
  const initialProfilePictureFile = worker?.profilePictureCropped;
  const [profilePictureFile, setProfilePictureFile] = useState<InputFile>(initialProfilePictureFile);
  const [fetching, setFetching] = useState<boolean>(false);
  const profilePictureUrlRef = useRef<string>();

  // this modal doesn't unmount when closed, so we need
  // to reset profilePictureFile every time the modal opens
  // if the profilePictureFile was altered by user
  React.useEffect(() => {
    if (isOpen && profilePictureFile !== initialProfilePictureFile) {
      setProfilePictureFile(initialProfilePictureFile);
    }
  }, [isOpen]);

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onCompleted: (data) => {
      profilePictureUrlRef.current = data.uploadSingleFile.downloadUrl;
    },
    onError: (error) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const [updateWorker] = useMutation<Worker, MutationUpdateWorkerArgs>(UPDATE_WORKER, {
    onCompleted: () => {
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (error) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: [GetWorkerDataDocument],
  });

  function isFile(file: InputFile): file is File {
    return !!(file as File)?.name;
  }

  const onAction = async (): Promise<void> => {
    if (isFile(profilePictureFile)) {
      setFetching(true);
      const fileId = await uploadCompressedFile(profilePictureFile, uploadFile);
      await updateWorker({
        variables: {
          workerId: worker.workerId,
          workerInput: {
            profilePictureFileId: fileId,
          },
        },
      });
      onConfirm(profilePictureUrlRef.current);
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      onAction={onAction}
      actionButtonEnabled={isFile(profilePictureFile) && !fetching}
    >
      {fetching ? (
        <LoadingError loading />
      ) : (
        <>
          <h2>Update profile photo</h2>
          <Dropzone
            name="profilePicture"
            label="Profile Picture"
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
            value={profilePictureFile ?? null}
            onChange={(file: InputFile): void => {
              setProfilePictureFile(file);
            }}
          />
        </>
      )}
    </Modal>
  );
}
