import React from 'react';
import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { Alert } from '@odin-labs/components';
import { UserUpdateIdentityInput, UpdateWorkerMutationVariables } from 'apollo/generated/client-operations';
import { ensureNonUndefinedFields, getPhoneNumberAsE164, nullifyEmptyFields, stringifyEmptyFields } from 'utils';
import {
  toFancySelectOptions,
  statesOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
  genderOptions as genderOptionsValues,
  jobTitleOptions as jobTitleOptionsValues,
  primaryLanguageOptions as primaryLanguageOptionsValues,
  raceOptions as raceOptionsValues,
  tradeOptions as tradeOptionsValues,
  unionAffiliationOptions as unionAffiliationOptionsValues,
  veteranOptions as veteranOptionsValues,
} from 'utils/constants';
import { getPrettyFormattedUtcDate, momentFormatter } from 'utils/dates';
import {
  dateValidation,
  emailValidation,
  ageValidation,
  phoneNumberValidation,
  ssnValidation,
  zipCodeValidation,
} from 'utils/validation';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { EnvelopeIcon, MapMarkerAltIcon } from 'components/icons';
import {
  FormInputTypes,
  TypedFormInputs,
  getUpdateInputValueFunction,
  GridColSpan,
  UseInputs,
  UseFormMethods,
} from 'components/form';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { citizenshipStatusOptions } from 'containers/worker/helpers/utils';
import { Worker, EditPersonalInfoFormData } from 'containers/worker/types';

const unionAffiliationOptions = toFancySelectOptions(unionAffiliationOptionsValues);
const jobTitleOptions = toFancySelectOptions(jobTitleOptionsValues);
const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);
const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
const genderOptions = toFancySelectOptions(genderOptionsValues);
const raceOptions = toFancySelectOptions(raceOptionsValues);
const veteranOptions = toFancySelectOptions(veteranOptionsValues);

const getFormInputs = ({
  canAddUnions,
  isEmailEditable,
  isPhoneEditable,
  shouldShowAlert,
}: {
  canAddUnions: boolean;
  isEmailEditable: boolean;
  isPhoneEditable: boolean;
  shouldShowAlert: boolean;
}): TypedFormInputs<EditPersonalInfoFormData & { alert?: undefined }> => ({
  firstName: {
    element: FormInputTypes.Field,
    label: 'First name (required)',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  middleInitial: {
    element: FormInputTypes.Field,
    label: 'Middle initial',
    elementProps: {
      fieldType: 'custom',
      cleaveOptions: { blocks: [1] },
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  lastName: {
    element: FormInputTypes.Field,
    label: 'Last name (required)',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  suffix: {
    element: FormInputTypes.Field,
    label: 'Suffix',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  birthDate: {
    element: FormInputTypes.Field,
    label: 'Date of birth',
    elementProps: {
      placeholder: 'MM/DD/YYYY',
      fieldType: 'pastDate',
    },
    validation: {
      pattern: dateValidation,
      validate: ageValidation('Date of birth', 14, 100),
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  ssnLastFour: {
    element: FormInputTypes.Field,
    label: 'Last 4 of social security number',
    elementProps: {
      fieldType: 'ssn4',
    },
    validation: { pattern: ssnValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  race: {
    element: FormInputTypes.Select,
    label: 'Race',
    elementProps: {
      options: raceOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  gender: {
    element: FormInputTypes.Select,
    label: 'Gender',
    elementProps: {
      options: genderOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  primaryLanguage: {
    element: FormInputTypes.Select,
    label: 'Primary language',
    elementProps: {
      options: primaryLanguageOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan4],
  },
  citizenshipStatus: {
    element: FormInputTypes.Select,
    label: 'Citizenship status',
    elementProps: {
      options: citizenshipStatusOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan4],
  },
  isVeteran: {
    element: FormInputTypes.Select,
    label: 'Veteran status',
    elementProps: {
      options: veteranOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan4],
  },
  addressLine1: {
    element: FormInputTypes.PlacesAutocomplete,
    label: 'Address',
    elementProps: {
      icon: MapMarkerAltIcon,
      onCommit: fillAddressDetails,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  addressLine2: {
    element: FormInputTypes.Field,
    label: 'Address line 2',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  addressCity: {
    element: FormInputTypes.Field,
    label: 'City',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan5],
  },
  addressState: {
    element: FormInputTypes.Select,
    label: 'State',
    elementProps: {
      placeholder: '',
      options: statesOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressZipCode: {
    element: FormInputTypes.Field,
    label: 'Zip code',
    elementProps: {
      fieldType: 'zipcode',
    },
    validation: { pattern: zipCodeValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan5],
  },
  phoneNumber: {
    element: FormInputTypes.Field,
    label: 'Phone number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
      disabled: !isPhoneEditable,
    },
    validation: { pattern: phoneNumberValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  email: {
    element: FormInputTypes.Field,
    label: 'Email address',
    elementProps: {
      disabled: !isEmailEditable,
      icon: EnvelopeIcon,
    },
    validation: { pattern: emailValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  emergencyContactName: {
    element: FormInputTypes.Field,
    label: 'Emergency contact name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  emergencyContactRelationship: {
    element: FormInputTypes.Select,
    label: 'Emergency contact relationship',
    elementProps: {
      options: emergencyContactRelationshipOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  emergencyContactPhone: {
    element: FormInputTypes.Field,
    label: 'Emergency contact phone number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: { pattern: phoneNumberValidation },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  trade: {
    element: FormInputTypes.Select,
    label: 'Trade',
    elementProps: {
      options: tradeOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  jobTitle: {
    element: FormInputTypes.Select,
    label: 'Job Title',
    elementProps: {
      options: jobTitleOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  unionAffiliation: {
    element: FormInputTypes.Select,
    label: 'Union Affiliation',
    elementProps: {
      isCreateable: canAddUnions,
      options: unionAffiliationOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
  },
  alert: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: (
        <Alert
          type="danger"
          text={
            'You are about to change the phone number or/and email address for an worker ' +
            'who already signed in using one of these credentials. '
          }
        />
      ),
    },
    layout: shouldShowAlert ? GridColSpan.SpanFull : 'odin-hidden',
  },
});

export const getFormInputsHook =
  (args: { worker: Worker; defaultValues: EditPersonalInfoFormData }): UseInputs<EditPersonalInfoFormData> =>
  (form: UseFormMethods<EditPersonalInfoFormData>): TypedFormInputs<EditPersonalInfoFormData> => {
    const { worker, defaultValues } = args;
    const { dirtyFields } = form.formState;

    const { currentUser: user } = React.useContext(AuthContext);
    const canAddUnions = user.isAllowed(to.addUnions);
    const canChangeAuthenticatedWorkerCredentials = user.isAllowed(to.changeAuthenticatedWorkerCredentials);

    const isEmailEditable =
      !defaultValues.email || worker.editableFields.email || canChangeAuthenticatedWorkerCredentials;
    const isPhoneEditable =
      !defaultValues.phoneNumber || worker.editableFields.phone || canChangeAuthenticatedWorkerCredentials;
    const shouldShowAlert =
      canChangeAuthenticatedWorkerCredentials &&
      ((dirtyFields.email && !worker.editableFields.email && !!defaultValues.email) ||
        (dirtyFields.phoneNumber && !worker.editableFields.phone && !!defaultValues.phoneNumber));

    return React.useMemo(
      () => getFormInputs({ canAddUnions, isEmailEditable, isPhoneEditable, shouldShowAlert }),
      [canAddUnions, isEmailEditable, isPhoneEditable, shouldShowAlert],
    );
  };

export const getDefaultValues = (worker: Worker): EditPersonalInfoFormData => {
  const {
    user,
    middleInitial,
    suffix,
    birthDate,
    ssnLastFour,
    race,
    gender,
    primaryLanguage,
    citizenshipStatus,
    isVeteran,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    jobTitle,
    emergencyContactName,
    emergencyContactRelationship,
    emergencyContactPhone,
    trade,
    unionAffiliation,
  } = worker ?? {};
  const { firstName, lastName, email, phoneNumber } = user?.identity ?? {};

  return {
    ...stringifyEmptyFields({
      firstName,
      lastName,
      email,
      middleInitial,
      suffix,
      birthDate: getPrettyFormattedUtcDate(birthDate),
      phoneNumber,
      ssnLastFour,
      addressLine1,
      addressLine2,
      addressCity,
      addressZipCode,
      emergencyContactName,
      emergencyContactPhone,
    }),
    ...nullifyEmptyFields({
      race: raceOptions.find((opt) => opt.value === race),
      gender: genderOptions.find((opt) => opt.value === gender),
      primaryLanguage: primaryLanguageOptions.find((opt) => opt.value === primaryLanguage),
      citizenshipStatus: citizenshipStatusOptions.find((opt) => opt.value === citizenshipStatus),
      isVeteran: veteranOptions.find((opt) => opt.value === (isVeteran ? 'Veteran' : 'Non veteran')),
      addressState: statesOptions.find((opt) => opt.value === addressState),
      jobTitle: jobTitleOptions.find((opt) => opt.value === jobTitle),
      emergencyContactRelationship: emergencyContactRelationshipOptions.find(
        (opt) => opt.value === emergencyContactRelationship,
      ),
      trade: tradeOptions.find((opt) => opt.value === trade),
      unionAffiliation: unionAffiliationOptions.find((opt) => opt.value === unionAffiliation),
    }),
  };
};

export type PersonalInfoUpdateInput = {
  userUpdateIdentityInput: UserUpdateIdentityInput;
} & UpdateWorkerMutationVariables;

export const getPersonalInfoUpdateInput = (
  worker: Worker,
  data: EditPersonalInfoFormData,
  dirtyFields: DeepMap<EditPersonalInfoFormData, true>,
): PersonalInfoUpdateInput => {
  const { workerId, user } = worker;
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const isVeteranInputValue = dirtyFields.isVeteran ? data.isVeteran?.value === 'Veteran' : undefined;
  const emergencyContactPhoneInputValue = dirtyFields.emergencyContactPhone
    ? getPhoneNumberAsE164(data.emergencyContactPhone) || null
    : undefined;
  const birthDateInputValue = dirtyFields.birthDate
    ? moment.utc(data.birthDate, momentFormatter).toDate() || null
    : undefined;
  const phoneNumberInputValue = getPhoneNumberAsE164(getUpdateInputValue('phoneNumber'));

  return ensureNonUndefinedFields<PersonalInfoUpdateInput>({
    workerId,

    workerInput: {
      middleInitial: getUpdateInputValue('middleInitial'),
      suffix: getUpdateInputValue('suffix'),
      race: getUpdateInputValue('race'),
      gender: getUpdateInputValue('gender'),
      primaryLanguage: getUpdateInputValue('primaryLanguage'),
      citizenshipStatus: getUpdateInputValue('citizenshipStatus'),
      isVeteran: isVeteranInputValue,
      addressLine1: getUpdateInputValue('addressLine1'),
      addressLine2: getUpdateInputValue('addressLine2'),
      addressCity: getUpdateInputValue('addressCity'),
      addressState: getUpdateInputValue('addressState'),
      addressZipCode: getUpdateInputValue('addressZipCode'),
      emergencyContactName: getUpdateInputValue('emergencyContactName'),
      emergencyContactRelationship: getUpdateInputValue('emergencyContactRelationship'),
      emergencyContactPhone: emergencyContactPhoneInputValue,
      trade: getUpdateInputValue('trade'),
      jobTitle: getUpdateInputValue('jobTitle'),
      unionAffiliation: getUpdateInputValue('unionAffiliation'),
      workerBaseInfoInput: {
        birthDate: birthDateInputValue,
        ssnLastFour: getUpdateInputValue('ssnLastFour'),
      },
    },
    userUpdateIdentityInput: {
      userAccountId: user.userAccountId,
      userIdentityInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: phoneNumberInputValue,
      },
    },
  });
};
