import React, { ReactElement, useState } from 'react';
import { Modal, SelectOptionElement } from '@odin-labs/components';
import { ApolloError } from '@apollo/client';
import {
  AnnouncementStatus,
  GetJobsiteAnnouncementsDocument,
  JobsiteAnnouncement,
  JobsiteAnnouncementFilterSpecification,
  JobsiteAnnouncementInput,
  useCreateJobsiteAnnouncementMutation,
} from 'apollo/generated/client-operations';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';
import { ArrowLeftIcon, EnvelopeIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { AnnouncementCard } from 'containers/announcement/components';
import { AnnouncementScheduleType, CreateAnnouncementModalContainerProps } from 'containers/announcements/types';

export function ReviewAnnouncementModalContainer({
  jobsiteOptions,
  contractorOptions,
  announcement,
  counts,
  onCancel,
  onBack,
  onAction,
}: CreateAnnouncementModalContainerProps): ReactElement {
  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const lookupOptionLabel = (value: string, list: SelectOptionElement[]): string => {
    const option = list.find((o) => o.value === value);
    return option?.label;
  };

  const specJobsiteAnnouncement = (
    announcementInput: Partial<JobsiteAnnouncementInput> & JobsiteAnnouncementFilterSpecification,
  ): Partial<JobsiteAnnouncement> => {
    const jobsiteNodes = announcementInput.filterJobsiteIds.map((jobsiteId) => ({
      node: {
        jobsiteId,
        name: lookupOptionLabel(jobsiteId, jobsiteOptions),
      },
    }));

    const contractorNodes = announcementInput.filterContractorIds.map((contractorId) => ({
      node: {
        contractorId,
        organization: { name: lookupOptionLabel(contractorId, contractorOptions) },
      },
    }));

    return {
      jobsites: {
        count: announcementInput.filterJobsiteIds.length,
        edges: jobsiteNodes,
      } as JobsiteAnnouncement['jobsites'],
      contractors: {
        count: announcementInput.filterContractorIds.length,
        edges: contractorNodes,
      } as JobsiteAnnouncement['contractors'],
      trades: announcementInput.filterTrades,
      languages: announcementInput.filterLanguages,
      jobsiteAccessStatus: announcementInput.filterJobsiteAccessStatus,
      accessWithinDayRange: announcementInput.filterAccessWithinDayRange,
      announcementType: announcementInput.announcementType,
      subject: announcementInput.subject,
      message: announcementInput.message,
    };
  };

  const jobsiteAnnouncementForReview = specJobsiteAnnouncement(announcement);

  const filterSpecification = (({
    announcementType,
    filterJobsiteIds,
    filterContractorIds,
    filterTitles,
    filterTrades,
    filterLanguages,
    filterJobsiteAccessStatus,
    filterAccessWithinDayRange,
  }): JobsiteAnnouncementFilterSpecification => ({
    announcementType,
    filterJobsiteIds,
    filterContractorIds,
    filterTitles,
    filterTrades,
    filterLanguages,
    filterJobsiteAccessStatus,
    filterAccessWithinDayRange,
  }))(announcement);

  const { subject, message, startAt, timeZone } = announcement;
  const jobsiteAnnouncementInput = {
    subject,
    message,
    status: AnnouncementStatus.Draft,
    filterSpecification,
    startAt,
    timeZone,
  } as JobsiteAnnouncementInput;

  const [createJobsiteAnnouncementMutation] = useCreateJobsiteAnnouncementMutation({
    variables: {
      jobsiteAnnouncementInput, // value for 'jobsiteAnnouncementInput'
    },
    onCompleted: () => {
      onAction();
      AlertService.alert('success', 'Success', 'Jobsite announcement successfully sent');
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (responseError: ApolloError) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    refetchQueries: [GetJobsiteAnnouncementsDocument],
  });

  const onSubmit = (): void => {
    if (isFetching) {
      return;
    }
    setFetching(true);
    createJobsiteAnnouncementMutation();
  };

  return (
    <Modal
      open
      setOpen={onCancel}
      title="Review Announcement"
      subtitle={`${
        startAt
          ? `${AnnouncementScheduleType.Scheduled}: ${getPrettyFormattedDateTimeWithTz(startAt, timeZone)}`
          : AnnouncementScheduleType.Immediate
      }`}
      actionText="Send"
      actionIcon={EnvelopeIcon}
      showBackButton
      onCancel={onCancel}
      onBack={onBack}
      actionButtonWithSpinner={isFetching}
      onAction={onSubmit}
      cancelText="Back"
      cancelButtonTheme="naked"
      cancelIcon={ArrowLeftIcon}
    >
      <AnnouncementCard announcement={jobsiteAnnouncementForReview} counts={counts} />
    </Modal>
  );
}
