import React, { ReactElement } from 'react';
import { SiteAccessBadge } from '@odin-labs/components';
import { Status } from 'components/status/Status';
import { getFormattedDate } from 'utils';
import { convertUTCtoLocalDate } from 'utils/dates';
import { JobsiteWorkerAccessComponentTypes } from './types';

export function JobsiteWorkerAccessComponent(props: JobsiteWorkerAccessComponentTypes): ReactElement {
  const { jobsiteWorker, type, showEndDate } = props;
  const { endDate, isAllowed } = jobsiteWorker?.currentAccess ?? {};

  const siteAccessText = showEndDate ? `Allowed until ${getFormattedDate(convertUTCtoLocalDate(endDate))}` : 'Allowed';

  switch (type) {
    case 'badge':
      return <SiteAccessBadge className="odin-grow-0 odin-my-3" status={isAllowed ? 'allowed' : 'not-allowed'} />;
    case 'status':
      return (
        <Status label={isAllowed ? siteAccessText : 'Not Allowed on Site'} status={isAllowed ? 'success' : 'danger'} />
      );
    default:
      return null;
  }
}
