import React from 'react';
import { getGraphQLError } from 'utils/error';
import { LinkIcon } from 'components/icons';
import { AlertService } from 'components/alertService';

export function CopyLink(props: { link: string; label?: string; className?: string }): React.ReactElement {
  const { link, label, className } = props;
  return (
    <a
      onClick={async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): Promise<void> => {
        event.preventDefault(); // prevent the link from navigating to its href
        try {
          await navigator.clipboard.writeText(link);
          AlertService.alert('success', 'Success', `Link successfully copied to clipboard`);
        } catch (error) {
          AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
        }
      }}
      href={link}
      className={className}
    >
      <LinkIcon className="odin-mr-2" />
      <span>{label || 'Copy Link'}</span>
    </a>
  );
}
