import React from 'react';
import cn from 'classnames';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import {
  UpdateWorkerMutationVariables,
  useGetJobsiteWithBadgeTemplatesQuery,
} from 'apollo/generated/client-operations';
import { dropzoneValidation } from 'components/dropzone/utils';
import { JobsiteBadgeTemplateOption, getJobsiteBadgeTemplatesOptions } from 'containers/worker/helpers/utils';
import { Worker } from 'containers/worker/types';
import { ensureNonUndefinedFields } from 'utils';
import { CreateCardPrintJobFormData } from './types';

export const getFormInputs = ({
  workerPhotoUrl,
  badgeTemplateOptions,
}: {
  workerPhotoUrl: string;
  badgeTemplateOptions: JobsiteBadgeTemplateOption[];
}): TypedFormInputs<CreateCardPrintJobFormData> => ({
  photo: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: (
        <img src={workerPhotoUrl} className="odin-object-cover odin-min-h-full odin-py-6" alt="appears on the badge" />
      ),
    },
    layout: cn(workerPhotoUrl ? 'odin-col-span-3 sm:odin-col-span-1' : 'odin-hidden'),
  },
  profilePictureFile: {
    element: FormInputTypes.Dropzone,
    label: 'Replace Photo',
    elementProps: {
      accept: {
        'application/pdf': [],
        'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
      },
    },
    validation: {
      validate: dropzoneValidation,
    },
    layout: cn(workerPhotoUrl ? 'odin-col-span-3 sm:odin-col-span-5' : 'odin-col-span-6'),
  },
  trade: {
    element: FormInputTypes.Field,
    label: 'Trade',
    layout: cn('odin-col-span-3'),
  },
  jobTitle: {
    element: FormInputTypes.Field,
    label: 'Trade class',
    layout: cn('odin-col-span-3'),
  },
  badgeTemplateName: {
    element: FormInputTypes.Select,
    label: 'Badge template',
    elementProps: {
      placeholder: 'Select one',
      options: badgeTemplateOptions,
      disabled: badgeTemplateOptions.length < 2,
    },
    validation: { required: true },
    layout: cn('odin-col-span-3'),
  },
});

export const useJobsiteBadgeTemplateOptions = (jobsiteId: string): JobsiteBadgeTemplateOption[] => {
  const { data } = useGetJobsiteWithBadgeTemplatesQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (!data) return [];
    const jobsiteBadgeTemplates = data?.getJobsite.jobsiteBadgeTemplates.edges.map(({ node }) => node);
    return getJobsiteBadgeTemplatesOptions(jobsiteBadgeTemplates);
  }, [data]);
};

export const getFormDefaultValues = ({
  worker,
  badgeTemplateOptions,
}: {
  worker: Worker;
  badgeTemplateOptions: JobsiteBadgeTemplateOption[];
}): CreateCardPrintJobFormData => {
  return {
    trade: worker.trade ?? '',
    jobTitle: worker.jobTitle ?? '',
    badgeTemplateName:
      badgeTemplateOptions.length === 1
        ? badgeTemplateOptions[0]
        : badgeTemplateOptions.find((opt) => opt.isDefault) ?? null,
  };
};

export type WorkerUpdateInput = Pick<UpdateWorkerMutationVariables['workerInput'], 'profilePictureFileId'>;

export const getWorkerUpdateInput = (profilePictureFileId: string): WorkerUpdateInput => {
  return ensureNonUndefinedFields<WorkerUpdateInput>({
    profilePictureFileId,
  });
};
