import React from 'react';
import cn from 'classnames';

const classes = {
  card: (extraClasses: string): string =>
    cn(
      extraClasses,
      'reactstrap-card odin-flex odin-flex-col odin-relative odin-min-w-0',
      'odin-border odin-shadow-odin-lite odin-rounded-lg',
      'odin-bg-white odin-bg-clip-border',
    ),
};

export type CardProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function Card(props: CardProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.card(className)}>{children}</div>;
}
