import React, { ReactElement } from 'react';
import cn from 'classnames';
import { HeaderProps } from 'components/header/types';
import { Col, Row } from 'components/reactstrap';

type TextAlign = HeaderProps['textAlign'];

const textAlignClasses: Record<TextAlign, string> = {
  left: 'odin-text-left',
  center: 'odin-text-center',
  right: 'odin-text-right',
};

const classes = {
  header: 'odin-mb-8 md:odin-mt-3',
  headerBody: 'odin-py-6 odin-border-b odin-border-gray-200',
  headerTitle: (textAlign: TextAlign): string => cn('odin-text-2.33xl odin-font-medium', textAlignClasses[textAlign]),
  headerPreTitle: (textAlign: TextAlign): string =>
    cn(
      'odin-text-2xs odin-font-medium odin-uppercase odin-tracking-widest odin-text-slate-400',
      textAlignClasses[textAlign],
    ),
  headerSubTitle: (textAlign: TextAlign): string =>
    cn('odin-mt-1.5 odin-text-black odin-text-base odin-leading-5', textAlignClasses[textAlign]),
};

export function Header({
  pretitle,
  title,
  subtitle,
  leftContent,
  rightContent,
  textAlign = 'left',
}: HeaderProps): ReactElement {
  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <Row className="odin-items-center">
          <Col>
            {pretitle ? <h6 className={classes.headerPreTitle(textAlign)}>{pretitle}</h6> : null}
            <h1 className={classes.headerTitle(textAlign)}>
              {title}
              {leftContent}
            </h1>
            {subtitle ? <h3 className={classes.headerSubTitle(textAlign)}>{subtitle}</h3> : null}
          </Col>
          {rightContent ? <div className="odin-px-4">{rightContent}</div> : null}
        </Row>
      </div>
    </div>
  );
}
