import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'components/reactstrap';
import { NoResultsProps } from './types';

export function NoResults(props: NoResultsProps): ReactElement {
  const { image, message } = props;
  return (
    <Container className="odin-mt-3">
      <Row>
        <Col className="odin-justify-center odin-text-center">
          {image ? <img alt="No Results" src={image} width="200" /> : null}
          <p>{message}</p>
        </Col>
      </Row>
    </Container>
  );
}
