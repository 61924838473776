import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields, stringifyEmptyFields } from 'utils';
import { statesOptions } from 'utils/constants';
import { DeveloperUpdateInput, OrganizationInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EnvelopeIcon, MapMarkerAltIcon } from 'components/icons';
import { OrganizationInfo } from 'containers/organization/types';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { emailValidation, phoneNumberValidation, zipCodeValidation } from 'utils/validation';
import { EditDeveloperFormData } from './types';

export const getFormInputs = (disableOrganizationInputs = false): TypedFormInputs<EditDeveloperFormData> => {
  return {
    // organization
    name: {
      element: FormInputTypes.Field,
      label: 'Display name',
      elementProps: {
        disabled: disableOrganizationInputs,
      },
      validation: {
        required: true,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    legalName: {
      element: FormInputTypes.Field,
      elementProps: {
        disabled: disableOrganizationInputs,
      },
      label: 'Legal Name',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    email: {
      element: FormInputTypes.Field,
      label: 'Email',
      elementProps: {
        icon: EnvelopeIcon,
        disabled: disableOrganizationInputs,
      },
      validation: {
        pattern: emailValidation,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    phoneNumber: {
      element: FormInputTypes.Field,
      label: 'Phone Number',
      elementProps: {
        fieldType: 'phone',
        showDefaultIcon: true,
        disabled: disableOrganizationInputs,
      },
      validation: {
        pattern: phoneNumberValidation,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    addressLine1: {
      element: FormInputTypes.PlacesAutocomplete,
      label: 'Address',
      elementProps: {
        disabled: disableOrganizationInputs,
        placeholder: 'Address',
        icon: MapMarkerAltIcon,
        onCommit: fillAddressDetails,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    addressLine2: {
      element: FormInputTypes.Field,
      elementProps: {
        disabled: disableOrganizationInputs,
      },
      label: 'Address, Line 2',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    addressCity: {
      element: FormInputTypes.Field,
      elementProps: {
        disabled: disableOrganizationInputs,
      },
      label: 'City',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    },
    addressState: {
      element: FormInputTypes.Select,
      elementProps: {
        disabled: disableOrganizationInputs,
        options: statesOptions,
      },
      label: 'State',
      layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
    },
    addressZipCode: {
      element: FormInputTypes.Field,
      label: 'ZIP Code',
      layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
      elementProps: {
        disabled: disableOrganizationInputs,
        fieldType: 'zipcode',
      },
      validation: {
        pattern: zipCodeValidation,
      },
    },
  };
};

export const getDefaultValues = (
  { organization }: { organization: OrganizationInfo } = { organization: null },
): EditDeveloperFormData => {
  const { name, legalName, addressLine1, addressLine2, addressCity, addressState, addressZipCode, email, phoneNumber } =
    organization ?? {};

  return {
    ...stringifyEmptyFields({
      // organization
      name,
      legalName,
      email,
      phoneNumber,
      addressLine1,
      addressLine2,
      addressCity,
      addressZipCode,
    }),
    addressState: statesOptions.find((option) => option.value === addressState) ?? null,
  };
};

export const getCommonOrganizationInput = (
  data: EditDeveloperFormData,
  dirtyFields: DeepMap<EditDeveloperFormData, true>,
): OrganizationInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);
  return ensureNonUndefinedFields<OrganizationInput>({
    name: getUpdateInputValue('name'),
    legalName: getUpdateInputValue('legalName'),
    email: getUpdateInputValue('email'),
    phoneNumber: getUpdateInputValue('phoneNumber'),
    addressLine1: getUpdateInputValue('addressLine1'),
    addressLine2: getUpdateInputValue('addressLine2'),
    addressCity: getUpdateInputValue('addressCity'),
    addressState: getUpdateInputValue('addressState'),
    addressZipCode: getUpdateInputValue('addressZipCode'),
  });
};

export const getUpdateInput = (
  developerId: string,
  data: EditDeveloperFormData,
  dirtyFields: DeepMap<EditDeveloperFormData, true>,
): DeveloperUpdateInput => {
  return ensureNonUndefinedFields<DeveloperUpdateInput>({
    developerId,
    organizationInput: getCommonOrganizationInput(data, dirtyFields),
  });
};
