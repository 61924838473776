import React from 'react';
import { Button } from '@odin-labs/components';
import { JobsiteWorker, useGetFormSubmissionJobsiteWorkersQuery } from 'apollo/generated/client-operations';
import { Change, EditType } from 'types';
import { useResettableState } from 'utils';
import { UserHardHatIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import {
  EditableFormSubmissionWorker,
  EditableEnhancedFormSubmissionWorker,
  EnhancedFormSubmissionWorker,
} from 'containers/jobsiteFormSubmission/types';
import { EditFormSubmissionWorkerModal } from './modals';
import { FormSubmissionWorker } from './FormSubmissionWorker';
import { FormSubmissionWorkersProps } from './types';

export const toEditableJobsiteFormSubmissionWorker = ({
  jfsWorker,
}: {
  jfsWorker: EnhancedFormSubmissionWorker;
}): EditableEnhancedFormSubmissionWorker => {
  const { jobsiteWorker: jw, historicalData, ...restJfsWorker } = jfsWorker;
  const { jobsiteWorkerAccess: jwAccess } = historicalData ?? {};

  return {
    ...restJfsWorker,
    jobsiteWorker: jw,
    jobsitesInfo: [
      {
        jobsiteWorker: jw as JobsiteWorker,
        jobsiteWorkerId: jw.jobsiteWorkerId,
        isJobsiteAccessAllowed: jwAccess?.isAllowedNow ?? false,
        jobsiteName: jw.jobsiteContractor.jobsite.name,
        contractorName: jw.jobsiteContractor.contractor?.organization?.name,
      },
    ],
  };
};

export const FormSubmissionWorkers = React.forwardRef<HTMLDivElement, FormSubmissionWorkersProps>(
  (props, ref): React.ReactElement => {
    const { value, onChange, jobsiteFormSubmissionId, associationType } = props;
    const {
      value: edit,
      setValue: openModal,
      resetValue: closeModal,
    } = useResettableState<Change<EditableEnhancedFormSubmissionWorker>>(null, null);

    const { data, loading } = useGetFormSubmissionJobsiteWorkersQuery({
      fetchPolicy: 'no-cache',
      skip: !jobsiteFormSubmissionId,
      variables: { id: jobsiteFormSubmissionId },
    });

    const { jobsiteId } = data?.getJobsiteFormSubmission.jobsiteForm.jobsite ?? {};

    const jfsWorkersById = Object.fromEntries(
      data?.getJobsiteFormSubmission.jobsiteWorkers.edges.map(({ node }) => [
        node.id,
        toEditableJobsiteFormSubmissionWorker({ jfsWorker: node }),
      ]) ?? [],
    );
    const jfsWorkers: EditableEnhancedFormSubmissionWorker[] = loading
      ? []
      : value
          .map((v) => {
            if (v.changeType === 'created') {
              return v as EditableEnhancedFormSubmissionWorker;
            }
            const jfsWorker = jfsWorkersById[v.id];
            return (
              jfsWorker && {
                ...jfsWorker,
                ...v,
                jobsiteWorker: { ...jfsWorker.jobsiteWorker, ...v.jobsiteWorker },
              }
            );
          })
          .filter(Boolean);
    const visibleJfsWorkers = jfsWorkers.filter((jfsW) => jfsW.changeType !== 'removed');

    const updateValueAndCloseModal = React.useCallback(
      (jfsWorker: EditableEnhancedFormSubmissionWorker, editType: EditType): void => {
        const { workerId } = jfsWorker.jobsiteWorker.contractorWorker.worker;
        let newValue: EditableFormSubmissionWorker[];
        if (editType === 'create') {
          const existingJfsW = jfsWorkers?.find((jfsW) => {
            return jfsW.jobsiteWorker.contractorWorker.worker.workerId === workerId;
          });
          // if jfsW has been previously removed then it will be updated
          if (existingJfsW?.changeType === 'removed') {
            newValue = value.map((jfsW) =>
              // existing jfsW id is preserved
              jfsW.id !== existingJfsW.id ? jfsW : { ...jfsW, ...jfsWorker, id: jfsW.id, changeType: 'updated' },
            );
          } else if (existingJfsW) {
            AlertService.alert('danger', '', 'Worker has already been assigned!');
          } else {
            // add new participant
            newValue = [...value, { ...jfsWorker, changeType: 'created', associationType }];
          }
        } else {
          newValue = value.map((jfsW) => (jfsW.id !== jfsWorker.id ? jfsW : { ...jfsW, ...jfsWorker }));
        }

        if (newValue) {
          onChange?.(newValue);
        }

        closeModal();
      },
      [edit, onChange, value],
    );

    const removeParticipant = (jfsWorker: EditableEnhancedFormSubmissionWorker): void => {
      const newValue: EditableFormSubmissionWorker[] =
        jfsWorker.changeType === 'created'
          ? value.filter((p) => p.id !== jfsWorker.id)
          : value.map((p) => (p.id !== jfsWorker.id ? p : { ...p, changeType: 'removed' }));

      onChange?.(newValue);
    };

    const workersList = (visibleJfsWorkers.length || null) && (
      <div className="odin-flex odin-flex-col odin-space-y-4">
        {visibleJfsWorkers.map((jfsWorker) => {
          return (
            <FormSubmissionWorker
              key={jfsWorker.jobsiteWorker.contractorWorker.worker.workerId}
              jfsWorker={jfsWorker}
              onRemoveIconClick={(): void => removeParticipant(jfsWorker)}
              onClick={(): void => openModal({ type: 'update', item: jfsWorker })}
            />
          );
        })}
      </div>
    );

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-space-y-9">
          <Button
            text="Add Worker to Report"
            theme="dashed"
            className="odin-w-full odin-justify-center odin-h-20"
            icon={UserHardHatIcon}
            onClick={(): void => openModal({ type: 'create' })}
          />
          {workersList}
        </div>
        <EditFormSubmissionWorkerModal
          isOpen={!!edit}
          closeModal={closeModal}
          onConfirm={updateValueAndCloseModal}
          jobsiteId={jobsiteId}
          jobsiteFormSubmissionId={jobsiteFormSubmissionId}
          jfsWorker={edit?.item}
          associationType={associationType}
        />
      </>
    );
  },
);
