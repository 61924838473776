import React from 'react';
import { getFaIcon } from '@odin-labs/components';
import { faBars } from 'components/icons';
import { navbarStyles } from './NavbarContainer.style';

const MobileMenuIcon = getFaIcon({ icon: faBars, className: navbarStyles.mobileMenuIcon });

type NavbarContainerProps = React.PropsWithChildren<{
  openSidebar: () => void;
}>;

export function NavbarContainer(props: NavbarContainerProps): React.ReactElement {
  const { openSidebar, children } = props;
  return (
    <header className={navbarStyles.header}>
      <button className={navbarStyles.mobileMenuButton} onClick={openSidebar}>
        <MobileMenuIcon aria-hidden="true" />
        <span className={navbarStyles.mobileMenuText}>Menu</span>
      </button>
      <div className={navbarStyles.bodyContainer}>{children}</div>
    </header>
  );
}
