import React from 'react';
import { Button, ButtonProps } from '@odin-labs/components';
import { CardTitle } from 'components/reactstrap';

export type WorkerAdminActionProps = React.PropsWithChildren<
  {
    label: string;
  } & Pick<ButtonProps, 'text' | 'onClick' | 'theme'>
>;

export function WorkerAdminAction(props: WorkerAdminActionProps): React.ReactElement {
  const { children, label, text, theme = 'secondary', onClick } = props;
  return (
    <div>
      <CardTitle as="h4" className="odin-mb-3">
        {label}
      </CardTitle>
      <div className="odin-flex odin-items-center">
        <Button theme={theme} text={text} onClick={onClick} />
        {children}
      </div>
    </div>
  );
}
