import React from 'react';
import moment, { Moment } from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { useGetJobsitesAndContractorsQuery } from 'apollo/generated/client-operations';
import { JobsiteContractor } from 'containers/jobsite/types';
import { FormInputTypes, GridColSpan, UseInputs, TypedFormInputs, UseFormMethods } from 'components/form';
import { BriefcaseIcon, EnvelopeIcon, WrenchIcon } from 'components/icons';
import { stringifyEmptyFields } from 'utils';
import { emailValidation, phoneNumberValidation } from 'utils/validation';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { JobsiteOptionElement } from './types';

export type EditContractorAssignmentFormData = {
  jobsiteId: JobsiteOptionElement;
  contractorIds: SelectOptionElement[];
  startDate: Moment;
  parentContractorId: SelectOptionElement;

  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;
};

export type EditContractorAssignmentInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  jobsiteId: string;
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: JobsiteOptionElement[];
  editType: 'create' | 'update';
};

export type EditContractorAssignmentFormElements = EditContractorAssignmentFormData & {
  contactInfoLabel: undefined;
};

type TypedFormInputsArgs = Pick<
  EditContractorAssignmentInputsArgs,
  'editType' | 'contractorsOptions' | 'jobsitesOptions'
> & {
  parentContractorsOptions: SelectOptionElement[];
  minStartDate: Moment;
};

const getFormInputs = ({
  editType,
  contractorsOptions,
  jobsitesOptions,
  parentContractorsOptions,
  minStartDate,
}: TypedFormInputsArgs): TypedFormInputs<EditContractorAssignmentFormElements> => ({
  jobsiteId: {
    element: FormInputTypes.Select,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsitesOptions,
      icon: BriefcaseIcon,
      disabled: (jobsitesOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  contractorIds: {
    element: FormInputTypes.Select,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      icon: WrenchIcon,
      disabled: editType === 'update' || (contractorsOptions?.length ?? 0) < 2,
      multiple: true,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  startDate: {
    element: FormInputTypes.DatePicker,
    label: 'Start Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
      minDate: minStartDate,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  parentContractorId: {
    element: FormInputTypes.Select,
    label: 'Parent Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: parentContractorsOptions,
      icon: WrenchIcon,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  contactInfoLabel: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: <h3 className="odin-pt-6 odin-border-t odin-border-gray-200">Jobsite Contact Info</h3>,
    },
    layout: [GridColSpan.SpanFull],
  },
  firstName: {
    element: FormInputTypes.Field,
    label: 'First Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  lastName: {
    element: FormInputTypes.Field,
    label: 'Last Name',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  title: {
    element: FormInputTypes.Field,
    label: 'Job Title',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  email: {
    element: FormInputTypes.Field,
    label: 'Email Address',
    elementProps: {
      icon: EnvelopeIcon,
    },
    validation: {
      pattern: emailValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  phoneNumber: {
    element: FormInputTypes.Field,
    label: 'Phone Number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      pattern: phoneNumberValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export const useParentContractorsOptions = (contractorIds: string[], jobsiteId: string): SelectOptionElement[] => {
  const { data } = useGetJobsitesAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
    variables: { userJobsitesInput: { jobsiteIds: [jobsiteId], includeAllJobsites: true } },
  });

  return React.useMemo(() => {
    if (!data) return [];

    const jobsite = data.getCurrentSession.user.jobsites.edges[0]?.node;
    const contractors = jobsite?.jobsiteContractors.edges
      .map(({ node: { contractor } }) => contractor)
      .filter((c) => !contractorIds?.includes(c.contractorId));

    return getContractorsOptions(contractors);
  }, [data, contractorIds]);
};

export const getFormInputsHook =
  (
    args: Omit<EditContractorAssignmentInputsArgs, 'jobsiteContractor'>,
  ): UseInputs<EditContractorAssignmentFormElements> =>
  ({
    watch,
    setValue,
  }: UseFormMethods<EditContractorAssignmentFormElements>): TypedFormInputs<EditContractorAssignmentFormElements> => {
    const { jobsiteId, contractorsOptions, jobsitesOptions, editType } = args;
    const selectedContractorOptions = watch('contractorIds');
    const parentContractorOption = watch('parentContractorId');

    const selectedContractorIds = selectedContractorOptions?.map(({ value }) => value);
    const selectedJobsiteStartDates = selectedContractorOptions?.map(({ startDate }) => startDate ?? null);
    const maxStartDate = selectedJobsiteStartDates?.reduce((result, date) => (result > date ? result : date), null);

    const parentContractorsOptions = useParentContractorsOptions(selectedContractorIds, jobsiteId);

    useDidUpdateEffect(() => {
      if (
        parentContractorOption &&
        !parentContractorsOptions?.some((pco) => pco.value === parentContractorOption.value)
      )
        setValue('parentContractorId', null);
    }, [selectedContractorIds]);

    return React.useMemo(
      () =>
        getFormInputs({
          editType,
          contractorsOptions,
          jobsitesOptions,
          parentContractorsOptions,
          minStartDate: maxStartDate && moment(maxStartDate),
        }),
      [editType, contractorsOptions, jobsitesOptions, parentContractorsOptions, maxStartDate],
    );
  };

export const getDefaultValues = (
  args: Omit<EditContractorAssignmentInputsArgs, 'editType'> & { parentContractorsOptions: SelectOptionElement[] },
): EditContractorAssignmentFormElements => {
  const { jobsiteId, jobsiteContractor, contractorsOptions, parentContractorsOptions, jobsitesOptions } = args;
  const { contractor, parentJobsiteContractor, contact, startDate } = jobsiteContractor ?? {};
  const { contractorId } = contractor ?? {};
  const { contractorId: parentContractorId } = parentJobsiteContractor?.contractor ?? {};
  const { firstName, lastName, title, email, phoneNumber } = contact ?? {};

  return {
    jobsiteId: jobsitesOptions.find(({ value }) => value === jobsiteId) ?? null,
    contractorIds: (contractorId && contractorsOptions?.filter(({ value }) => value === contractorId)) ?? null,
    startDate: startDate ? moment(startDate) : null,
    parentContractorId: parentContractorsOptions?.find(({ value }) => value === parentContractorId) ?? null,
    contactInfoLabel: undefined,

    ...stringifyEmptyFields({ firstName, lastName, title, email, phoneNumber }),
  };
};
