import React from 'react';
import cn from 'classnames';
import { MomentInput } from 'moment';
import { getISODateTimeToFormattedDate } from 'utils/dates';

export const classes = {
  siteAccessText: cn('odin-text-1.5xs odin-text-odin-dark-gray'),
};

type SiteAccessTextProps = {
  hasAccess: boolean;
  endDate: MomentInput;
};

export function SiteAccessText({ hasAccess, endDate }: SiteAccessTextProps): React.ReactElement {
  const endDateText = endDate && getISODateTimeToFormattedDate(endDate);
  const siteAccessText = endDateText && `Site access ${hasAccess ? 'expires' : 'expired'} on ${endDateText}`;
  return siteAccessText ? <p className={classes.siteAccessText}>{}</p> : null;
}
