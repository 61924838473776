import { GetWorkerDataDocument, useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import React, { ReactElement, useState } from 'react';
import { getGraphQLError } from 'utils/error';
import { useIsMounted } from 'utils';
import { FormOnSubmit, ModalForm } from 'components/form';
import { DocumentExemptionModalProps } from './types';
import { DocumentExemptionFormData, getDefaultValues, getFormInputsHook } from './DocumentExemptionModal.forms';

export function DocumentExemptionModal(props: DocumentExemptionModalProps): ReactElement {
  const { isOpen, closeModal, onClosed, jobsiteWorker } = props;
  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    refetchQueries: [GetWorkerDataDocument],
  });

  const onSubmit: FormOnSubmit<DocumentExemptionFormData> = async (data, event): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    const { exemptionType, exemptionReason } = data;

    try {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId: jobsiteWorker.jobsiteWorkerId,
          jobsiteWorkerInput: {
            siteSpecificTrainingSkipReason:
              exemptionType.value === 'siteSpecificTrainingSkipReason' ? exemptionReason : null,
            nycSstExemptionReason: exemptionType.value === 'nycSstExemptionReason' ? exemptionReason : null,
            documentExemptionReason: exemptionType.value === 'documentExemptionReason' ? exemptionReason : null,
          },
        },
      });

      // we don't need to call closeModal() here, because the modal will be closed by the parent component
      // which gets re-rendered after the mutation is completed and GetWorkerDataDocument is re-fetched
      // closeModal();
      AlertService.alert('success', 'Success', 'Worker exemption successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = getFormInputsHook();
  const defaultValues = React.useMemo(() => getDefaultValues({ jobsiteWorker }), [jobsiteWorker]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      onIsDirtyChange={setIsFormDirty}
      size="sm"
      title="Update worker exemptions"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save"
      actionButtonEnabled={isFormDirty}
      actionButtonWithSpinner={isFetching}
    />
  );
}
