import { Alert } from '@odin-labs/components';
import cn from 'classnames';
import { FormInputTypes, GridColSpan, RadioGroupFormOption, TypedFormInputs } from 'components/form';
import {
  AnnouncementScheduleType,
  CreateAnnouncementData,
  ScheduleAnnouncementFormData,
} from 'containers/announcements/types';
import moment from 'moment/moment';
import React from 'react';
import { momentISODateFormatter, momentTimeFormatter } from 'utils/dates';
import { timeValidation } from 'utils/validation';

const schedulingOptions: RadioGroupFormOption<
  Pick<ScheduleAnnouncementFormData, 'startDate' | 'startTime' | 'timeZone'>,
  AnnouncementScheduleType
>[] = [
  {
    label: AnnouncementScheduleType.Immediate.toString(),
    value: AnnouncementScheduleType.Immediate,
  },
  {
    label: AnnouncementScheduleType.Scheduled.toString(),
    value: AnnouncementScheduleType.Scheduled,
    description: (
      <div className="odin-w-full">
        <Alert text="Any filters you set will be applied to workers on your jobsite at the time of sending." />
      </div>
    ),
    inputsContainerLayout: cn('odin-flex odin-flex-col sm:odin-flex-row odin-gap-y-3 odin-gap-x-2 odin-mt-3'),
    inputs: [
      {
        name: 'startDate',
        element: FormInputTypes.DatePicker,
        label: `Delivery Date`,
        elementProps: {
          maxDate: moment().add(1, 'month'),
          minDate: moment(),
          showDefaultIcon: true,
          numberOfMonths: 1,
        },
        validation: {
          required: false,
        },
        layout: cn('odin-w-full sm:odin-w-1/3'),
      },
      {
        name: 'startTime',
        element: FormInputTypes.Field,
        label: 'Delivery time',
        elementProps: {
          placeholder: 'hh:mm',
          fieldType: 'time',
          showDefaultIcon: true,
        },
        validation: {
          pattern: {
            value: timeValidation,
            message: 'Must provide valid time.',
          },
          required: true,
        },
        layout: cn('odin-w-full sm:odin-w-1/3'),
      },
      {
        name: 'timeZone',
        element: FormInputTypes.Field,
        label: 'Time Zone',
        elementProps: {
          disabled: true,
        },
        layout: cn('odin-w-full sm:odin-w-1/3'),
      },
    ],
  },
];

export const getFormInputs = (): TypedFormInputs<ScheduleAnnouncementFormData> => {
  return {
    scheduleType: {
      element: FormInputTypes.RadioGroup,
      label: `When should this announcement be sent out?`,
      elementProps: {
        options: schedulingOptions,
      },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getDefaultValues = (announcement: CreateAnnouncementData): ScheduleAnnouncementFormData => {
  const { startAt, timeZone } = announcement ?? {};
  const defaultDate = startAt
    ? moment(startAt).tz(timeZone)
    : moment(Date.now()).tz(timeZone).add(1, 'hour').add(2, 'minute');

  const startDate = defaultDate.format(momentISODateFormatter);
  const startTime = defaultDate.format(momentTimeFormatter);

  return {
    scheduleType: {
      value: startAt ? AnnouncementScheduleType.Scheduled : AnnouncementScheduleType.Immediate,
      label: null,
    },
    startDate,
    startTime,
    timeZone,
  };
};
