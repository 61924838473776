import { DeepMap } from 'react-hook-form';
import {
  ChangeType,
  GeofencingEnforcementType,
  JobsiteAppCheckInModuleInput,
} from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { SiteAreas } from 'containers/jobsiteConfiguration/components';
import { getAppCheckInModule } from 'containers/jobsiteConfiguration/helpers/utils';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { ensureNonUndefinedFields, getSelectOptionsFromStringEnum } from 'utils';

export const geofencingEnforcementTypeOptions = getSelectOptionsFromStringEnum(GeofencingEnforcementType, [
  GeofencingEnforcementType.None,
  GeofencingEnforcementType.Hard,
]);

export const geofencingSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['geofencing']> = {
  siteAreas: {
    element: FormInputTypes.CustomInput,
    elementProps: { customInput: SiteAreas },
    layout: [GridColSpan.SpanFull],
  },
  geofencingEnforcementType: {
    element: FormInputTypes.Select,
    label: 'Enforcement Type',
    elementProps: {
      options: geofencingEnforcementTypeOptions,
      isClearable: false,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  crewGeofencingEnforcementType: {
    element: FormInputTypes.Select,
    label: 'Crew Enforcement Type',
    elementProps: {
      options: geofencingEnforcementTypeOptions,
      isClearable: false,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
};

export const getGeofencingSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['geofencing'] => {
  const appCheckInModule = getAppCheckInModule(jobsite?.modules);
  const { areas, geofencingEnforcementType, crewGeofencingEnforcementType } = appCheckInModule ?? {};

  return {
    siteAreas:
      areas?.map(({ id, name, checkInRadius, checkInCoordinate }) => ({
        id,
        name,
        radius: checkInRadius,
        latitude: checkInCoordinate?.lat,
        longitude: checkInCoordinate?.lng,
      })) ?? [],
    geofencingEnforcementType:
      geofencingEnforcementTypeOptions.find((option) => option.value === geofencingEnforcementType) ??
      geofencingEnforcementTypeOptions[0],
    crewGeofencingEnforcementType:
      geofencingEnforcementTypeOptions.find((option) => option.value === crewGeofencingEnforcementType) ??
      geofencingEnforcementTypeOptions[0],
  };
};

type GeofencingSectionUpdateInput = Required<
  Pick<JobsiteAppCheckInModuleInput, 'areas' | 'geofencingEnforcementType' | 'crewGeofencingEnforcementType'>
>;

export const getGeofencingSectionUpdateInput = (
  geofencing: EditJobsiteConfigurationFormData['geofencing'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['geofencing'], true>,
): GeofencingSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(geofencing, dirtyFields);

  const areas: JobsiteAppCheckInModuleInput['areas'] = getUpdateInputValue('siteAreas')
    ?.filter((a) => a.changeType)
    .map((a) => ({
      id: a.id,
      name: a.name,
      checkInRadius: a.radius,
      checkInCoordinate: {
        lat: a.latitude,
        lng: a.longitude,
      },
      isInboundEnabled: true,
      isOutboundEnabled: true,
      changeType: a.changeType as ChangeType,
    }));

  return ensureNonUndefinedFields<GeofencingSectionUpdateInput>({
    areas,
    geofencingEnforcementType: getUpdateInputValue('geofencingEnforcementType'),
    crewGeofencingEnforcementType: getUpdateInputValue('crewGeofencingEnforcementType'),
  });
};
