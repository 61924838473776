import React from 'react';
import cn from 'classnames';
import { MomentInput } from 'moment';

import { getISODateTimeToFormattedDate } from 'utils/dates';

export const classes = {
  siteAccessText: cn('odin-text-1.5xs odin-text-odin-dark-gray'),
};

export function SiteStatusText({ isActive, endDate }: { isActive: boolean; endDate: MomentInput }): React.ReactElement {
  const endDateText = getISODateTimeToFormattedDate(endDate);
  const siteStatusText = endDateText && `${isActive ? 'Planned completion by' : 'Completed on'} ${endDateText}`;
  return siteStatusText ? <p className={classes.siteAccessText}>{siteStatusText}</p> : null;
}
