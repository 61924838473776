import React from 'react';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  MenuItemProps,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { ensureNonEmptyItems, getFormattedPhoneNumber, getInitialsForUser } from 'utils';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import { faUserTimes } from 'components/icons';
import { DateTimeCell, DateTimeCellData } from 'containers/jobsiteAccessActivity/helpers/cells';
import { GetUsersOrderByField } from 'apollo/generated/client-operations';
import { JobsiteMember, JobsiteUserColumn, JobsiteUserFilters } from './types';

const UserTimesIcon = getFixedWidthFaIcon({ icon: faUserTimes });

export const getColumns = ({
  user,
  jobsiteId,
  openRemoveJobsiteUserModal,
}: {
  user: AuthUser;
  jobsiteId: string;
  openRemoveJobsiteUserModal: (args: { jobsiteMemberId: string; memberUserAccountId: string }) => void;
}): JobsiteUserColumn[] => {
  const canRemoveJobsiteUsers = user.isAllowed(to.removeJobsiteUsers, jobsiteId);
  const isActionsButtonVisible = canRemoveJobsiteUsers;

  return ensureNonEmptyItems<JobsiteUserColumn>([
    {
      id: 'user',
      Header: 'User',
      accessor: (jobsiteMember: JobsiteMember): string => {
        const { firstName, lastName } = jobsiteMember.user.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (jobsiteMember: JobsiteMember): AvatarCellComponentProps => ({
        src: jobsiteMember.user?.worker?.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(jobsiteMember.user),
        objectFit: 'cover',
        size: 'xs',
      }),
    },
    {
      id: 'email',
      Header: <>Email</>,
      accessor: (jobsiteMember: JobsiteMember): string => jobsiteMember.user.identity.email,
    },
    {
      id: 'phoneNumber',
      Header: 'Phone Number',
      accessor: (jobsiteMember: JobsiteMember): string =>
        getFormattedPhoneNumber(jobsiteMember.user.identity.phoneNumber),
    },
    {
      id: 'role',
      Header: 'Role',
      // cellClassName: cn('odin-whitespace-pre-wrap'),
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (jobsiteMember: JobsiteMember): ChipsCellComponentProps => ({
        chips: jobsiteMember.user.roles.map((role) => ({
          text: role.object.objectDetails.name,
          secondaryText: role.displayName,
          withDot: false,
        })),
      }),
      disableSortBy: true,
    },
    {
      id: 'lastSessionDate',
      Header: 'Last Session',
      accessor: (jobsiteMember: JobsiteMember): DateTimeCellData => {
        const { createdAt } = jobsiteMember.user.lastSession ?? {};
        const timeZoneName = ''; // moment.tz(createdAt, timeZone).zoneAbbr();
        return createdAt && { date: new Date(createdAt), timeZoneName };
      },
      Cell: DateTimeCell,
    },
    {
      id: 'lastSessionVersion',
      Header: 'Odin version',
      accessor: (jobsiteMember: JobsiteMember): string => jobsiteMember.user.lastSession?.appVersion,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (jobsiteMember: JobsiteMember): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<MenuItemProps>([
          canRemoveJobsiteUsers && {
            icon: UserTimesIcon,
            text: 'Remove User Role',
            theme: 'danger',
            onClick: (): void =>
              openRemoveJobsiteUserModal({
                jobsiteMemberId: jobsiteMember.id,
                memberUserAccountId: jobsiteMember.user.userAccountId,
              }),
          },
        ]),
      }),
    },
  ]);
};

export const getFilterItems = ({ search }: { search: string }): FilterItems<JobsiteUserFilters> => ({
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

export const orderByFields: Record<string, GetUsersOrderByField> = {
  user: GetUsersOrderByField.Name,
  email: GetUsersOrderByField.Email,
  phoneNumber: GetUsersOrderByField.PhoneNumber,
  lastSessionDate: GetUsersOrderByField.LastActiveAt,
  lastSessionVersion: GetUsersOrderByField.AppVersion,
};
