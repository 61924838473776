import React, { ReactElement } from 'react';
import { Button } from '@odin-labs/components';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { MedicalDocumentCardProps } from 'components/cards/types';
import { ObjectHistory } from 'components/objectHistory';
import { Status } from 'components/status';
import { Thumbnail } from 'components/thumbnail';
import { Card, CardBody } from 'components/reactstrap';
import { DownloadIcon } from 'components/icons';
import { DocumentKey } from 'containers/worker/utils';

export function MedicalDocumentCard({
  medicalDocumentVersion,
  documentTypeKey,
}: MedicalDocumentCardProps): ReactElement {
  const getTestResult = (): ReactElement => {
    if (
      documentTypeKey === DocumentKey.MedicalDrugTestingConsentForm ||
      documentTypeKey === DocumentKey.MedicalCovid19OrientationAndProcedures
    ) {
      return null;
    }

    const result: string = medicalDocumentVersion?.additionalFieldValues?.find(
      (fieldValue: { key: string }) => fieldValue?.key === 'result',
    )?.value;
    const expirationDate: string = medicalDocumentVersion?.additionalFieldValues?.find(
      (fieldValue: { key: string }) => fieldValue?.key === 'conditional-access-expiration-date',
    )?.value;

    if (result === 'Pass' || result === 'No pass') {
      return <Status label={result} status={result === 'Pass' ? 'success' : 'danger'} />;
    }

    const label = (
      <div>
        <div className="odin-mb-1 font-size-base">Conditional Pass</div>
        <div className="font-size-sm">{getISODateTimeToFormattedDate(expirationDate)}</div>
      </div>
    );

    return <Status label={label} status="warning" />;
  };

  const [file] = medicalDocumentVersion?.files ?? [];
  return (
    <Card className="odin-mb-1">
      <CardBody className="odin-flex odin-flex-col odin-gap-y-1.5">
        <div>{getTestResult()}</div>
        <div>
          <Thumbnail file={file} />
        </div>
        <Button
          size="xs"
          className="odin-mt-1.5 odin-w-full odin-justify-center"
          onClick={(): void => {
            window.open(file?.downloadUrl);
          }}
          icon={DownloadIcon}
          text="Download"
        />
        <div className="odin-text-xs">
          <ObjectHistory object={medicalDocumentVersion} />
        </div>
      </CardBody>
    </Card>
  );
}
