import React from 'react';
import { to } from 'acl';
import { Link, useParams } from 'react-router-dom';
import { Button, TabConfig } from '@odin-labs/components';
import { AuthContext } from 'auth';
import { LoadingError } from 'components/loadingError';
import { BanReasonModal } from 'components/modals/BanReasonModal';
import { JobsiteBan, WorkerProfileBanner } from 'containers/worker/helpers/WorkerProfileBanner';

import { ensureDistinctItems, ensureNonEmptyItems, getFullNameForUser, useBoolean, useModalState } from 'utils';
import { convertUTCtoLocalDate } from 'utils/dates';

import { AuthUser } from 'auth/types';
import { Grid2Icon, LockKeyholeIcon, PrintIcon } from 'components/icons';
import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { HeaderAction } from 'components/header/types';
import { JobsiteWorker, WorkerTabApi, WorkerTabProps } from './types';
import { AssignWorkerModal, ChangeWorkerAccessModal } from './modals';
import {
  WorkerOverview,
  WorkerAssignments,
  WorkerDocuments,
  WorkerMedical,
  WorkerBadges,
  WorkerAccessActivity,
  WorkerTimecards,
  WorkerComments,
  WorkerAdminActions,
  WorkerUserRoles,
  WorkerFormSubmissions,
} from './tabs';
import { useWorkerData } from './data/useWorkerData';
import { WorkerTitle } from './components';

const getTabsConfig = (user: AuthUser): TabConfig<WorkerTabProps>[] =>
  ensureNonEmptyItems<TabConfig<WorkerTabProps>>([
    user.isAllowed(to.seeWorkerProfileOverview) && {
      name: `overview`,
      text: 'Overview',
      relativePath: '',
      component: WorkerOverview,
    },
    user.isAllowed(to.seeWorkerProfileJobsites) && {
      name: 'jobsites',
      text: 'Jobsites',
      relativePath: '/jobsites',
      component: WorkerAssignments,
    },
    user.isAllowed(to.seeWorkerProfileDocuments) && {
      name: `documents`,
      text: 'Documents',
      relativePath: '/documents',
      component: WorkerDocuments,
    },
    user.isAllowed(to.seeWorkerProfileMedical) && {
      name: `medical`,
      text: 'Medical',
      relativePath: '/medical',
      component: WorkerMedical,
    },
    user.isAllowed(to.seeWorkerProfileBadges) && {
      name: `badges`,
      text: 'Badges',
      relativePath: '/badges',
      leftIcon: user.hasPaidAccess ? undefined : LockKeyholeIcon,
      component: WorkerBadges,
    },
    user.isAllowed(to.seeWorkerProfileAccessActivity) && {
      name: `accessActivity`,
      text: 'Access Activity',
      relativePath: '/access-activity',
      leftIcon: user.hasPaidAccess ? undefined : LockKeyholeIcon,
      component: WorkerAccessActivity,
    },
    user.isAllowed(to.seeWorkerProfileTimecards) && {
      name: `timeCards`,
      text: 'Timecards',
      relativePath: '/timecards',
      leftIcon: user.hasPaidAccess ? undefined : LockKeyholeIcon,
      component: WorkerTimecards,
    },
    user.isAllowed(to.seeWorkerProfileFormSubmissions) && {
      name: `formSubmissions`,
      text: 'Forms',
      relativePath: '/forms',
      leftIcon: user.hasPaidAccess ? undefined : LockKeyholeIcon,
      component: WorkerFormSubmissions,
    },
    user.isAllowed(to.seeWorkerProfileComments) && {
      name: `comments`,
      text: 'Comments',
      relativePath: '/comments',
      component: WorkerComments,
    },
    user.isAllowed(to.seeWorkerProfileActions) && {
      name: `actions`,
      text: 'Actions',
      relativePath: '/actions',
      component: WorkerAdminActions,
    },
    user.isAllowed(to.seeWorkerProfileUserRoles) && {
      name: `userRoles`,
      text: 'User Roles',
      relativePath: '/user-roles',
      component: WorkerUserRoles,
    },
  ]);

export function WorkerContainer(): React.ReactElement {
  const { workerId } = useParams<{ workerId: string }>();
  const baseRoute = `/worker/${workerId}`;

  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: isAssignWorkerModalOpen,
    setTrue: openAssignWorkerModal,
    setFalse: closeAssignWorkerModal,
  } = useBoolean(false);

  const {
    value: jobsiteWorkerToChangeAccessFor,
    isModalOpen: isChangeWorkerAccessModalOpen,
    openModal: openChangeWorkerAccessModal,
    closeModal: closeChangeWorkerAccessModal,
    resetModalValue: resetJobsiteWorkerToChangeAccessFor,
  } = useModalState<JobsiteWorker>(null);

  const {
    value: jobsiteBanToShowReasonFor,
    isModalOpen: isBanReasonModalOpen,
    openModal: openBanReasonModal,
    closeModal: closeBanReasonModal,
    resetModalValue: resetJobsiteBanToShowReasonFor,
  } = useModalState<JobsiteBan>(null);

  const {
    loading,
    error,
    worker,
    isLocked,
    jobsiteWorkers,
    jobsiteIds,
    developerIds,
    jobsiteNames,
    refetch,
    updateWorkerState,
  } = useWorkerData(workerId);

  const tabsConfig = getTabsConfig(user);
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const jobsiteDocumentConditionalPasses = ensureDistinctItems(
    jobsiteWorkers
      .filter((jw) => jw.documentsCompletedSkipExpiresAt)
      .map((jw) => convertUTCtoLocalDate(jw.documentsCompletedSkipExpiresAt)),
  );

  const jobsiteMedicalConditionalPasses = ensureDistinctItems(
    jobsiteWorkers
      .filter((jw) => jw.urineTestConditionalPassExpiresAt)
      .map((jw) => convertUTCtoLocalDate(jw.urineTestConditionalPassExpiresAt)),
  );

  const jobsiteBans = jobsiteWorkers
    .filter((jw) => jw.isBanned)
    .map((jobsiteWorker) => {
      return {
        jobsiteWorker,
        jobsiteName: jobsiteWorker.jobsiteContractor?.jobsite?.name,
        workerName: getFullNameForUser(worker.user),
      };
    });

  const getWorkersHeaderActions = (): React.ReactElement => {
    const content = ensureNonEmptyItems([
      !isLocked && !!jobsiteWorkers?.length && (
        <Button
          key="print"
          theme="secondary"
          text="Print View"
          hideTextOnMobile
          icon={PrintIcon}
          as={Link}
          to={`${baseRoute}/print`}
        />
      ),
      !isLocked && !worker?.isArchived && !user.isContractor && user.isAllowed(to.assignWorkers) && (
        <Button
          key="assign-worker"
          text="Assign Worker"
          hideTextOnMobile
          icon={Grid2Icon}
          onClick={openAssignWorkerModal}
        />
      ),
    ]);
    return content.length ? <div className="odin-flex odin-gap-x-3">{content}</div> : null;
  };

  const [tabApi, setTabApi] = React.useState<WorkerTabApi>(null);

  const tabsPageProps: WorkerTabProps = {
    loading,
    worker,
    isLocked,
    jobsiteWorkers,
    jobsiteIds,
    developerIds,
    jobsiteNames,
    openChangeWorkerAccessModal,
    refetchWorkerData: refetch,
    onTabApiChange: setTabApi,
    updateWorkerState,
  };

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  return (
    <Container className="worker-container">
      <WorkerProfileBanner
        worker={worker}
        jobsiteBans={jobsiteBans}
        jobsiteDocumentConditionalPasses={jobsiteDocumentConditionalPasses}
        jobsiteMedicalConditionalPasses={jobsiteMedicalConditionalPasses}
        openChangeWorkerAccessModal={openChangeWorkerAccessModal}
        openBanReasonModal={openBanReasonModal}
      />
      <NewHeader
        title={<WorkerTitle worker={worker} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          baseRoute,
          headerActions: ensureNonEmptyItems<HeaderAction>([
            'reload',
            user.isAllowed(to.seeChanges) ? 'history' : null,
          ]),
          children: getWorkersHeaderActions(),
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      {worker?.isArchived ? null : (
        <AssignWorkerModal
          isOpen={isAssignWorkerModalOpen}
          closeModal={closeAssignWorkerModal}
          selectedWorker={worker}
          jobsiteWorkers={jobsiteWorkers}
        />
      )}
      <BanReasonModal
        isOpen={isBanReasonModalOpen}
        closeModal={closeBanReasonModal}
        onClosed={resetJobsiteBanToShowReasonFor}
        jobsiteBan={jobsiteBanToShowReasonFor}
      />
      <ChangeWorkerAccessModal
        isOpen={isChangeWorkerAccessModalOpen}
        closeModal={closeChangeWorkerAccessModal}
        onClosed={resetJobsiteWorkerToChangeAccessFor}
        worker={worker}
        jobsiteWorker={jobsiteWorkerToChangeAccessFor}
      />
    </Container>
  );
}
