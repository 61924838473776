import React, { ReactElement, useState } from 'react';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { AlertService } from 'components/alertService';
import { TrashIcon } from 'components/icons';
import { Modal } from '@odin-labs/components';
import { useArchiveJobsiteWorkerAccessEventMutation } from 'apollo/generated/client-operations';
import { ArchiveJobsiteAccessEventModalProps } from './types';

export function ArchiveJobsiteAccessEventModal(props: ArchiveJobsiteAccessEventModalProps): ReactElement {
  const { jobsiteWorkerCheckInId, accessEventId, sessionDate, isOpen, closeModal: onCancel, onConfirm } = props;
  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [archiveJobsiteWorkerAccessEvent] = useArchiveJobsiteWorkerAccessEventMutation();

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await archiveJobsiteWorkerAccessEvent({ variables: { input: { accessEventId, jobsiteWorkerCheckInId } } });
      AlertService.alert('success', 'Success', 'A jobsite access event was successfully archived.');

      // delay the refresh with 2 seconds as the swipeEvents deletion
      // is done asynchronously within the pubsub process
      setTimeout(() => {
        if (isMounted()) setIsSaving(false);
        onConfirm();
      }, 2000);
    } catch (error) {
      if (isMounted()) setIsSaving(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      onAction={onAction}
      size="xs"
      actionText="Archive Event"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={isSaving}
    >
      <h2 className="font-weight-bold odin-mb-1" style={{ textAlign: 'center' }}>
        Archive this Access Event?
      </h2>
      <p style={{ textAlign: 'center', padding: '0 40px' }}>
        <br />
        Archiving will make this event ineligible for Timecard calculations, and will trigger a recalculation of the
        Timecard for the date of {sessionDate}. Are you sure you want to archive this event?
      </p>
    </Modal>
  );
}
