import React from 'react';
import cn from 'classnames';

const classes = {
  row: (extraClasses: string): string => cn(extraClasses, 'reactstrap-row odin-flex odin-flex-wrap'),
};

export type RowProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function Row(props: RowProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.row(className)}>{children}</div>;
}
