import React from 'react';
import { Badge, TabConfig } from '@odin-labs/components';
import { LoadingError } from 'components/loadingError';

import { getFullNameForUser } from 'utils';

import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { useGetChangeWorkerQuery } from 'apollo/generated/client-operations';
import { CardTitle } from 'components/reactstrap';
import { Worker, WorkerTabApi, WorkerChangeTabProps } from './types';
import {
  WorkerOverview,
  WorkerJobsites,
  WorkerDocuments,
  WorkerBadges,
  // WorkerAccessEvents,
  // WorkerComments,
  // WorkerAdminActions,
  WorkerUserRoles,
} from './tabs';

const getTabsConfig = (): TabConfig<WorkerChangeTabProps>[] => [
  {
    name: `overview`,
    text: 'Overview',
    relativePath: '',
    component: WorkerOverview,
  },
  {
    name: 'jobsites',
    text: 'Jobsites',
    relativePath: '/jobsites',
    component: WorkerJobsites,
  },
  {
    name: `documents`,
    text: 'Documents',
    relativePath: '/documents',
    component: WorkerDocuments,
  },
  {
    name: `badges`,
    text: 'Badges',
    relativePath: '/badges',
    component: WorkerBadges,
  },
  // {
  //   name: `accessActivity`,
  //   text: 'Access Activity',
  //   relativePath: '/access-activity',
  //   component: WorkerAccessEvents,
  // },
  // {
  //   name: `comments`,
  //   text: 'Comments',
  //   relativePath: '/comments',
  //   component: WorkerComments,
  // },
  // {
  //   name: `actions`,
  //   text: 'Actions',
  //   relativePath: '/actions',
  //   component: WorkerAdminActions,
  // },
  {
    name: `userRoles`,
    text: 'User Roles',
    relativePath: '/user-roles',
    component: WorkerUserRoles,
  },
];

export function WorkerTitle({ worker }: { worker: Worker }): React.ReactElement {
  return (
    <CardTitle as="h1">
      {getFullNameForUser(worker?.user)}
      <div className="odin-flex odin-gap-x-1.5 odin-mb-1.5 odin-mt-3">
        <Badge className="odin-font-normal" color="indigo" type="rounded" text={worker?.quickCode} size="xl" />
      </div>
    </CardTitle>
  );
}

export function WorkerChangeContainer({ entityId: workerId }: { entityId: string }): React.ReactElement {
  const baseRoute = `/entity-change/worker/${workerId}`;

  const {
    data: workerData,
    error: workerError,
    loading: workerLoading,
    refetch: refetchWorkerData,
  } = useGetChangeWorkerQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !workerId,
    variables: { workerId },
  });
  const worker = workerData?.getWorker;

  const tabsConfig = getTabsConfig();
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const [tabApi, setTabApi] = React.useState<WorkerTabApi>(null);

  const loading = workerLoading;
  const error = workerError;

  const tabsPageProps: WorkerChangeTabProps = {
    loading,
    worker,
    changesLayout: 'horizontal',
    onTabApiChange: setTabApi,
  };

  if (loading || error) {
    return <LoadingError loading={loading} error={error} />;
  }

  return (
    <Container className="worker-change-container odin-pb-6">
      <NewHeader
        title={<WorkerTitle worker={worker} />}
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: async (): Promise<void> => {
            await refetchWorkerData();
            tabApi?.refetchData();
          },
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
    </Container>
  );
}
