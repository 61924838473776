import React, { ReactElement } from 'react';
import { Table, Badge } from '@odin-labs/components';
import { WorkerTabProps } from 'containers/print/types';
import workerImage from 'images/worker.png';
import { Card, CardHeader, CardHeaderTitle, CardItem } from 'components/reactstrap';
import { getFormattedPhoneNumber, getWorkerFullName, getWorkerOrientationStatusBadgeProps, useBoolean } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { EyeIcon, EyeSlashIcon } from 'components/icons';
import { getCommentsColumns, getContractorsColumns } from './WorkerOverview.tables';

export function WorkerOverview(props: WorkerTabProps): ReactElement {
  const { loading, worker, workerComments, jobsiteWorkers, contractorWorkers } = props;
  const { value: isSsnVisible, toggle: toggleSsn } = useBoolean(true);

  const activeJobsiteWorkers = jobsiteWorkers?.filter(({ endDate }) => {
    return endDate == null || new Date(endDate) > new Date();
  });

  const commentsColumns = React.useMemo(() => getCommentsColumns(), []);
  const contractorsColumns = React.useMemo(() => getContractorsColumns(), []);

  const workerAddress = worker?.addressLine1 && (
    <>
      {[worker?.addressLine1, worker?.addressLine2].filter(Boolean).join(', ')}
      <br />
      {[worker?.addressCity, worker?.addressState, worker?.addressZipCode].filter(Boolean).join(', ')}
    </>
  );

  return (
    <div className="odin-flex odin-flex-col md:odin-flex-row odin-gap-6 odin-mb-6">
      <div className="md:odin-w-1/3 odin-flex odin-flex-col odin-gap-y-6">
        <Card className="odin-pt-6">
          <img
            className="odin-m-auto odin-w-1/2"
            src={worker?.profilePictureCropped?.downloadUrl || workerImage}
            alt="Worker"
          />
          <div className="odin-p-6 odin-text-center">
            <CardHeaderTitle as="h2">{getWorkerFullName(worker)}</CardHeaderTitle>
            <p className="odin-text-1.5xs odin-mt-1 text-gray-700">{worker?.trade}</p>
          </div>
        </Card>
        {activeJobsiteWorkers.length ? (
          <Card>
            <CardHeader>
              <CardHeaderTitle as="h4" className="odin-mr-auto">
                Job Site Orientations
              </CardHeaderTitle>
            </CardHeader>
            <div className="odin-p-6">
              {activeJobsiteWorkers?.map((jobsiteWorker) => {
                return (
                  <div key={jobsiteWorker.jobsiteWorkerId}>
                    <CardHeaderTitle as="h2">{jobsiteWorker?.jobsiteContractor?.jobsite?.name}</CardHeaderTitle>
                    <CardHeaderTitle as="h2" className="odin-mt-2">
                      <Badge
                        type="rounded"
                        size="lg"
                        className="!odin-py-1 !odin-px-3"
                        {...getWorkerOrientationStatusBadgeProps(jobsiteWorker?.orientationStatus)}
                      />
                    </CardHeaderTitle>
                    <CardHeaderTitle as="p" className="odin-text-gray-600 odin-mt-2">
                      Orientation Date: {getISODateTimeToFormattedDate(jobsiteWorker?.orientationDate)}
                    </CardHeaderTitle>
                  </div>
                );
              })}
            </div>
          </Card>
        ) : null}
      </div>
      <div className="md:odin-w-2/3 odin-flex odin-flex-col odin-gap-y-6">
        <Card>
          <CardHeader>
            <CardHeaderTitle as="h4" className="odin-mr-auto">
              Personal
            </CardHeaderTitle>
          </CardHeader>
          <div className="md:odin-flex odin-p-6">
            <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
              <CardItem label="Date of Birth" value={getISODateTimeToFormattedDate(worker?.birthDate)} />
              <CardItem
                label={
                  <>
                    <span>Last 4 of SSN</span>
                    <span
                      className="odin-ml-2 odin-text-odin-primary odin-cursor-pointer print:odin-hidden"
                      onClick={toggleSsn}
                    >
                      {isSsnVisible ? <EyeIcon /> : <EyeSlashIcon />}
                    </span>
                  </>
                }
                value={isSsnVisible ? worker?.ssnLastFour : null}
              />
              <CardItem label="Race" value={worker?.race} />
              <CardItem label="Gender" value={worker?.gender} />
              <CardItem label="Primary Language" value={worker?.primaryLanguage} />
              <CardItem label="Veteran Status" value={worker?.isVeteran ? 'Yes' : 'No'} />
            </div>
            <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
              <CardItem label="Address" value={workerAddress} />
              <CardItem label="Phone" value={getFormattedPhoneNumber(worker?.user?.identity?.phoneNumber)} />
              <CardItem label="Email address" value={worker?.user?.identity?.email} />
              <CardItem label="Emergency Contact" value={worker?.emergencyContactName} />
              <CardItem label="Emergency Relationship" value={worker?.emergencyContactRelationship} />
              <CardItem label="Emergency Phone" value={getFormattedPhoneNumber(worker?.emergencyContactPhone)} />
            </div>
          </div>
        </Card>
        <Card>
          <CardHeader>
            <CardHeaderTitle as="h4" className="odin-mr-auto">
              Work
            </CardHeaderTitle>
          </CardHeader>
          <div className="md:odin-flex odin-p-6">
            <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
              <CardItem label="Trade" value={worker?.trade} />
              <CardItem label="Job Title" value={worker?.jobTitle} />
              <CardItem label="Union affiliation" value={worker?.unionAffiliation} />
            </div>
            <div className="md:odin-w-1/2 odin-flex odin-flex-col odin-gap-y-3">
              <CardItem label="Skilled Labor" value={worker?.isSkilled ? 'Yes' : 'No'} />
            </div>
          </div>
        </Card>
        <Card>
          <CardHeader className="!odin-border-b-0">
            <CardHeaderTitle as="h4" className="odin-mr-auto">
              Comments
            </CardHeaderTitle>
          </CardHeader>
          <div className="odin-m-0 odin-p-0">
            <Table
              loading={loading}
              columns={commentsColumns}
              data={workerComments}
              remote
              cellClassName="!odin-pl-5"
              disableGlobalFilter
              disableSortBy
            />
          </div>
        </Card>
        <Card>
          <CardHeader className="!odin-border-b-0">
            <CardHeaderTitle as="h4" className="odin-mr-auto">
              Employer
            </CardHeaderTitle>
          </CardHeader>
          <div className="odin-m-0 odin-p-0">
            <Table
              loading={loading}
              columns={contractorsColumns}
              data={contractorWorkers}
              remote
              disableGlobalFilter
              disableSortBy
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
