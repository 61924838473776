import React from 'react';
import { Moment } from 'moment';
import cn from 'classnames';
import { GetJobsiteFormSubmissionsOrderByField } from 'apollo/generated/client-operations';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  getFixedWidthFaIcon,
  MenuItemProps,
} from '@odin-labs/components';
import { AuthUser, to } from 'acl';
import { getJobsitesOptions } from 'containers/users/modals/utils';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';
import { ensureNonEmptyItems, getInitialsForUser, getUserFullName } from 'utils';
import { faEdit, faFileChartLine, faTrash, UserHardHatIcon } from 'components/icons';
import { Jobsite } from 'graphql/client/useAvailableJobsites';
import { getDeveloperOptionsFromJobsites } from 'utils/filters';
import {
  DefaultExtraData,
  FormSubmissionColumn,
  FormSubmission,
  FormSubmissionFilters,
  FormSubmissionFiltersOptions,
  TypedFormSubmission,
  FormColumnName,
} from './types';

const FileChartLineIcon = getFixedWidthFaIcon({ icon: faFileChartLine });
const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

const classes = {
  manPowerContainer: cn('odin-flex odin-items-center odin-gap-x-1.5'),
  manPowerIcon: cn('odin-text-base odin-text-odin-primary'),
  manPowerText: cn('odin-text-gray-900 odin-text-sm odin-leading-5 odin-font-normal'),
};

export function ManpowerGatewayCell({ value }: { value: string }): React.ReactElement {
  return (
    <div className={classes.manPowerContainer}>
      <UserHardHatIcon className={classes.manPowerIcon} />
      <span className={classes.manPowerText}>{value}</span>
    </div>
  );
}

type GetAvatarColumnArgs<TExtraData extends DefaultExtraData = DefaultExtraData> = Omit<
  FormSubmissionColumn<TExtraData>,
  'Cell' | 'CellLoading' | 'componentProps'
> & {
  componentProps: (formSubmission: TypedFormSubmission<TExtraData>) => AvatarCellComponentProps;
};

export function getAvatarColumn<TExtraData extends DefaultExtraData = DefaultExtraData>(
  args: GetAvatarColumnArgs<TExtraData>,
): FormSubmissionColumn<TExtraData> {
  return {
    Cell: AvatarCell,
    CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} />,
    ...(args as FormSubmissionColumn<TExtraData>),
  };
}

export const getColumns = ({
  extraColumns = [],
  user,
  navigateToFormSubmissionPrintPage,
  navigateToFormSubmissionEditPage,
  openRemoveFormSubmissionModal,
}: {
  extraColumns: FormSubmissionColumn[];
  user: AuthUser;
  navigateToFormSubmissionPrintPage: (formSubmission: FormSubmission) => void;
  navigateToFormSubmissionEditPage: (formSubmission: FormSubmission) => void;
  openRemoveFormSubmissionModal: (formSubmission: FormSubmission) => void;
}): FormSubmissionColumn[] => {
  const canEditFormSubmissions = user.isAllowed(to.editFormSubmissions);
  const canRemoveFormSubmissions = user.isAllowed(to.removeFormSubmissions);

  return [
    {
      id: FormColumnName.JobsiteContractor,
      Header: 'Jobsite / Contractor',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (formSubmission: FormSubmission): ChipsCellComponentProps => ({
        chips: [
          {
            text: formSubmission?.jobsiteForm.jobsite.name,
            secondaryText: formSubmission.jobsiteContractors.edges.find(
              ({ node: jsfC }) => jsfC.associationType === 'assignment',
            )?.node.jobsiteContractor.contractor.organization.name,
            withDot: false,
            // isActive: formSubmission.jobsiteWorker?.currentAccess.isAllowed,
          },
        ],
      }),
    },
    ...extraColumns,
    {
      id: FormColumnName.CreatedBy,
      Header: 'Created by',
      accessor: (formSubmission: FormSubmission): string => getUserFullName(formSubmission.objectHistory.createdBy),
      Cell: AvatarCell,
      CellLoading: (): React.ReactElement => <AvatarSkeleton size={['md', 'xl:xs']} />,
      componentProps: (formSubmission: FormSubmission): AvatarCellComponentProps => ({
        src: formSubmission.objectHistory.createdBy.worker.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(formSubmission.objectHistory.createdBy),
        objectFit: 'cover',
        size: ['md', 'xl:xs'],
        detail: getPrettyFormattedDateTimeWithTz(formSubmission.objectHistory.createdAt, formSubmission.timeZone, true),
      }),
    },
    {
      id: FormColumnName.Actions,
      Header: '',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (formSubmission: FormSubmission): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<MenuItemProps>([
          {
            icon: FileChartLineIcon,
            text: 'View',
            theme: 'secondary',
            onClick: (): void => navigateToFormSubmissionPrintPage(formSubmission),
          },
          canEditFormSubmissions && {
            icon: EditIcon,
            text: 'Edit',
            theme: 'secondary',
            onClick: (): void => navigateToFormSubmissionEditPage(formSubmission),
          },
          canRemoveFormSubmissions && {
            icon: TrashIcon,
            text: 'Delete',
            theme: 'danger',
            onClick: (): void => openRemoveFormSubmissionModal(formSubmission),
          },
        ]),
      }),
    },
  ];
};

export const getFilterItems = ({
  filterKeys,
  startDate,
  endDate,
  jobsites,
  filtersOptions,
  developerIds,
  jobsiteIds,
  contractorIds,
  formIds,
  createdByIds,
  hasAttachments,
}: {
  filterKeys: Array<keyof FormSubmissionFilters>;
  startDate: Moment | string;
  endDate: Moment | string;
  jobsites: Jobsite[];
  filtersOptions: FormSubmissionFiltersOptions;
  developerIds: string[];
  jobsiteIds: string[];
  contractorIds: string[];
  formIds: string[];
  createdByIds: string[];
  hasAttachments: boolean;
}): FilterItems<FormSubmissionFilters> => {
  const result: FilterItems<Required<FormSubmissionFilters>> = {
    dateRange: {
      header: 'Date',
      type: 'datePicker',
      defaultValue: { startDate, endDate },
      layout: cn('odin-col-span-full'),
      componentProps: {
        isRange: true,
      },
    },
    developerIds: {
      header: 'Client',
      type: 'dropdown',
      defaultValue: developerIds,
      componentProps: {
        options: getDeveloperOptionsFromJobsites(jobsites),
        allowSearch: false,
      },
    },
    jobsiteIds: {
      header: 'Jobsite',
      type: 'dropdown',
      defaultValue: jobsiteIds,
      componentProps: {
        options: getJobsitesOptions(jobsites),
      },
    },
    contractorIds: {
      header: 'Contractor',
      type: 'dropdown',
      defaultValue: contractorIds,
      componentProps: {
        options: filtersOptions?.contractors,
        isVirtual: true,
      },
    },
    formIds: {
      header: 'Form',
      type: 'dropdown',
      defaultValue: formIds,
      componentProps: {
        options: filtersOptions?.forms,
        allowSearch: false,
      },
    },
    createdByIds: {
      header: 'Created by',
      type: 'dropdown',
      defaultValue: createdByIds,
      componentProps: {
        options: filtersOptions?.createdByUsers,
      },
    },
    hasAttachments: {
      header: 'Has Attachment(s)',
      type: 'checkbox',
      defaultValue: hasAttachments,
    },
  };

  if (filterKeys?.length) {
    Object.keys(result).forEach((key) => {
      if (!filterKeys.includes(key as keyof FormSubmissionFilters)) {
        delete result[key as keyof FormSubmissionFilters];
      }
    });
    return result;
  }

  // default filter items exclude formIds
  delete result.formIds;
  return result;
};

export const orderByFields: Record<string, GetJobsiteFormSubmissionsOrderByField> = {
  [FormColumnName.JobsiteContractor]: GetJobsiteFormSubmissionsOrderByField.Name,
  [FormColumnName.Date]: GetJobsiteFormSubmissionsOrderByField.Date,
  [FormColumnName.Manpower]: GetJobsiteFormSubmissionsOrderByField.Manpower,
  [FormColumnName.StartTime]: GetJobsiteFormSubmissionsOrderByField.StartTime,
  [FormColumnName.EndTime]: GetJobsiteFormSubmissionsOrderByField.EndTime,
  [FormColumnName.CreatedBy]: GetJobsiteFormSubmissionsOrderByField.CreatedBy,
};
