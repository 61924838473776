import React from 'react';
import cn from 'classnames';

export type CardHeaderTitleType = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type CardHeaderTitleProps<C extends CardHeaderTitleType> = {
  as: C;
} & React.HTMLAttributes<C>;

const fontSizeClasses: Partial<Record<CardHeaderTitleType, string>> = {
  h1: cn('odin-text-2.33xl'),
  h2: cn('odin-text-xl'),
  h3: cn('odin-text-[17px]'),
  h4: cn('odin-text-1.5sm'),
};

const classes = {
  cardHeaderTitle: (extraClasses: string, type: CardHeaderTitleType): string =>
    cn('reactstrap-card-header-title', extraClasses, type !== 'p' && 'odin-font-medium', fontSizeClasses[type]),
};

export function CardHeaderTitle<C extends CardHeaderTitleType>(props: CardHeaderTitleProps<C>): React.ReactElement {
  const {
    as: HComponent,
    className,
    ...restProps
  }: { as: React.ElementType } & Omit<CardHeaderTitleProps<C>, 'as'> = props;
  return <HComponent className={classes.cardHeaderTitle(className, HComponent)} {...restProps} />;
}
