import React from 'react';
import cn from 'classnames';
import { Avatar, Badge, BadgeColor, Icon } from '@odin-labs/components';
import {
  AnnouncementStatus,
  AnnouncementType,
  JobsiteAccessStatusType,
  JobsiteAnnouncement,
} from 'apollo/generated/client-operations';
import {
  WrenchIcon,
  ToolboxIcon,
  ToolsIcon,
  LanguageIcon,
  CalendarIcon,
  ClockIcon,
  ChevronRightIcon,
} from 'components/icons';
import { Announcement, CreateAnnouncementData } from 'containers/announcements/types';
import { getInitialsForUser } from 'utils';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';

export type AnnouncementInput = {
  announcement: Partial<Announcement> | Partial<CreateAnnouncementData>;
};

type AnnouncementSectionProps = { id: string; title: string; children: React.ReactNode };

export const classes = {
  section: cn('odin-space-y-5 sm:odin-space-y-9 odin-divide-y-2 odin-divide-gray-900'),
  sectionHeader: cn('odin-text-lg odin-font-medium odin-leading-6 odin-text-gray-900'),
  fourspan: cn('odin-col-span-4'),
  children: cn('odin-mt-5 md:odin-mt-0 odin-col-span-8 odin-grid odin-grid-cols-1 odin-gap-6'),
  announcementCard: cn('odin-flex odin-gap-5 odin-flex-col odin-overflow-y-auto odin-h-[600px]'),
  hbar: cn('odin-bg-gray-200'),

  header: cn(
    `odin-mb-1.5 odin-block odin-text-sm odin-font-medium odin-text-gray-900
     odin-col-span-2 odin-mt-2 odin-align-middle odin-text-left`,
  ),
  value: cn('odin-col-span-2 odin-align-middle odin-text-left'),
};

const accessStatusDescription: Record<JobsiteAccessStatusType, string> = {
  AccessAllowed: 'Currently allowed on site',
  CurrentlyOnsite: 'Currently on site',
  RecentlyOnsite: 'On site',
};

export const announcementTypeColor: Record<AnnouncementType, BadgeColor> = {
  Email: 'purple',
  Sms: 'blue',
};

export const announcementStatusColor: Record<AnnouncementStatus, BadgeColor> = {
  Sent: 'green',
  Draft: 'yellow',
  Processing: 'blue',
  Scheduled: 'yellow',
  Archived: 'gray',
};

export const announcementTitle = (announcement: Announcement, maxLength = 50): React.ReactElement => {
  const { subject, message } = announcement;
  const subjectMessage = <strong>{subject.substring(0, maxLength)}</strong>;
  const fullTitle =
    subject.length < maxLength ? (
      <>
        {subjectMessage}
        {': '}
        {message.substring(0, maxLength - subject.length - 2)}
      </>
    ) : (
      subjectMessage
    );
  return fullTitle;
};

export function AnnouncementTypeBadge(props: AnnouncementInput): React.ReactElement {
  const { announcement } = props;
  return (
    <Badge
      text={announcement?.announcementType === 'Sms' ? 'SMS' : announcement?.announcementType}
      color={announcementTypeColor[announcement?.announcementType]}
      size="lg"
    />
  );
}

export function AnnouncementStatusBadge(props: AnnouncementInput): React.ReactElement {
  const { announcement } = props;
  return <Badge text={announcement?.status} color={announcementStatusColor[announcement?.status]} size="lg" />;
}

export function AnnouncementSentBy(props: AnnouncementInput): React.ReactElement {
  const { announcement } = props as { announcement: Announcement };
  const { sentBy } = announcement;
  if (sentBy) {
    const { identity } = sentBy;
    const { firstName, lastName } = identity;
    const name = [firstName, lastName].join(' ') ?? 'Unknown';
    const url = sentBy?.profilePictureCropped?.downloadUrl;

    return (
      <div className="odin-flex odin-row">
        <Avatar placeholder={getInitialsForUser(sentBy)} objectFit="cover" src={url} size="sm" />
        <div className="odin-ml-1">{name}</div>
      </div>
    );
  }
  return <div />; // use a div as placehodler to preserve the layout
}

export type RecipientCountsProps = {
  counts: {
    totalCount: number;
    totalReachableCount: number;
  };
};
const warningMessage =
  'Based on your filter criteria, no workers will receive this announcement: please update your filter criteria';

export function RecipientCounts(args: RecipientCountsProps): React.ReactElement {
  const { counts } = args;

  return (
    <div className="odin-container">
      <div className="odin-grid odin-grid-cols-6  odin-w-[400px]">
        <div className={classes.header}>Workers Identified</div>
        <div className="odin-col-span-1 odin-row-span-2">
          <ChevronRightIcon className="odin-text-odin-primary odin-text-2xl odin-mt-3 odin-ml-3" />
        </div>
        <div className={classes.header}>Have contact info</div>

        <div className={classes.value}>{counts?.totalCount ?? '...'}</div>
        <div className={classes.value}>{counts?.totalReachableCount ?? '...'}</div>
      </div>
      {counts?.totalReachableCount === 0 ? (
        <div className="odin-col-auto odin-mt-3 odin-text-odin-danger">{warningMessage}</div>
      ) : null}
    </div>
  );
}

export const getSiteAccessStatusDescription = (announcement: Partial<JobsiteAnnouncement>): string => {
  const { jobsiteAccessStatus, accessWithinDayRange } = announcement;
  const baseDescription = accessStatusDescription[jobsiteAccessStatus];
  if (jobsiteAccessStatus === 'RecentlyOnsite')
    return [baseDescription, 'within the last', accessWithinDayRange, 'days'].join(' ');

  return baseDescription;
};

export function AnnouncementSection({ id, title, children }: AnnouncementSectionProps): React.ReactElement {
  return (
    <div id={id} className={classes.section}>
      <div className="odin-bg-white odin-pt-5 sm:odin-pt-9">
        <div className="md:odin-grid md:odin-grid-cols-12 md:odin-gap-6">
          <div className={classes.fourspan}>
            <h3 className={classes.sectionHeader}>{title}</h3>
          </div>
          <div className={classes.children}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export function SplitCol({ children }: { children: React.ReactNode }): React.ReactElement {
  return <div className="odin-grid odin-grid-cols-2">{children}</div>;
}

export function AttributeDescription({
  label,
  value,
  values,
  icon: IconComponent,
  valueClassName = '',
}: {
  label: string;
  value?: string | React.ReactElement;
  values?: string[];
  icon?: Icon;
  valueClassName?: string;
}): React.ReactElement {
  const fullValueClassName = `odin-block odin-text-base odin-font-normal odin-text-gray-900 ${valueClassName}`;
  return (
    <div className="odin-col-span-1">
      <div className="odin-field odin-flex odin-justify-between odin-items-center">
        <div className="odin-mb-1.5 odin-block odin-text-sm odin-font-medium odin-text-gray-900">{label}</div>
      </div>
      <div className="odin-flex odin-item-start odin-gap-2 odin-items-baseline">
        {IconComponent && <IconComponent className="odin-text-odin-primary" />}
        <div className={fullValueClassName}>{value || values?.join(', ')}</div>
      </div>
    </div>
  );
}

export function AnnouncementSummary({
  announcement,
}: {
  announcement: Partial<JobsiteAnnouncement>;
}): React.ReactElement {
  const { sentAt, startAt, timeZone } = announcement;
  return (
    <AnnouncementSection id="summary" title="Summary">
      <SplitCol>
        <AttributeDescription label="Subject" value={announcement.subject} />
        <AttributeDescription label="Message" value={announcement.message} />
      </SplitCol>

      <SplitCol>
        <AttributeDescription
          label="Scheduled"
          icon={CalendarIcon}
          value={startAt ? getPrettyFormattedDateTimeWithTz(startAt, timeZone) : 'Immediate'}
        />
        <AttributeDescription
          label="Sent"
          icon={ClockIcon}
          value={sentAt ? getPrettyFormattedDateTimeWithTz(sentAt, timeZone) : 'Not yet'}
        />
      </SplitCol>

      <SplitCol>
        {announcement?.sentBy ? (
          <AttributeDescription label="Sent By" value={<AnnouncementSentBy announcement={announcement} />} />
        ) : (
          <div />
        )}
        <AttributeDescription label="Status" value={<AnnouncementStatusBadge announcement={announcement} />} />
      </SplitCol>
    </AnnouncementSection>
  );
}

const selectedContractorsList = (announcement: Partial<JobsiteAnnouncement>): string[] =>
  announcement.contractors?.edges?.length
    ? announcement.contractors?.edges.map((edge) => edge?.node?.organization.name)
    : ['All Contractors'];

const selectedTradesList = (announcement: Partial<JobsiteAnnouncement>): string[] =>
  announcement.trades.length ? announcement.trades : ['All Trades'];

const selectedLanguagesList = (announcement: Partial<JobsiteAnnouncement>): string[] =>
  announcement.languages?.length ? announcement.languages : ['All Languages'];

export function AnnouncementCard({
  announcement,
  counts,
}: {
  announcement: Partial<JobsiteAnnouncement>;
  counts?: RecipientCountsProps['counts'];
}): React.ReactElement {
  const { subject, message } = announcement;
  return (
    <div className={classes.announcementCard}>
      <div>
        <AnnouncementTypeBadge announcement={announcement} />
      </div>
      <div className="odin-mt-2 odin-ml-1">
        <div className={classes.section}>
          <AttributeDescription label={subject} value={message} valueClassName="odin-whitespace-pre-wrap odin-w-full" />
        </div>
      </div>
      <hr className={classes.hbar} />

      <AttributeDescription
        icon={ToolboxIcon}
        label="Jobsite(s)"
        values={announcement.jobsites?.edges?.map((edge) => edge?.node?.name)}
      />
      <AttributeDescription icon={WrenchIcon} label="Contractor(s)" values={selectedContractorsList(announcement)} />
      <AttributeDescription icon={ToolsIcon} label="Trade(s)" values={selectedTradesList(announcement)} />
      <AttributeDescription icon={LanguageIcon} label="Language(s)" values={selectedLanguagesList(announcement)} />
      <AttributeDescription
        icon={ClockIcon}
        label="Site Access Status"
        value={getSiteAccessStatusDescription(announcement)}
      />
      <hr className={classes.hbar} />
      <RecipientCounts counts={counts} />
    </div>
  );
}

export function AnnouncementFilters({
  announcement,
}: {
  announcement: Partial<JobsiteAnnouncement>;
}): React.ReactElement {
  return (
    <AnnouncementSection id="filters" title="Filters">
      <AttributeDescription
        icon={ToolboxIcon}
        label="Jobsite(s)"
        values={announcement.jobsites?.edges?.map((edge) => edge?.node?.name)}
      />
      <AttributeDescription icon={WrenchIcon} label="Contractor(s)" values={selectedContractorsList(announcement)} />
      <AttributeDescription icon={ToolsIcon} label="Trade(s)" values={selectedTradesList(announcement)} />
      <AttributeDescription icon={LanguageIcon} label="Language(s)" values={selectedLanguagesList(announcement)} />
      <AttributeDescription
        icon={ClockIcon}
        label="Site Access Status"
        value={getSiteAccessStatusDescription(announcement)}
      />
    </AnnouncementSection>
  );
}

export function AnnouncementPerformance({
  counts,
}: {
  announcement: Partial<JobsiteAnnouncement>;
  counts: RecipientCountsProps['counts'];
}): React.ReactElement {
  return (
    <AnnouncementSection id="performance" title="Performance">
      <RecipientCounts counts={counts} />
    </AnnouncementSection>
  );
}
