import React from 'react';
import cn from 'classnames';

const classes = {
  formGroup: (extraClasses: string): string => cn(extraClasses, 'reactstrap-form-group odin-mb-5.5'),
};

export type FormGroupProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function FormGroup(props: FormGroupProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.formGroup(className)}>{children}</div>;
}
