import React, { ReactElement } from 'react';
import { Filter, MenuItemProps, Table, TableContainer } from '@odin-labs/components';
import { useGetDevelopersContainerCurrentSessionQuery } from 'apollo/generated/client-operations';
import { to } from 'acl';
import { AuthContext } from 'auth';
import { PlusIcon } from 'components/icons';
import { Container } from 'components/container';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { useHistory } from 'react-router-dom';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { DeveloperColumn, getColumns, getFilterItems } from './helpers/tables';
import { AddDeveloperModal } from './modals';
import { Developer, DevelopersFilters } from './types';

const developerProfileUrl = '/client/';

export function DevelopersContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);

  const { page, search, updateUrl, loading: isUrlLoading } = usePageQueryParams();
  const offset = page * limit;

  const {
    value: isAddDeveloperModalOpen,
    setTrue: openAddDeveloperModal,
    setFalse: closeAddDeveloperModal,
  } = useBoolean(false);

  const {
    data: developersData,
    loading: developersLoading,
    error: developersError,
    refetch,
  } = useGetDevelopersContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userDevelopersInput: {
        paginationInput: { limit, offset },
        search,
      },
    },
  });

  const { user: userData } = developersData?.getCurrentSession ?? {};
  const developers = userData?.developers.edges.map((c) => c.node);

  const developersCount = userData?.developers.count ?? 0;
  const pageCount = Math.ceil(developersCount / limit);

  const columns = React.useMemo<DeveloperColumn[]>(() => getColumns(), []);

  const filterItems = React.useMemo(() => getFilterItems({ search }), [search]);

  const loading = developersLoading;
  const error = developersError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<DevelopersFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: developer }: { data: Developer }): void => {
    history.push(`${developerProfileUrl}${developer.developerId}`);
  };

  const menuItems = ensureNonEmptyItems<MenuItemProps>([
    user.isAllowed(to.addDevelopers) && {
      text: 'Add Client',
      icon: PlusIcon,
      onClick: openAddDeveloperModal,
    },
  ]);

  return (
    <Container className="clients-container odin-pb-6">
      <NewHeader title="Clients" titleInfo={developersCount} actionsProps={{ menuItems, onReloadPressed: refetch }} />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={developers}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <AddDeveloperModal
        isOpen={isAddDeveloperModalOpen}
        onCancel={closeAddDeveloperModal}
        onConfirm={(newDeveloper): void => {
          closeAddDeveloperModal();
          history.push(`${developerProfileUrl}${newDeveloper.developerId}/info`);
        }}
      />
    </Container>
  );
}
