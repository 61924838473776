import { CardStatus, WorkerCard, WorkerCardType } from 'apollo/generated/client-operations';
import { typedComponentProps } from 'utils';
import { BadgeChecklist } from 'components/badgeChecklist/BadgeChecklist';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { Worker } from 'containers/worker/types';
import { IssueFacialRecognitionBadgeFormData } from './types';

export const getFormInputs = (options: {
  notifyWorker: boolean;
}): TypedFormInputs<IssueFacialRecognitionBadgeFormData> => ({
  phoneNumber: {
    element: FormInputTypes.Field,
    label: 'Phone Number',
    layout: options?.notifyWorker ? [GridColSpan.SpanFull] : 'odin-hidden',
    elementProps: {
      disabled: !options?.notifyWorker,
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      required: true,
    },
  },
  badges: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: BadgeChecklist,
      ...typedComponentProps<typeof BadgeChecklist>({ disableSelection: true }),
    },
    layout: GridColSpan.SpanFull,
  },
});

const cardStatusesToDeactivate = [CardStatus.Active, CardStatus.SentToWorker];

export const getFormDefaultValues = (
  worker: Worker,
  workerCards: WorkerCard[],
): IssueFacialRecognitionBadgeFormData => ({
  phoneNumber: worker?.user.identity.phoneNumber,
  // email: worker?.user?.identity?.email,
  badges: workerCards
    ?.filter(
      ({ cardStatus, workerCardFormat }) =>
        cardStatusesToDeactivate.includes(cardStatus) && workerCardFormat.cardType === WorkerCardType.FacialRecognition,
    )
    ?.map((workerCard) => ({ workerCard, selected: true })),
});
