import React from 'react';

const classes = {
  container: 'odin-flex odin-gap-x-6',
  label: 'odin-w-1/2 odin-text-sm odin-font-medium',
  value: 'odin-w-1/2 odin-text-sm odin-text-gray-600 odin-break-all',
};

export type CardItemProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};

export function CardItem(props: CardItemProps): React.ReactElement {
  const { label, value } = props;
  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
}
