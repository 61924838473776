import { Status } from 'components/status/Status';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { JobsiteWorkerCardStatusProps } from 'components/jobsiteWorkerCard/types';
import { classes } from 'components/jobsiteWorkerCard/JobsiteWorkerCard.style';

export function JobsiteWorkerSiteSafetyStatus(props: Omit<JobsiteWorkerCardStatusProps, 'worker'>): ReactElement {
  const { jobsiteWorker, showLabel, disableActions, confirmSelfOnboardingWorkerEdit } = props;
  const { siteSpecificTrainingCompletedAt, siteSpecificTrainingSkipExpiresAt, siteSpecificTrainingSkipReason } =
    jobsiteWorker;
  const history = useHistory();

  const goToOnboardingSiteSpecificOrientation = React.useCallback((): void => {
    history.push(`/onboarding/${jobsiteWorker?.jobsiteWorkerId}/site-specific-orientation`);
  }, [history, jobsiteWorker]);

  const getSubLabel = ({
    text,
    onClick = goToOnboardingSiteSpecificOrientation,
  }: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
  }): ReactElement => (
    <span className="odin-underline odin-cursor-pointer odin-text-1.5xs odin-text-odin-primary" onClick={onClick}>
      {text}
    </span>
  );

  let label: ReactElement;
  let status: 'warning' | 'success' | 'default' | 'danger';
  let subLabel: ReactElement;

  if (jobsiteWorker.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding) {
    label = <p>Self-Onboarding</p>;
    subLabel =
      showLabel &&
      getSubLabel({
        text: 'Start training',
        onClick: () => confirmSelfOnboardingWorkerEdit({ then: goToOnboardingSiteSpecificOrientation }),
      });
    status = 'warning';
  } else if (siteSpecificTrainingCompletedAt && siteSpecificTrainingSkipReason == null) {
    label = <p>Complete</p>;
    subLabel = showLabel && getSubLabel({ text: 'View training' });
    status = 'success';
  } else if (siteSpecificTrainingSkipExpiresAt) {
    label = <p>Expired</p>;
    subLabel = showLabel && <span>Expired {getISODateTimeToFormattedDate(siteSpecificTrainingSkipExpiresAt)}</span>;
    status = 'danger';
  } else if (siteSpecificTrainingSkipReason) {
    label = <p>Exempt</p>;
    subLabel = showLabel && <span>{siteSpecificTrainingSkipReason}</span>;
    status = 'warning';
  } else {
    label = <p>Not started</p>;
    status = 'danger';
    subLabel = showLabel && getSubLabel({ text: 'Start training' });
  }

  return (
    <>
      <Status label={label} status={status} className={classes.footerLabel} />
      {!disableActions && subLabel}
    </>
  );
}
