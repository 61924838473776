import cn from 'classnames';

export const commonClasses = {
  form: '', // cn('odin-pr-3'),
  formInputsContainer: cn(
    'odin-space-y-5 -odin-mt-5',
    'sm:odin-space-y-9 -odin-mt-9',
    'odin-divide-y odin-divide-gray-200 odin-pb-9',
  ),
};
