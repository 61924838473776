import React, { ReactElement } from 'react';
import { Badge } from '@odin-labs/components';
import { classes } from './StepInfoTitle.style';

export type StepInfoTitleProps = {
  title?: string;
  subtitle?: string;
  stepNumberInfo?: {
    stepsCount: number;
    currentStep: number;
  };
  withBottomBorder?: boolean;
  hideOnMobile?: boolean;
};

export function StepInfoTitle(props: StepInfoTitleProps): ReactElement {
  const { title, subtitle, stepNumberInfo, withBottomBorder, hideOnMobile } = props;
  const { stepsCount, currentStep } = stepNumberInfo ?? {};

  return (
    <div className={classes.container(withBottomBorder, hideOnMobile)}>
      <div className="odin-flex odin-flex-row-reverse odin-justify-between sm:odin-block sm:odin-space-y-2">
        {stepNumberInfo && (
          <div className="odin-shrink-0">
            <Badge size="lg" type="basic" color="blue" text={`${currentStep} of ${stepsCount}`} />
          </div>
        )}
        <div className="odin-space-y-2">
          <h2 className={classes.title}>{title}</h2>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
    </div>
  );
}
