import React from 'react';
import cn from 'classnames';

const classes = {
  cardBody: (extraClasses: string): string =>
    cn(extraClasses, 'reactstrap-card-body odin-flex-auto odin-relative odin-min-h-1 odin-p-6'),
};

export type CardBodyProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function CardBody(props: CardBodyProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.cardBody(className)}>{children}</div>;
}
