import { useMutation } from '@apollo/client';
import {
  JobsiteWorker,
  JobsiteWorkerOrientationStatus,
  MutationUpdateJobsiteWorkerArgs,
} from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { Status } from 'components/status/Status';
import { UPDATE_JOBSITE_WORKER } from 'containers/workerOnboardings/helpers/queries';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';

import { JobsiteWorkerCardStatusProps } from 'components/jobsiteWorkerCard/types';
import { classes } from 'components/jobsiteWorkerCard/JobsiteWorkerCard.style';

export function JobsiteWorkerOnboardingStatus(props: Omit<JobsiteWorkerCardStatusProps, 'worker'>): ReactElement {
  const { jobsiteWorker, showLabel, openDocumentExemptModal, disableActions, confirmSelfOnboardingWorkerEdit } = props;
  const { orientationStatus: onboardingStatus, badgingCompletedAt } = jobsiteWorker ?? {};

  const history = useHistory();
  let label;
  let status: 'warning' | 'success' | 'default' | 'danger' = 'danger';
  let subLabel: ReactElement;

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  const routeToOrientation = async (): Promise<void> => {
    const { jobsiteWorkerId } = jobsiteWorker;
    if (onboardingStatus === JobsiteWorkerOrientationStatus.NotStarted) {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            orientationStatus: JobsiteWorkerOrientationStatus.InProgress,
            orientationDate: getCurrentISOFormattedDateTime(),
            isAntipassbackExempt: false,
          },
        },
      });
    }
    history.push(`/onboarding/${jobsiteWorkerId}/personal-information`);
  };

  const getSubLabel = ({
    text,
    onClick,
  }: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
  }): React.ReactElement => (
    <span className="odin-underline odin-cursor-pointer odin-text-1.5xs odin-text-odin-primary" onClick={onClick}>
      {text}
    </span>
  );

  const goToOnboardingPersonalInformation = React.useCallback((): void => {
    history.push(`/onboarding/${jobsiteWorker?.jobsiteWorkerId}/personal-information`);
  }, [history, jobsiteWorker]);

  switch (onboardingStatus) {
    case JobsiteWorkerOrientationStatus.NotStarted:
      label = <p>Not Started</p>;
      subLabel =
        showLabel && getSubLabel({ text: 'Start onboarding', onClick: (): Promise<void> => routeToOrientation() });
      break;

    case JobsiteWorkerOrientationStatus.SelfOnboarding:
      label = <p>Self-Onboarding</p>;
      subLabel =
        showLabel &&
        getSubLabel({
          text: 'Open onboarding',
          onClick: () => confirmSelfOnboardingWorkerEdit({ then: goToOnboardingPersonalInformation }),
        });
      status = 'warning';
      break;

    case JobsiteWorkerOrientationStatus.InProgress:
      label = <p>In Progress</p>;
      subLabel =
        showLabel &&
        getSubLabel({
          text: 'Open onboarding',
          onClick: goToOnboardingPersonalInformation,
        });
      status = 'warning';
      break;

    case JobsiteWorkerOrientationStatus.Complete:
      label = <p>Complete</p>;
      subLabel =
        showLabel &&
        badgingCompletedAt &&
        getSubLabel({ text: 'Add Exemption', onClick: () => openDocumentExemptModal(jobsiteWorker) });
      status = 'success';
      break;
    default:
      break;
  }

  return (
    <>
      <Status label={label} status={status} className={classes.footerLabel} />
      {!disableActions && subLabel}
    </>
  );
}
