import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { UpdateWorkerMedicalNotesFormData } from './types';

export const getFormInputs = (): TypedFormInputs<UpdateWorkerMedicalNotesFormData> => ({
  medicalNotes: {
    element: FormInputTypes.Field,
    label: 'Medical notes',
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = (args: { medicalNotes?: string }): UpdateWorkerMedicalNotesFormData => {
  const { medicalNotes } = args ?? {};

  return {
    medicalNotes: medicalNotes ?? '',
  };
};
