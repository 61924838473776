import React, { useState, ReactElement } from 'react';
import {
  GetContractorContainerContractorJobsitesDocument,
  useJobsiteUpdateContractorMutation,
} from 'apollo/generated/client-operations';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { getJobsitesOptions } from './utils';
import { EditJobsiteAssignmentModalProps } from './types';
import {
  getDefaultValues,
  getFormInputsHook,
  EditJobsiteAssignmentFormData,
  useParentContractorsOptions,
  getUpdateInput,
} from './EditJobsiteAssignmentModal.forms';

export function EditJobsiteAssignmentModal(props: EditJobsiteAssignmentModalProps): ReactElement {
  const { jobsiteContractor, isOpen, contractor, jobsites, onCancel } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [jobsiteUpdateContractor] = useJobsiteUpdateContractorMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetContractorContainerContractorJobsitesDocument],
  });

  const onSubmit: FormOnSubmit<EditJobsiteAssignmentFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getUpdateInput(jobsiteContractor.id, data, dirtyFields);
      await jobsiteUpdateContractor({ variables: { input } });

      // onConfirm is not needed; the modal will be closed by refetching the jobsites query;
      AlertService.alert('success', 'Success', 'Jobsite Assignment successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const { contractorsOptions, jobsitesOptions } = React.useMemo(
    () => ({
      contractorsOptions: getContractorsOptions([contractor]),
      jobsitesOptions: getJobsitesOptions(jobsites),
    }),
    [contractor, jobsites],
  );

  const parentContractorsOptions = useParentContractorsOptions({
    jobsiteIds: [jobsiteContractor.jobsite.jobsiteId],
    contractorId: contractor.contractorId,
    skip: !isOpen,
  });

  const formInputs = getFormInputsHook({
    editType: 'update',
    contractorId: contractor.contractorId,
    contractorsOptions,
    jobsitesOptions,
    isModalOpen: isOpen,
  });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        contractorId: contractor.contractorId,
        jobsiteContractor,
        contractorsOptions,
        jobsitesOptions,
        parentContractorsOptions,
      }),
    [contractor.contractorId, jobsiteContractor, contractorsOptions, jobsitesOptions, parentContractorsOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Edit Jobsite Assignment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save Changes"
      actionButtonWithSpinner={isSaving}
    />
  );
}
