import React from 'react';
import cn from 'classnames';
import { EllipsisDropdownButton, MenuItemProps, getFixedWidthFaIcon } from '@odin-labs/components';
import { InputFile } from 'types';
import { getUserFullName } from 'utils';
import { getPrettyFormattedDateTime } from 'utils/dates';
import { faEdit, faTrash } from 'components/icons';
import { Document } from 'components/document';
import { FormSubmissionDocumentProps } from './types';

export const classes = {
  container: cn('odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg', 'odin-p-6 odin-space-y-2'),
  workerInfoRow: cn('odin-flex'),
  workerNameContainer: cn('odin-flex'),
  workerName: cn('odin-py-1', 'odin-ml-3', 'odin-text-base', 'odin-font-medium', 'odin-text-gray-900', 'odin-truncate'),
  workerInfoContainer: cn(
    'odin-grid',
    'odin-grid-cols-2',
    'odin-gap-y-1.5',
    'odin-ml-11',
    'sm:odin-flex',
    'sm:odin-space-x-4',
  ),
  workerInfoItem: cn(
    'worker-info-item-container',
    'odin-flex',
    'odin-items-center',
    'odin-text-1.5xs',
    'odin-truncate',
  ),
  workerInfoItemIcon: (icon: string): string =>
    cn(icon, 'worker-info-item-icon', 'odin-text-odin-primary', 'odin-text-sm', 'odin-font-normal', 'odin-mr-1.5'),
  participantTypeContainer: cn('odin-space-x-5'),
  removeIcon: (icon: string): string =>
    cn(
      icon,
      'worker-info-item-icon',
      'odin-text-gray-600',
      'odin-w-5 odin-h-5',
      'odin-cursor-pointer',
      'hover:odin-text-red-600',
    ),
};

const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export function FormSubmissionDocument(props: FormSubmissionDocumentProps): React.ReactElement {
  const { document, onEditClick, onDeleteClick } = props;
  const { description, files, objectHistory } = document;
  const file = files?.[0] as InputFile;

  const { createdBy, createdAt } = objectHistory ?? {};

  const menuItems: MenuItemProps[] = [
    {
      icon: EditIcon,
      text: 'Edit Attachment',
      theme: 'secondary',
      onClick: onEditClick,
    },
    {
      icon: TrashIcon,
      text: 'Delete Attachment',
      theme: 'danger',
      onClick: onDeleteClick,
    },
  ];

  return (
    <div className={classes.container}>
      <div>{file && <Document file={file} />}</div>
      <div className={classes.workerInfoRow}>
        <div className="odin-grow odin-text-sm odin-text-gray-900 odin-pr-3">{description}</div>
        <EllipsisDropdownButton menuItems={menuItems} />
      </div>
      {createdBy && (
        <span className="odin-text-xs odin-text-gray-600">
          Uploaded by {getUserFullName(createdBy)}, {getPrettyFormattedDateTime(createdAt)}
        </span>
      )}
    </div>
  );
}
