import React, { ReactElement } from 'react';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';
import { Button } from '@odin-labs/components';
import { to } from 'acl';
import { AuthContext } from 'auth';
import { JobsiteWorkerDocumentCardProps } from 'components/cards/types';
import { DownloadIcon, ExclamationTriangleIcon, MagnetIcon } from 'components/icons';
import { ObjectHistory } from 'components/objectHistory';
import { Thumbnail } from 'components/thumbnail';
import { WorkerDocumentFormModalContainer } from 'containers/modals/WorkerDocumentFormModalContainer';
import { DocumentKey, getWorkerDocumentVersionLastUpdated } from 'containers/worker/utils';
import { useBoolean } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import {
  ExtractionProcessorType,
  IdCardData,
  useExtractIdDataFromFileMutation,
} from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { AlertService } from 'components/alertService';
import { classes } from './JobsiteWorkerDocumentCard.style';

type DocumentCardProps = Pick<
  JobsiteWorkerDocumentCardProps,
  'jobsiteWorkerDocument' | 'jobsiteWorkerDocumentVersion'
> & {
  canEditMetadata: boolean;
  isAdmin: boolean;
};

function ActiveDocumentCard(props: DocumentCardProps): ReactElement {
  const { jobsiteWorkerDocumentVersion, jobsiteWorkerDocument, canEditMetadata, isAdmin } = props;
  const { value: isEditModalOpen, setTrue: openEditModal, setFalse: closeEditModal } = useBoolean();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [extractData, setExtractData] = React.useState<IdCardData>(null);
  const { key: documentTypeKey } = jobsiteWorkerDocument.jobsiteWorkerDocumentType?.workerDocumentType ?? {};
  let extractionProcessor: ExtractionProcessorType;
  switch (documentTypeKey) {
    case DocumentKey.GovernmentIssuedId:
      extractionProcessor = ExtractionProcessorType.IdCard;
      break;
    case DocumentKey.NycSiteSafetyTrainingCard:
      extractionProcessor = ExtractionProcessorType.SstCard;
      break;
    case DocumentKey.OshaCard:
      extractionProcessor = ExtractionProcessorType.OshaCard;
      break;
    default:
      break;
  }

  const [extractIdDataFromFile] = useExtractIdDataFromFileMutation({
    fetchPolicy: 'no-cache',
    variables: {
      extractionInput: {
        fileId: jobsiteWorkerDocumentVersion?.files[0]?.fileId,
        extractionType: extractionProcessor,
      },
    },
    onCompleted: async (data) => {
      setIsFetching(false);
      setExtractData(data.extractIdDataFromFile?.extractedData);
      // AlertInstance.alert(
      //   'tc',
      //   'success',
      //   'Extracted Data',
      //   JSON.stringify(data.extractIdDataFromFile?.extractedData, null, 2),
      // );
    },
    onError: async (resetError: ApolloError) => {
      setIsFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(resetError));
    },
  });

  const { additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const getFieldValue = (fieldKeys: string[]): string => {
    return additionalFieldValues?.find((fieldValue) => fieldKeys.includes(fieldValue?.key))?.value;
  };

  const cardType = getFieldValue(['type', 'card-type']);
  const stateIssued = getFieldValue(['state-issued']);
  const issueDate = getISODateTimeToFormattedDate(getFieldValue(['issue-date']));

  const { workerDocumentType } = jobsiteWorkerDocument?.jobsiteWorkerDocumentType ?? {};
  const workerDocumentKey = workerDocumentType?.key;
  const showDataExtraction =
    isAdmin &&
    (workerDocumentKey === DocumentKey.GovernmentIssuedId ||
      workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard ||
      workerDocumentKey === DocumentKey.OshaCard);
  const isTrainingConnectCard =
    workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard &&
    getFieldValue(['is-training-connect-card'])?.toLowerCase() === 'true'
      ? 'Yes'
      : 'No';

  const lastUpdated = getWorkerDocumentVersionLastUpdated(jobsiteWorkerDocumentVersion);
  const fileDownloadUrl = jobsiteWorkerDocumentVersion?.files[0]?.downloadUrl;

  return (
    <div className="odin-mb-1">
      <div className="odin-flex odin-justify-between">
        <div className="odin-flex odin-flex-col odin-justify-around">
          <div className="odin-font-medium">{cardType}</div>
          <div className="odin-text-1.5xs">Uploaded: {getISODateTimeToFormattedDate(lastUpdated)}</div>
        </div>
        <div className="odin-flex odin-gap-x-1.5">
          {jobsiteWorkerDocumentVersion?.files.map((file) => {
            return <Thumbnail key={file.fileId} file={file} size="md" />;
          })}
        </div>
      </div>
      {showDataExtraction && (
        <>
          <div className="odin-mt-4 odin-items-center">
            <div className={classes.butterBar}>
              Extract data using Document-AI
              <Button
                size="xs"
                className={cn(!canEditMetadata && 'odin-w-full')}
                onClick={(): void => {
                  if (isFetching) {
                    return;
                  }
                  setIsFetching(true);
                  setExtractData(null);
                  extractIdDataFromFile();
                }}
                icon={MagnetIcon}
                text="Extract"
              />
            </div>
          </div>
          {extractData && (
            <>
              <div className={classes.butterBar}>
                <div className="odin-text-1.5xs odin-w-1/2">Name</div>
                <div className="odin-text-1.5xs odin-w-1/2">{extractData.fullName}</div>
              </div>
              <div className={classes.butterBar}>
                <div className="odin-text-1.5xs odin-w-1/2">ID</div>
                <div className="odin-text-1.5xs odin-w-1/2">{extractData.identifier}</div>
              </div>
              <div className={classes.butterBar}>
                <div className="odin-text-1.5xs odin-w-1/2">Issued</div>
                <div className="odin-text-1.5xs odin-w-1/2">{extractData.issueDate}</div>
              </div>
              <div className={classes.butterBar}>
                <div className="odin-text-1.5xs odin-w-1/2">Expiration</div>
                <div className="odin-text-1.5xs odin-w-1/2">{extractData.expirationDate}</div>
              </div>
              <div className={classes.butterBar}>
                <div className="odin-text-1.5xs odin-w-1/2">DOB</div>
                <div className="odin-text-1.5xs odin-w-1/2">{extractData.dateOfBirth}</div>
              </div>
            </>
          )}
        </>
      )}

      <div className="odin-grid odin-grid-cols-2 odin-mt-1.5 odin-items-center">
        <div className="odin-font-medium odin-text-1.5xs odin-mb-2">
          {fileDownloadUrl ? (
            <Button
              size="xs"
              className={cn('odin-justify-center', !canEditMetadata && 'odin-w-full')}
              onClick={(): void => {
                const url =
                  fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?')
                    ? `/onboarding/document/${jobsiteWorkerDocument?.jobsiteWorkerDocumentId}/print`
                    : fileDownloadUrl;
                window.open(url);
              }}
              icon={DownloadIcon}
              text="Download"
            />
          ) : (
            <div className={classes.noFileFound}>
              <ExclamationTriangleIcon className="odin-mr-1.5 odin-text-white" />
              No file found
            </div>
          )}
        </div>
        <div className="odin-text-1.5xs">
          {canEditMetadata && (
            <span
              className="odin-underline odin-cursor-pointer odin-text-1.5xs odin-text-odin-primary"
              onClick={openEditModal}
            >
              Edit Document
            </span>
          )}
        </div>
        {stateIssued && (
          <>
            <div className="odin-font-medium odin-text-1.5xs">State issued</div>
            <div className="odin-text-1.5xs">{stateIssued}</div>
          </>
        )}
        {issueDate && (
          <>
            <div className="odin-font-medium odin-text-1.5xs">Issue date</div>
            <div className="odin-text-1.5xs">{issueDate}</div>
          </>
        )}
        {workerDocumentKey === DocumentKey.NycSiteSafetyTrainingCard && (
          <>
            <div className="odin-font-medium odin-text-1.5xs">Training Connect Card</div>
            <div className="odin-text-1.5xs">{isTrainingConnectCard}</div>
          </>
        )}
      </div>
      <ObjectHistory
        className="odin-mt-1.5 odin-text-1.5xs odin-text-slate-500"
        object={jobsiteWorkerDocumentVersion}
      />

      <WorkerDocumentFormModalContainer
        isModalOpen={isEditModalOpen}
        jobsiteId={jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.jobsite?.jobsiteId}
        jobsiteWorkerDocument={jobsiteWorkerDocument}
        jobsiteWorkerDocumentVersion={jobsiteWorkerDocumentVersion}
        setModalOpen={closeEditModal}
      />
    </div>
  );
}

function PreviousDocumentCard(props: DocumentCardProps): ReactElement {
  const { jobsiteWorkerDocumentVersion, jobsiteWorkerDocument, canEditMetadata } = props;
  const { additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const getFieldValue = (fieldKeys: string[]): string => {
    return additionalFieldValues?.find((fieldValue) => fieldKeys.includes(fieldValue?.key))?.value;
  };

  const cardType = getFieldValue(['type', 'card-type']);
  const lastUpdated = getWorkerDocumentVersionLastUpdated(jobsiteWorkerDocumentVersion);

  return (
    <div className="odin-mb-1">
      <div className="odin-flex odin-justify-between">
        <div className="odin-flex odin-flex-col odin-justify-around">
          <div className="odin-font-medium">{cardType}</div>
          <div className="odin-text-1.5xs">Uploaded: {getISODateTimeToFormattedDate(lastUpdated)}</div>
        </div>
        <div className="odin-flex odin-gap-x-1.5">
          {jobsiteWorkerDocumentVersion?.files.map((file) => {
            return <Thumbnail key={file.fileId} file={file} size="md" />;
          })}
        </div>
      </div>
      <div className="odin-flex odin-mt-1.5">
        <Button
          size="xs"
          className={cn('odin-justify-center', !canEditMetadata && 'odin-w-full')}
          onClick={(): void => {
            const fileDownloadUrl = jobsiteWorkerDocumentVersion?.files[0]?.downloadUrl;
            const url =
              fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?')
                ? `/onboarding/document/${jobsiteWorkerDocument?.jobsiteWorkerDocumentId}/print`
                : fileDownloadUrl;
            window.open(url);
          }}
          icon={DownloadIcon}
          text="Download"
        />
      </div>
    </div>
  );
}

export function JobsiteWorkerDocumentCard(props: JobsiteWorkerDocumentCardProps): ReactElement {
  const { isActive, isEditable, jobsiteWorkerDocument, jobsiteWorkerDocumentVersion } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const { workerDocumentType } = jobsiteWorkerDocument?.jobsiteWorkerDocumentType ?? {};
  const canEditMetadata =
    isEditable &&
    !workerDocumentType?.isPhi &&
    user.isAllowed(to.editMetadata, jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.jobsite?.jobsiteId);
  const isAdmin = user.isAllowed(to.addOrganizations);

  const DocumentCard = isActive ? ActiveDocumentCard : PreviousDocumentCard;

  return (
    <DocumentCard
      jobsiteWorkerDocument={jobsiteWorkerDocument}
      jobsiteWorkerDocumentVersion={jobsiteWorkerDocumentVersion}
      canEditMetadata={canEditMetadata}
      isAdmin={isAdmin}
    />
  );
}
