import React from 'react';
import {
  AppErrorCode,
  SelfOnboardingStepKey,
  useAddAndIssueFacialRecognitionBadgeMutation,
  useUpdateWorkerMutation,
  useUploadSingleFileMutation,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { Form, FormOnSubmit } from 'components/form';
import { InvalidPhotoAlert } from 'components/invalidPhotoAlert';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { StepActions, StepInfo, StepInfoTitle } from 'containers/selfOnboarding/steps/components';
import { SelfOnboardingProfilePhotoFormData, SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { uploadCompressedFile } from 'utils';
import { getGraphQLError, hasGraphQLErrorCode } from 'utils/error';
import { ProfilePhotoDirections } from './ProfilePhotoDirections';
import { getDefaultValues, getFormInputs } from './ProfilePhotoStep.forms';

export function ProfilePhotoStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { worker, profilePhoto } = state;
  const { goToStep, getStepNumberInfo, goToNextStep, state: navigationState } = navigation;
  const { isReview } = navigationState;
  const stepNumberInfo = getStepNumberInfo();

  const [isSaving, setIsSaving] = React.useState(false);
  const [updateWorker] = useUpdateWorkerMutation();
  const [uploadFile] = useUploadSingleFileMutation();
  const [addAndIssueFacialRecognitionBadge] = useAddAndIssueFacialRecognitionBadgeMutation();
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const onSubmit: FormOnSubmit<SelfOnboardingProfilePhotoFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (await isSelfOnboardingClosed()) {
        goToStep(SelfOnboardingStepKey.Closed);
        return;
      }

      const { workerId } = worker ?? {};

      try {
        if (dirtyFields?.file) {
          const { file } = data;
          const profilePictureFileId = await uploadCompressedFile(file, uploadFile);

          await updateWorker({
            variables: {
              workerId: worker.workerId,
              workerInput: {
                profilePictureFileId,
              },
            },
          });

          updateState({ profilePhoto: data });
        }

        const { jobsite } = jobsiteInvitation?.jobsiteContractor || {};
        const { jobsiteId, jobsiteCardFormats } = jobsite ?? {};
        const workerCardFormats = jobsiteCardFormats?.edges?.map(({ node }) => node?.workerCardFormat).filter(Boolean);
        const facialRecCardFormat = workerCardFormats?.find((wcf) => wcf.cardType === WorkerCardType.FacialRecognition);
        if (facialRecCardFormat) {
          await addAndIssueFacialRecognitionBadge({
            variables: {
              workerId,
              jobsiteId,
              workerCardInput: {
                type: WorkerCardType.FacialRecognition,
                isTemporary: false,
                workerCardFormatId: facialRecCardFormat.workerCardFormatId,
              },
            },
          });
        }

        setIsSaving(false);
        goToNextStep();
      } catch (error) {
        event.preventDefault();
        setIsSaving(false);
        if (
          hasGraphQLErrorCode(
            error,
            AppErrorCode.AlcatrazImageRejected,
            AppErrorCode.AlcatrazProfileAlreadyAssociated,
            AppErrorCode.AlcatrazProfileConflict,
          )
        ) {
          formApi.setError('file', {
            message: <InvalidPhotoAlert localize={localize} />,
            shouldFocus: true,
          });
        } else {
          AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
        }
      }
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      AlertService.alert('danger', 'Error uploading profile picture', getGraphQLError(error));
    }
  };

  const isProfilePhotoDisabled = !!worker.isAssignedToMultipleJobsites && !!profilePhoto.file;
  const inputs = React.useMemo(() => getFormInputs({ isProfilePhotoDisabled }), [isProfilePhotoDisabled]);
  const defaultValues = React.useMemo(() => getDefaultValues(profilePhoto), [profilePhoto]);
  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.self_onboarding_profile_photo_header)}
          subtitle={localize(Copy.self_onboarding_profile_photo_instructions)}
          stepNumberInfo={stepNumberInfo}
        />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus
        renderBelow={
          <div className="odin-mt-6">
            <ProfilePhotoDirections localize={localize} />
            <StepActions
              jobsiteInvitation={jobsiteInvitation}
              localize={localize}
              isReview={isReview}
              actions={isReview ? 'all' : 'continue'}
              onSkip={goToNextStep}
              continueActionWithSpinner={isSaving}
            />
          </div>
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
