import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { JobsiteCloseOutInput } from 'apollo/generated/client-operations';
import { getUpdateInputValueFunction, FormInputTypes, TypedFormInputs, GridColSpan } from 'components/form';
import { CloseOutJobsiteFormData } from './types';

export const getFormInputs = (): TypedFormInputs<CloseOutJobsiteFormData> => ({
  endDate: {
    element: FormInputTypes.DatePicker,
    label: 'End Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull],
  },
});

export const getDefaultValues = (): CloseOutJobsiteFormData => {
  return {
    endDate: moment(),
  };
};

export const getCloseOutInput = (
  jobsiteId: string,
  data: CloseOutJobsiteFormData,
  dirtyFields: DeepMap<CloseOutJobsiteFormData, true>,
): JobsiteCloseOutInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return {
    jobsiteId,
    endDate: getUpdateInputValue('endDate', true),
  };
};
