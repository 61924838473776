import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Alert, Button } from '@odin-labs/components';
import { PreviewIcon } from 'components/icons';
import { JobsiteWorkerDocumentUploadProps } from './types';

const classes = {
  container: (extraClasses: string): string =>
    cn(
      'jobsite-worker-document-upload odin',
      'odin-pt-5 sm:odin-pt-9',
      extraClasses,
      'odin-gap-6 odin-flex odin-flex-col',
      'lg:odin-grid lg:odin-grid-cols-3',
    ),
  labelContainer: (hideLabel: boolean): string =>
    cn(
      'lg:odin-col-span-1',
      'odin-flex odin-items-center odin-justify-between',
      'lg:odin-flex-col lg:odin-items-start lg:odin-justify-start lg:odin-gap-y-6',
      hideLabel && 'odin-hidden',
    ),
  contentContainer: (hideLabel: boolean): string =>
    cn(
      hideLabel ? 'odin-col-span-full' : 'odin-col-span-2',
      'odin-flex odin-flex-col odin-gap-6',
      'odin-grid odin-grid-cols-12',
    ),
};

export function JobsiteWorkerDocumentUpload(props: JobsiteWorkerDocumentUploadProps): ReactElement {
  const { children, className, files, label, hideLabel, previewUrl, showExistingDocumentAlert } = props;

  return (
    <div className={classes.container(className)}>
      <div className={classes.labelContainer(hideLabel)}>
        <h3 className="odin-text-base odin-font-medium">{label}</h3>
        {previewUrl && (
          <Button
            text="Preview Document"
            theme="secondary"
            size="sm"
            icon={PreviewIcon}
            as="a"
            href={previewUrl}
            target="_blank"
            hideTextOnMobile
          />
        )}
      </div>
      <div className={classes.contentContainer(hideLabel)}>
        {children}
        {files}
        {showExistingDocumentAlert && (
          <div className="odin-col-span-full">
            <Alert
              text={
                <span>
                  This worker already has a <b>{label}</b> on file: please review and update it as necessary.
                </span>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
