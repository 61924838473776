import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { JobsiteContractor } from 'containers/contractor/types';
import { FormInputTypes, GridColSpan, TypedFormInputs, getUpdateInputValueFunction } from 'components/form';
import { WrenchIcon } from 'components/icons';
import { JobsiteContractorUpdateStripePaymentMethodInput } from 'apollo/generated/client-operations';

export type AssociateJobsiteContractorPaymentMethodFormData = {
  stripePaymentMethodId: SelectOptionElement;
};

export type AssociateJobsiteContractorPaymentMethodInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  stripePaymentMethodsOptions: SelectOptionElement[];
};

type TypedFormInputsArgs = AssociateJobsiteContractorPaymentMethodInputsArgs;

export const getFormInputs = ({
  stripePaymentMethodsOptions,
}: TypedFormInputsArgs): TypedFormInputs<AssociateJobsiteContractorPaymentMethodFormData> => ({
  stripePaymentMethodId: {
    element: FormInputTypes.Select,
    label: 'Payment Method',
    elementProps: {
      placeholder: 'Select payment method',
      options: stripePaymentMethodsOptions,
      icon: WrenchIcon,
      // disabled: (contractorsOptions?.length ?? 0) < 2,
    },
    validation: {
      required: false,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export const getDefaultValues = (
  args: AssociateJobsiteContractorPaymentMethodInputsArgs,
): AssociateJobsiteContractorPaymentMethodFormData => {
  const { jobsiteContractor, stripePaymentMethodsOptions } = args;
  const { stripePaymentMethodId } = jobsiteContractor ?? {};

  return {
    stripePaymentMethodId:
      stripePaymentMethodsOptions?.find((option) => option.value === stripePaymentMethodId) ?? null,
  };
};

export const getUpdateInput = (
  jobsiteContractorId: string,
  data: AssociateJobsiteContractorPaymentMethodFormData,
  dirtyFields: DeepMap<AssociateJobsiteContractorPaymentMethodFormData, true>,
): JobsiteContractorUpdateStripePaymentMethodInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return {
    stripePaymentMethodId: getUpdateInputValue('stripePaymentMethodId', true),
    jobsiteContractorId,
  };
};
