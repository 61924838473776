import React from 'react';
import { useAssignContractorWorkerToJobsiteMutation } from 'apollo/generated/client-operations';
import { Form, FormOnSubmit } from 'components/form';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { useGetIsSelfOnboardingClosed } from 'containers/selfOnboarding/helpers/useGetIsSelfOnboardingClosed';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  SelfOnboardingContractorSelectionFormData,
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
} from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { StepActions, StepInfo, StepInfoTitle, StepJobsiteInfo } from 'containers/selfOnboarding/steps/components';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { isStringEnumValue } from 'utils';
import { getFormInputsHook } from './ContractorSelectionStep.forms';

export function ContractorSelectionStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { user, state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { contractorOptions, contractorId, worker, jobsiteWorker } = state;
  const { workerId, selfOnboardingCurrentStep } = worker ?? {};
  const { goToStep, goToNextStep, updateQueryParams, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;
  const { jobsite } = jobsiteInvitation?.jobsiteContractor ?? {};
  const { jobsiteId } = jobsite ?? {};

  const [isSaving, setIsSaving] = React.useState(false);
  const [assignContractorWorkerToJobsite] = useAssignContractorWorkerToJobsiteMutation();
  const { isSelfOnboardingClosed } = useGetIsSelfOnboardingClosed();

  const [isContinueActionEnabled, setIsContinueActionEnabled] = React.useState(!!contractorId);

  const onSubmit: FormOnSubmit<SelfOnboardingContractorSelectionFormData> = async (data, event): Promise<void> => {
    try {
      if (isSaving) return;
      setIsSaving(true);

      if (workerId && (await isSelfOnboardingClosed())) {
        goToStep(SelfOnboardingStepKey.Closed);
        return;
      }

      let isAlreadyAssigned = !!jobsiteWorker && !jobsiteWorker.jobsiteContractor.contractor.isDefaultContractor;
      if (user && !isAlreadyAssigned) {
        // assign worker to the jobsite and contractor
        const response = await assignContractorWorkerToJobsite({
          variables: {
            input: {
              ignoreIfAlreadyAssigned: true,
              workerId,
              jobsiteId,
              contractorId: data.contractorId.value,
              jobsiteWorkerInput: {},
            },
          },
        });

        isAlreadyAssigned = response.data.assignContractorWorkerToJobsite.isAlreadyAssigned;
        const newJobsiteWorker = response.data.assignContractorWorkerToJobsite.jobsiteWorker;
        updateState({ ...data, jobsiteWorker: newJobsiteWorker });
      } else {
        updateState({ ...data });
      }

      updateQueryParams({ contractorId: data.contractorId.value });

      setIsSaving(false);

      if (isAlreadyAssigned && isStringEnumValue(SelfOnboardingStepKey, selfOnboardingCurrentStep)) {
        goToStep(selfOnboardingCurrentStep);
      } else {
        goToNextStep();
      }
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const inputs = getFormInputsHook({ localize, contractorOptions, setIsContinueActionEnabled });
  const defaultValues = React.useMemo(() => ({ contractorId }), [contractorId]);

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={`${localize(Copy.self_onboarding_worker_match_header)} 👋`}
          subtitle={localize(Copy.self_onboarding_worker_match_instructions)}
          withBottomBorder
          hideOnMobile
        />
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingContractorSelectionFormData) ?? true}
        renderBelow={
          <StepActions
            jobsiteInvitation={jobsiteInvitation}
            localize={localize}
            isReview={isReview}
            actions={isReview ? 'all' : 'continue'}
            onSkip={goToNextStep}
            continueActionEnabled={isContinueActionEnabled}
            continueActionWithSpinner={isSaving}
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
