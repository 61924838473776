import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, MenuItemProps, DropdownFilterOption, Table, TableContainer, useBoolean } from '@odin-labs/components';
import {
  useGetJobsiteAnnouncementsQuery,
  useDeleteJobsiteAnnouncementMutation,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { useIsMounted, useResettableState } from 'utils';
import { getGraphQLError } from 'utils/error';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getDeveloperOptionsFromJobsites, getJobsiteIdsForDevelopers } from 'utils/filters';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { BullhornIcon } from 'components/icons';
import { Container } from 'components/container';
import { LoadingError } from 'components/loadingError';
import { NewHeader } from 'components/header/NewHeader';
import { LockedFeatureAlert } from 'components/lockedFeatureAlert';
import { AlertService } from 'components/alertService';
import { getColumns, getFilterItems, AnnouncementColumn } from './AnnouncementsContainer.tables';
import { DeleteAnnouncementModal } from './modals/DeleteAnnouncementModal';
import {
  Announcement,
  AnnouncementFilters,
  AnnouncementStatus,
  AnnouncementType,
  EditableAnnouncement,
  JobsiteSelectOptionElement,
} from './types';
import { AddAnnouncementModal } from './modals/AddAnnouncementModal';

const baseRoute = '/announcements';
const announcementDetailsUrl = '/announcement/';

const enableStatusFiltering = true;
const enableSearch = false;

const lockedFeatureMessage = (
  <>
    This feature is part of Announcements. Announcements lets you communicate with your workforce over SMS and email.
    Contact&nbsp;
    <a href="mailto:support@useodin.com" target="blank">
      support@useodin.com
    </a>
    &nbsp;to learn more.
  </>
);

export function AnnouncementsContainer(): ReactElement {
  const history = useHistory();

  const {
    page,
    jobsiteIds,
    developerIds,
    announcementStatus,
    announcementType,
    search,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams();

  const offset = page * limit;

  const {
    value: isAddAnnouncementsModalOpen,
    setTrue: openAddAnnouncementsModal,
    setFalse: closeAddAnnouncementsModal,
  } = useBoolean(false);

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {
    value: selectedAnnouncement,
    setValue: openDeleteAnnouncementModal,
    resetValue: closeDeleteAnnouncementModal,
  } = useResettableState<Announcement>(null);

  const {
    jobsites: availableJobsites,
    loading: userJobsitesLoading,
    error: userJobsitesError,
  } = useAvailableJobsites();

  const { currentUser: user } = React.useContext(AuthContext);
  const isFeatureLocked = !user.hasAnnouncementsEnabled;

  const menuItems: MenuItemProps[] = [
    {
      onClick: openAddAnnouncementsModal,
      text: 'Make Announcement',
      icon: BullhornIcon,
      disabled: isFeatureLocked,
    },
  ];

  const jobsites = React.useMemo(() => {
    return availableJobsites?.filter(
      ({ jobsiteId, featuresModule }) =>
        featuresModule?.announcementsEnabled && user.isAllowed(to.addJobsiteAnnouncements, jobsiteId),
    );
  }, [availableJobsites, user]);

  const { jobsiteOptions, developerOptions } = React.useMemo((): {
    jobsiteOptions: JobsiteSelectOptionElement[];
    developerOptions: DropdownFilterOption[];
  } => {
    return {
      jobsiteOptions:
        jobsites?.map((jobsite) => {
          const { jobsiteId: value, name: label } = jobsite;
          return { value, label, jobsite };
        }) ?? [],
      developerOptions: getDeveloperOptionsFromJobsites(jobsites),
    };
  }, [jobsites]);

  const [deleteJobsiteAnnouncement] = useDeleteJobsiteAnnouncementMutation();

  const {
    data: announcementsData,
    loading: announcementsLoading,
    error: announcementsError,
    refetch: refetchAnnouncements,
  } = useGetJobsiteAnnouncementsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: isUrlLoading || isFeatureLocked,
    variables: {
      getJobsiteAnnouncementsInput: {
        jobsiteIds,
        announcementType: announcementType as AnnouncementType,
        status: announcementStatus as AnnouncementStatus,
        paginationInput: { limit, offset },
      },
    },
  });

  const announcements = announcementsData?.getJobsiteAnnouncements?.edges.map(({ node }) => node);
  const announcementsCount = announcementsData?.getJobsiteAnnouncements?.count;
  const pageCount = Math.ceil(announcementsCount / limit);

  const performAnnouncementDelete = React.useCallback(
    async (announcement: EditableAnnouncement) => {
      if (isSaving) return;
      setIsSaving(true);

      try {
        await deleteJobsiteAnnouncement({ variables: { jobsiteAnnouncementId: announcement.jobsiteAnnouncementId } });
        closeDeleteAnnouncementModal();
        AlertService.alert('success', 'Success', 'A jobsite announcement was successfully removed.');
        await refetchAnnouncements();
      } catch (ex) {
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(ex));
      } finally {
        if (isMounted()) setIsSaving(false);
      }
    },
    [selectedAnnouncement],
  );

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<AnnouncementFilters>): void => {
    updateUrl({ page: null, ...changedFilters, ...getJobsiteIdsForDevelopers(jobsites, changedFilters.developerIds) });
  };

  const onRowClickHandler = ({ data: clickedAnnouncement }: { data: Announcement }): void => {
    history.push(`${announcementDetailsUrl}${clickedAnnouncement.jobsiteAnnouncementId}`);
  };

  const columns = React.useMemo<AnnouncementColumn[]>(
    () => getColumns({ openDeleteModal: openDeleteAnnouncementModal }),
    [],
  );

  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        jobsiteOptions,
        developerOptions,
        search,
        developerIds,
        jobsiteIds,
        announcementStatus,
        announcementType,
        enableStatusFiltering,
        enableSearch,
      }),
    [
      jobsiteOptions,
      developerOptions,
      search,
      developerIds,
      jobsiteIds,
      announcementStatus,
      announcementType,
      enableStatusFiltering,
      enableSearch,
    ],
  );

  const loading = announcementsLoading || userJobsitesLoading;
  const error = announcementsError || userJobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isFeatureLocked) {
    return (
      <div className="odin-p-8">
        <LockedFeatureAlert message={lockedFeatureMessage} />
      </div>
    );
  }

  return (
    <Container>
      <NewHeader
        title="Announcements"
        titleInfo={announcementsCount}
        actionsProps={{ menuItems, baseRoute, onReloadPressed: refetchAnnouncements }}
      />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={announcements}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          cellClassName="!odin-pl-5"
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      {isAddAnnouncementsModalOpen && (
        <AddAnnouncementModal
          jobsites={jobsites}
          jobsiteOptions={jobsiteOptions}
          closeModal={closeAddAnnouncementsModal}
        />
      )}
      <DeleteAnnouncementModal
        isOpen={!!selectedAnnouncement}
        onCancel={closeDeleteAnnouncementModal}
        onConfirm={performAnnouncementDelete}
        announcement={selectedAnnouncement}
      />
    </Container>
  );
}
