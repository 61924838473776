import React from 'react';
import { Toggle } from '@odin-labs/components';
import cn from 'classnames';
import { EditJobsiteConfigWorkerInfoField } from 'containers/jobsiteConfiguration/types';

export type WorkerInfoFieldProps = {
  onChange?: (e: EditJobsiteConfigWorkerInfoField) => void;
  value?: EditJobsiteConfigWorkerInfoField;
  name?: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
};

export const WorkerInfoField = React.forwardRef(
  (props: WorkerInfoFieldProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange, name, label, disabled, error, className } = props;
    const { isRequired = false, isHidden = false } = value ?? {};

    return (
      <div ref={ref} className={cn(className, 'odin-flex odin-justify-between')}>
        <div className="odin-text-sm odin-font-medium odin-text-gray-900">{label}</div>
        <div className="odin-flex odin-gap-x-5">
          <Toggle
            name={`${name}.isVisible`}
            label="Visible"
            value={!isHidden}
            disabled={disabled}
            onChange={(newValue): void => {
              onChange?.({ isRequired, isHidden: !newValue });
            }}
          />
          <Toggle
            name={`${name}.isRequired`}
            label="Required"
            value={isRequired}
            onChange={(newValue): void => {
              onChange?.({ isRequired: newValue, isHidden });
            }}
            disabled={disabled || isHidden}
          />
        </div>
        {error ? <p className="odin-mt-2 odin-text-sm odin-text-odin-danger">{error}</p> : null}
      </div>
    );
  },
);
