import React from 'react';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import { JobsiteWorker } from 'containers/worker/types';

type DocumentExemptionType =
  | 'notExempt'
  | keyof Pick<JobsiteWorker, 'siteSpecificTrainingSkipReason' | 'nycSstExemptionReason' | 'documentExemptionReason'>;

export type DocumentExemptionFormData = {
  exemptionType: SelectOptionElement<DocumentExemptionType>;
  exemptionReason: string;
};

const documentExemptTypes: SelectOptionElement<DocumentExemptionType>[] = [
  {
    value: 'notExempt',
    label: 'Not exempt',
  },
  {
    value: 'siteSpecificTrainingSkipReason',
    label: 'Exempt from safety training',
  },
  {
    value: 'nycSstExemptionReason',
    label: 'Exempt from SST documents',
  },
  {
    value: 'documentExemptionReason',
    label: 'Exempt from all safety documents',
  },
];

const getFormInputs = ({
  isExemptionReasonVisible,
}: {
  isExemptionReasonVisible: boolean;
}): TypedFormInputs<DocumentExemptionFormData> => ({
  exemptionType: {
    element: FormInputTypes.Select,
    label: 'Worker exemptions',
    elementProps: { options: documentExemptTypes, isClearable: false },
    validation: { required: true },
    layout: GridColSpan.SpanFull,
  },
  exemptionReason: {
    element: FormInputTypes.Field,
    label: 'Reason for exemption',
    validation: { required: true },
    layout: isExemptionReasonVisible ? GridColSpan.SpanFull : 'odin-hidden',
  },
});

export const getFormInputsHook =
  (): UseInputs<DocumentExemptionFormData> =>
  (form: UseFormMethods<DocumentExemptionFormData>): TypedFormInputs<DocumentExemptionFormData> => {
    const { watch, setValue } = form;
    const exemptionTypeOption = watch('exemptionType');
    const exemptionTypeValue = exemptionTypeOption?.value;

    useDidUpdateEffect(() => {
      if (exemptionTypeValue === null) setValue('exemptionReason', '');
    }, [exemptionTypeValue]);

    const isExemptionReasonVisible = exemptionTypeValue && exemptionTypeValue !== 'notExempt';

    return React.useMemo(() => getFormInputs({ isExemptionReasonVisible }), [isExemptionReasonVisible]);
  };

export const getDefaultValues = (args: { jobsiteWorker: JobsiteWorker }): DocumentExemptionFormData => {
  const { jobsiteWorker } = args;
  const { siteSpecificTrainingSkipReason, nycSstExemptionReason, documentExemptionReason } = jobsiteWorker ?? {};

  let exemptionTypeValue: DocumentExemptionType = 'notExempt';
  let exemptionReason = '';
  if (siteSpecificTrainingSkipReason) {
    exemptionTypeValue = 'siteSpecificTrainingSkipReason';
    exemptionReason = siteSpecificTrainingSkipReason;
  } else if (nycSstExemptionReason) {
    exemptionTypeValue = 'nycSstExemptionReason';
    exemptionReason = nycSstExemptionReason;
  } else if (documentExemptionReason) {
    exemptionTypeValue = 'documentExemptionReason';
    exemptionReason = documentExemptionReason;
  }

  return {
    exemptionType: documentExemptTypes.find((opt) => opt.value === exemptionTypeValue) ?? null,
    exemptionReason,
  };
};
