import React from 'react';
import { Button } from '@odin-labs/components';
import { PlusIcon, RedoIcon } from 'components/icons';
import { FilePreview } from 'components/filePreview';
import { DropzoneAvatarProps } from './types';

const classes = {
  imageContainer: 'odin-flex odin-flex-col odin-items-center odin-gap-y-6',
  image: 'odin-rounded-full odin-object-cover',
};

const imageSizeStyles: Record<DropzoneAvatarProps['size'], React.CSSProperties> = {
  sm: { width: 120, height: 120 },
  lg: { width: 260, height: 260 },
};

export function DropzoneAvatar(props: DropzoneAvatarProps): React.ReactElement {
  const { imageFile, imageAlt, size = 'lg', hideChangePhotoButton } = props;
  const imageStyle = imageSizeStyles[size];
  const showChangePhotoButton = !hideChangePhotoButton;

  if (imageFile) {
    return (
      <div className={classes.imageContainer}>
        <div>
          <FilePreview file={imageFile} alt={imageAlt} style={imageStyle} className={classes.image} />
        </div>
        {showChangePhotoButton && <Button theme="white" text="Change Photo" icon={RedoIcon} />}
      </div>
    );
  }

  return (
    <div className={classes.imageContainer}>
      <div>
        <svg
          className="odin-rounded-full"
          style={imageStyle}
          viewBox="0 0 260 260"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6978_72198)">
            <rect width="260" height="260" rx="130" fill="#F9FAFB" />
            <path
              d={
                'M130 172.407C169.462 172.407 217.889 199.14 217.889 217.798V280.778H42.1111V217.798C42.1111 ' +
                '199.14 90.45 172.407 130 172.407Z'
              }
              fill="#E5E7EB"
            />
            <path
              d={
                'M130 30.9556C104.576 30.9556 80.9192 51.6876 80.9192 77.3609V113.454C80.9192 139.127 ' +
                '104.576 159.859 130 159.859C155.424 159.859 179.081 139.127 179.081 113.454V77.3609C179.081 ' +
                '51.6876 155.424 30.9556 130 30.9556Z'
              }
              fill="#E5E7EB"
            />
          </g>
          <rect x="0.5" y="0.5" width="259" height="259" rx="129.5" stroke="#D1D5DB" strokeDasharray="5 2" />
          <defs>
            <clipPath id="clip0_6978_72198">
              <rect width="260" height="260" rx="130" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      {showChangePhotoButton && <Button theme="white" text="Photo" icon={PlusIcon} />}
    </div>
  );
}
