import React from 'react';
import cn from 'classnames';

export type NavbarProps = React.PropsWithChildren<
  Partial<Pick<HTMLDivElement, 'className'>> & { light?: boolean; fixed?: 'top' | 'bottom'; withoutBorder?: boolean }
>;

const classes = {
  navbar: ({ className: extraClasses, light, fixed, withoutBorder }: NavbarProps): string =>
    cn(
      extraClasses,
      'reactstrap-navbar',
      'odin-flex odin-flex-wrap odin-items-center odin-justify-between odin-py-3 odin-px-4',
      light && 'odin-bg-white odin-border-[#ebeced]',
      fixed ? 'odin-fixed odin-z-[1030]' : 'odin-relative',
      fixed === 'top' && 'odin-fixed odin-top-0 odin-left-0 odin-right-0',
      fixed === 'bottom' && 'odin-fixed odin-bottom-0 odin-left-0 odin-right-0',
      !withoutBorder && (!fixed || fixed === 'top' ? 'odin-border-b' : 'odin-border-t'),
    ),
};

export function Navbar(props: NavbarProps): React.ReactElement {
  const { children } = props;
  return <nav className={classes.navbar(props)}>{children}</nav>;
}
