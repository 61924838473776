import React from 'react';
import { Modal } from '@odin-labs/components';
import { useEnableJobsiteWorkerSelfOnboardingMutation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { EnableJobsiteWorkerSelfOnboardingModalProps } from './types';

const modalText =
  "By resetting this worker's status to self-onboarding, they will be able to access this onboarding " +
  'and will be able to make changes to their data and documents. Are you sure you want to do this?';

export function EnableJobsiteWorkerSelfOnboardingModal(
  props: EnableJobsiteWorkerSelfOnboardingModalProps,
): React.ReactElement {
  const { isOpen, closeModal, onClosed, onConfirm, jobsiteWorker, refetchQueries } = props;
  const { jobsiteWorkerId } = jobsiteWorker ?? {};

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [enableJobsiteWorkerSelfOnboarding] = useEnableJobsiteWorkerSelfOnboardingMutation({
    fetchPolicy: 'no-cache',
    refetchQueries,
  });

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await enableJobsiteWorkerSelfOnboarding({ variables: { jobsiteWorkerId } });

      onConfirm?.();
      AlertService.alert('success', 'Success', `Self-onboarding successfully enabled`);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      afterLeave={onClosed}
      size="xs"
      title="Enable self-onboarding"
      text={modalText}
      titleAlignment="center"
      textAlignment="center"
      onAction={onAction}
      actionText="Continue"
      actionButtonWithSpinner={isSaving}
    />
  );
}
