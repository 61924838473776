import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Table, TableContainer, TableProps } from '@odin-labs/components';
import {
  GetJobsitesContainerCurrentSessionDocument,
  StateAbbreviation,
  useGetJobsitesContainerCurrentSessionQuery,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useModalState } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { LoadingError } from 'components/loadingError';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { CloseOutJobsiteModal } from 'containers/jobsites/modals/jobsite';
import { ArchiveJobsiteModal } from 'containers/jobsiteConfiguration/tabs/modals';
import { Jobsite, JobsitesFilters, JobsitesWidgetProps } from './types';
import { getColumns, getFilterItems } from './JobsitesWidget.tables';

const jobsitesProfileUrl = '/jobsite/';

export function JobsitesWidget(props: JobsitesWidgetProps): ReactElement {
  const { onTabApiChange, isTemplate } = props;

  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: jobsiteToCloseOut,
    isModalOpen: isCloseOutJobsiteModalOpen,
    openModal: openCloseOutJobsiteModal,
    closeModal: closeCloseOutJobsiteModal,
    resetModalValue: resetJobsiteToCloseOut,
  } = useModalState<Jobsite>(null);

  const {
    value: jobsiteToArchive,
    isModalOpen: isArchiveJobsiteModalOpen,
    openModal: openArchiveJobsiteModal,
    closeModal: closeArchiveJobsiteModal,
    resetModalValue: resetJobsiteToArchive,
  } = useModalState<Jobsite>(null);

  const history = useHistory();
  const { page, developerIds, cities, states, search, updateUrl, loading: isUrlLoading } = usePageQueryParams();
  const offset = page * limit;
  const statesValues = states?.length ? Object.values(StateAbbreviation).filter((s) => states.includes(s)) : null;

  const { data, loading, error, refetch } = useGetJobsitesContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userJobsitesInput: {
        includeAllJobsites: true,
        isTemplate,
        developerIds,
        cities,
        states: statesValues,
        search,
        paginationInput: { limit, offset },
      },
    },
  });

  const userData = data?.getCurrentSession.user;
  const filtersOptions = userData?.jobsites.filtersOptions;
  const jobsites = userData?.jobsites.edges.map(({ node }) => node);
  const { count: jobsitesCount, templatesCount } = userData?.jobsites ?? {};
  const itemsCount = isTemplate ? templatesCount : jobsitesCount;
  const pageCount = Math.ceil(itemsCount / limit);

  const navigateTo = React.useCallback(
    (path: string, openInNewTab: boolean): void => {
      if (openInNewTab) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }
    },
    [history],
  );

  const navigateToJobsiteConfigurationEditPage = React.useCallback(
    (jobsite: Jobsite, openInNewTab: boolean): void => {
      navigateTo(`/jobsite/${jobsite.jobsiteId}/settings`, openInNewTab);
    },
    [navigateTo],
  );

  const navigateToJobsitePage = React.useCallback(
    (jobsite: Jobsite, openInNewTab: boolean): void => {
      navigateTo(`/jobsite/${jobsite.jobsiteId}`, openInNewTab);
    },
    [navigateTo],
  );

  const columns = React.useMemo(
    () =>
      getColumns({
        user,
        isTemplate,
        navigateToJobsiteConfigurationEditPage,
        navigateToJobsitePage,
        openCloseOutJobsiteModal,
        openArchiveJobsiteModal,
      }),
    [
      user,
      isTemplate,
      navigateToJobsiteConfigurationEditPage,
      navigateToJobsitePage,
      openCloseOutJobsiteModal,
      openArchiveJobsiteModal,
    ],
  );
  const filterItems = React.useMemo(
    () => getFilterItems({ filtersOptions, developerIds, cities, states, search }),
    [jobsites, developerIds, search],
  );

  React.useEffect(
    () => onTabApiChange({ refetchData: refetch, jobsitesCount, templatesCount }),
    [refetch, jobsitesCount, templatesCount],
  );

  if (error) {
    return <LoadingError error={error} />;
  }

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFilterChangeHandler = (changedFilters: Partial<JobsitesFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler: TableProps<Jobsite>['onRowClick'] = ({ data: jobsite, event }): void => {
    const { metaKey, ctrlKey } = (event as React.KeyboardEvent) ?? {};
    if (metaKey || ctrlKey) {
      window.open(`${jobsitesProfileUrl}${jobsite.jobsiteId}`, '_blank');
    } else {
      history.push(`${jobsitesProfileUrl}${jobsite.jobsiteId}`);
    }
  };

  return (
    <>
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={jobsites}
          initialState={{ pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <CloseOutJobsiteModal
        isOpen={isCloseOutJobsiteModalOpen}
        onCancel={closeCloseOutJobsiteModal}
        onConfirm={closeCloseOutJobsiteModal}
        onClosed={resetJobsiteToCloseOut}
        jobsiteId={jobsiteToCloseOut?.jobsiteId}
      />
      <ArchiveJobsiteModal
        isOpen={isArchiveJobsiteModalOpen}
        closeModal={closeArchiveJobsiteModal}
        onClosed={resetJobsiteToArchive}
        jobsite={jobsiteToArchive}
        refetchQueries={[GetJobsitesContainerCurrentSessionDocument]}
      />
    </>
  );
}
