import React from 'react';
import { Modal } from '@odin-labs/components';
import {
  UserPendingChangeType,
  useUserCancelPendingChangeMutation,
  GetUserPendingChangeDocument,
} from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { TrashIcon } from 'components/icons';
import { CancelUserChangeModalProps } from './types';

const changeTypeText: Record<UserPendingChangeType, string> = {
  phone: 'phone number',
  email: 'email address',
};

const alertChangeTypeText: Record<UserPendingChangeType, string> = {
  phone: 'Phone number',
  email: 'Email address',
};

export function CancelUserChangeModal(props: CancelUserChangeModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm, workerId, changeType } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [cancelPendingChange] = useUserCancelPendingChangeMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetUserPendingChangeDocument],
  });

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await cancelPendingChange({ variables: { input: { workerId, changeType } } });

      onConfirm();
      AlertService.alert(
        'success',
        'Success',
        `${alertChangeTypeText[changeType]} pending change successfully cancelled`,
      );
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={`Are you sure you want to cancel the current ${changeTypeText[changeType]} pending change?`}
      titleAlignment="center"
      text=""
      textAlignment="center"
      onAction={onAction}
      actionText="Cancel Pending Change"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={isSaving}
      cancelText="Close"
    />
  );
}
