import React, { ReactElement, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  MutationUploadSingleFileArgs,
  useCreateJobsiteWorkerDocumentMutation,
  useCreateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { InputFile } from 'types';
import { AuthContext } from 'auth/authContext';
import { AlertService } from 'components/alertService';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { signatureFormInput } from 'containers/workerOnboarding/helpers/forms';
import { FILE_UPLOAD } from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';
import { SignatureDataType, OnboardingStepProps, UploadFileResponse } from 'containers/workerOnboarding/types';
import { isServerFile, uploadCompressedFile, useDeferredFormSubmission, useIsMounted } from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';
import { commonClasses } from './common.style';

const documentKeys = [DocumentKey.Signature];

export function SignatureStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<SignatureDataType>();

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;
  const developerName = jobsiteWorker?.jobsiteContractor?.jobsite?.developer?.name ?? 'the developer of this jobsite';

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
    awaitRefetchQueries: true,
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useCreateJobsiteWorkerDocumentMutation({
    onCompleted: () => {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            signatureCompletedAt: getCurrentISOFormattedDateTime(),
            signatureSkipReason: null,
          },
        },
      });
    },
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useCreateJobsiteWorkerDocumentVersionMutation({
    onCompleted: () => {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            signatureCompletedAt: getCurrentISOFormattedDateTime(),
            signatureSkipReason: null,
          },
        },
      });
    },
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const onSubmit: FormOnSubmit<SignatureDataType> = async (data): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      await Promise.all(
        documentKeys.map(async (documentKey) => {
          const fileIds = [];
          const frontFile = data[`${documentKey}-front`] as InputFile;
          if (frontFile) {
            if (isServerFile(frontFile)) {
              fileIds.push(frontFile.fileId);
            } else {
              const fileId = await uploadCompressedFile(frontFile, uploadFile);
              if (fileId) {
                fileIds.push(fileId);
              }
            }
          }

          const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

          if (jobsiteWorkerDocumentId) {
            return createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: [],
                },
              },
            });
          }

          return createJobsiteWorkerDocument({
            variables: {
              jobsiteWorkerDocumentInput: {
                jobsiteWorkerId,
                jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentKey],
                key: documentKey,
              },
              jobsiteWorkerDocumentVersionInput: {
                fileIds,
                additionalFieldValues: [],
              },
            },
          });
        }),
      );

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          signatureCompletedAt: getCurrentISOFormattedDateTime(),
          signatureSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          signatureCompletedAt: null,
          signatureSkipReason: null,
        },
      },
    });
  };

  const visibleDocuments = getVisibleDocuments(jobsiteWorker);
  const loading = parentLoading || fetching;

  const inputs = React.useMemo(() => {
    return filteredDocumentTypeIds[DocumentKey.Signature] && visibleDocuments?.includes(DocumentKey.Signature)
      ? [signatureFormInput()]
      : [];
  }, [filteredDocumentTypeIds, visibleDocuments]);

  return (
    <>
      <div className="odin-relative">
        <fieldset disabled={!!isContractorMember}>
          <Header title="Signature" />
          <StepLoading loading={loading} hasTransparentBackground={!inputs.length} />
          {!!inputs.length && (
            <p>
              I permit {developerName}, or its assignee, to populate a sign-in sheet with the signature below based on
              below based on turnstile entry and exit times.
            </p>
          )}
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultFormValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
            className={commonClasses.form}
            inputsContainerClassName={commonClasses.formInputsContainer}
          />
        </fieldset>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
