import { FormInputTypes, TypedFormInputs, GridColSpan } from 'components/form';
import { dropzoneValidation } from 'components/dropzone/utils';
import { SelfOnboardingProfilePhotoFormData } from 'containers/selfOnboarding/types';

export const getFormInputs = ({
  isProfilePhotoDisabled,
}: {
  isProfilePhotoDisabled: boolean;
}): TypedFormInputs<SelfOnboardingProfilePhotoFormData> => {
  return {
    file: {
      element: FormInputTypes.Dropzone,
      label: 'Profile photo',
      elementProps: {
        accept: {
          'image/*': ['.png', '.jpg', '.jpeg'],
        },
        isAvatar: true,
        disabled: isProfilePhotoDisabled,
      },
      validation: {
        required: true,
        validate: dropzoneValidation,
      },
      layout: [GridColSpan.SpanFull],
    },
  };
};

export const getDefaultValues = (
  profilePhoto: SelfOnboardingProfilePhotoFormData,
): SelfOnboardingProfilePhotoFormData => {
  const { file } = profilePhoto ?? {};

  return {
    file: file ?? null,
  };
};
