import React from 'react';
import cn from 'classnames';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  getFaIcon,
  MenuItemProps,
} from '@odin-labs/components';
import { AuthUser, to } from 'acl';
import { ensureNonEmptyItems } from 'utils';
import { getPrettyFormattedUtcDate } from 'utils/dates';
import { faBriefcase, BusinessTimeIcon, TrashIcon, FileChartLineIcon, CogIcon } from 'components/icons';
import { Jobsite, JobsiteColumn, JobsiteColumnName, JobsiteFiltersOptions, JobsitesFilters } from './types';

const BriefcaseIcon = getFaIcon({ icon: faBriefcase, className: cn('odin-text-base odin-text-odin-primary') });

export const getColumns = ({
  user,
  isTemplate,
  navigateToJobsiteConfigurationEditPage,
  navigateToJobsitePage,
  openCloseOutJobsiteModal,
  openArchiveJobsiteModal,
}: {
  user: AuthUser;
  isTemplate: boolean;
  navigateToJobsiteConfigurationEditPage: (jobsite: Jobsite, openInNewTab: boolean) => void;
  navigateToJobsitePage: (jobsite: Jobsite, openInNewTab: boolean) => void;
  openCloseOutJobsiteModal: (jobsite: Jobsite) => void;
  openArchiveJobsiteModal: (jobsite: Jobsite) => void;
}): JobsiteColumn[] =>
  ensureNonEmptyItems<JobsiteColumn>([
    {
      id: JobsiteColumnName.Jobsite,
      Header: 'Name',
      accessor: 'name',
      Cell: AvatarCell,
      CellLoading: (): React.ReactElement => <AvatarSkeleton size="xs" type="rounded" hideDetails />,
      componentProps: (jobsite: Jobsite): AvatarCellComponentProps => ({
        icon: BriefcaseIcon,
        type: 'rounded',
        placeholder: (jobsite.name.substring(0, 2) || '??').toUpperCase(),
        objectFit: 'cover',
        size: 'xs',
      }),
    },
    {
      id: JobsiteColumnName.Client,
      Header: 'Client',
      accessor: (jobsite: Jobsite): string => {
        return jobsite?.developer?.organization?.name;
      },
    },
    ...(isTemplate
      ? []
      : [
          {
            id: JobsiteColumnName.Location,
            Header: 'Location',
            accessor: (jobsite: Jobsite): string => {
              const { addressCity, addressState } = jobsite ?? {};
              return [addressCity, addressState].filter(Boolean).join(', ');
            },
          },
          {
            id: JobsiteColumnName.StartDate,
            Header: 'Start Date',
            accessor: (jobsite: Jobsite): string => getPrettyFormattedUtcDate(jobsite?.startDate),
          },
          {
            id: JobsiteColumnName.EndDate,
            Header: 'End Date',
            accessor: (jobsite: Jobsite): string => getPrettyFormattedUtcDate(jobsite?.endDate),
          },
          {
            id: JobsiteColumnName.Actions,
            Header: '',
            Cell: DropdownButtonCell,
            CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
            componentProps: (jobsite: Jobsite): DropdownButtonCellComponentProps => ({
              cellAlignment: 'right',
              menuItems: ensureNonEmptyItems<MenuItemProps>([
                {
                  icon: FileChartLineIcon,
                  text: 'View',
                  theme: 'secondary',
                  onClick: (event): void => navigateToJobsitePage(jobsite, event.metaKey || event.ctrlKey),
                },
                user.isAllowed(to.editJobsiteConfiguration) && {
                  icon: CogIcon,
                  text: 'Edit Configuration',
                  theme: 'secondary',
                  onClick: (event): void =>
                    navigateToJobsiteConfigurationEditPage(jobsite, event.metaKey || event.ctrlKey),
                },
                user.isAllowed(to.closeOutJobsites) && {
                  icon: BusinessTimeIcon,
                  text: 'Close out',
                  theme: 'secondary',
                  disabled: !!jobsite?.endDate,
                  onClick: (): void => openCloseOutJobsiteModal(jobsite),
                },
                user.isAllowed(to.archiveJobsites) && {
                  icon: TrashIcon,
                  text: 'Archive',
                  theme: 'danger',
                  onClick: (): void => openArchiveJobsiteModal(jobsite),
                },
              ]),
            }),
          },
        ]),
  ]);

export const getFilterItems = ({
  filtersOptions,
  developerIds,
  cities,
  states,
  search,
}: {
  filtersOptions: JobsiteFiltersOptions;
  developerIds: string[];
  cities: string[];
  states: string[];
  search: string;
}): FilterItems<JobsitesFilters> => ({
  developerIds: {
    header: 'Client',
    type: 'dropdown',
    defaultValue: developerIds,
    componentProps: {
      options: filtersOptions?.developers ?? [],
      allowSearch: false,
    },
  },
  cities: {
    header: 'City',
    type: 'dropdown',
    defaultValue: cities,
    componentProps: {
      options: filtersOptions?.cities ?? [],
      isMulti: true,
      allowSearch: false,
    },
  },
  states: {
    header: 'State',
    type: 'dropdown',
    defaultValue: states,
    componentProps: {
      options: filtersOptions?.states ?? [],
      isMulti: true,
      allowSearch: false,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});
