import moment from 'moment';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { workerAccessRevokingCategoryOptions } from 'containers/worker/utils';
import { AccessRevokingType, RevokeWorkerAccessFormData } from './types';

export const getFormInputs = ({
  accessRevokingType,
}: {
  accessRevokingType: AccessRevokingType;
}): TypedFormInputs<RevokeWorkerAccessFormData> => {
  const isSuspension = accessRevokingType === AccessRevokingType.Suspend;
  const isOffboarding = accessRevokingType === AccessRevokingType.Offboard;

  return {
    endDate: {
      element: FormInputTypes.DatePicker,
      label: 'Offboard date',
      isHidden: !isOffboarding,
      elementProps: {
        showDefaultIcon: true,
        numberOfMonths: 1,
        minDate: moment(),
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull],
    },
    banExpiresAt: {
      element: FormInputTypes.DatePicker,
      label: 'Suspension ends on',
      isHidden: !isSuspension,
      elementProps: {
        showDefaultIcon: true,
        numberOfMonths: 1,
        minDate: moment().add(1, 'day'),
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull],
    },
    category: {
      element: FormInputTypes.Select,
      label: 'Category',
      isHidden: isOffboarding,
      elementProps: {
        options: workerAccessRevokingCategoryOptions,
      },
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    approvedBy: {
      element: FormInputTypes.Field,
      label: 'Approved By',
      isHidden: isOffboarding,
      validation: { required: true },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    },
    reason: {
      element: FormInputTypes.TextAreaField,
      label: 'Reason',
      isHidden: isOffboarding,
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getDefaultValues = ({
  accessRevokingType,
}: {
  accessRevokingType: AccessRevokingType;
}): RevokeWorkerAccessFormData => {
  const isSuspension = accessRevokingType === AccessRevokingType.Suspend;
  const isOffboarding = accessRevokingType === AccessRevokingType.Offboard;

  return {
    endDate: isOffboarding ? moment().startOf('day') : null,
    banExpiresAt: isSuspension ? moment().startOf('day').add(1, 'day') : null,
    category: null,
    approvedBy: '',
    reason: '',
  };
};
