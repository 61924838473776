import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { primaryLanguageOptions, toFancySelectOptions, tradeOptions as tradeOptionsValues } from 'utils/constants';
import { RequiredField } from 'utils/validation';
import { FormInputTypes, GridColSpan, RadioGroupFormOption, TypedFormInputs } from 'components/form';
import {
  CreateAnnouncementData,
  FilterRecipientsFormData,
  JobsiteAccessStatusType,
  Jobsite,
} from 'containers/announcements/types';
import { defaultFromExisting } from 'containers/announcements/utils';
import { RecipientCounts } from 'containers/announcement/components';

const jobsiteAccessOptions: RadioGroupFormOption<
  Pick<FilterRecipientsFormData, 'filterAccessWithinDayRange'>,
  JobsiteAccessStatusType
>[] = [
  {
    label: 'Currently allowed on site',
    value: JobsiteAccessStatusType.AccessAllowed,
  },
  {
    label: 'Currently on site',
    value: JobsiteAccessStatusType.CurrentlyOnsite,
  },
  {
    label: 'On site within the last x days',
    value: JobsiteAccessStatusType.RecentlyOnsite,
    inputs: [
      {
        name: 'filterAccessWithinDayRange',
        element: FormInputTypes.Field,
        elementProps: {
          fieldType: 'number',
        },
        validation: {
          required: {
            value: true,
            message: RequiredField`Date range`,
          },
        },
        layout: GridColSpan.SmSpan3,
      },
    ],
  },
];

type FilterRecipientsFormDataInput = {
  contractorOptions: SelectOptionElement[];
  announcement: CreateAnnouncementData;
  counts?: { totalReachableCount: number; totalCount: number };
  selectedJobsites?: Jobsite[];
  isWorkerAccessEnabled?: boolean;
};

const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const languageOptions = toFancySelectOptions(primaryLanguageOptions);

export const getFormInputs = ({
  contractorOptions,
  counts,
  isWorkerAccessEnabled,
}: FilterRecipientsFormDataInput): TypedFormInputs<FilterRecipientsFormData> => ({
  contractorIds: {
    element: FormInputTypes.Select,
    elementProps: {
      label: 'Contractor(s)',
      placeholder: 'All Contractors',
      options: contractorOptions,
      multiple: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  trades: {
    element: FormInputTypes.Select,
    elementProps: {
      label: 'Trade(s)',
      placeholder: 'All Trades',
      options: tradeOptions,
      multiple: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  languages: {
    element: FormInputTypes.Select,
    elementProps: {
      label: 'Language',
      placeholder: 'Any Language',
      options: languageOptions,
      multiple: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  jobsiteAccessStatus: {
    element: FormInputTypes.RadioGroup,
    elementProps: {
      label: 'Jobsite Access Status',
      options: isWorkerAccessEnabled ? jobsiteAccessOptions : jobsiteAccessOptions.slice(0, 1),
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull],
  },
  recipientCounts: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: <RecipientCounts counts={counts} />,
    },
    layout: GridColSpan.SpanFull,
  },
});

type FilterRecipientsFormDataInputHook = FilterRecipientsFormDataInput & {
  updateAnnouncement: (data: Partial<CreateAnnouncementData>) => void;
  isWorkerAccessEnabled?: boolean;
};

export const getFormInputsHook =
  (args: FilterRecipientsFormDataInputHook) =>
  ({ watch }: UseFormMethods<FilterRecipientsFormData>): TypedFormInputs<FilterRecipientsFormData> => {
    const { contractorOptions, announcement, counts, updateAnnouncement, isWorkerAccessEnabled } = args;

    const { contractorIds, trades, jobsiteAccessStatus, filterAccessWithinDayRange, languages } = watch([
      'contractorIds',
      'trades',
      'languages',
      'jobsiteAccessStatus',
      'filterAccessWithinDayRange',
    ]);

    React.useEffect(() => {
      updateAnnouncement({
        ...(contractorIds ? { filterContractorIds: contractorIds.map(({ value }) => value) } : {}),
        ...(trades ? { filterTrades: trades.map(({ value }) => value) } : {}),
        ...(languages ? { filterLanguages: languages.map(({ value }) => value) } : {}),
        ...(jobsiteAccessStatus ? { filterJobsiteAccessStatus: jobsiteAccessStatus.value } : {}),
        filterAccessWithinDayRange: filterAccessWithinDayRange ? Number(filterAccessWithinDayRange) : undefined,
      });
    }, [contractorIds, trades, jobsiteAccessStatus, filterAccessWithinDayRange, languages]);

    return React.useMemo(
      () => getFormInputs({ contractorOptions, announcement, counts, isWorkerAccessEnabled }),
      [contractorOptions, announcement, counts, isWorkerAccessEnabled],
    );
  };

export const getDefaultValues = (
  announcement: CreateAnnouncementData,
  contractorOptions: SelectOptionElement[],
): FilterRecipientsFormData => {
  return {
    contractorIds: defaultFromExisting(contractorOptions, announcement.filterContractorIds),
    trades: defaultFromExisting(tradeOptions, announcement.filterTrades),
    languages: defaultFromExisting(languageOptions, announcement.filterLanguages),
    jobsiteAccessStatus:
      jobsiteAccessOptions.find((o) => o.value === announcement.filterJobsiteAccessStatus) ?? jobsiteAccessOptions[0],
    filterAccessWithinDayRange: announcement.filterAccessWithinDayRange,
    recipientCounts: undefined,
  };
};
