import React, { useState, ReactElement } from 'react';
import {
  GetJobsiteContainerDocument,
  GetJobsiteContainerContractorsDocument,
  useJobsiteAddContractorsMutation,
} from 'apollo/generated/client-operations';
import { PlusIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { getJobsitesOptions } from './utils';
import {
  getDefaultValues,
  getFormInputsHook,
  EditContractorAssignmentFormElements,
  getAddInput,
} from './AddContractorAssignmentModal.forms';
import { AddContractorAssignmentModalProps } from './types';

export function AddContractorAssignmentModal(props: AddContractorAssignmentModalProps): ReactElement {
  const { isOpen, contractors, jobsite, closeModal } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [jobsiteAddContractors] = useJobsiteAddContractorsMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsiteContainerContractorsDocument, GetJobsiteContainerDocument],
  });

  const onSubmit: FormOnSubmit<EditContractorAssignmentFormElements> = async (
    data,
    event,
    dirtyFields,
  ): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getAddInput(data, dirtyFields);
      await jobsiteAddContractors({ variables: { input } });

      closeModal();
      AlertService.alert('success', 'Success', 'Jobsite Assignment successfully added');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const { contractorsOptions, jobsitesOptions } = React.useMemo(
    () => ({
      contractorsOptions: getContractorsOptions(contractors),
      jobsitesOptions: getJobsitesOptions([jobsite]),
    }),
    [contractors, jobsite],
  );

  const formInputs = getFormInputsHook({
    editType: 'create',
    jobsiteId: jobsite.jobsiteId,
    contractorsOptions,
    jobsitesOptions,
  });

  const defaultValues = React.useMemo(
    () => getDefaultValues({ contractorsOptions, jobsitesOptions }),
    [contractorsOptions, jobsitesOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title="Add Contractor Assignment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Assignment"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isSaving}
    />
  );
}
