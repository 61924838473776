import React from 'react';
import { useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { FormOnSubmit, ModalForm } from 'components/form';
import { useIsMounted } from 'utils';
import { UpdateWorkerMedicalNotesFormData, UpdateWorkerMedicalNotesModalProps } from './types';
import { getDefaultValues, getFormInputs } from './UpdateWorkerMedicalNotesModal.forms';

export function UpdateWorkerMedicalNotesModal(props: UpdateWorkerMedicalNotesModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, refetchQueries, jobsiteWorker } = props;
  const { jobsiteWorkerId } = jobsiteWorker ?? {};

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({ refetchQueries });

  const onSubmit: FormOnSubmit<UpdateWorkerMedicalNotesFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const { medicalNotes } = data;

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: { medicalNotes },
        },
      });

      onConfirm();
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(jobsiteWorker), [jobsiteWorker]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={closeModal}
      title="Update medical notes"
      onIsDirtyChange={setIsFormDirty}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Save"
      actionButtonEnabled={isFormDirty}
      actionButtonWithSpinner={isSaving}
    />
  );
}
