import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { JobsiteWorker } from 'containers/worker/types';
import { workerAccessRevokingCategoryOptions } from 'containers/worker/utils';
import { RestoreWorkerAccessFormData } from './types';

export const getFormInputs = (): TypedFormInputs<RestoreWorkerAccessFormData> => ({
  category: {
    element: FormInputTypes.Select,
    label: 'Category',
    elementProps: {
      options: workerAccessRevokingCategoryOptions,
      disabled: true,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  approvedBy: {
    element: FormInputTypes.Field,
    label: 'Approved By',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  reason: {
    element: FormInputTypes.TextAreaField,
    label: 'Reason',
    layout: GridColSpan.SpanFull,
  },
});

export const getDefaultValues = (jobsiteWorker: JobsiteWorker): RestoreWorkerAccessFormData => {
  return {
    category: workerAccessRevokingCategoryOptions.find((opt) => opt.value === jobsiteWorker?.banCategory) ?? null,
    approvedBy: '',
    reason: '',
  };
};
