import React from 'react';
import { ApolloError } from '@apollo/client';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { JobsiteWorker, useGetFormSubmissionAvailableWorkersQuery } from 'apollo/generated/client-operations';
import { JobsiteInfo, SearchWorkerResult } from 'components/searchBox/types';
import { getFormattedPhoneNumber } from 'utils';

type WorkerSearchData = {
  searchWorkerResults: SearchWorkerResult[];
  loading: boolean;
  searchWorkers: (searchTerm: string) => void;
};

export const useWorkerSearchData = (jobsiteFormSubmissionId: string, search: string): WorkerSearchData => {
  const {
    data,
    loading,
    refetch: getFormSubmissionAvailableWorkers,
  } = useGetFormSubmissionAvailableWorkersQuery({
    fetchPolicy: 'no-cache',
    onError: (responseError: ApolloError) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: {
      id: jobsiteFormSubmissionId,
      availableWorkersInput: { limit: 50, search, includeSiblingJobsites: true },
    },
    skip: !jobsiteFormSubmissionId,
  });

  const formSubmissionJobsiteId = data?.getJobsiteFormSubmission.jobsiteForm.jobsite.jobsiteId;

  const searchWorkerResults =
    data?.getJobsiteFormSubmission.availableWorkers.edges.map(({ node: worker }): SearchWorkerResult => {
      const jobsiteWorkers = worker.jobsiteWorkers.edges.map(({ node }) => node);

      // try to get the jobsiteWorker corresponding to the form submission jobsite; otherwise get the first one
      const jobsiteWorker =
        jobsiteWorkers.find((jw) => jw.jobsiteContractor.jobsite.jobsiteId === formSubmissionJobsiteId) ??
        jobsiteWorkers?.[0];

      const { firstName, lastName, email, phoneNumber } = worker.user.identity ?? {};

      return {
        id: worker.workerId,
        searchTerm: null,
        firstName,
        lastName,
        email,
        phone: getFormattedPhoneNumber(phoneNumber),
        quickCode: worker.quickCode,
        trade: worker.trade,
        tradeClass: worker.jobTitle,
        unionAffiliation: worker.unionAffiliation,
        badgeId: jobsiteWorker?.stickerNumber?.toString(),
        imageUrl: worker.profilePicture?.downloadUrl,
        jobsitesInfo: jobsiteWorkers.map<JobsiteInfo>((jw) => {
          return {
            jobsiteWorker: { ...jw, contractorWorker: { worker } } as JobsiteWorker,
            jobsiteWorkerId: jw.jobsiteWorkerId,
            isJobsiteAccessAllowed: jw.currentAccess.isAllowed ?? false,
            jobsiteId: jw.jobsiteContractor.jobsite.jobsiteId,
            jobsiteName: jw.jobsiteContractor.jobsite.name,
            contractorName: jw.jobsiteContractor.contractor?.organization?.name,
          };
        }),
      };
    }) ?? [];

  const searchWorkers = React.useCallback(
    (searchTerm: string): void => {
      getFormSubmissionAvailableWorkers({
        id: jobsiteFormSubmissionId,
        availableWorkersInput: { limit: 50, search: searchTerm, includeSiblingJobsites: true },
      });
    },
    [getFormSubmissionAvailableWorkers, jobsiteFormSubmissionId],
  );

  return {
    searchWorkers,
    searchWorkerResults,
    loading,
  };
};
