import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { Button } from '@odin-labs/components';
import { useGetJobsiteWorkerQuery } from 'apollo/generated/client-operations';
import { NavbarContentProps } from 'routes/types';
import { OnboardingStepKey, OnboardingUrlParams } from './types';

export function WorkerOnboardingNavbarContent(props: NavbarContentProps): React.ReactElement {
  const { currentRoute, pathname } = props;

  const currentRoutePath = currentRoute?.path as string;
  const { jobsiteWorkerId, step } = matchPath<OnboardingUrlParams>(pathname, { path: currentRoutePath })?.params ?? {};

  const { data: jobsiteWorkerData } = useGetJobsiteWorkerQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteWorkerId },
    skip: !jobsiteWorkerId,
  });

  const { workerId } = jobsiteWorkerData?.getJobsiteWorker?.contractorWorker?.worker ?? {};

  return (
    <>
      <div className="odin-grow" />
      <div className="odin-flex odin-justify-end">
        <Button
          as={Link}
          to={`/worker/${workerId}`}
          text="Exit to profile"
          className="odin-mr-1.5 hover:odin-text-white"
        />
        <Button
          as={Link}
          to={step === OnboardingStepKey.SiteSpecificOrientation ? '/site-orientations' : '/onboardings'}
          text={step === OnboardingStepKey.SiteSpecificOrientation ? 'Exit to orientations' : 'Exit to onboardings'}
          className="hover:odin-text-white"
        />
      </div>
    </>
  );
}
