import React from 'react';
import cn from 'classnames';

export type CardTitleType = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type CardTitleProps<C extends CardTitleType> = {
  as: C;
} & React.HTMLAttributes<C>;

const fontSizeClasses: Partial<Record<CardTitleType, string>> = {
  h1: cn('odin-text-2.33xl'),
  h2: cn('odin-text-xl'),
  h3: cn('odin-text-[17px]'),
  h4: cn('odin-text-1.5sm'),
};

const classes = {
  cardTitle: (extraClasses: string, type: CardTitleType): string =>
    cn(
      'reactstrap-card-header-title',
      extraClasses,
      'odin-mb-2 -odin-tracking-wide',
      type !== 'p' && 'odin-font-medium',
      fontSizeClasses[type],
    ),
};

export function CardTitle<C extends CardTitleType>(props: CardTitleProps<C>): React.ReactElement {
  const { as: HComponent, className, ...restProps }: { as: React.ElementType } & Omit<CardTitleProps<C>, 'as'> = props;

  return <HComponent className={classes.cardTitle(className, HComponent)} {...restProps} />;
}
