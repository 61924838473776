import React from 'react';
import cn from 'classnames';
import { SectionChildrenGridColumns, SectionGap, SectionProps } from './types';

const childrenGridColumnsClasses: { [key in SectionChildrenGridColumns]: string } = {
  1: cn('odin-grid-cols-1'),
  2: cn('odin-grid-cols-2'),
  3: cn('odin-grid-cols-3'),
  4: cn('odin-grid-cols-4'),
  5: cn('odin-grid-cols-5'),
  6: cn('odin-grid-cols-6'),
  7: cn('odin-grid-cols-7'),
  8: cn('odin-grid-cols-8'),
  9: cn('odin-grid-cols-9'),
  10: cn('odin-grid-cols-10'),
  11: cn('odin-grid-cols-11'),
  12: cn('odin-grid-cols-12'),
};

const horizontalGapClasses: { [key in SectionGap]: string } = {
  xs: cn('odin-gap-x-4'),
  sm: cn('odin-gap-x-5'),
  base: cn('odin-gap-x-6'),
  lg: cn('odin-gap-x-7'),
  xl: cn('odin-gap-x-8'),
};

const verticalGapClasses: { [key in SectionGap]: string } = {
  xs: cn('odin-gap-y-4'),
  sm: cn('odin-gap-y-5'),
  base: cn('odin-gap-y-6'),
  lg: cn('odin-gap-y-7'),
  xl: cn('odin-gap-y-8'),
};

export function Section(props: SectionProps): React.ReactElement {
  const { children, id, className, title, details, horizontalGap, verticalGap, childrenGridColumns = '6' } = props;
  return (
    <div
      id={id}
      className={cn('md:odin-grid md:odin-grid-cols-3 md:odin-gap-6 odin-bg-white odin-pt-5 sm:odin-pt-9', className)}
    >
      <div className="md:odin-col-span-1">
        <h3 className="odin-text-lg odin-font-medium odin-leading-6 odin-text-gray-900">{title}</h3>
        <p className="odin-mt-1 odin-text-sm odin-text-gray-500">{details}</p>
      </div>
      <div
        className={cn(
          'odin-mt-5 md:odin-mt-0 md:odin-col-span-2',
          'odin-flex odin-flex-col odin-gap-6',
          'md:odin-grid',
          childrenGridColumnsClasses[childrenGridColumns],
          horizontalGapClasses[horizontalGap],
          verticalGapClasses[verticalGap],
        )}
      >
        {children}
      </div>
    </div>
  );
}
