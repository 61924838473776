import React, { ReactElement } from 'react';
import JSONTree from 'react-json-tree';
import { Modal } from '@odin-labs/components';
import { JsonTreeModalProps } from './types';

export function JsonTreeModal({ isOpen = false, closeModal, onClosed, data, title }: JsonTreeModalProps): ReactElement {
  return (
    <Modal
      open={isOpen}
      onAction={closeModal}
      setOpen={closeModal}
      afterLeave={onClosed}
      title={title}
      actionText="Close"
      // shouldHideCancel
    >
      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
        <JSONTree data={data} />
      </div>
    </Modal>
  );
}
