import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { BuildingColumnsIcon } from 'components/icons';
import { EditJobsiteTemplateFormData } from './types';

export const getAsNumber = (value: string | number): number => {
  if (!value) return null;
  if (typeof value === 'number') return value;
  return Number(value.replace('\u00B0', ''));
};

export const getFormInputs = ({
  developersOptions,
}: {
  developersOptions: SelectOptionElement[];
}): TypedFormInputs<EditJobsiteTemplateFormData> => ({
  name: {
    element: FormInputTypes.Field,
    label: 'Name',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  developerId: {
    element: FormInputTypes.Select,
    label: 'Client',
    elementProps: {
      options: developersOptions,
      icon: BuildingColumnsIcon,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
});
