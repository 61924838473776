import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  AvatarCell,
  AvatarCellComponentProps,
  AvatarSkeleton,
  ChipProps,
  ChipsCell,
  ChipsCellComponentProps,
  ChipsSkeleton,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  FilterItems,
  Icon,
  MenuItemProps,
  getFixedWidthFaIcon,
  getFaIcon,
} from '@odin-labs/components';
import { ensureNonEmptyItems, getInitialsForUser } from 'utils';
import { isAfterToday } from 'utils/dates';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faCreditCardFront,
  faCheck,
  faTrash,
} from 'components/icons';
import {
  ContractorPaymentMethod,
  ContractorSubcontractorColumn,
  ContractorSubcontractorFilters,
  ContractorWorker,
  ContractorWorkerColumn,
  ContractorWorkersFilters,
  Jobsite,
  StripePaymentMethodColumn,
  Subcontractor,
} from 'containers/contractor/types';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import { StripePaymentMethod } from 'apollo/generated/client-operations';
import { generateStripePaymentMethodDisplayValue } from './utils';

const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });
const CheckIcon = getFixedWidthFaIcon({ icon: faCheck });

// ContractorWorker
export const getWorkerColumns = ({
  user,
  openRemoveContractorWorkerModal,
}: {
  user: AuthUser;
  openRemoveContractorWorkerModal: (contractorMemberId: string) => void;
}): ContractorWorkerColumn[] => {
  const canRemoveContractorWorkers = user.isAllowed(to.removeContractorWorkers);
  const isActionsButtonVisible = canRemoveContractorWorkers;

  return ensureNonEmptyItems<ContractorWorkerColumn>([
    {
      id: 'worker',
      Header: 'Worker',
      accessor: (contractorWorker: ContractorWorker): string => {
        const { firstName, lastName } = contractorWorker.worker.user.identity ?? {};
        return [firstName, lastName].join(' ');
      },
      Cell: AvatarCell,
      CellLoading: <AvatarSkeleton size="xs" hideDetails />,
      componentProps: (contractorWorker: ContractorWorker): AvatarCellComponentProps => ({
        src: contractorWorker.worker.profilePictureCropped?.downloadUrl,
        placeholder: getInitialsForUser(contractorWorker.worker.user),
        objectFit: 'cover',
        size: 'xs',
      }),
      disableSortBy: true,
    },
    {
      id: 'quickCode',
      Header: <>Quick&nbsp;Code</>,
      accessor: (contractorWorker: ContractorWorker): string => contractorWorker.worker.quickCode,
      disableSortBy: true,
    },
    {
      id: 'badgeNumber',
      Header: <>Badge&nbsp;Numbers</>,
      accessor: (contractorWorker: ContractorWorker): string =>
        contractorWorker.worker.workerCards.edges.map(({ node }) => node.cardNumber).join(', '),
      disableSortBy: true,
    },
    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      accessor: (): string[] => [],
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (contractorWorker: ContractorWorker): ChipsCellComponentProps => ({
        chips: contractorWorker.jobsiteWorkers.edges.map(({ node }) => ({
          text: node.jobsiteContractor.jobsite.name,
          secondaryText: node.jobsiteContractor.contractor.organization.name,
          isActive: node.currentAccess.isAllowed,
        })),
      }),
      disableSortBy: true,
    },
    isActionsButtonVisible && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (contractorWorker: ContractorWorker): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<MenuItemProps>([
          canRemoveContractorWorkers && {
            icon: TrashIcon,
            text: 'Remove Contractor',
            theme: 'danger',
            onClick: (): void => openRemoveContractorWorkerModal(contractorWorker.id),
          },
        ]),
      }),
    },
  ]);
};

export const getWorkerFilterItems = ({
  jobsites,
  jobsiteIds,
  search,
}: {
  jobsites: Jobsite[];
  jobsiteIds: string[];
  search: string;
}): FilterItems<ContractorWorkersFilters> => ({
  jobsiteIds: {
    header: 'Jobsite',
    type: 'dropdown',
    defaultValue: jobsiteIds,
    componentProps: {
      options: jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label })) ?? [],
      isMulti: true,
    },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

// Subcontractor
export const getSubcontractorColumns = (menuItems?: MenuItemProps[]): ContractorSubcontractorColumn[] =>
  ensureNonEmptyItems<ContractorSubcontractorColumn>([
    {
      id: 'contractor',
      Header: 'Contractor',
      accessor: (contractor: Subcontractor): string => contractor.name,
      disableSortBy: true,
    },

    {
      id: 'jobsiteAssignments',
      Header: 'Jobsite Assignments',
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (contractor: Subcontractor): ChipsCellComponentProps => ({
        chips: contractor.jobsiteContractors.map<ChipProps>((jobsiteContractor) => ({
          text: jobsiteContractor.jobsite.name,
          secondaryText: `${jobsiteContractor.jobsiteWorkers.count} workers `,
          isActive: !jobsiteContractor.endDate || isAfterToday(jobsiteContractor.endDate),
        })),
      }),
      disableSortBy: true,
    },
    menuItems?.length && {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems,
      }),
    },
  ]);

export const ccIconsByBrand: Record<string, IconDefinition> = {
  visa: faCcVisa,
  mastercard: faCcMastercard,
  amex: faCcAmex,
  discover: faCcDiscover,
  jcb: faCcJcb,
  diners: faCcDinersClub,
};

export const getCreditCardIcon = (brand: string): Icon => {
  return getFaIcon({ icon: ccIconsByBrand[brand] ?? faCreditCardFront, className: 'fa-xl' });
};

// Not currently used; subcontractor filters are not implemented
export const getSubcontractorFilterItems = (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  _args: any,
): FilterItems<ContractorSubcontractorFilters> => ({});

export const getPaymentMethodsColumns = ({
  openRemovePaymentMethodModal,
  openSetDefaultPaymentMethodModal,
}: {
  openRemovePaymentMethodModal: (paymentMethodId: string) => void;
  openSetDefaultPaymentMethodModal: (paymentMethodId: string) => void;
}): StripePaymentMethodColumn[] =>
  ensureNonEmptyItems<StripePaymentMethodColumn>([
    {
      id: 'brand',
      Header: 'Brand',
      accessor: (paymentMethod: StripePaymentMethod): string => paymentMethod.brand,
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (pm: StripePaymentMethod): ChipsCellComponentProps => ({
        chips: ensureNonEmptyItems([
          {
            text: pm.brand,
            isActive: pm.isDefault,
            withDot: false,
          },
        ]),
      }),
      disableSortBy: true,
    },
    {
      id: 'last4',
      Header: 'Card',
      // accessor: generateStripePaymentMethodDisplayValue,
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (pm: StripePaymentMethod): ChipsCellComponentProps => ({
        chips: ensureNonEmptyItems([
          {
            text: generateStripePaymentMethodDisplayValue(pm),
            isActive: pm.isDefault,
            icon: getCreditCardIcon(pm.brand),
            withDot: false,
          },
          pm.isDefault && {
            text: '',
            secondaryText: 'default',
            icon: CheckIcon,
            withDot: false,
          },
        ]),
      }),
      disableSortBy: true,
    },
    {
      id: 'jobsites',
      Header: 'Jobsites',
      Cell: ChipsCell,
      CellLoading: ChipsSkeleton,
      componentProps: (paymentMethod: ContractorPaymentMethod): ChipsCellComponentProps => ({
        chips: paymentMethod.jobsites?.map((jobsite) => ({
          text: jobsite.name,
          isActive: !jobsite.endDate || isAfterToday(jobsite.endDate),
        })),
      }),
      // accessor: (paymentMethod: any): string => paymentMethod.jobsites?.toString(),
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'actions',
      Cell: DropdownButtonCell,
      CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
      componentProps: (paymentMethod: ContractorPaymentMethod): DropdownButtonCellComponentProps => ({
        cellAlignment: 'right',
        menuItems: ensureNonEmptyItems<MenuItemProps>([
          {
            icon: CheckIcon,
            disabled: paymentMethod.isDefault,
            text: 'Make this default',
            onClick: (): void => openSetDefaultPaymentMethodModal(paymentMethod.id),
          },
          {
            icon: TrashIcon,
            text: 'Remove this payment method',
            theme: 'danger',
            onClick: (): void => openRemovePaymentMethodModal(paymentMethod.id),
          },
        ]),
      }),
    },
  ]);
