import React from 'react';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { ensureNonEmptyItems } from 'utils';
import { alcoholBreathTestOptions, medicalTestOptions } from 'utils/constants';
import { FormInput, UseFormMethods, UseInputs } from 'components/form';
import {
  generateJobsiteMedicalWorkerDocument,
  getDocumentPreviewUrl,
  getDocumentsWithExistingDocumentAlerts,
  JobsiteMedicalWorkerDocumentArgs,
  medicalConditionsInputs,
} from 'containers/workerOnboarding/helpers/forms';
import { CONDITIONAL_PASS } from 'containers/workerOnboarding/helpers/useMedicalDocumentsUpload';
import { OnboardingStepKey, OnboardingStepProps, WorkerDocumentsDataType } from 'containers/workerOnboarding/types';
import { DocumentKey } from 'containers/worker/utils';
import { getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';

const medicalDocumentsKeys = [
  DocumentKey.MedicalDrugTestingConsentForm,
  DocumentKey.MedicalBreathAlcoholTest,
  DocumentKey.MedicalUrineDrugTest,
  DocumentKey.MedicalCovid19OrientationAndProcedures,
] as const;
type MedicalDocumentKey = typeof medicalDocumentsKeys[number];

type MedicalDocumentConfig = Omit<
  JobsiteMedicalWorkerDocumentArgs,
  'documentKey' | 'previewUrl' | 'showExistingDocumentAlert'
>;

type GetFormInputsArgs = Pick<
  OnboardingStepProps,
  'jobsiteWorker' | 'defaultFormValues' | 'filteredDocumentTypeIds' | 'documents'
>;

const medicalDocumentsConfig: Record<MedicalDocumentKey, MedicalDocumentConfig> = {
  [DocumentKey.MedicalDrugTestingConsentForm]: {
    label: 'Drug Testing Consent Form',
    showDateSigned: true,
    requiredFields: ['date-signed', 'front'],
  },
  [DocumentKey.MedicalBreathAlcoholTest]: {
    label: 'Breath alcohol test',
    selectOptions: alcoholBreathTestOptions,
    requiredFields: ['result', 'front'],
  },
  [DocumentKey.MedicalUrineDrugTest]: {
    label: 'Urine drug test',
    selectOptions: medicalTestOptions,
    requiredFields: ['result', 'front'],
  },
  [DocumentKey.MedicalCovid19OrientationAndProcedures]: {
    label: 'COVID-19 Orientation and Procedures',
    showDateSigned: true,
    requiredFields: ['date-signed', 'front'],
  },
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<WorkerDocumentsDataType> =>
  (form: UseFormMethods<WorkerDocumentsDataType>): FormInput<WorkerDocumentsDataType>[] => {
    const { jobsiteWorker, defaultFormValues, filteredDocumentTypeIds, documents } = args;
    const { watch } = form ?? {};
    const { dirtyFields } = form?.formState ?? {};
    const [showConditionalDrug, setShowConditionalDrug] = React.useState<boolean>();

    const urineDrugTestResultOption = watch?.(`${DocumentKey.MedicalUrineDrugTest}-result`) as SelectOptionElement;

    useDidUpdateEffect(() => {
      setShowConditionalDrug(urineDrugTestResultOption?.value === CONDITIONAL_PASS);
    }, [urineDrugTestResultOption]);

    const visibleDocuments = getVisibleDocuments(jobsiteWorker);

    const generateJobsiteMedicalWorkerDocumentIfAssigned = (
      docArgs: JobsiteMedicalWorkerDocumentArgs,
    ): FormInput<WorkerDocumentsDataType> | undefined => {
      return (
        filteredDocumentTypeIds[docArgs.documentKey] &&
        visibleDocuments?.includes(docArgs.documentKey) &&
        generateJobsiteMedicalWorkerDocument(docArgs)
      );
    };

    const withFilesDocumentIds = Object.fromEntries(
      documents
        .filter(({ key: doc }) =>
          defaultFormValues.some(
            ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName as string),
          ),
        )
        .map(({ key, id }) => [key, id]),
    );

    const getPreviewUrl = (documentKey: string): string => {
      return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
    };

    const documentsWithExistingDocumentAlerts = getDocumentsWithExistingDocumentAlerts({
      stepKey: OnboardingStepKey.MedicalInfo,
      jobsiteWorker,
      documentKeys: medicalDocumentsKeys,
      documents,
    });

    const getShowExistingDocumentAlert = (documentKey: string): boolean => {
      return documentsWithExistingDocumentAlerts[documentKey];
    };

    return ensureNonEmptyItems<FormInput<WorkerDocumentsDataType>>([
      ...Object.entries(medicalDocumentsConfig).map(([documentKey, medicalDocumentConfig]) => {
        return generateJobsiteMedicalWorkerDocumentIfAssigned({
          ...medicalDocumentConfig,
          documentKey,
          previewUrl: getPreviewUrl(documentKey),
          showExistingDocumentAlert: getShowExistingDocumentAlert(documentKey),
          suppressRequiredWhenDocIsNotDirty: true,
          dirtyFields,
          showExpiration:
            documentKey === DocumentKey.MedicalUrineDrugTest &&
            (showConditionalDrug ??
              defaultFormValues?.some(
                ({ name, value }) =>
                  name === `${DocumentKey.MedicalUrineDrugTest}-result` && value === CONDITIONAL_PASS,
              )),
        });
      }),
      medicalConditionsInputs(),
    ]);
  };

type GetDefaultValuesArgs = Pick<OnboardingStepProps, 'jobsiteWorker' | 'defaultFormValues'>;

export const getDefaultValues = (args: GetDefaultValuesArgs): WorkerDocumentsDataType => {
  const { jobsiteWorker, defaultFormValues } = args;
  return {
    ...Object.fromEntries(defaultFormValues.map(({ name, value }) => [name, value])),
    medicalNotes: jobsiteWorker?.medicalNotes ?? '',
    ...Object.entries(medicalDocumentsConfig)
      .filter(([, config]) => config.selectOptions)
      .reduce(
        (acc, [documentKey, config]) => ({
          ...acc,
          [`${documentKey}-result`]:
            config.selectOptions.find(
              ({ value }) => value === defaultFormValues.find(({ name }) => name === `${documentKey}-result`)?.value,
            ) ?? null,
        }),
        {},
      ),
  };
};
