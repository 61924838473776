import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AuthContext } from 'auth';
import type { ReactElement } from 'react';
import logo from 'images/ODIN.svg';
import { SidebarContainerProps, SidebarItem, SidebarProps } from './types';
import { getClasses } from './utils';
import { MobileSidebar } from './MobileSidebar';
import { TabletSidebar } from './TabletSidebar';
import { DesktopSidebar } from './DesktopSidebar';

export function DefaultSidebarContainer(props: SidebarContainerProps): ReactElement {
  const { routes, isSidebarOpen, closeSidebar } = props;
  const { pathname: locationPathname } = useLocation();
  const [appVersion, setAppVersion] = useState<string>('');

  const { currentUser: user } = React.useContext(AuthContext);

  useEffect(() => {
    async function fetchHealthCheck(): Promise<void> {
      const res = await fetch('/health');
      const { version } = await res.json();
      setAppVersion(version);
    }

    fetchHealthCheck();
  }, []);

  const sidebarItems = routes
    .filter((route) => route?.sidebar)
    .sort((a, b) => a.sidebar.order - b.sidebar.order)
    .map((route) => ({
      key: route.sidebar.pathname,
      acl: route.acl,
      icon: route.sidebar.icon,
      path: route.sidebar.pathname,
      text: route.sidebar.item,
      locked: route.sidebar.locked,
    }));

  const getItemProps = (item: SidebarItem): { isLocked: boolean; isActive: boolean } => {
    const { locked, path } = item;
    return {
      isLocked: typeof locked === 'function' ? locked(user) : locked,
      isActive: !!matchPath(locationPathname, { path }),
    };
  };

  const sidebarHeader = <img className="odin-h-6 odin-w-auto odin-m-2" src={logo} alt="ODIN" />;
  const sidebarFooter = appVersion && <span className="odin-text-base">{`v${appVersion}`}</span>;

  const sidebarProps: SidebarProps = {
    isSidebarOpen,
    closeSidebar,
    items: sidebarItems,
    getItemProps,
    classes: getClasses(),
    header: sidebarHeader,
    footer: sidebarFooter,
  };

  return (
    <>
      <MobileSidebar {...sidebarProps} />
      <TabletSidebar {...sidebarProps} />
      <DesktopSidebar {...sidebarProps} />
    </>
  );
}
