import React from 'react';
import { InfoRowProps } from 'components/infoRow/types';
import {
  JobsiteInvitation,
  Localize,
  SelfOnboardingState,
  SelfOnboardingStepConfig,
  SelfOnboardingStepKey,
} from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { getFormattedPhoneNumber, typedAs } from 'utils';
import { FormFieldsConfig, getFormFieldConfig } from 'components/form';
import { getStepConfig } from 'containers/selfOnboarding/helpers/utils';
import { getFieldsConfig } from 'containers/selfOnboarding/helpers/forms';

type EditField =
  | keyof SelfOnboardingState['basicInfo']
  | keyof SelfOnboardingState['profile']
  | keyof SelfOnboardingState['emergencyContact'];

export type WorkerInfoRowConfig = Omit<InfoRowProps, 'onEditClick'> & {
  editStep: SelfOnboardingStepKey;
  editField: EditField;
  isHidden?: boolean;
};

const getName = ({
  firstName,
  middleInitial,
  lastName,
}: { firstName?: string; middleInitial?: string; lastName?: string } = {}): string =>
  [firstName, middleInitial, lastName].filter(Boolean).join(' ');

type GetInfoRowsArgs = {
  state: SelfOnboardingState;
  localize: Localize;
  jobsiteInvitation: JobsiteInvitation;
};

export function getInfoRows(args: GetInfoRowsArgs): WorkerInfoRowConfig[] {
  const { state, localize, jobsiteInvitation } = args;
  const { basicInfo, profile, emergencyContact, worker } = state ?? {};
  const { editableFields: workerEditableFields, isAssignedToMultipleJobsites: isWorkerAssignedToMultipleJobsites } =
    worker ?? {};
  const { email, birthDate, phoneNumber, ssnLastFour } = basicInfo ?? {};
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    primaryLanguage,
    race,
    gender,
    isVeteran,
    trade,
    jobTitle,
    unionAffiliation,
  } = profile ?? {};
  const { emergencyContactName, emergencyContactPhone, emergencyContactRelationship } = emergencyContact ?? {};

  const address = profile && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState?.label].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  const emailLink = email && (
    <a href={`mailto:${email}`} target="blank">
      {email}
    </a>
  );
  const phoneNumberLink = phoneNumber && <a href={`tel:${phoneNumber}`}>{getFormattedPhoneNumber(phoneNumber)}</a>;

  const stepConfigs: Partial<Record<SelfOnboardingStepKey, SelfOnboardingStepConfig>> = {};
  const resolveFieldsConfig = (stepKey: SelfOnboardingStepKey): FormFieldsConfig => {
    const stepConfig =
      stepConfigs[stepKey] ??
      (stepConfigs[stepKey] = getStepConfig(jobsiteInvitation?.jobsiteContractor.jobsite.modules, stepKey));
    return getFieldsConfig(stepConfig.fields);
  };

  const isFieldHidden = (stepKey: SelfOnboardingStepKey, field: EditField): boolean => {
    const fieldsConfig = resolveFieldsConfig(stepKey);
    const { isHidden: isHiddenFromConfig } = getFormFieldConfig(field, fieldsConfig) ?? {};
    return !!isHiddenFromConfig;
  };

  return typedAs<WorkerInfoRowConfig[]>([
    {
      label: localize(Copy.full_name_label),
      value: getName(basicInfo),
      editStep: SelfOnboardingStepKey.BasicInfo,
      editField: 'firstName',
    },
    {
      label: localize(Copy.birth_date_label),
      value: birthDate,
      editStep: SelfOnboardingStepKey.BasicInfo,
      editField: 'birthDate',
    },
    {
      label: localize(Copy.ssn_last_four_label),
      value: ssnLastFour,
      editStep: SelfOnboardingStepKey.BasicInfo,
      editField: 'ssnLastFour',
    },
    {
      label: localize(Copy.address_line_1_label),
      value: address,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'addressLine1',
    },
    {
      label: localize(Copy.phone_number_label),
      value: phoneNumberLink,
      editStep: SelfOnboardingStepKey.BasicInfo,
      editField: 'phoneNumber',
      allowEdit: !isWorkerAssignedToMultipleJobsites && (workerEditableFields?.phone ?? false),
    },
    {
      label: localize(Copy.email_label),
      value: emailLink,
      editStep: SelfOnboardingStepKey.BasicInfo,
      editField: 'email',
      allowEdit: !isWorkerAssignedToMultipleJobsites && (workerEditableFields?.email ?? false),
    },
    {
      label: localize(Copy.gender_label),
      value: gender?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'gender',
    },
    {
      label: localize(Copy.race_label),
      value: race?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'race',
    },
    {
      label: localize(Copy.primary_language_label),
      value: primaryLanguage?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'primaryLanguage',
    },
    {
      label: localize(Copy.union_affiliation_label),
      value: unionAffiliation?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'unionAffiliation',
    },
    {
      label: localize(Copy.is_veteran_label),
      value: isVeteran?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'isVeteran',
    },
    {
      label: localize(Copy.trade_label),
      value: trade?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'trade',
    },
    {
      label: localize(Copy.job_title_label),
      value: jobTitle?.label,
      editStep: SelfOnboardingStepKey.Profile,
      editField: 'jobTitle',
    },
    {
      label: localize(Copy.emergency_contact_name_label),
      value: emergencyContactName,
      editStep: SelfOnboardingStepKey.EmergencyContactInfo,
      editField: 'emergencyContactName',
    },
    {
      label: localize(Copy.emergency_contact_relationship_label),
      value: emergencyContactRelationship?.label,
      editStep: SelfOnboardingStepKey.EmergencyContactInfo,
      editField: 'emergencyContactRelationship',
    },
    {
      label: localize(Copy.emergency_contact_phone_label),
      value: getFormattedPhoneNumber(emergencyContactPhone),
      editStep: SelfOnboardingStepKey.EmergencyContactInfo,
      editField: 'emergencyContactPhone',
    },
  ]).filter(({ editStep, editField }) => !isFieldHidden(editStep, editField));
}
