import React, { ReactElement } from 'react';
import cn from 'classnames';
import { ServerFile } from 'types';
import { FilePreview } from 'components/filePreview';
import { PdfPreview } from './PdfPreview';

export type ThumbnailSize = 'md' | 'lg' | 'full';

export const sizeClasses: Record<ThumbnailSize, string> = {
  md: 'odin-w-14 odin-h-14',
  lg: 'odin-w-16 odin-h-16',
  full: 'odin-w-full odin-h-full',
};

export type ThumbnailProps = {
  file: ServerFile;
  className?: string;
  size?: ThumbnailSize;
};

export function Thumbnail(props: ThumbnailProps): ReactElement {
  const { file, className, size = 'lg' } = props;
  const { fileType, originalFileName, downloadUrl } = file ?? {};
  const isPdf = fileType?.toLowerCase() === 'pdf';
  const alt = originalFileName;

  const classes = cn(
    'odin-rounded-md',
    sizeClasses[size],
    isPdf ? 'odin-p-1 odin-bg-[#F9FBFD] odin-border odin-border-[#E3EBF6] odin-max-w-full' : 'odin-object-cover',
    className,
  );

  if (isPdf) {
    return <PdfPreview isThumbnail fileURL={downloadUrl} alt={alt} className={classes} />;
  }
  return <FilePreview file={file} alt={alt} className={classes} />;
}
