import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { ArrowToRightIcon, BriefcaseIcon } from 'components/icons';
import { FormInputTypes, TypedFormInputs, UseInputs, UseFormMethods } from 'components/form';
import { JobsiteAccessEventFormData } from 'containers/worker/types';
import { getJobsiteDateTimeNow } from 'utils/dates';
import { timeValidation } from 'utils/validation';

const checkInDirectionOptions: SelectOptionElement[] = ['Inbound', 'Outbound'].map((direction) => ({
  value: direction,
  label: direction,
}));

const reasonOptions: SelectOptionElement[] = [
  'Worker forgot to clock in',
  'Worker forgot to clock out',
  'Worker does not have Mobile App',
].map((reason) => ({ value: reason, label: reason }));

export const getAddJobsiteAccessEventFormInputsHook =
  ({ jobsiteOptions }: { jobsiteOptions: SelectOptionElement[] }): UseInputs<JobsiteAccessEventFormData> =>
  ({ watch, setValue }: UseFormMethods<JobsiteAccessEventFormData>): TypedFormInputs<JobsiteAccessEventFormData> => {
    const selectedJobsiteOption = watch('jobsiteId');

    useDidUpdateEffect(() => {
      if (selectedJobsiteOption) {
        const { date, time } = getJobsiteDateTimeNow(selectedJobsiteOption);
        setValue('checkInDate', date);
        setValue('checkInTime', time);
      }
    }, [selectedJobsiteOption?.timeZone]);

    return React.useMemo<TypedFormInputs<JobsiteAccessEventFormData>>(
      () => ({
        jobsiteId: {
          element: FormInputTypes.Select,
          elementProps: {
            placeholder: 'Select jobsite',
            label: 'Jobsite',
            options: jobsiteOptions,
            icon: BriefcaseIcon,
            disabled: (jobsiteOptions?.length ?? 0) < 2,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
        checkInDirection: {
          element: FormInputTypes.Select,
          elementProps: {
            placeholder: 'Select direction',
            label: 'Direction',
            options: checkInDirectionOptions,
            icon: ArrowToRightIcon,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
        checkInDate: {
          element: FormInputTypes.DatePicker,
          elementProps: {
            label: 'Date',
            maxDate: moment().add(1, 'month'),
            showDefaultIcon: true,
            numberOfMonths: 1,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6 sm:odin-col-span-3'),
        },
        checkInTime: {
          element: FormInputTypes.Field,
          elementProps: {
            label: 'Time',
            placeholder: 'hh:mm',
            fieldType: 'time',
            showDefaultIcon: true,
          },
          validation: {
            pattern: timeValidation,
            required: true,
          },
          layout: cn('odin-col-span-6 sm:odin-col-span-3'),
        },
        reason: {
          element: FormInputTypes.Select,
          elementProps: {
            placeholder: 'Select reason',
            label: 'Reason',
            options: reasonOptions,
          },
          validation: {
            required: true,
          },
          layout: cn('odin-col-span-6'),
        },
      }),
      [jobsiteOptions],
    );
  };

export const getAddJobsiteAccessEventFormDefaultValues = ({
  jobsiteOptions,
}: {
  jobsiteOptions: JobsiteAccessEventFormData['jobsiteId'][];
}): JobsiteAccessEventFormData => {
  const defaultJobsiteOption = jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null;
  const { date = '', time = '' } = defaultJobsiteOption ? getJobsiteDateTimeNow(defaultJobsiteOption) : {};

  return {
    jobsiteId: jobsiteOptions?.length === 1 ? jobsiteOptions[0] : null,
    checkInDirection: null,
    checkInDate: date,
    checkInTime: time,
    reason: null,
  };
};
