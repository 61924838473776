import { gql } from '@apollo/client';
import { Jobsite } from 'apollo/generated/client-operations';

export const FILE_UPLOAD = gql`
  mutation UploadSingleFile($fileInput: FileInput!) {
    uploadSingleFile(fileInput: $fileInput) {
      fileId
      fileType
      downloadUrl
    }
  }
`;

export const GET_JOBSITE_WORKER_DOCUMENT_TYPES = gql`
  query GetAllJobsiteWorkerDocumentTypes($jobsiteId: ID!) {
    getAllJobsiteWorkerDocumentTypes(jobsiteId: $jobsiteId) {
      id
      workerDocumentType {
        key
      }
      additionalFields {
        key
        name
      }
    }
  }
`;

export interface GetJobsiteQueryResponse {
  getJobsite: Jobsite;
}

export const GET_JOBSITE = gql`
  query GetJobsite($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      name
    }
  }
`;

export const GET_ALL_DOCUMENTS_FOR_JOBSITE_WORKER = gql`
  query GetAllDocumentsForJobsiteWorker($jobsiteWorkerId: ID!, $includePhi: Boolean) {
    getAllDocumentsForJobsiteWorker(jobsiteWorkerId: $jobsiteWorkerId, includePhi: $includePhi) {
      jobsiteWorkerDocumentId
      key
      jobsiteWorkerDocumentType {
        jobsite {
          jobsiteId
        }
        workerDocumentType {
          key
          name
        }
        additionalFields {
          key
          name
        }
      }
      objectHistory {
        createdAt
        updatedAt
      }
      jobsiteWorkerDocumentVersions {
        jobsiteWorkerDocumentVersionId
        files {
          fileId
          fileType
          uploadedAt
          downloadUrl
          originalFileName
        }
        objectHistory {
          createdAt
          updatedAt
        }
        additionalFieldValues {
          key
          value
        }
      }
    }
  }
`;

export const GET_ALL_CONTRACTORS_FOR_WORKER = gql`
  query GetAllContractorsForWorker($workerId: ID!) {
    getAllContractorsForWorker(workerId: $workerId) {
      startDate
      endDate
      contractor {
        organization {
          name
        }
      }
    }
  }
`;

export const IS_WORKER_CARD_ASSIGNED = gql`
  query IsWorkerCardAssigned($cardNumber: String!, $cardType: WorkerCardType!) {
    isWorkerCardAssigned(cardNumber: $cardNumber, cardType: $cardType) {
      success
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation UpdateWorker($workerId: ID!, $workerInput: WorkerInput!) {
    updateWorker(workerId: $workerId, workerInput: $workerInput) {
      birthDate
      ssnLastFour
      workerId

      user {
        userAccountId
        createdAt
        identity {
          firstName
          lastName
          createdAt
          updatedAt
        }
        roles {
          key
          displayName
          roleCreatedAt
          roleUpdatedAt
          mappingCreatedAt
        }
      }
    }
  }
`;
