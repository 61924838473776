import React, { ReactElement, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  GetWorkerCardsDocument,
  MutationRemoveCardFromWorkerArgs,
  Success,
  WorkerCard,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { CheckIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertService } from 'components/alertService';
import { getFormInputs, getFormDefaultValues } from './DeactivateBadgesModalContent.forms';
import { DeactivateWorkerBadgesData } from './types';

export interface DeactivateBadgesModalContentProps {
  setOpen: (open: boolean) => void;
  onAction: () => void;
  workerCards: WorkerCard[];
  workerCardType: WorkerCardType;
}

export function DeactivateBadgesModalContent(props: DeactivateBadgesModalContentProps): ReactElement {
  const { workerCards = [], setOpen, onAction } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    refetchQueries: [GetWorkerCardsDocument],
  });

  const onSubmit: FormOnSubmit<DeactivateWorkerBadgesData> = async (data, event): Promise<void> => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      const { badges } = data;

      const workerCardIdsToDeactivate = badges
        .filter(({ selected }) => selected)
        .map(({ workerCard }) => workerCard.workerCardId);

      if (workerCardIdsToDeactivate.length) {
        await removeCardFromWorker({
          variables: {
            workerCardIds: workerCardIdsToDeactivate,
          },
        });
      }

      onAction();
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getFormDefaultValues(workerCards), [workerCards]);

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Deactivate"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
