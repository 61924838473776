import {
  GetDeveloperContainerDeveloperInfoDocument,
  useDeveloperUpdateMutation,
} from 'apollo/generated/client-operations';
import { CheckIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { FormOnSubmit, ModalForm } from 'components/form';
import React, { ReactElement, useState } from 'react';
import { isEmpty, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getDefaultValues, getFormInputs, getUpdateInput } from './EditDeveloperModal.forms';
import { EditDeveloperFormData, EditDeveloperModalProps } from './types';

export function EditDeveloperModal(props: EditDeveloperModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, developer } = props;
  const { developerId } = developer ?? {};

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [developerUpdate] = useDeveloperUpdateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetDeveloperContainerDeveloperInfoDocument],
  });

  const onSubmit: FormOnSubmit<EditDeveloperFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isFetching) return;
    setFetching(true);
    try {
      if (isEmpty(dirtyFields)) return;
      const input = getUpdateInput(developerId, data, dirtyFields);
      await developerUpdate({ variables: { input } });

      onConfirm();
      AlertService.alert('success', 'Success', 'Client successfully updated');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setFetching(false);
    }
  };

  const formInputs = React.useMemo(() => getFormInputs(), [developer]);
  const defaultValues = React.useMemo(() => getDefaultValues(developer), [developer]);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Edit Client Info"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Save Changes"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
