import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AuthContext } from 'auth';
import type { ReactElement } from 'react';
import { generatePath } from 'utils/generatePath';
import { LockIcon } from 'components/icons';
import { SidebarProps } from './types';

export function TabletSidebar(props: SidebarProps): ReactElement {
  const { items, getItemProps, classes, header, footer } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  return (
    <div className={cn(classes.background, 'sidebar-tablet odin-hidden md:odin-flex md:odin-flex-col xl:odin-hidden')}>
      <div className="odin-w-full odin-py-6 odin-flex odin-flex-col odin-grow odin-items-center odin-overflow-y-auto">
        <div className={classes.header('tablet')}>{header}</div>
        <nav className="odin-flex odin-flex-col odin-mt-5 odin-w-full odin-px-3 odin-gap-y-1.5">
          {items.map((item): ReactElement => {
            if (!item.acl || user.hasAnyRole(item.acl)) {
              const { isLocked, isActive } = getItemProps(item);
              const { icon: Icon, status } = item;
              const { text: statusText, color: statusColor, icon: StatusIcon } = status ?? {};

              return (
                <Link
                  key={item.path}
                  to={generatePath(item.path, {})}
                  className={cn(classes.item(isActive, 'tablet'), 'odin-flex-col odin-p-2')}
                >
                  <div className="odin-flex odin-flex-col odin-items-center odin-text-sm">
                    {Icon && (
                      <Icon
                        className="odin-text-xl odin-w-8 odin-h-8 
                    !odin-flex odin-justify-center odin-items-center"
                      />
                    )}
                    <span className="odin-mt-1 odin-text-center">{item.text}</span>
                    {isLocked && <LockIcon />}
                  </div>
                  {statusText && (
                    <div className={cn(statusColor, 'odin-flex odin-items-center odin-mt-2 odin-gap-x-2')}>
                      {StatusIcon && <StatusIcon />} {statusText}
                    </div>
                  )}
                </Link>
              );
            }
            return null;
          })}
        </nav>
      </div>
      <div className={classes.footer('tablet')}>{footer}</div>
    </div>
  );
}
