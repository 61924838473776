import React from 'react';
import { Button } from '@odin-labs/components';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import { FilePlusIcon } from 'components/icons';
import {
  DeleteFormSubmissionDocumentModal,
  EditFormSubmissionDocumentModal,
  EditableJobsiteFormSubmissionDocument,
  AddFormSubmissionDocumentModal,
} from './modals';
import { FormSubmissionDocumentsProps } from './types';
import { FormSubmissionDocument } from './FormSubmissionDocument';

export const FormSubmissionDocuments = React.forwardRef(
  (props: FormSubmissionDocumentsProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange } = props;

    const { change, closeModal, openAddModal, openEditModal, openDeleteModal, updateValueAndCloseModal } =
      useModalChangeActions<EditableJobsiteFormSubmissionDocument>({
        items: value,
        onChange,
        idField: 'id',
      });

    const documents = value?.filter((p) => p.changeType !== 'removed');
    const documentsList = (documents?.length || null) && (
      <div className="odin-grid odin-grid-cols-1 odin-gap-y-3 sm:odin-grid-cols-2 sm:odin-gap-x-3">
        {documents.map((document) => (
          <FormSubmissionDocument
            key={document.id}
            document={document}
            onEditClick={(): void => openEditModal(document)}
            onDeleteClick={(): void => openDeleteModal(document)}
          />
        ))}
      </div>
    );

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-space-y-9">
          <Button
            text="Add Attachment"
            theme="dashed"
            className="odin-w-full odin-justify-center odin-h-20"
            icon={FilePlusIcon}
            onClick={openAddModal}
          />
          {documentsList}
        </div>
        <AddFormSubmissionDocumentModal
          isOpen={change?.type === 'create'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
        />
        <EditFormSubmissionDocumentModal
          isOpen={change?.type === 'update'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          document={change?.item}
        />
        <DeleteFormSubmissionDocumentModal
          isOpen={change?.type === 'remove'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          document={change?.item}
        />
      </>
    );
  },
);
