import React from 'react';
import moment from 'moment';
import { DeepMap, ValidationRule, ValidationValueMessage } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { camelToSnakeCase, camelToTitleCase, isStringEnumKey } from 'utils';
import { InvalidField, RequiredField } from 'utils/validation';
import {
  ApplyConfigArgs,
  ApplyLocalizationArgs,
  FormData,
  FormDefaultValue,
  FormDefaultValues,
  FormInput,
  TypedFormInputs,
  FormInputTypes,
  UseInputs,
  UseFormMethods,
  FormFieldsConfig,
  FormFieldConfig,
} from './types';

export const getFormInputsFromTyped = <TFormData extends Record<string, unknown>>(
  typedFormInputs: TypedFormInputs<TFormData>,
  fieldPrefix?: string,
): FormInput<TFormData>[] => {
  return (
    typedFormInputs &&
    Object.entries(typedFormInputs).map(
      ([name, input]) =>
        ({
          name: [fieldPrefix, name].filter(Boolean).join('.'),
          ...input,
        } as FormInput<TFormData>),
    )
  );
};

export const resolveFormDefaultValues = <TFormData extends Record<string, unknown>>(
  defaultValues: FormDefaultValue<TFormData>[] | FormDefaultValues<TFormData>,
): FormDefaultValues<TFormData> => {
  return Array.isArray(defaultValues)
    ? (Object.fromEntries(
        defaultValues.map(({ name, value }) => [name as keyof TFormData, value]),
      ) as FormDefaultValues<TFormData>)
    : defaultValues;
};

type UpdateInputValue<T> = T extends moment.Moment
  ? Date
  : T extends SelectOptionElement<infer X>
  ? X
  : T extends Array<SelectOptionElement<infer Y>>
  ? Array<Y>
  : T;

export type GetUpdateInputValueFunction<TData extends FormData> = <TField extends keyof TData>(
  path: TField,
  force?: boolean,
) => UpdateInputValue<TData[TField]>;

const isSelectOptionElement = (value: unknown): value is SelectOptionElement => !!(value as { value: unknown })?.value;

export function getUpdateInputValueFunction<TData extends Record<string, unknown>>(
  data: TData,
  dirtyFields: DeepMap<TData, true>,
): GetUpdateInputValueFunction<TData> {
  return function getUpdateInputValue<TField extends keyof TData>(
    field: TField,
    /** Forces the function to return the field value even when the field is not dirty. */
    force?: boolean,
  ): UpdateInputValue<TData[TField]> {
    const isDirty = dirtyFields?.[field];
    if (isDirty || force) {
      const fieldValue = data[field];
      if (moment.isMoment(fieldValue)) {
        return fieldValue.toDate() as UpdateInputValue<TData[TField]>;
      }
      if (isSelectOptionElement(fieldValue)) {
        return fieldValue.value as UpdateInputValue<TData[TField]>;
      }
      if (Array.isArray(fieldValue) && fieldValue.length && isSelectOptionElement(fieldValue[0])) {
        return fieldValue.map((option) => option.value) as UpdateInputValue<TData[TField]>;
      }
      if (fieldValue === '' || fieldValue == null) {
        return null;
      }
      return fieldValue as UpdateInputValue<TData[TField]>;
    }
    // this field won't get updated on the back-end
    return undefined;
  };
}

export const ensureValidationMessages = <TFormData>(inputs: FormInput<TFormData>[]): FormInput<TFormData>[] => {
  return inputs.map((input) => {
    const { validation } = input;
    const { required, pattern } = validation ?? {};

    if (input.element !== FormInputTypes.Section && input.element !== FormInputTypes.Panel) {
      const { elementProps } = input;
      const fieldLabel = input.label ?? input.elementProps?.label ?? camelToTitleCase(input.name);

      const requiredAsObject = required as { value: boolean; message: string };
      const requiredValue = typeof required === 'boolean' ? required : requiredAsObject?.value;
      const shouldUpdateRequiredFieldMessage = requiredValue && requiredAsObject.message === undefined;
      const shouldUpdateNote = !requiredValue && !elementProps?.disabled && elementProps?.note === undefined;

      const patternAsObject = pattern as { value: RegExp; message: string };
      const patternValue = pattern instanceof RegExp ? pattern : patternAsObject?.value;
      const shouldUpdateInvalidFieldMessage = patternValue && patternAsObject.message === undefined;

      if (shouldUpdateRequiredFieldMessage || shouldUpdateInvalidFieldMessage || shouldUpdateNote)
        return {
          ...input,
          elementProps: {
            ...elementProps,
            ...(shouldUpdateNote ? { note: 'Optional' } : undefined),
          },
          validation: {
            ...validation,
            ...(shouldUpdateRequiredFieldMessage
              ? {
                  required: {
                    value: requiredValue,
                    message: RequiredField`${fieldLabel}`,
                  },
                }
              : undefined),
            ...(shouldUpdateInvalidFieldMessage
              ? {
                  pattern: {
                    value: patternValue,
                    message: InvalidField`${fieldLabel}`,
                  },
                }
              : undefined),
          },
        };
    }

    return input;
  });
};

export const applyAutoFocus = <TFormData>(
  inputs: FormInput<TFormData>[],
  autoFocus: boolean | keyof TFormData,
): FormInput<TFormData>[] => {
  if (!autoFocus) {
    return inputs;
  }

  const autoFocusInput = (typeof autoFocus === 'string' ? inputs.find((i) => i.name === autoFocus) : null) ?? inputs[0];
  return inputs.map((input) => {
    const { element, elementProps } = input;
    if (input === autoFocusInput && element !== FormInputTypes.Section && element !== FormInputTypes.Panel) {
      return {
        ...input,
        elementProps: {
          ...elementProps,
          autoFocus: true,
        },
      };
    }
    return input;
  });
};

export const isRequiredAnObject = (
  required: string | ValidationRule<boolean>,
): required is ValidationValueMessage<boolean> => {
  return required && typeof required === 'object' && Object.prototype.hasOwnProperty.call(required, 'value');
};

export const getFormFieldConfig = (inputName: string, config: FormFieldsConfig): FormFieldConfig => {
  const { fields: configFields, inputToFieldMap } = config;
  const configFieldInfo = inputToFieldMap?.[inputName] ?? inputName;
  const configField =
    typeof configFieldInfo === 'string'
      ? configFields[configFieldInfo] // configFieldInfo is a key
      : { ...configFields[configFieldInfo.key], ...configFieldInfo }; // configFieldInfo is an override object
  return configField;
};

export const applyConfig = <TFormData extends FormData>(args: ApplyConfigArgs<TFormData>): FormInput<TFormData>[] => {
  const { inputs, config } = args;
  if (!config) return inputs;

  const { inputsFilter } = config;

  return inputs.map((input) => {
    // apply config only for filtered inputs, when `inputsFilter` is not nullish
    if (inputsFilter && !inputsFilter.includes(input.name)) {
      return input;
    }

    const { validation = {} } = input;
    const { required } = validation;

    const { isRequired: isRequiredFromConfig, isHidden: isHiddenFromConfig } =
      getFormFieldConfig(input.name, config) ?? {};

    // if the field is hidden from config, we don't need to apply any other config
    if (isHiddenFromConfig) {
      return {
        ...input,
        isHidden: true,
      };
    }

    const label =
      input.element !== FormInputTypes.Section && input.element !== FormInputTypes.Panel
        ? input.elementProps?.label ?? input.label
        : undefined;

    if (isRequiredFromConfig !== undefined) {
      const newRequired: ValidationRule<boolean> = isRequiredAnObject(required)
        ? { ...required, value: isRequiredFromConfig ?? required.value }
        : { value: isRequiredFromConfig, message: RequiredField`${label ?? camelToTitleCase(input.name)}` };

      return {
        ...input,
        validation: {
          ...input.validation,
          required: newRequired,
        },
      };
    }
    return input;
  });
};

export const applyVisibility = <TFormData extends FormData>(args: {
  inputs: FormInput<TFormData>[];
}): FormInput<TFormData>[] => {
  const { inputs } = args;
  const visibleInputs = inputs.filter((input) => !input.isHidden);
  const visibleFields = visibleInputs.map((input) => input.name);
  return visibleInputs.map((input) =>
    typeof input.layout === 'function' ? { ...input, layout: input.layout({ visibleFields }) } : input,
  );
};

type LocalizedFields = 'label' | 'placeholder' | 'message' | 'note';

export const applyLocalization = <TFormData extends FormData>(
  args: ApplyLocalizationArgs<TFormData>,
): FormInput<TFormData>[] => {
  const { inputs, localization } = args;
  if (!localization) return inputs;

  const { localize, copy } = localization;

  const tryToLocalize = <TField extends LocalizedFields>(field: TField, key: string): { [k: string]: string } => {
    if (isStringEnumKey(copy, key)) {
      return { [field]: localize(copy[key]) };
    }
    return undefined;
  };

  return inputs.map((input): FormInput<TFormData> => {
    const { labelCopy } = input;
    const fieldName = camelToSnakeCase(input.name);
    if (input.element !== FormInputTypes.Section && input.element !== FormInputTypes.Panel) {
      const { required, pattern } = input.validation ?? {};

      const labelKey = labelCopy ?? `${fieldName}_label`;
      // const placeholderKey = `${fieldName}_mask_text`;
      const invalidValueKey = `${fieldName}_invalid_value_validation`;
      const missingValueKey = `${fieldName}_missing_value_validation`;

      // const localizedSelectPlaceHolder =
      //   input.element === FormInputTypes.Select
      //     ? { placeholder: localize(Copy.picklist_mask_general) }
      //     : undefined;

      const localizedOptions =
        input.element === FormInputTypes.Select
          ? {
              options: (input.elementProps.options as SelectOptionElement[])?.map(
                (option: SelectOptionElement): SelectOptionElement => ({
                  ...option,
                  ...tryToLocalize('label', `${fieldName}_option_${option.key}`),
                }),
              ),
            }
          : undefined;

      const requiredAsObject = required as { value: boolean; message: string };
      const requiredValue = typeof required === 'boolean' ? required : requiredAsObject?.value;
      const localizedRequired =
        required !== undefined
          ? {
              required: {
                value: requiredValue,
                message: tryToLocalize('message', missingValueKey)?.message,
              },
            }
          : undefined;

      const patternAsObject = pattern as { value: RegExp; message: string };
      const patternValue = pattern instanceof RegExp ? pattern : patternAsObject?.value;
      const localizedPattern =
        pattern !== undefined
          ? {
              pattern: {
                value: patternValue,
                message: tryToLocalize('message', invalidValueKey)?.message,
              },
            }
          : undefined;

      const localizedNote = requiredValue ? undefined : tryToLocalize('note', copy.field_optional_label);

      return {
        ...input,
        elementProps: {
          ...input.elementProps,
          ...tryToLocalize('label', labelKey),
          placeholder: input.elementProps?.placeholder ?? '',
          // ...(localizedSelectPlaceHolder ?? tryToLocalize('placeholder', placeholderKey)),
          ...localizedNote,
          ...localizedOptions,
        },
        validation: {
          ...input.validation,
          ...localizedRequired,
          ...localizedPattern,
        },
      };
    }
    return input;
  });
};

export const resolveInputs = <TFields extends FormData>(
  inputsArg: FormInput<TFields>[] | TypedFormInputs<TFields> | UseInputs<TFields>,
  methods: UseFormMethods<TFields>,
  fieldPrefix?: string,
): FormInput<TFields>[] => {
  if (typeof inputsArg === 'function') {
    const returnedInputs = inputsArg(methods);
    if (Array.isArray(returnedInputs)) {
      return returnedInputs;
    }
    return getFormInputsFromTyped(returnedInputs, fieldPrefix);
  }
  if (Array.isArray(inputsArg)) {
    return inputsArg;
  }
  return getFormInputsFromTyped(inputsArg, fieldPrefix);
};

export const useResolvedInputs = <TFields extends FormData>(
  inputsArg: FormInput<TFields>[] | TypedFormInputs<TFields> | UseInputs<TFields>,
  methods: UseFormMethods<TFields>,
): FormInput<TFields>[] => {
  if (typeof inputsArg === 'function') {
    const returnedInputs = inputsArg(methods);
    if (Array.isArray(returnedInputs)) {
      return returnedInputs;
    }
    return React.useMemo(() => getFormInputsFromTyped(returnedInputs), [returnedInputs]);
  }
  if (Array.isArray(inputsArg)) {
    return inputsArg;
  }
  return React.useMemo(() => getFormInputsFromTyped(inputsArg), [inputsArg]);
};

export type EnhancedInputsArgs<TFormData extends FormData> = ApplyConfigArgs<TFormData> &
  ApplyLocalizationArgs<TFormData> & {
    autoFocus: boolean | keyof TFormData;
  };

export const getEnhancedInputs = <TFields extends FormData>(
  args: EnhancedInputsArgs<TFields>,
): FormInput<TFields>[] => {
  const { inputs, config, localization, autoFocus } = args;
  if (!inputs) return inputs;
  const configuredInputs = applyConfig({ inputs, config });
  const visibleInputs = applyVisibility({ inputs: configuredInputs });
  const localizedInputs = applyLocalization({ inputs: visibleInputs, localization });
  const inputsWithValidationMessages = ensureValidationMessages(localizedInputs);
  const enhancedInputs = applyAutoFocus(inputsWithValidationMessages, autoFocus);
  return enhancedInputs;
};
