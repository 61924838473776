import cn from 'classnames';

export const classes = {
  butterBar: cn(
    'odin-border odin-bg-odin-light-yellow odin-border-odin-warning/30',
    'odin-flex-1',
    'odin-rounded-md',
    'odin-flex',
    'odin-items-center',
    'odin-p-1 sm:odin-p-4',
    'odin-mb-3',
  ),
  noFileFound:
    'odin-px-2.5 odin-py-1.5 odin-rounded-md odin-inline-block ' +
    'odin-text-white odin-text-1.5xs odin-font-normal odin-bg-odin-danger',
};
