import React from 'react';
import { ApolloError } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  UserAssignmentType,
  UserRoleKey,
  useGetUserRoleModalContractorsQuery,
  useGetUserRoleModalJobsitesQuery,
  useGetDevelopersQuery,
} from 'apollo/generated/client-operations';
import { ContractorForOption, ContractorOptionElement, getContractorsOptions } from 'containers/contractor/helpers';
import { AuthContext } from 'auth';
import { getDevelopersOptions } from 'containers/jobsites/helpers/utils';
import { AddUserRoleModalProps, JobsiteForOption } from './types';
import { getJobsitesOptions, getUserRolesOptions } from './utils';

type UserModalOptions = {
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  contractorsOptions: ContractorOptionElement[];
  developersOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  error: ApolloError;
  loading: boolean;
};

export function useUserModalOptions(
  args: Pick<AddUserRoleModalProps, 'userRoleType' | 'userRoles'> & {
    jobsite?: JobsiteForOption;
    contractor?: ContractorForOption;
    workerId?: string;
    assignedObjectIds?: AddUserRoleModalProps['assignedObjectIds'];
    includeAssignedObjects?: boolean;
  },
): UserModalOptions {
  const { userRoleType, userRoles, jobsite, contractor, workerId, assignedObjectIds, includeAssignedObjects } = args;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    data: workerJobsitesData,
    error: workerJobsitesError,
    loading: workerJobsitesLoading,
  } = useGetUserRoleModalJobsitesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      workerId: workerId ?? user.worker.workerId,
      availableRoleObjectsInput: { includeAssignedObjects },
    },
    skip: userRoleType === undefined || (!!userRoleType && userRoleType !== UserAssignmentType.Jobsite) || !!jobsite,
  });

  const {
    data: workerContractorsData,
    error: workerContractorsError,
    loading: workerContractorsLoading,
  } = useGetUserRoleModalContractorsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      workerId: workerId ?? user.worker.workerId,
      availableRoleObjectsInput: { includeAssignedObjects },
    },
    skip:
      userRoleType === undefined || (!!userRoleType && userRoleType !== UserAssignmentType.Contractor) || !!contractor,
  });

  const { data: developersData, error: developersError, loading: developersLoading } = useGetDevelopersQuery({});

  const error = workerJobsitesError ?? workerContractorsError ?? developersError;
  const loading = workerJobsitesLoading || workerContractorsLoading || developersLoading;

  return React.useMemo<UserModalOptions>(() => {
    const { jobsites: availableJobsites } = workerJobsitesData?.getWorker.user.availableRoleObjects ?? {};
    const { contractors: availableContractors } = workerContractorsData?.getWorker.user.availableRoleObjects ?? {};

    const jobsites: JobsiteForOption[] = jobsite ? [jobsite] : availableJobsites;
    const contractors: ContractorForOption[] = contractor ? [contractor] : availableContractors;
    const developers = developersData?.getCurrentSession.user.developers.edges.map(({ node }) => node);

    const unassignedJobsites =
      userRoleType === UserAssignmentType.Jobsite && !jobsite && assignedObjectIds?.length
        ? jobsites?.filter((j) => !assignedObjectIds.includes(j.jobsiteId))
        : jobsites;

    const unassignedContractors =
      userRoleType === UserAssignmentType.Contractor && !contractor && assignedObjectIds?.length
        ? contractors?.filter((c) => !assignedObjectIds.includes(c.contractorId))
        : contractors;

    return {
      userRolesOptions: getUserRolesOptions({ userRoles, userRoleType, showAdminRoles: user.isAdmin }),
      jobsitesOptions: getJobsitesOptions(unassignedJobsites),
      contractorsOptions: getContractorsOptions(unassignedContractors),
      developersOptions: getDevelopersOptions(developers),
      error,
      loading,
    };
  }, [
    workerJobsitesData,
    workerContractorsData,
    userRoles,
    userRoleType,
    user,
    jobsite,
    contractor,
    assignedObjectIds,
    error,
    loading,
  ]);
}
