import React from 'react';
import {
  Column,
  DropdownButtonCell,
  DropdownButtonCellComponentProps,
  DropdownButtonSkeleton,
  getFixedWidthFaIcon,
} from '@odin-labs/components';
import { faTrash, faWrench } from 'components/icons';
import { EditableDocumentFile } from 'containers/jobsiteConfiguration/components/documentFiles/modals';
import { languageOptions } from 'containers/jobsiteConfiguration/helpers/utils';
import { FilePreview, FilePreviewLinkContainerProps, isServerFile } from 'components/filePreview';
import cn from 'classnames';

export type DocumentFileColumn = Column<EditableDocumentFile>;

type DocumentCellProps = { componentProps: EditableDocumentFile };

const WrenchIcon = getFixedWidthFaIcon({ icon: faWrench });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

function FilePreviewLink(props: FilePreviewLinkContainerProps): React.ReactElement {
  const { file, children } = props;
  const fileDownloadUrl = file && (isServerFile(file) ? file?.downloadUrl : URL.createObjectURL(file));
  return (
    <div className="odin-flex odin-space-x-3 odin-py-2">
      <a
        href={fileDownloadUrl}
        onClick={(e): void => {
          e.stopPropagation();
        }}
        target="_blank"
        className="avatar avatar-lg"
        rel="noreferrer"
      >
        {children}
      </a>
    </div>
  );
}

export const getColumns = ({
  openEditModal,
  openDeleteModal,
}: {
  openEditModal: (file: EditableDocumentFile) => void;
  openDeleteModal: (file: EditableDocumentFile) => void;
}): DocumentFileColumn[] => [
  {
    id: 'language',
    Header: 'Language',
    accessor: (documentFile): string => languageOptions.find((opt) => opt.value === documentFile.language)?.label,
    headerClassName: cn('odin-w-1/4'),
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: 'title',
    headerClassName: cn('odin-w-2/4'),
  },
  {
    id: 'preview',
    Header: 'Preview',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { file, language } = componentProps ?? {};
      return (
        <FilePreview
          linkContainer={FilePreviewLink}
          file={file}
          alt={`document-${language}-file`}
          className="odin-rounded-sm odin-h-full"
        />
      );
    },
    componentProps: (data: EditableDocumentFile): EditableDocumentFile => {
      return data;
    },
  },
  {
    id: 'actions',
    Header: '',
    Cell: DropdownButtonCell,
    CellLoading: (): React.ReactElement => <DropdownButtonSkeleton cellAlignment="right" />,
    componentProps: (file: EditableDocumentFile): DropdownButtonCellComponentProps => ({
      cellAlignment: 'right',
      menuItems: [
        [
          {
            icon: WrenchIcon,
            text: 'Edit File',
            theme: 'secondary',
            onClick: (): void => openEditModal(file),
          },
          {
            icon: TrashIcon,
            text: 'Delete File',
            theme: 'danger',
            onClick: (): void => openDeleteModal(file),
          },
        ],
      ],
    }),
  },
];
