import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef } from 'react';
import cn from 'classnames';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@odin-labs/components';
import { setRefFactory } from 'components/utils';
import { Form } from 'components/form';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import { WizardProps, WizardStepProps } from './types';

const textAlignClasses: Record<'left' | 'center' | 'right', string> = {
  left: 'odin-text-left',
  center: 'odin-text-center',
  right: 'odin-text-right',
};

function BaseWizard<TFields>({
  name,
  steps,
  loading,
  forwardedRef,
  textAlign = 'left',
  shouldResetSteps = true,
  finalSubmitText = 'Submit',
  backEnabled = true,
}: WizardProps<TFields>): ReactElement {
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>();

  let position = 0;
  steps.forEach((step: WizardStepProps<TFields>, index) => {
    const match = matchPath(step.path, { path: location?.pathname });
    if (match && match?.isExact) {
      position = index;
    }
  });

  useImperativeHandle(forwardedRef, () => ({
    onNextStep: (): void => {
      if (position < steps.length) {
        history.push(steps[position + 1]?.path);
      }
    },
    onPreviousStep: (): void => {
      if (position > 0) {
        history.push(steps[position - 1]?.path);
      }
    },
  }));

  useEffect(() => {
    const windowListener = (): void => {
      if (window != null) {
        window.onbeforeunload = (): string => {
          return 'Reload site?';
        };
      }
    };

    const checkRedirect = (): void => {
      const match = matchPath(steps[0].path, { path: location?.pathname });
      if (!match && !match?.isExact && shouldResetSteps) {
        history.push(steps[0]?.path);
      } else {
        windowListener();
      }
    };

    checkRedirect();

    return (): void => {
      if (window != null) {
        window.onbeforeunload = null;
      }
    };
  }, []);

  return (
    <div className="odin-flex odin-flex-col odin-items-center odin-justify-center odin-w-full" id={name}>
      <Switch>
        {steps.map((step: WizardStepProps<TFields>) => {
          const { ref: outerRef, ...stepFormProps } = step.form;
          const setRef = setRefFactory<HTMLFormElement>({ innerRef: formRef, outerRef });

          return (
            <Route path={step.path} key={step.name} exact>
              <div className="odin-w-full">
                <div className="odin-items-center">
                  <div id={step?.name}>
                    <Header
                      textAlign={textAlign}
                      title={step?.title}
                      pretitle={`Step: ${position + 1} of ${steps.length}`}
                      subtitle={step.subtitle}
                    />
                    {loading && (
                      <div className="table-backdrop odin-m--5">
                        <LoadingError loading />
                      </div>
                    )}
                    <Form ref={setRef} {...stepFormProps} />
                  </div>
                </div>
                <hr />
                {!loading && (
                  <div className="odin-flex odin-justify-between odin-flex-row-reverse odin-mb-9 odin-px-3">
                    <Button
                      theme="primary"
                      text={position + 1 === steps.length ? finalSubmitText : 'Submit'}
                      onClick={(): void => {
                        formRef.current.requestSubmit();
                      }}
                    />
                    {position > 0 && backEnabled && (
                      <Button
                        theme="white"
                        text="Back"
                        onClick={(): void => {
                          history.push(steps[position - 1]?.path);
                        }}
                      />
                    )}
                  </div>
                )}
                <h6
                  className={cn(
                    'odin-uppercase odin-tracking-widest odin-text-slate-400 odin-mb-3',
                    textAlignClasses[textAlign],
                  )}
                >
                  {`Step: ${position + 1} of ${steps.length}`}
                </h6>
              </div>
            </Route>
          );
        })}
        <Route path="*" exact>
          <Redirect to={steps[0]?.path} />
        </Route>
      </Switch>
    </div>
  );
}

export const Wizard = forwardRef((props: any, ref: any): ReactElement => {
  return <BaseWizard {...props} forwardedRef={ref} />;
});
