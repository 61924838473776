import React from 'react';
import { Badge, BadgeProps, Column } from '@odin-labs/components';
import { JobsiteWorkerDocument, JobsiteWorkerDocumentVersion } from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { getFormattedDate } from 'utils';
import { ObjectHistory } from 'components/objectHistory';
import { ObjectHistoryFormat } from 'components/objectHistory/types';
import { Thumbnail } from 'components/thumbnail';
import { DocumentKey, getLatestWorkerDocument, getMedicalDocType } from 'containers/worker/utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';

enum MedicalTestResult {
  Pass = 'Pass',
  NoPass = 'No pass',
  ConditionalPass = 'Conditional pass',
}

type GetDocumentTestResultArgs = {
  docTypeKey: string;
  document: JobsiteWorkerDocument['jobsiteWorkerDocumentVersions'][number];
};
type TestResult = Pick<BadgeProps, 'text' | 'color'>;

export type DocumentData = {
  document: JobsiteWorkerDocument;
  latestVersion: JobsiteWorkerDocumentVersion;
  docTypeName: string;
  testReason: string;
  testDate: string;
  testResult: TestResult;
  additionalNotes: string;
  expirationDate: string;
};

type DocumentCellProps = { componentProps: DocumentData };

const getTestResult = (args: GetDocumentTestResultArgs): TestResult => {
  const { docTypeKey, document } = args;

  if (
    docTypeKey === DocumentKey.MedicalDrugTestingConsentForm ||
    docTypeKey === DocumentKey.MedicalCovid19OrientationAndProcedures
  ) {
    return null;
  }

  const getAdditionalFieldValue = (fieldKey: string): string | undefined =>
    document.additionalFieldValues?.filter(Boolean).find(({ key }) => key === fieldKey)?.value;

  const testResult: string = getAdditionalFieldValue('result');

  return {
    text: testResult,
    // eslint-disable-next-line no-nested-ternary
    color: testResult === MedicalTestResult.Pass ? 'green' : testResult === MedicalTestResult.NoPass ? 'red' : 'yellow',
  };
};

export const toDocumentData = (document: JobsiteWorkerDocument): DocumentData => {
  const { workerDocumentType } = document?.jobsiteWorkerDocumentType ?? {};
  const { key: docTypeKey } = workerDocumentType ?? {};
  const docTypeName = getMedicalDocType(document?.jobsiteWorkerDocumentType?.workerDocumentType?.key);
  const latestVersion = getLatestWorkerDocument(document);
  const fieldValues = (latestVersion?.additionalFieldValues ?? []).filter(Boolean);

  const getAdditionalFieldValue = (fieldKey: string): string | undefined =>
    fieldValues.filter(Boolean).find(({ key }) => key === fieldKey)?.value;

  const expirationDate = getAdditionalFieldValue('expiration-date');
  const testReason = getAdditionalFieldValue('test-reason');
  const testDate = getFormattedDate(getAdditionalFieldValue('test-taken-date'));
  const testResult = getTestResult({ docTypeKey, document: latestVersion });
  const additionalNotes = getAdditionalFieldValue('notes');

  return {
    document,
    latestVersion,
    docTypeName,
    testReason,
    testDate,
    testResult,
    additionalNotes,
    expirationDate,
  };
};

export const getColumns = (): Column<DocumentData>[] => [
  {
    id: 'docType',
    Header: 'Document Type',
    accessor: 'docTypeName',
  },
  {
    id: 'testReason',
    Header: 'Test Reason',
    accessor: 'testReason',
  },
  {
    id: 'testDate',
    Header: 'Date of Test',
    accessor: 'testDate',
  },
  {
    id: 'testResult',
    Header: 'Test Result',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { testResult = null, expirationDate } = componentProps ?? {};
      return (
        testResult && (
          <Badge className="odin-uppercase odin-leading-relaxed odin-font-medium" {...testResult}>
            {expirationDate && (
              <div className="odin-text-1.5xs odin-mt-1 odin-ml-2.5">
                {getISODateTimeToFormattedDate(expirationDate)}
              </div>
            )}
          </Badge>
        )
      );
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'additionalNotes',
    Header: 'Notes',
    accessor: 'additionalNotes',
  },
  {
    id: 'updatedBy',
    Header: 'Last Updated By',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { latestVersion } = componentProps ?? {};
      return <ObjectHistory object={latestVersion} format={ObjectHistoryFormat.Name} />;
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
  {
    id: 'preview',
    Header: 'Preview',
    Cell: ({ componentProps }: DocumentCellProps): React.ReactNode => {
      const { document, latestVersion } = componentProps ?? {};
      return (
        <div className="odin-flex odin-space-x-3 odin-py-2">
          {latestVersion?.files.map((file: ServerFile, index: number) => {
            const fileDownloadUrl = file?.downloadUrl;
            const isHtmlFile = fileDownloadUrl?.endsWith('.html') || fileDownloadUrl?.includes('.html?');
            return (
              <a
                href={isHtmlFile ? `/onboarding/document/${document.jobsiteWorkerDocumentId}/print` : file?.downloadUrl}
                onClick={(e): void => {
                  e.stopPropagation();
                }}
                target="_blank"
                download={!isHtmlFile}
                className="odin-relative odin-inline-block odin-w-16 odin-h-16"
                rel="noreferrer"
                key={`${index}-${file?.downloadUrl}`} // eslint-disable-line react/no-array-index-key
              >
                <Thumbnail file={file} />
              </a>
            );
          })}
        </div>
      );
    },
    componentProps: (data: DocumentData): DocumentData => {
      return data;
    },
  },
];
