import React, { ReactElement, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import {
  MutationUploadSingleFileArgs,
  useCreateJobsiteWorkerDocumentMutation,
  useCreateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { InputFile } from 'types';
import { AlertService } from 'components/alertService';
import { FILE_UPLOAD } from 'containers/workerOnboarding/helpers/queries';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import { getISOFormattedDate, getISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';

import { LoadingError } from 'components/loadingError';
import { UploadFileResponse, WorkerDocumentsDataType } from 'containers/workerOnboarding/types';

import { Form } from 'components/form';
import { ensureNonEmptyItems, isServerFile, uploadCompressedFile, useIsMounted } from 'utils';
import { DocumentKey } from 'containers/worker/utils';
import { useJobsiteWorkerOnboardingDocuments } from 'containers/workerOnboarding/helpers/useJobsiteWorkerOnboardingDocuments';
import { FormOnSubmit } from 'components/form/types';
import { UploadDocumentModalContainerProps } from './types';
import { getDefaultValues, getDocumentTypeName, getFormInputsHook } from './WorkerDocumentFormModalContainer.forms';

export function WorkerDocumentFormModalContainer(props: UploadDocumentModalContainerProps): ReactElement {
  const {
    isModalOpen,
    jobsiteWorker,
    setModalOpen,
    jobsiteWorkerDocument,
    jobsiteWorkerDocumentVersion,
    jobsiteWorkerDocumentTypeKey,
    makeNewDocument = false,
  } = props;
  const { jobsiteWorkerDocumentId } = jobsiteWorkerDocument ?? {};
  const workerDocumentTypeKey =
    jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.workerDocumentType?.key || jobsiteWorkerDocumentTypeKey;

  const formRef = useRef<HTMLFormElement>();
  const [fetching, setFetching] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const {
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    error: documentsError,
    loading: documentsLoading,
  } = useJobsiteWorkerOnboardingDocuments({ jobsiteWorker });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (uploadFileError) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(uploadFileError));
    },
  });

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation();
  const [createJobsiteWorkerDocument] = useCreateJobsiteWorkerDocumentMutation();
  const [createJobsiteWorkerDocumentVersion] = useCreateJobsiteWorkerDocumentVersionMutation();
  const [updateJobsiteWorkerDocumentVersion] = useUpdateJobsiteWorkerDocumentVersionMutation();

  const onSubmit: FormOnSubmit<WorkerDocumentsDataType> = async (data, event): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    const additionalFieldValues: any = [];

    try {
      const documents = jobsiteWorkerDocuments.map((document) => {
        return {
          key: document?.jobsiteWorkerDocumentType?.workerDocumentType?.key,
          id: document?.jobsiteWorkerDocumentId,
        };
      });

      const existingDocument = documents?.find(({ key, id }) => {
        return jobsiteWorkerDocumentId ? id === jobsiteWorkerDocumentId : key === workerDocumentTypeKey;
      });

      const fields: string[] = Object.keys(data);
      const filesToUpload: Promise<{ fileId: string; fileName: string }>[] = [];

      fields.forEach((field) => {
        const fieldName = field.replace(`${workerDocumentTypeKey}-`, '');
        if (fieldName === 'front' || fieldName === 'back') {
          const filesValue = data[field] as InputFile | InputFile[];
          const files = Array.isArray(filesValue) ? filesValue : [filesValue];
          if (files?.length) {
            filesToUpload.push(
              ...files.map(async (file) => {
                if (isServerFile(file)) {
                  return Promise.resolve({ fileId: file.fileId, fileName: file.originalFileName });
                }
                const uploadedFileId = await uploadCompressedFile(file, uploadFile);
                return { fileId: uploadedFileId, fileName: file.name };
              }),
            );
          }
        } else {
          const rawValue = data[field];
          if (rawValue !== undefined) {
            additionalFieldValues.push({
              key: fieldName,
              value: fieldName.includes('-date') ? getISOFormattedDate(rawValue as string) : rawValue?.toString(),
              type: 'string',
            });
          }
        }
      });

      const uploadedFiles = await Promise.all(filesToUpload);
      const fileIds = ensureNonEmptyItems(uploadedFiles).map((f) => f.fileId);

      const sstCompletedAtRaw = data[`${DocumentKey.SiteSpecificOrientation}-orientation-date`] as string;
      if (sstCompletedAtRaw) {
        await updateJobsiteWorker({
          refetchQueries: [GET_WORKER_DOCUMENTS],
          variables: {
            jobsiteWorkerId: jobsiteWorkerDocument?.jobsiteWorker?.jobsiteWorkerId || jobsiteWorker?.jobsiteWorkerId,
            jobsiteWorkerInput: {
              siteSpecificTrainingCompletedAt: getISOFormattedDateTime(sstCompletedAtRaw),
            },
          },
        });
      }

      if (jobsiteWorkerDocumentVersion) {
        await updateJobsiteWorkerDocumentVersion({
          refetchQueries: [GET_WORKER_DOCUMENTS],
          variables: {
            jobsiteWorkerDocumentVersionId: jobsiteWorkerDocumentVersion?.jobsiteWorkerDocumentVersionId,
            jobsiteWorkerDocumentVersionInput: {
              fileIds,
              additionalFieldValues,
            },
          },
        });
      } else if (existingDocument && !makeNewDocument) {
        await createJobsiteWorkerDocumentVersion({
          refetchQueries: [GET_WORKER_DOCUMENTS],
          variables: {
            jobsiteWorkerDocumentId: existingDocument.id,
            jobsiteWorkerDocumentVersionInput: {
              fileIds,
              additionalFieldValues,
            },
          },
        });
      } else {
        await createJobsiteWorkerDocument({
          refetchQueries: [GET_WORKER_DOCUMENTS],
          variables: {
            jobsiteWorkerDocumentInput: {
              jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
              jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[workerDocumentTypeKey],
              key: workerDocumentTypeKey,
            },
            jobsiteWorkerDocumentVersionInput: {
              fileIds,
              additionalFieldValues,
            },
          },
        });
      }
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setFetching(false);
        setModalOpen(false);
      }
    }
  };

  const defaultValues = React.useMemo(
    () => getDefaultValues(workerDocumentTypeKey, jobsiteWorkerDocumentVersion),
    [workerDocumentTypeKey, jobsiteWorkerDocumentVersion],
  );

  const inputs = getFormInputsHook({ workerDocumentTypeKey });

  const workerDocumentTypeName = jobsiteWorkerDocument?.jobsiteWorkerDocumentType?.workerDocumentType?.name;
  const documentTypeName = React.useMemo(
    () => getDocumentTypeName({ workerDocumentTypeKey, workerDocumentTypeName }),
    [workerDocumentTypeKey, workerDocumentTypeName],
  );

  return (
    <Modal
      title={`Update ${documentTypeName}`}
      actionText="Save"
      onAction={(): void => {
        formRef.current?.requestSubmit();
      }}
      setOpen={(): void => {
        setFetching(false);
        setModalOpen(false);
      }}
      open={isModalOpen}
      actionButtonWithSpinner={fetching}
    >
      {fetching || documentsLoading || documentsError ? (
        <LoadingError loading={fetching || documentsLoading} error={documentsError} />
      ) : (
        <div className="worker-document-form-modal">
          <Form inputs={inputs} ref={formRef} defaultValues={defaultValues} onSubmit={onSubmit} />
        </div>
      )}
    </Modal>
  );
}
