import { SelectOptionElement } from '@odin-labs/components';
import { SessionDisplayColumns } from 'apollo/generated/client-operations';

export type DeveloperForOption = { developerId: string; organization: { name: string } };
export type JobsiteForOption = { jobsiteId: string; name: string; parentId?: string };
export type JobsiteTemplateForOption = JobsiteForOption & { developer?: { developerId: string } };
export type JobsiteTemplateOption = SelectOptionElement & { developerId: string };

export const getDevelopersOptions = (developers: DeveloperForOption[]): SelectOptionElement[] =>
  developers?.map(({ developerId: value, organization: { name: label } }) => ({ value, label }));

export const getJobsiteOptions = (jobsites: JobsiteForOption[]): SelectOptionElement[] =>
  jobsites?.map(({ jobsiteId: value, name: label }) => ({ value, label }));

export const getJobsiteTemplateOptions = (jobsites: JobsiteTemplateForOption[]): JobsiteTemplateOption[] =>
  jobsites?.map(({ jobsiteId: value, name: label, developer: { developerId } }) => ({ value, label, developerId }));

export const getSessionDisplayColumnOptions = (): SelectOptionElement[] =>
  Object.keys(SessionDisplayColumns).map((label) => ({
    label,
    value: SessionDisplayColumns[label as keyof typeof SessionDisplayColumns]?.toString(),
  }));
