import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Button, SortingRule, Table, TableContainer } from '@odin-labs/components';
import {
  useGetWorkersContainerCurrentSessionQuery,
  GetJobsiteWorkersOrderByField,
  GetWorkersContainerCurrentSessionDocument,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { useQueryOrderBy, useBoolean, ensureNonEmptyItems } from 'utils';
import { paginationSizePerPage as limit } from 'utils/constants';
import { getJobsiteIdsForDevelopers } from 'utils/filters';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { SortOrder } from 'utils/useQueryOrderBy';
import { IdCardIcon, PlusIcon, UsersCogIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { LoadingError } from 'components/loadingError';
import { SelectJobsiteModal } from 'components/modals/SelectJobsiteModal';
import { Container } from 'components/container';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { AddWorkerModal, ChangeContractorModal } from 'containers/worker/modals';
import { getColumns, getFilterItems, orderByFields, WorkerColumn } from './helpers/tables';
import { Worker, WorkersFilters } from './types';

const refetchQueries = [GetWorkersContainerCurrentSessionDocument];

export function WorkersContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const isNotContractorUser = !user.isContractor;

  const {
    page,
    developerIds,
    jobsiteIds,
    contractorIds,
    trades,
    tradeClasses,
    search,
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams({ updateJobsiteSelection: true });

  const offset = page * limit;

  const { value: isAddWorkerModalOpen, setTrue: openAddWorkerModal, setFalse: closeAddWorkerModal } = useBoolean(false);
  const {
    value: isChangeContractorModalOpen,
    setTrue: openChangeContractorModal,
    setFalse: closeChangeContractorModal,
  } = useBoolean(false);
  const {
    value: isSelectJobsiteModalOpen,
    setTrue: openSelectJobsiteModal,
    setFalse: closeSelectJobsiteModal,
  } = useBoolean(false);

  const { tableSortInfo, /* orderBy, */ setNewOrderBy } = useQueryOrderBy<string, GetJobsiteWorkersOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetJobsiteWorkersOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const {
    data,
    loading: workersLoading,
    error: workersError,
    refetch: refetchWorkers,
  } = useGetWorkersContainerCurrentSessionQuery({
    fetchPolicy: 'no-cache',
    skip: isUrlLoading,
    notifyOnNetworkStatusChange: true,
    variables: {
      userWorkersInput: {
        paginationInput: { limit, offset },
        jobsiteIds,
        contractorIds,
        trades,
        tradeClasses,
        search,
      },
    },
  });
  const { user: userData } = data?.getCurrentSession ?? {};

  const workers = userData?.workers.edges.map(({ node }) => node);
  const contractors = userData?.contractors.edges.map(({ node }) => node);

  const workersCount = userData?.workers.count ?? 0;
  const pageCount = Math.ceil(workersCount / limit);
  const { filtersOptions } = userData?.workers ?? {};

  const columns = React.useMemo<WorkerColumn[]>(() => getColumns(), []);
  const filterItems = React.useMemo(
    () =>
      getFilterItems({
        jobsites,
        contractors,
        filtersOptions,
        developerIds,
        jobsiteIds,
        contractorIds,
        trades,
        tradeClasses,
        search,
      }),
    [jobsites, contractors, filtersOptions, developerIds, jobsiteIds, contractorIds, trades, tradeClasses, search],
  );

  const isReassigningWorkersAvailable = jobsiteIds?.length === 1 && contractorIds?.length === 1 && !!workers?.length;
  const [reassignmentJobsiteId] = (isReassigningWorkersAvailable ? jobsiteIds : null) ?? [];
  const [reassignmentContractorId] = (isReassigningWorkersAvailable ? contractorIds : null) ?? [];

  const getWorkersHeaderActions = (): React.ReactElement => {
    const content = ensureNonEmptyItems([
      user.isAllowed(to.addWorkers) && (
        <Button key="add-worker" text="Add Worker" hideTextOnMobile icon={PlusIcon} onClick={openAddWorkerModal} />
      ),
      isReassigningWorkersAvailable && user.isAllowed(to.reassignWorkers) && (
        <Button
          key="reassign-workers"
          text="Reassign workers"
          hideTextOnMobile
          icon={UsersCogIcon}
          onClick={openChangeContractorModal}
        />
      ),
      user.isAllowed(to.issueTempBadges) && isNotContractorUser && (
        <Button
          key="issue-temp-badge"
          text="Issue temp badge"
          hideTextOnMobile
          icon={IdCardIcon}
          onClick={openSelectJobsiteModal}
        />
      ),
    ]);
    return content.length ? <div className="odin-flex odin-gap-x-3">{content}</div> : null;
  };

  const loading = workersLoading || userJobsitesLoading;
  const error = workersError || userJobsitesError;

  if (error) {
    return <LoadingError error={error} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<Worker>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({ page: pageIndex ? pageIndex + 1 : null });
  };

  const onFilterChangeHandler = (changedFilters: Partial<WorkersFilters>): void => {
    updateUrl({
      page: null,
      ...changedFilters,
      ...getJobsiteIdsForDevelopers(jobsites, changedFilters.developerIds),
    });
  };

  const onRowClickHandler = ({ data: worker }: { data: Worker }): void => {
    history.push(`/worker/${worker.workerId}`);
  };

  const jobsiteWorkers = isReassigningWorkersAvailable
    ? workers?.flatMap((worker) =>
        worker.jobsiteWorkers.edges
          .map(({ node }) => node)
          .filter(
            ({
              jobsiteContractor: {
                jobsite: { jobsiteId },
                contractor: { contractorId },
              },
            }) => jobsiteId === reassignmentJobsiteId && contractorId === reassignmentContractorId,
          ),
      )
    : undefined;
  const jobsitesWithCardFormats = jobsites?.filter((j) => j.jobsiteCardFormats.edges.length);

  return (
    <Container className="workers-container">
      <NewHeader
        title="Workers"
        titleInfo={workersCount}
        actionsProps={{
          onReloadPressed: refetchWorkers,
          children: getWorkersHeaderActions(),
        }}
      />
      <TableContainer>
        <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFilterChangeHandler} />
        <Table
          loading={loading}
          columns={columns}
          data={workers}
          initialState={{ sortBy: tableSortBy, pageSize: limit }}
          pageCount={pageCount}
          pageIndex={page}
          remote
          enablePagination
          onRowClick={onRowClickHandler}
          onPageChange={onPageChangeHandler}
          onSortByChange={onSortByChangeHandler}
          disableGlobalFilter
          disableSortBy
        />
      </TableContainer>
      <AddWorkerModal
        isOpen={isAddWorkerModalOpen}
        closeModal={closeAddWorkerModal}
        defaultJobsiteId={jobsiteIds?.length === 1 ? jobsiteIds[0] : null}
      />
      <ChangeContractorModal
        isOpen={isChangeContractorModalOpen}
        closeModal={closeChangeContractorModal}
        refetchQueries={refetchQueries}
        jobsiteId={reassignmentJobsiteId}
        contractorId={reassignmentContractorId}
        jobsiteWorkers={jobsiteWorkers}
      />
      <SelectJobsiteModal
        isOpen={isSelectJobsiteModalOpen}
        jobsites={jobsitesWithCardFormats}
        onCancel={closeSelectJobsiteModal}
        onConfirm={({ jobsiteId }): void => {
          history.push(`/jobsite/${jobsiteId}/issue-badge/`);
        }}
      />
    </Container>
  );
}
