import React from 'react';
import moment from 'moment';
import { SelectOptionElement } from '@odin-labs/components';
import { useIsMounted } from 'utils';
import { getGraphQLError, hasGraphQLErrorName } from 'utils/error';
import { GetWorkerCardsDocument, useAddCardToWorkerMutation, WorkerCardType } from 'apollo/generated/client-operations';
import { CheckIcon } from 'components/icons';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertService } from 'components/alertService';
import { MatchedWorkerCard } from 'containers/workerOnboarding/types';
import { getFormDefaultValues, getFormInputs } from './AssignBluetoothCardModalContent.forms';
import { AssignBluetoothCardData } from './types';

export interface AssignBluetoothCardModalContentProps {
  workerId: string;
  workerCard: MatchedWorkerCard;
  jobsitesOptions: SelectOptionElement[];
  setOpen: () => void;
  onAction: (reassignData?: AssignBluetoothCardData) => void;
}

export function AssignBluetoothCardModalContent(props: AssignBluetoothCardModalContentProps): React.ReactElement {
  const { setOpen, onAction, workerId, workerCard, jobsitesOptions } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const [addCardToWorker] = useAddCardToWorkerMutation({
    refetchQueries: [GetWorkerCardsDocument],
  });

  const onSubmit: FormOnSubmit<AssignBluetoothCardData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { jobsiteId, cardNumber } = data;

    try {
      await addCardToWorker({
        variables: {
          jobsiteId: jobsiteId.value,
          workerId,
          workerCardInput: {
            cardNumber,
            startDate: moment().toDate(),
            endDate: moment().add(2, 'years').endOf('day').toDate(),
            type: WorkerCardType.Bluetooth,
            matchingWorkerCardId: workerCard?.workerCardId,
          },
        },
      });

      AlertService.alert('success', 'Success', 'Bluetooth card assigned');
      onAction();
    } catch (error) {
      event.preventDefault();
      if (hasGraphQLErrorName(error, 'ResourceAlreadyExistsError')) {
        onAction(data);
      } else {
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = React.useMemo(() => getFormInputs({ jobsitesOptions }), [jobsitesOptions]);
  const defaultValues = React.useMemo(
    () => getFormDefaultValues({ jobsitesOptions, workerCard }),
    [jobsitesOptions, workerCard],
  );

  return (
    <ModalFormContent
      setOpen={setOpen}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Assign Badge"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
