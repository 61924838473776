import React from 'react';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { getPrettyFormattedUtcDate } from 'utils/dates';
import { WorkerDocumentsDataType } from 'containers/workerOnboarding/types';
import { FormDefaultValue, FormInput, FormInputTypes, GridColSpan, UseFormMethods, UseInputs } from 'components/form';
import { AcknowledgmentProgress } from 'components/acknowledgmentProgress';
import {
  generateJobsiteWorkerDocument,
  getFilesData,
  signatureFormInput,
} from 'containers/workerOnboarding/helpers/forms';
import {
  governmentIdDocumentInput,
  newYorkSstDocumentInput,
  oshaWorkerDocumentInput,
  lirrBlueTrainingDocumentInput,
  confinedSpacesTrainingDocumentInput,
  WorkerDocumentsFormData,
} from 'containers/workerOnboarding/steps/WorkDocumentsStep.forms';
import { DocumentKey } from 'containers/worker/utils';
import { JobsiteWorkerDocumentVersion } from 'apollo/generated/client-operations';
import { dateValidation } from 'utils/validation';
import { otherCardTypes } from 'utils/constants';

const getDocumentFormInput = (
  workerDocumentTypeKey: string,
): FormInput<WorkerDocumentsFormData> | FormInput<WorkerDocumentsDataType> => {
  const areInputsRequired = true;
  const hideLabel = true;

  switch (workerDocumentTypeKey) {
    case DocumentKey.OshaCard:
      return oshaWorkerDocumentInput({ areInputsRequired, hideLabel });
    case DocumentKey.NycSiteSafetyTrainingCard:
      return newYorkSstDocumentInput({
        // TODO: for now while editing metadata on an existing doc version, always assume they're not exempt
        sstExempt: false,
        areInputsRequired,
        hideLabel,
      });
    case DocumentKey.GovernmentIssuedId:
      return governmentIdDocumentInput({ areInputsRequired, hideLabel });
    case DocumentKey.LIRRBlueTraining:
      return lirrBlueTrainingDocumentInput({ areInputsRequired, hideLabel });
    case DocumentKey.ConfinedSpacesTraining:
      return confinedSpacesTrainingDocumentInput({ areInputsRequired, hideLabel });
    case DocumentKey.SiteSpecificOrientation:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: workerDocumentTypeKey,
        label: 'Site Specific Orientation',
        hideLabel,
        requiredFields: ['front'],
        children: {
          [`${workerDocumentTypeKey}-orientation-date`]: {
            element: FormInputTypes.Field,
            label: 'Date of training',
            elementProps: { fieldType: 'date', placeholder: 'Date of training' },
            validation: { pattern: dateValidation, required: true },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
        },
      });
    case DocumentKey.HudsonYardsEmployeeOrientation:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: workerDocumentTypeKey,
        label: 'Hudson Yards Employee Orientation',
        hideLabel,
      });
    case DocumentKey.Signature:
      return signatureFormInput();
    case DocumentKey.JobsiteSafetyVideo:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: DocumentKey.JobsiteSafetyVideo,
        label: 'Worker Safety Video Acknowledgment',
        hideLabel,
        requiredFields: ['front'],
        children: {
          stickerNumber: undefined,
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-status`]: {
            element: FormInputTypes.Toggle,
            label: 'Worker has watched the video',
            elementProps: {
              toggleDescription: 'Worker completed in self-onboarding',
              toggleAlignment: 'right-with-space',
              disabled: true,
            },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-progress-fraction`]: {
            element: FormInputTypes.CustomInput,
            label: 'Acknowledgment progress',
            elementProps: { customInput: AcknowledgmentProgress },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
          [`${DocumentKey.JobsiteSafetyVideo}-acknowledgment-date`]: {
            element: FormInputTypes.Field,
            label: 'Acknowledgment date',
            elementProps: {
              placeholder: 'mm/dd/yyyy',
              fieldType: 'date',
            },
            validation: { pattern: dateValidation, required: true },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
        },
      });
    case DocumentKey.JobsiteSafetyDocument:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: DocumentKey.JobsiteSafetyDocument,
        label: 'Worker Safety Document Acknowledgment',
        hideLabel,
        requiredFields: ['front'],
        children: {
          stickerNumber: undefined,
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-status`]: {
            element: FormInputTypes.Toggle,
            label: 'Worker has read the document',
            elementProps: {
              toggleDescription: 'Worker completed in self-onboarding',
              toggleAlignment: 'right-with-space',
              disabled: true,
            },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-progress-fraction`]: {
            element: FormInputTypes.CustomInput,
            label: 'Acknowledgment progress',
            elementProps: { customInput: AcknowledgmentProgress },
            layout: 'odin-hidden',
          },
          [`${DocumentKey.JobsiteSafetyDocument}-acknowledgment-date`]: {
            element: FormInputTypes.Field,
            label: 'Acknowledgment date',
            elementProps: {
              placeholder: 'mm/dd/yyyy',
              fieldType: 'date',
            },
            validation: { pattern: dateValidation, required: true },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
        },
      });
    case DocumentKey.AdditionalCertifications:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: workerDocumentTypeKey,
        label: 'Additional Certification',
        hideLabel,
        requiredFields: ['front'],
      });
    case DocumentKey.Generic:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: workerDocumentTypeKey,
        label: 'Additional document',
        hideLabel,
        options: otherCardTypes.map((otherCardType) => ({ label: otherCardType, value: otherCardType })),
        hasBack: true,
        requiredFields: ['front'],
      });
    default:
      return generateJobsiteWorkerDocument<WorkerDocumentsDataType>({
        documentKey: workerDocumentTypeKey,
        label: 'Additional document',
        hideLabel,
        requiredFields: ['front'],
        hasBack: true,
        children: {
          [`${workerDocumentTypeKey}-issue-date`]: {
            element: FormInputTypes.Field,
            label: 'Issue date',
            elementProps: { fieldType: 'date', placeholder: 'Issue date' },
            validation: { pattern: dateValidation, required: true },
            layout: 'col-sm-6',
          },
          [`${workerDocumentTypeKey}-expiration-date`]: {
            element: FormInputTypes.Field,
            label: 'Expiration date',
            elementProps: { fieldType: 'date', placeholder: 'Expiration date' },
            validation: { pattern: dateValidation, required: true },
            layout: 'col-sm-6',
          },
        },
      });
  }
};

const useAcknowledgmentProgressUpdate = (
  documentKey: DocumentKey,
  form: UseFormMethods<WorkerDocumentsDataType>,
): void => {
  const { watch, setValue } = form;
  const frontFile = watch(`${documentKey}-front`);

  useDidUpdateEffect(() => {
    const value = frontFile ? 1 : 0;
    setValue(`${documentKey}-acknowledgment-progress-fraction`, value.toString());
  }, [frontFile]);
};

type GetFormInputsArgs = {
  workerDocumentTypeKey: string;
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<WorkerDocumentsDataType> =>
  (form: UseFormMethods<WorkerDocumentsDataType>): FormInput<WorkerDocumentsDataType>[] => {
    const { workerDocumentTypeKey } = args;

    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyVideo, form);
    useAcknowledgmentProgressUpdate(DocumentKey.JobsiteSafetyDocument, form);

    return React.useMemo(() => {
      const documentFormInput = getDocumentFormInput(workerDocumentTypeKey) as FormInput<WorkerDocumentsDataType>;
      return [documentFormInput];
    }, [workerDocumentTypeKey]);
  };

const getInitialDefaultValues = (workerDocumentTypeKey: string): Record<string, unknown> => {
  switch (workerDocumentTypeKey) {
    case DocumentKey.NycSiteSafetyTrainingCard:
      return {
        [`${DocumentKey.NycSiteSafetyTrainingCard}-exemption-reason`]: '',
        [`${DocumentKey.NycSiteSafetyTrainingCard}-is-training-connect-card`]: false,
        [`${DocumentKey.NycSiteSafetyTrainingCard}-type`]: null as SelectOptionElement,
        [`${DocumentKey.NycSiteSafetyTrainingCard}-issue-date`]: '',
        [`${DocumentKey.NycSiteSafetyTrainingCard}-expiration-date`]: '',
      };

    case DocumentKey.OshaCard:
      return {
        [`${DocumentKey.OshaCard}-type`]: null as SelectOptionElement,
        [`${DocumentKey.OshaCard}-issue-date`]: '',
        [`${DocumentKey.OshaCard}-number`]: '',
      };

    case DocumentKey.GovernmentIssuedId:
      return {
        [`${DocumentKey.GovernmentIssuedId}-type`]: null as SelectOptionElement,
        [`${DocumentKey.GovernmentIssuedId}-state-issued`]: '',
        [`${DocumentKey.GovernmentIssuedId}-number`]: '',
        [`${DocumentKey.GovernmentIssuedId}-issue-date`]: '',
        [`${DocumentKey.GovernmentIssuedId}-expiration-date`]: '',
      };

    case DocumentKey.LIRRBlueTraining:
      return {
        [`${DocumentKey.LIRRBlueTraining}-expiration-date`]: '',
      };

    case DocumentKey.ConfinedSpacesTraining:
      return {
        [`${DocumentKey.ConfinedSpacesTraining}-expiration-date`]: '',
      };

    default:
      return {};
  }
};

export const getDefaultValues = (
  workerDocumentTypeKey: string,
  jobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion,
): FormDefaultValue<WorkerDocumentsDataType>[] => {
  const { files, additionalFieldValues } = jobsiteWorkerDocumentVersion ?? {};
  const initialValues = Object.entries(getInitialDefaultValues(workerDocumentTypeKey)).map(([name, value]) => ({
    name,
    value,
  }));
  return [
    ...initialValues,
    ...getFilesData(workerDocumentTypeKey, files),
    ...(additionalFieldValues?.map((additionalField) => ({
      name: `${workerDocumentTypeKey}-${additionalField?.key}`,
      value:
        (additionalField?.key?.includes('date')
          ? getPrettyFormattedUtcDate(additionalField?.value)
          : additionalField?.value) ?? '',
    })) ?? []),
  ];
};

type GetDocumentTypeNameArgs = {
  workerDocumentTypeKey: string;
  workerDocumentTypeName: string;
};

export const getDocumentTypeName = (args: GetDocumentTypeNameArgs): string => {
  const { workerDocumentTypeKey, workerDocumentTypeName } = args;

  if (workerDocumentTypeName) {
    return workerDocumentTypeName;
  }

  const input = getDocumentFormInput(workerDocumentTypeKey);
  return input?.element !== FormInputTypes.Section && input?.element !== FormInputTypes.Panel
    ? input?.elementProps?.label ?? input?.label
    : '';
};
