import React from 'react';
import cn from 'classnames';
import { Alert, SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { useGetJobsiteAndContractorsQuery } from 'apollo/generated/client-operations';
import { JobsiteWorker } from 'containers/worker/types';
import { JobsiteContractor } from 'containers/contractor/types';
import { AuthUser } from 'auth/types';
import { to } from 'acl';
import { ensureNonEmptyItems } from 'utils';
import { CHANGE_JOBSITE_ASSIGNMENT_BANNER_TEXT, DELETE_JOBSITE_ASSIGNMENT_BANNER_TEXT } from 'utils/constants';
import { RequiredField } from 'utils/validation';
import { BriefcaseIcon, WrenchIcon } from 'components/icons';
import {
  FormInputTypes,
  GridColSpan,
  UseInputs,
  TypedFormInputs,
  UseFormMethods,
  RadioGroupFormOption,
} from 'components/form';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { CorrectJobsiteAssignmentFormData, JobsiteAssignmentCorrectionType } from './types';

export type CorrectJobsiteAssignmentInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  contractorId: string;
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
};

export type JobsiteAssignmentCorrectionTypeOption = RadioGroupFormOption<
  Pick<CorrectJobsiteAssignmentFormData, 'newJobsiteId' | 'newContractorId'>,
  JobsiteAssignmentCorrectionType
>;

export const getJobsiteAssignmentCorrectionTypeOptions = ({
  user,
  newContractorOptions,
  newJobsiteOptions,
  selectedCorrectionTypeValue,
}: {
  user: AuthUser;
  newContractorOptions: SelectOptionElement[];
  newJobsiteOptions: SelectOptionElement[];
  selectedCorrectionTypeValue: JobsiteAssignmentCorrectionType;
}): JobsiteAssignmentCorrectionTypeOption[] =>
  ensureNonEmptyItems<JobsiteAssignmentCorrectionTypeOption>([
    {
      value: JobsiteAssignmentCorrectionType.Change,
      label: 'Change this assignment to a new jobsite',
      inputsContainerLayout: cn('odin-flex odin-gap-x-2'),
      inputs: [
        {
          name: 'newJobsiteId',
          element: FormInputTypes.Select,
          elementProps: {
            placeholder: 'Select jobsite',
            options: newJobsiteOptions,
            icon: BriefcaseIcon,
          },
          validation: {
            required: {
              value: selectedCorrectionTypeValue === JobsiteAssignmentCorrectionType.Change,
              message: RequiredField`New jobsite`,
            },
          },
          layout: cn('sm:odin-w-1/2'),
        },
        {
          name: 'newContractorId',
          element: FormInputTypes.Select,
          elementProps: {
            placeholder: 'Select contractor',
            options: newContractorOptions,
            icon: WrenchIcon,
            disabled: (newContractorOptions?.length ?? 0) < 1,
          },
          validation: {
            required: {
              value: selectedCorrectionTypeValue === JobsiteAssignmentCorrectionType.Change,
              message: RequiredField`Contractor`,
            },
          },
          layout: cn('sm:odin-w-1/2'),
        },
      ],
    },
    user.isAllowed(to.chooseToDeleteJobsiteAssignment) && {
      value: JobsiteAssignmentCorrectionType.Deletion,
      label: 'Delete this jobsite assignment and all associated data',
    },
  ]);

const getFormInputs = ({
  editJobsiteOptions,
  correctionTypeOptions,
  selectedCorrectionTypeValue,
}: {
  editJobsiteOptions: SelectOptionElement[];
  correctionTypeOptions: JobsiteAssignmentCorrectionTypeOption[];
  selectedCorrectionTypeValue: JobsiteAssignmentCorrectionType;
}): TypedFormInputs<CorrectJobsiteAssignmentFormData> => ({
  jobsiteId: {
    element: FormInputTypes.Select,
    label: 'Which Jobsite Assignment would you like to edit?',
    elementProps: {
      placeholder: 'Select jobsite',
      options: editJobsiteOptions,
      icon: BriefcaseIcon,
      disabled: (editJobsiteOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull],
  },
  correctionType: {
    element: FormInputTypes.RadioGroup,
    label: 'What kind of change would you like to make?',
    elementProps: {
      options: correctionTypeOptions,
      preventSubmitOnEnter: true,
    },
    layout: GridColSpan.SpanFull,
  },
  alert: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: (
        <Alert
          type="danger"
          text={
            selectedCorrectionTypeValue === JobsiteAssignmentCorrectionType.Change
              ? CHANGE_JOBSITE_ASSIGNMENT_BANNER_TEXT
              : DELETE_JOBSITE_ASSIGNMENT_BANNER_TEXT
          }
        />
      ),
    },
    layout: GridColSpan.SpanFull,
  },
});

export const useContractorsOptions = (jobsiteId: string, jobsiteWorkers: JobsiteWorker[]): SelectOptionElement[] => {
  const { data: jobsiteContractors, loading } = useGetJobsiteAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];

    const alreadyAssignedContractorIds = jobsiteWorkers
      ?.filter((jw) => jw.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
      .map((jw) => jw.jobsiteContractor.contractor.contractorId);

    const contractors = jobsiteContractors?.getJobsite.jobsiteContractors.edges
      .map(({ node }) => node.contractor)
      .filter((c) => !alreadyAssignedContractorIds?.includes(c.contractorId));

    return getContractorsOptions(contractors);
  }, [jobsiteContractors]);
};

export const getFormInputsHook =
  (args: {
    user: AuthUser;
    editJobsiteOptions: SelectOptionElement[];
    newJobsiteOptions: SelectOptionElement[];
    jobsiteWorkers: JobsiteWorker[];
  }): UseInputs<CorrectJobsiteAssignmentFormData> =>
  ({
    watch,
    setValue,
  }: UseFormMethods<CorrectJobsiteAssignmentFormData>): TypedFormInputs<CorrectJobsiteAssignmentFormData> => {
    const { user, editJobsiteOptions, newJobsiteOptions, jobsiteWorkers } = args;

    const selectedNewJobsiteOption = watch('newJobsiteId');
    const selectedNewJobsiteId = selectedNewJobsiteOption?.value;

    const newContractorOptions = useContractorsOptions(selectedNewJobsiteId, jobsiteWorkers);

    useDidUpdateEffect(() => {
      setValue('newContractorId', newContractorOptions?.length === 1 ? newContractorOptions[0] : null);
    }, [newContractorOptions]);

    const selectedCorrectionTypeOption = watch('correctionType');
    const selectedCorrectionTypeValue = selectedCorrectionTypeOption?.value;

    return React.useMemo(() => {
      const correctionTypeOptions = getJobsiteAssignmentCorrectionTypeOptions({
        user,
        newJobsiteOptions,
        newContractorOptions,
        selectedCorrectionTypeValue,
      });
      return getFormInputs({ editJobsiteOptions, correctionTypeOptions, selectedCorrectionTypeValue });
    }, [editJobsiteOptions, user, newJobsiteOptions, newContractorOptions, selectedCorrectionTypeValue]);
  };

export const getDefaultValues = (args: {
  editJobsiteOptions: SelectOptionElement[];
  newJobsiteOptions: SelectOptionElement[];
  newContractorOptions: SelectOptionElement[];
}): CorrectJobsiteAssignmentFormData => {
  const { editJobsiteOptions, newJobsiteOptions, newContractorOptions } = args;

  return {
    jobsiteId: editJobsiteOptions?.length === 1 ? editJobsiteOptions[0] : null,
    correctionType: { value: JobsiteAssignmentCorrectionType.Change, label: null },
    newJobsiteId: newJobsiteOptions?.length === 1 ? newJobsiteOptions[0] : null,
    newContractorId: newContractorOptions?.length === 1 ? newContractorOptions[0] : null,
    alert: undefined,
  };
};
