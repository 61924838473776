import React from 'react';
import { Router } from 'react-router';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { Toaster } from 'react-hot-toast';
import * as GA from 'analytics/GoogleAnalytics';
import { appConfig } from 'config/app';
import { getApolloClient, GetApolloClientArgs } from 'apollo';
import { ApolloState } from 'apollo/types';
import { AuthProvider } from 'auth/AuthProvider';
import { useAuth } from 'auth/hooks';
import { FeatureFlagsProvider } from 'featureFlags';
import { DialogProvider } from 'dialog/DialogProvider';
import { Routes } from 'routes/Routes';
import { LoadingError } from 'components/loadingError';

import { AppProps } from './types';

export function App({ apolloState, history }: AppProps): React.ReactElement {
  const [client, setClient] = React.useState<ApolloClient<ApolloState>>();
  const authState = useAuth();

  React.useEffect(() => {
    const options: GetApolloClientArgs = {
      initialState: apolloState,
      graphQLUrl: appConfig.graphQLUrl,
      getAccessToken: authState.getAccessToken,
      refreshAccessToken: authState.refreshAccessToken,
      signOut: authState.signOut,
    };
    getApolloClient(options).then(setClient);
  }, []);

  return client ? (
    <ApolloProvider client={client}>
      <AuthProvider authState={authState}>
        <FeatureFlagsProvider>
          <Router history={history}>
            <GA.RouteTracker />
            <DialogProvider>
              <Routes />
            </DialogProvider>
            <Toaster containerStyle={{ zIndex: 10001 }} />
          </Router>
        </FeatureFlagsProvider>
      </AuthProvider>
    </ApolloProvider>
  ) : (
    <LoadingError loading />
  );
}
