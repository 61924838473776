import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { DropzoneProps } from 'components/dropzone';
import { dropzoneValidation } from 'components/dropzone/utils';
import { EditDocumentFileFormData } from 'containers/jobsiteConfiguration/types';
import { EditableDocumentFile } from './types';

export const getFormInputs = ({
  editType,
  languageOptions,
  accept,
}: {
  editType: 'create' | 'update';
  languageOptions: SelectOptionElement[];
  accept: DropzoneProps['accept'];
}): TypedFormInputs<EditDocumentFileFormData> => ({
  language: {
    element: FormInputTypes.Select,
    label: 'Language',
    elementProps: {
      options: languageOptions,
      disabled: editType === 'update',
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  title: {
    element: FormInputTypes.Field,
    label: 'Document Title',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan4],
  },
  file: {
    element: FormInputTypes.Dropzone,
    label: 'Add File',
    elementProps: { accept },
    validation: { required: true, validate: dropzoneValidation },
    layout: [GridColSpan.SpanFull],
  },
});

export const getDefaultValues = ({
  editType,
  languageOptions,
  documentFile,
}: {
  editType: 'create' | 'update';
  languageOptions: SelectOptionElement[];
  documentFile?: EditableDocumentFile;
}): EditDocumentFileFormData => {
  const { language, title, file } = documentFile ?? {};
  return {
    language:
      (editType === 'update' ? languageOptions.find((option) => option.value === language) : languageOptions[0]) ??
      null,
    title: title ?? '',
    file: file ?? null,
  };
};
