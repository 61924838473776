import React, { ReactElement, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  MutationUploadSingleFileArgs,
  useCreateJobsiteWorkerDocumentMutation,
  useCreateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { InputFile } from 'types';
import { AuthContext } from 'auth';
import { AlertService } from 'components/alertService';
import { Form, FormInput, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import {
  generateJobsiteWorkerDocument,
  getDocumentPreviewUrl,
  JobsiteWorkerDocumentArgs,
} from 'containers/workerOnboarding/helpers/forms';
import { FILE_UPLOAD } from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments, getVisibleSteps } from 'containers/workerOnboarding/helpers/utils';
import {
  OnboardingStepKey,
  OnboardingStepProps,
  UploadFileResponse,
  WorkerDocumentsDataType,
} from 'containers/workerOnboarding/types';
import {
  ensureNonEmptyItems,
  isServerFile,
  uploadCompressedFile,
  useDeferredFormSubmission,
  useIsMounted,
} from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';
import { commonClasses } from './common.style';

const documentKeys = [DocumentKey.HudsonYardsEmployeeOrientation, DocumentKey.MonadnockEyeProtectionAcknowledgement];

export function OnboardingTrainingStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<WorkerDocumentsDataType>();

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useCreateJobsiteWorkerDocumentMutation({
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useCreateJobsiteWorkerDocumentVersionMutation();

  const onSubmit: FormOnSubmit<WorkerDocumentsDataType> = async (data): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      await Promise.all(
        documentKeys
          .filter((documentKey) => filteredDocumentTypeIds[documentKey])
          .map(async (documentKey) => {
            const fileIds = [];
            const frontFile = data[`${documentKey}-front`] as InputFile;
            if (frontFile) {
              if (isServerFile(frontFile)) {
                fileIds.push(frontFile.fileId);
              } else {
                const fileId = await uploadCompressedFile(frontFile, uploadFile);
                if (fileId) {
                  fileIds.push(fileId);
                }
              }
            }

            const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

            if (jobsiteWorkerDocumentId) {
              return createJobsiteWorkerDocumentVersion({
                variables: {
                  jobsiteWorkerDocumentId,
                  jobsiteWorkerDocumentVersionInput: {
                    fileIds,
                    additionalFieldValues: [],
                  },
                },
              });
            }

            return createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: filteredDocumentTypeIds[documentKey],
                  key: documentKey,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues: [],
                },
              },
            });
          }),
      );

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            onboardingSignedAt: getCurrentISOFormattedDateTime(),
            onboardingSkipReason: null,
          },
        },
      });

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          onboardingSignedAt: getCurrentISOFormattedDateTime(),
          onboardingSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          onboardingSignedAt: null,
          onboardingSkipReason: null,
        },
      },
    });
  };

  const visibleDocuments = getVisibleDocuments(jobsiteWorker);

  const generateJobsiteWorkerDocumentIfAssigned = (
    args: JobsiteWorkerDocumentArgs,
  ): FormInput<WorkerDocumentsDataType> | undefined => {
    return (
      filteredDocumentTypeIds[args.documentKey] &&
      visibleDocuments?.includes(args.documentKey) &&
      generateJobsiteWorkerDocument(args)
    );
  };

  const withFilesDocumentIds = Object.fromEntries(
    documents
      .filter(({ key: doc }) =>
        defaultFormValues.some(
          ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName as string),
        ),
      )
      .map(({ key, id }) => [key, id]),
  );

  const getPreviewUrl = (documentKey: string): string => {
    return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
  };

  const inputs = ensureNonEmptyItems([
    generateJobsiteWorkerDocumentIfAssigned({
      documentKey: DocumentKey.HudsonYardsEmployeeOrientation,
      label: 'Hudson Yards Employee Orientation',
      getPreviewUrl,
    }),
    generateJobsiteWorkerDocumentIfAssigned({
      documentKey: DocumentKey.MonadnockEyeProtectionAcknowledgement,
      label: 'Monadnock Eye Protection Acknowledgement',
      getPreviewUrl,
    }),
  ]);

  const visibleSteps = getVisibleSteps(jobsiteWorker?.jobsiteContractor?.jobsite);
  const loading = parentLoading || fetching;

  return !visibleSteps?.includes(OnboardingStepKey.OnboardingTraining) ? (
    <>
      <div>
        <h2 className="odin-mt-4">You don&lsquo;t have permission to view this page</h2>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        hideLockSession
        hideSave
        hideSkipOptions={isContractorMember}
        onResetComplete={(): void => resetComplete()}
        onForceComplete={(reason: string): void => overrideComplete(reason)}
      />
    </>
  ) : (
    <>
      <div className="odin-relative">
        <fieldset disabled={isContractorMember}>
          <Header title="Onboarding training" />
          <StepLoading loading={loading} hasTransparentBackground={!inputs.length} />
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultFormValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
            className={commonClasses.form}
            inputsContainerClassName={commonClasses.formInputsContainer}
          />
        </fieldset>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideLockSession
      />
    </>
  );
}
