import React, { useState, ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { CheckIcon } from 'components/icons';
import { ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';

import {
  GetContractorContainerContractorInfoDocument,
  GetContractorContainerContractorSubcontractorsDocument,
  useContractorUpdateMutation,
} from 'apollo/generated/client-operations';
import { getDefaultValues, getFormInputs } from './EditContractorModal.forms';
import { EditContractorFormData, EditContractorModalProps } from './types';

export function EditContractorModal(props: EditContractorModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, contractor } = props;

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [contractorUpdate] = useContractorUpdateMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onConfirm();
      AlertService.alert('success', 'Success', 'Contractor successfully updated');
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (responseError: ApolloError) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    refetchQueries: [
      GetContractorContainerContractorSubcontractorsDocument,
      GetContractorContainerContractorInfoDocument,
    ],
  });

  const onSubmit = (data: EditContractorFormData): void => {
    if (isFetching) {
      return;
    }

    setFetching(true);

    const {
      // organization
      name,
      legalName,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZipCode,

      // billing contact
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhoneNumber,

      websiteUrl,
      description,
    } = nullifyEmptyFields(data);

    contractorUpdate({
      variables: {
        input: {
          contractorId: contractor.contractorId,
          organizationInput: {
            name,
            legalName,
            addressLine1,
            addressLine2,
            addressCity,
            addressState: addressState?.value,
            addressZipCode,
          },
          billingContact: {
            firstName: contactFirstName,
            lastName: contactLastName,
            email: contactEmail,
            phoneNumber: contactPhoneNumber,
          },
          websiteUrl,
          description,
        },
      },
    });
  };

  const formInputs = getFormInputs();
  const defaultValues = getDefaultValues(contractor);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Edit Contractor Info"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Save Changes"
      actionIcon={CheckIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
