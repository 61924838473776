import React from 'react';
import cn from 'classnames';
import { SelectOptionElement } from '@odin-labs/components';
import { BriefcaseIcon } from 'components/icons';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import { SearchWorkerInfo } from 'components/searchBox/searchWorkerInfo/SearchWorkerInfo';
import {
  JobsiteFormSubmissionWorkerEdit,
  ParticipantType,
} from 'containers/jobsiteFormSubmission/components/formSubmissionWorkers/types';
import { FormSubmissionWorkerFormData } from './types';

export const participantTypeOptions: SelectOptionElement<ParticipantType>[] = [
  { value: ParticipantType.Injured, label: 'Injured' },
  { value: ParticipantType.Suspected, label: 'General Suspected' },
  { value: ParticipantType.Responsible, label: 'Responsible' },
  { value: ParticipantType.Witness, label: 'Witness' },
];

export const getJobsiteWorkerOptions = (jfsWorkerEdit: JobsiteFormSubmissionWorkerEdit): SelectOptionElement[] =>
  jfsWorkerEdit.jobsitesInfo?.map((j) => ({
    value: j.jobsiteWorkerId,
    label: j.jobsiteName,
  }));

export const getFormInputs = (
  jfsWorkerEdit: JobsiteFormSubmissionWorkerEdit,
  jobsiteWorkerOptions: SelectOptionElement[],
): TypedFormInputs<FormSubmissionWorkerFormData> => ({
  worker: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: (
        <SearchWorkerInfo
          isClickable={false}
          className="odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg"
          searchWorkerResult={jfsWorkerEdit.searchWorkerResult}
        />
      ),
    },
    layout: cn('odin-col-span-6'),
  },
  jobsiteWorkerId: {
    element: FormInputTypes.Select,
    elementProps: {
      placeholder: 'Select jobsite',
      label: 'Jobsite',
      options: jobsiteWorkerOptions,
      icon: BriefcaseIcon,
      disabled: (jobsiteWorkerOptions?.length ?? 0) < 2,
    },
    validation: {
      required: {
        value: true,
        message: 'Jobsite required',
      },
    },
    layout: cn('odin-col-start-1 odin-col-span-3'),
  },
  participantType: {
    element: FormInputTypes.Select,
    elementProps: {
      label: 'Role(s) in Incident',
      options: participantTypeOptions,
    },
    validation: {
      required: {
        value: true,
        message: 'Incident Type required',
      },
    },
    layout: cn('odin-col-span-3'),
  },
});

export const getDefaultValues = (
  jfsWorkerEdit: Partial<JobsiteFormSubmissionWorkerEdit>,
  jobsiteWorkerOptions: SelectOptionElement[],
): FormSubmissionWorkerFormData => {
  return {
    worker: undefined,
    participantType:
      participantTypeOptions?.find((ptOption) => ptOption.value === jfsWorkerEdit.extraData?.participantType) || null,
    jobsiteWorkerId:
      jobsiteWorkerOptions?.find((jwOption) => jwOption.value === jfsWorkerEdit.jobsiteWorker?.jobsiteWorkerId) || null,
  };
};
