import { WorkerBaseInfoInput } from 'apollo/generated/client-operations';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import moment from 'moment/moment';
import { DeepMap } from 'react-hook-form';
import { ensureNonUndefinedFields } from 'utils';
import { momentFormatter } from 'utils/dates';
import { dateValidation } from 'utils/validation';
import { UserValidationFormData } from 'containers/facialRecognition/steps/types';

export const getFormInputs = (): TypedFormInputs<UserValidationFormData> => {
  return {
    birthDate: {
      element: FormInputTypes.Field,
      label: 'Date of birth',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
        inputMode: 'numeric',
      },
      validation: { required: true, pattern: dateValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

export type FacialRecognitionUserValidationInput = Pick<WorkerBaseInfoInput, 'birthDate'> & { workerId: string };

export const getUpdateInput = (
  workerId: string,
  data: UserValidationFormData,
  dirtyFields: DeepMap<UserValidationFormData, true>,
): FacialRecognitionUserValidationInput => {
  const birthDateInputValue = dirtyFields.birthDate
    ? moment(data.birthDate, momentFormatter).toDate() || null
    : undefined;

  return ensureNonUndefinedFields<FacialRecognitionUserValidationInput>({
    workerId,
    birthDate: birthDateInputValue,
  });
};
