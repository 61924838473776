import { CardStatus, WorkerCard, WorkerCardType } from 'apollo/generated/client-operations';
import { QrcodeIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { BadgeChecklist } from 'components/badgeChecklist/BadgeChecklist';
import { AssignQRFormData } from './types';

export const getFormInputs = (): TypedFormInputs<AssignQRFormData> => ({
  qrCodeNumber: {
    element: FormInputTypes.Field,
    label: 'QR Code Number',
    elementProps: {
      icon: QrcodeIcon,
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SpanFull,
  },
  badges: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: BadgeChecklist,
    },
    layout: GridColSpan.SpanFull,
  },
});

export const getFormDefaultValues = (workerCards: WorkerCard[]): AssignQRFormData => ({
  qrCodeNumber: '',
  badges: workerCards
    ?.filter(
      ({ cardStatus, workerCardFormat }) =>
        cardStatus === CardStatus.Active && workerCardFormat.cardType === WorkerCardType.QrCode,
    )
    ?.map((workerCard) => ({ workerCard, selected: true })),
});
