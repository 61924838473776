import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Logo } from 'components/logo';
import { Container, Navbar } from 'components/reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FullLayoutNavbarProps } from './types';

const classes = {
  closeButton: cn(
    'odin-p-0 odin-bg-transparent odin-border-0 odin-float-right',
    'odin-font-semibold odin-text-gray-400 odin-text-3xl print:odin-hidden',
    'hover:odin-text-gray-800',
  ),
};

export function FullLayoutNavbarContainer({ hideClose, className = {}, fixed }: FullLayoutNavbarProps): ReactElement {
  const history = useHistory();
  return (
    <Navbar light className={className} fixed={fixed}>
      <Container className="odin-flex odin-flex-wrap odin-items-center odin-justify-between">
        <div className="odin-p-3">
          <Link to="/">
            <Logo small />
          </Link>
        </div>
        <div>
          {!hideClose && (
            <button className={classes.closeButton} onClick={(): void => history.goBack()}>
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      </Container>
    </Navbar>
  );
}
