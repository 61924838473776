import React from 'react';
import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { JobsiteCreateInput } from 'apollo/generated/client-operations';
import { ensureNonUndefinedFields } from 'utils';
import { getUpdateInputValueFunction, TypedFormInputs, UseFormMethods, UseInputs } from 'components/form';
import { type JobsiteTemplateOption } from 'containers/jobsites/helpers/utils';
import { EditJobsiteFormData } from './types';
import { getFormInputs } from './EditJobsiteModal.forms';

type GetFormInputsHookArgs = {
  templatesOptions: JobsiteTemplateOption[];
  developersOptions: SelectOptionElement[];
};

export const getFormInputsHook =
  (args: GetFormInputsHookArgs): UseInputs<EditJobsiteFormData> =>
  (form: UseFormMethods<EditJobsiteFormData>): TypedFormInputs<EditJobsiteFormData> => {
    const { watch, setValue } = form;
    const { templatesOptions, developersOptions } = args;
    const selectedTemplateOption = watch('templateId');
    const selectedTemplateId = selectedTemplateOption?.value;
    const selectedTemplateDeveloperId = selectedTemplateOption?.developerId;

    useDidUpdateEffect(() => {
      if (selectedTemplateDeveloperId && developersOptions?.length) {
        const developerOption = developersOptions.find((option) => option.value === selectedTemplateDeveloperId);
        setValue('developerId', developerOption, { shouldDirty: true });
      }
    }, [selectedTemplateDeveloperId, developersOptions]);

    const isDeveloperDisabled = !!selectedTemplateId || (developersOptions?.length ?? 0) < 2;

    return React.useMemo(
      () => getFormInputs({ templatesOptions, developersOptions, isDeveloperDisabled }),
      [templatesOptions, developersOptions, isDeveloperDisabled],
    );
  };

type GetDefaultValuesArgs = {
  templatesOptions: SelectOptionElement[];
  developersOptions: SelectOptionElement[];
};

export const getDefaultValues = (args: GetDefaultValuesArgs): EditJobsiteFormData => {
  const { developersOptions } = args;
  return {
    name: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: null,
    addressZipCode: '',
    developerId: developersOptions?.length === 1 ? developersOptions[0] : null,
    templateId: null,
    startDate: moment().startOf('day'),
    endDate: null,
    timeZone: null,
    latitude: '',
    longitude: '',
  };
};

const getAsNumber = (value: string | number): number => {
  if (typeof value === 'number') return value;
  if (value === undefined) return undefined;
  if (value === null || value === '') return null;
  return Number(value.replace('\u00B0', ''));
};

export const getAddInput = (
  data: EditJobsiteFormData,
  dirtyFields: DeepMap<EditJobsiteFormData, true>,
): JobsiteCreateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  return ensureNonUndefinedFields<JobsiteCreateInput>({
    name: data.name,
    templateId: getUpdateInputValue('templateId', true),
    developerId: getUpdateInputValue('developerId', true),
    addressLine1: getUpdateInputValue('addressLine1'),
    addressLine2: getUpdateInputValue('addressLine2'),
    addressCity: getUpdateInputValue('addressCity'),
    addressState: getUpdateInputValue('addressState'),
    addressZipCode: getUpdateInputValue('addressZipCode'),
    timeZone: getUpdateInputValue('timeZone'),
    latitude: getAsNumber(getUpdateInputValue('latitude')),
    longitude: getAsNumber(getUpdateInputValue('longitude')),
    startDate: getUpdateInputValue('startDate', true),
    endDate: getUpdateInputValue('endDate'),
  });
};
