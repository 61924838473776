import React, { useState, ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import {
  JobsiteAppCheckInModule,
  JobsiteWorkerCheckInType,
  useCheckInJobsiteWorkersMutation,
} from 'apollo/generated/client-operations';
import { JobsiteAccessEventFormData, JobsiteWorker } from 'containers/worker/types';
import { getJobsiteDateTime } from 'utils/dates';
import { getAppCheckInModule } from 'containers/worker/helpers/utils';
import { AddJobsiteAccessEventModalProps } from './types';
import {
  getAddJobsiteAccessEventFormInputsHook,
  getAddJobsiteAccessEventFormDefaultValues,
} from './AddJobsiteAccessEventModal.forms';

export function AddJobsiteAccessEventModal(props: AddJobsiteAccessEventModalProps): ReactElement {
  const { jobsiteWorkers, isOpen, closeModal: onCancel, onConfirm } = props;
  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [checkInJobsiteWorker] = useCheckInJobsiteWorkersMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onConfirm();
      AlertService.alert(
        'success',
        'Success',
        'A jobsite access event was successfully added. Please refresh the page to see the event.',
      );
      if (isMounted()) {
        setFetching(false);
      }
    },
    onError: (responseError: ApolloError) => {
      if (isMounted()) {
        setFetching(false);
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
  });

  const jobsiteWorkersWithCheckIns = jobsiteWorkers?.filter((jw) => {
    const appCheckInModule = getAppCheckInModule(jw?.jobsiteContractor?.jobsite?.modules);
    return appCheckInModule?.areas?.length;
  });

  const getJobsiteWorker = (jobsiteId: string): JobsiteWorker => {
    return jobsiteWorkersWithCheckIns?.find((jw) => jw.jobsiteContractor?.jobsite.jobsiteId === jobsiteId);
  };

  const onSubmit = (data: JobsiteAccessEventFormData): void => {
    if (isFetching) {
      return;
    }

    const jobsiteWorker = getJobsiteWorker(data.jobsiteId.value);
    const jobsite = jobsiteWorker?.jobsiteContractor?.jobsite;
    const appCheckInModule = jobsite?.modules?.find(
      // eslint-disable-next-line no-underscore-dangle
      (jm): jm is JobsiteAppCheckInModule => jm.__typename === 'JobsiteAppCheckInModule',
    );
    const [firstArea] = appCheckInModule?.areas ?? [];

    setFetching(true);

    checkInJobsiteWorker({
      variables: {
        input: {
          jobsiteId: data.jobsiteId.value,
          jobsiteWorkerIds: [jobsiteWorker?.jobsiteWorkerId],
          checkInType: JobsiteWorkerCheckInType.WebAppCheckIn,
          checkInAreaId: firstArea?.id,
          checkInDirection: data.checkInDirection.value,
          checkInDateTime: getJobsiteDateTime({ date: data.checkInDate, time: data.checkInTime, jobsite }),
          checkInReason: data.reason.value,
        },
      },
    });
  };

  const jobsiteOptions = React.useMemo(
    () =>
      jobsiteWorkersWithCheckIns?.map((jw) => {
        const { jobsiteId, name, timeZone } = jw.jobsiteContractor?.jobsite ?? {};
        return {
          value: jobsiteId,
          label: name,
          timeZone,
        };
      }),
    [jobsiteWorkersWithCheckIns],
  );

  const inputs = getAddJobsiteAccessEventFormInputsHook({ jobsiteOptions });
  const defaultValues = React.useMemo(
    () => getAddJobsiteAccessEventFormDefaultValues({ jobsiteOptions }),
    [jobsiteOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Access Event"
      actionText="Save"
      actionButtonWithSpinner={isFetching}
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
    />
  );
}
