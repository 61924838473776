import { Column } from '@odin-labs/components';
import { JobsitesTabApi } from 'containers/jobsites/types';
import { PageSearchParams } from 'utils';
import type { GetJobsitesContainerCurrentSessionQuery } from 'apollo/generated/client-operations';

export type JobsitesUser = GetJobsitesContainerCurrentSessionQuery['getCurrentSession']['user'];
export type Jobsite = JobsitesUser['jobsites']['edges'][number]['node'];
export type JobsiteFiltersOptions = JobsitesUser['jobsites']['filtersOptions'];

export type JobsitesFilters = Pick<PageSearchParams, 'developerIds' | 'cities' | 'states' | 'search'>;

export type JobsiteColumn = Column<Jobsite>;

export type JobsitesWidgetProps = {
  onTabApiChange: (api: JobsitesTabApi) => void;
  isTemplate?: boolean;
};

// TO CHANGE
export enum JobsiteColumnName {
  Jobsite = 'jobsite',
  Client = 'client',
  Location = 'location',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Actions = 'actions',
}
