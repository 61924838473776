import React from 'react';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import {
  CCureEntityType,
  ChangeType,
  JobsiteUpdateInput,
  WorkerCardType,
  JobsiteCCureEntityMappingInput,
} from 'apollo/generated/client-operations';
import { typedComponentProps } from 'utils';
import {
  FormInputTypes,
  getUpdateInputValueFunction,
  GridColSpan,
  TypedFormInputs,
  UseFormMethods,
  UseInputs,
} from 'components/form';
import { CCureClearances } from 'containers/jobsiteConfiguration/components';
import { EditableCCureClearance } from 'containers/jobsiteConfiguration/components/cCureClearances/modals';
import {
  CCureEntitySelectOptionElement,
  EditJobsiteConfigurationFormData,
  Jobsite,
} from 'containers/jobsiteConfiguration/types';
import { WorkerCardFormatOption } from 'containers/worker/helpers/utils';

export type CCureSectionInputsArgs = {
  workerCardFormatsOptions: WorkerCardFormatOption[];
  cCureEnvironmentsOptions: SelectOptionElement[];
  defaultProximityOptions: SelectOptionElement[];
  defaultQrOptions: SelectOptionElement[];
  clearanceOptions: CCureEntitySelectOptionElement[];
  controllerOptions: CCureEntitySelectOptionElement[];
  doorOptions: CCureEntitySelectOptionElement[];
  inboundAreaOptions: CCureEntitySelectOptionElement[];
  outboundAreaOptions: CCureEntitySelectOptionElement[];
  firstClearance?: EditableCCureClearance;
};

const getCCureSectionInputs = ({
  workerCardFormatsOptions,
  cCureEnvironmentsOptions,
  defaultProximityOptions,
  defaultQrOptions,
  clearanceOptions: cCureClearancesOptions,
  controllerOptions,
  doorOptions,
  inboundAreaOptions,
  outboundAreaOptions,
  firstClearance,
}: CCureSectionInputsArgs): TypedFormInputs<EditJobsiteConfigurationFormData['cCure']> => ({
  clearances: {
    element: FormInputTypes.CustomInput,
    elementProps: {
      customInput: CCureClearances,
      ...typedComponentProps<typeof CCureClearances>({
        cCureClearancesOptions,
        cCureEnvironmentsOptions,
      }),
    },
    layout: [GridColSpan.SpanFull],
  },
  supportedCardFormats: {
    element: FormInputTypes.Select,
    label: 'Supported Card Formats',
    layout: [GridColSpan.SpanFull],
    elementProps: {
      multiple: true,
      clearToNull: true,
      options: workerCardFormatsOptions ?? [],
    },
  },
  defaultProximity: {
    element: FormInputTypes.Select,
    label: 'Default Proximity',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      clearToNull: true,
      options: defaultProximityOptions ?? [],
      disabled: !defaultProximityOptions?.length,
    },
  },
  defaultQr: {
    element: FormInputTypes.Select,
    label: 'Default QR',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      clearToNull: true,
      options: defaultQrOptions ?? [],
      disabled: !defaultQrOptions?.length,
    },
  },
  doors: {
    element: FormInputTypes.Select,
    label: 'Doors',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      multiple: true,
      clearToNull: true,
      disabled: firstClearance == null,
      options: doorOptions ?? [],
    },
  },
  controllers: {
    element: FormInputTypes.Select,
    label: 'Controllers',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      multiple: true,
      clearToNull: true,
      disabled: firstClearance == null,
      options: controllerOptions ?? [],
    },
  },
  inboundAreas: {
    element: FormInputTypes.Select,
    label: 'Inbound Areas',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      multiple: true,
      clearToNull: true,
      disabled: firstClearance == null,
      options: inboundAreaOptions ?? [],
    },
  },
  outboundAreas: {
    element: FormInputTypes.Select,
    label: 'Outbound Areas',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      multiple: true,
      clearToNull: true,
      disabled: firstClearance == null,
      options: outboundAreaOptions ?? [],
    },
  },
});

export const getCCureSectionCardFormatsDefaultOptions = ({
  supportedCardFormatsOptions,
  workerCardFormatsOptions,
  defaultProximity,
  defaultQr,
}: {
  supportedCardFormatsOptions: SelectOptionElement[];
  workerCardFormatsOptions: WorkerCardFormatOption[];
  defaultProximity: SelectOptionElement;
  defaultQr: SelectOptionElement;
}): {
  defaultProximityOptions: SelectOptionElement[];
  defaultQrOptions: SelectOptionElement[];
  shouldResetDefaultProximity: boolean;
  shouldResetDefaultQr: boolean;
} => {
  const supportedCardFormatIds = supportedCardFormatsOptions?.map((opt) => opt.value);

  const getOptions = (cardType: WorkerCardType): WorkerCardFormatOption[] =>
    workerCardFormatsOptions?.filter(
      (wcfOpt) => wcfOpt.cardType === cardType && supportedCardFormatIds?.includes(wcfOpt.value),
    );

  const getShouldResetDefault = (defaultOption: SelectOptionElement, options: WorkerCardFormatOption[]): boolean => {
    return defaultOption && !options?.some((opt) => opt.value === defaultOption.value);
  };

  const defaultProximityOptions = getOptions(WorkerCardType.Proximity);
  const defaultQrOptions = getOptions(WorkerCardType.QrCode);

  return {
    defaultProximityOptions,
    defaultQrOptions,
    shouldResetDefaultProximity: getShouldResetDefault(defaultProximity, defaultProximityOptions),
    shouldResetDefaultQr: getShouldResetDefault(defaultQr, defaultQrOptions),
  };
};

export type CCureSectionInputsHookArgs = Omit<CCureSectionInputsArgs, 'defaultProximityOptions' | 'defaultQrOptions'>;

export const getCCureSectionInputsHook =
  (args: CCureSectionInputsHookArgs): UseInputs<EditJobsiteConfigurationFormData['cCure']> =>
  ({
    watch,
    setValue,
    getValues,
  }: UseFormMethods<EditJobsiteConfigurationFormData['cCure']>): TypedFormInputs<
    EditJobsiteConfigurationFormData['cCure']
  > => {
    const {
      workerCardFormatsOptions,
      cCureEnvironmentsOptions,
      clearanceOptions,
      controllerOptions,
      doorOptions,
      inboundAreaOptions,
      outboundAreaOptions,
    } = args;
    const supportedCardFormatsOptions = watch('cCure.supportedCardFormats') as SelectOptionElement[];
    const currentClearances = (watch('cCure.clearances') as EditableCCureClearance[]) ?? [];
    const [firstClearance] = currentClearances.filter((ccc) => ccc.changeType !== 'removed');

    const currentCCureNamespaces = Array.from(
      new Set(currentClearances.filter((ccc) => ccc.changeType !== 'removed').map((c) => c.cCureEnvironment.namespace)),
    );
    // TODO: Fix UseInputs type to accept FormT
    const { cCure } = getValues() as unknown as EditJobsiteConfigurationFormData;
    const { defaultProximity, defaultQr } = cCure ?? {};

    const { defaultProximityOptions, defaultQrOptions, shouldResetDefaultProximity, shouldResetDefaultQr } =
      React.useMemo(() => {
        return getCCureSectionCardFormatsDefaultOptions({
          supportedCardFormatsOptions,
          workerCardFormatsOptions,
          defaultProximity,
          defaultQr,
        });
      }, [supportedCardFormatsOptions, workerCardFormatsOptions]);

    useDidUpdateEffect(() => {
      // clear defaultProximity and defaultQr values if their options don't exist
      if (shouldResetDefaultProximity) {
        setValue('cCure.defaultProximity', null);
      }
      if (shouldResetDefaultQr) {
        setValue('cCure.defaultQr', null);
      }
    }, [defaultProximityOptions, defaultQrOptions]);

    return React.useMemo(() => {
      return getCCureSectionInputs({
        workerCardFormatsOptions,
        cCureEnvironmentsOptions,
        defaultProximityOptions,
        defaultQrOptions,
        clearanceOptions,
        controllerOptions: controllerOptions.filter((so) =>
          currentCCureNamespaces.includes(so.entity.cCureEnvironment.namespace),
        ),
        doorOptions: doorOptions.filter((so) => currentCCureNamespaces.includes(so.entity.cCureEnvironment.namespace)),
        inboundAreaOptions: inboundAreaOptions.filter((so) =>
          currentCCureNamespaces.includes(so.entity.cCureEnvironment.namespace),
        ),
        outboundAreaOptions: outboundAreaOptions.filter((so) =>
          currentCCureNamespaces.includes(so.entity.cCureEnvironment.namespace),
        ),
        firstClearance,
      });
    }, [
      workerCardFormatsOptions,
      cCureEnvironmentsOptions,
      clearanceOptions,
      defaultProximityOptions,
      defaultQrOptions,
      firstClearance,
      currentCCureNamespaces,
    ]);
  };

export const getCCureSectionDefaultValues = (
  jobsite: Jobsite,
  workerCardFormatsOptions: WorkerCardFormatOption[],
  cCureControllersOptions?: CCureEntitySelectOptionElement[],
  cCureDoorsOptions?: CCureEntitySelectOptionElement[],
  cCureInboundAreasOptions?: CCureEntitySelectOptionElement[],
  cCureOutboundAreasOptions?: CCureEntitySelectOptionElement[],
): EditJobsiteConfigurationFormData['cCure'] => {
  const { jobsiteCardFormats, cCureClearances, cCureEntityMappings } = jobsite ?? {};
  const supportedCardFormats = jobsiteCardFormats?.edges.map(({ node }) => node);
  const supportedCardFormatIds = supportedCardFormats?.map((jcf) => jcf.workerCardFormat.workerCardFormatId);
  const defaultProximityId = supportedCardFormats?.find((jcf) => jcf.isDefaultForCardType === WorkerCardType.Proximity)
    ?.workerCardFormat.workerCardFormatId;
  const defaultQrId = supportedCardFormats?.find((jcf) => jcf.isDefaultForCardType === WorkerCardType.QrCode)
    ?.workerCardFormat.workerCardFormatId;
  const mappings =
    cCureEntityMappings?.edges
      .map((e) => e.node)
      .reduce((group: Record<CCureEntityType, string[]>, mapping) => {
        // eslint-disable-next-line no-param-reassign
        group[mapping.entityType] = group[mapping.entityType] ?? [];
        group[mapping.entityType].push(`${mapping.cCureEnvironment?.namespace}:${mapping.objectId}`);
        return group;
      }, {} as Record<CCureEntityType, string[]>) ?? ({} as Record<CCureEntityType, string[]>);

  const supportedCardFormatsOptions: SelectOptionElement[] = supportedCardFormatIds?.length
    ? workerCardFormatsOptions.filter((opt) => supportedCardFormatIds.includes(opt.value))
    : null;
  const defaultProximityOption = defaultProximityId
    ? supportedCardFormatsOptions?.find((opt) => opt.value === defaultProximityId)
    : null;
  const defaultQrOption = defaultQrId ? supportedCardFormatsOptions?.find((opt) => opt.value === defaultQrId) : null;

  const clearances = cCureClearances?.edges.map(({ node }) => node) ?? null;

  return {
    clearances,
    supportedCardFormats: supportedCardFormatsOptions,
    defaultProximity: defaultProximityOption,
    defaultQr: defaultQrOption,
    controllers: cCureControllersOptions.filter((soe) => mappings[CCureEntityType.Controller]?.includes(soe.value)),
    doors: cCureDoorsOptions.filter((soe) => mappings[CCureEntityType.Door]?.includes(soe.value)),
    inboundAreas: cCureInboundAreasOptions.filter((soe) => mappings[CCureEntityType.InArea]?.includes(soe.value)),
    outboundAreas: cCureOutboundAreasOptions.filter((soe) => mappings[CCureEntityType.OutArea]?.includes(soe.value)),
  };
};

type CCureSectionUpdateInput = Required<
  Pick<
    JobsiteUpdateInput,
    'cCureClearances' | 'supportedCardFormatIds' | 'defaultProximityCardId' | 'defaultQrCodeId' | 'cCureEntityMappings'
  >
>;

export const getCCureSectionUpdateInput = (
  cCure: EditJobsiteConfigurationFormData['cCure'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['cCure'], true>,
): CCureSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(cCure, dirtyFields);

  const cCureClearances: CCureSectionUpdateInput['cCureClearances'] = getUpdateInputValue('clearances')
    ?.filter((c) => c.changeType)
    ?.map((c) => ({
      isDefault: c.isDefault ?? false,
      isSelectable: c.isSelectable ?? false,
      cCureClearanceId: c.cCureClearanceId,
      cCureEnvironmentId: c.cCureEnvironment.cCureEnvironmentId,
      cCureClearanceObjectId: c.cCureClearanceObjectId,
      changeType: c.changeType as ChangeType,
      name: c.name,
    }));
  const firstClearance = cCure.clearances.find((c) => c.changeType !== 'removed');
  const cCureEntityMappings: JobsiteCCureEntityMappingInput[] = [];
  if (firstClearance) {
    cCureEntityMappings.push(
      ...(cCure.controllers?.map((soe) => ({
        entityType: CCureEntityType.Controller,
        objectId: soe.entity.objectId,
        environmentId: soe.entity.cCureEnvironment.cCureEnvironmentId,
      })) ?? []),
    );
    cCureEntityMappings.push(
      ...(cCure.doors?.map((soe) => ({
        entityType: CCureEntityType.Door,
        objectId: soe.entity.objectId,
        environmentId: soe.entity.cCureEnvironment.cCureEnvironmentId,
      })) ?? []),
    );
    cCureEntityMappings.push(
      ...(cCure.inboundAreas?.map((soe) => ({
        entityType: CCureEntityType.InArea,
        objectId: soe.entity.objectId,
        environmentId: soe.entity.cCureEnvironment.cCureEnvironmentId,
      })) ?? []),
    );
    cCureEntityMappings.push(
      ...(cCure.outboundAreas?.map((soe) => ({
        entityType: CCureEntityType.OutArea,
        objectId: soe.entity.objectId,
        environmentId: soe.entity.cCureEnvironment.cCureEnvironmentId,
      })) ?? []),
    );
  }

  return {
    cCureClearances,
    supportedCardFormatIds: getUpdateInputValue('supportedCardFormats'),
    defaultProximityCardId: getUpdateInputValue('defaultProximity'),
    defaultQrCodeId: getUpdateInputValue('defaultQr'),
    cCureEntityMappings,
  };
};
