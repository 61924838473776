import React from 'react';
import { Button } from '@odin-labs/components';
import backgroundImage from 'images/crane.jpg';

export function MobileOnlyContainer(): React.ReactElement {
  return (
    <div className="mobile-only-container odin-w-full odin-border-t-2 odin-border-odin-primary">
      <div className="odin-flex align-items-center justify-content-center">
        <div className="odin-p-9">
          <div className="odin-text-center">
            <h6 className="odin-uppercase odin-text-gray-400 odin-mb-6">Please use our app</h6>
            <h1 className="odin-text-2xl lg:odin-text-2.66xl odin-mb-3">
              Please download the mobile app from the Apple app store or Google play to login.
            </h1>
            <p className="odin-text-gray-400 odin-mb-6">Click below to download</p>
            <Button as="a" text="Download Now" href="https://useodin.com/download" />
          </div>
        </div>
        <div className="odin-hidden md:odin-block odin-w-full">
          <div
            className="odin-bg-cover odin-bg-no-repeat odin-bg-center odin-h-screen -odin-mt-3 -odin-mr-7"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          />
        </div>
      </div>
    </div>
  );
}
