import React from 'react';
import cn from 'classnames';
import { getFaIcon } from '@odin-labs/components';
import { faCheck, faTimes } from 'components/icons';
import { Copy as SelfOnboardingCopy } from 'containers/selfOnboarding/steps/utils';
import { FacialRecognitionPhotoDirectionsProps } from 'containers/facialRecognition/types';

export type ProfilePhotoDirectionItemProps = {
  text: string;
  permission: 'allowed' | 'notAllowed';
};

const CheckIcon = getFaIcon({ icon: faCheck, className: cn('odin-text-odin-primary') });
const TimesIcon = getFaIcon({ icon: faTimes, className: cn('odin-text-odin-danger') });

export function ProfilePhotoDirectionItem(props: ProfilePhotoDirectionItemProps): React.ReactElement {
  const { text, permission } = props;
  const Icon = permission === 'allowed' ? CheckIcon : TimesIcon;
  return (
    <div className="odin-flex odin-gap-x-3 odin-items-center odin-text-base">
      <Icon />
      <div className="odin-text-base odin-font-medium">{text}</div>
    </div>
  );
}

export function ProfilePhotoDirections(props: FacialRecognitionPhotoDirectionsProps): React.ReactElement {
  const { localize } = props;
  return (
    <div className="odin-flex odin-flex-col odin-gap-y-3">
      <ProfilePhotoDirectionItem
        permission="allowed"
        text={localize(SelfOnboardingCopy.self_onboarding_profile_photo_direction_1)}
      />
      <ProfilePhotoDirectionItem
        permission="allowed"
        text={localize(SelfOnboardingCopy.self_onboarding_profile_photo_direction_2)}
      />
      <ProfilePhotoDirectionItem
        permission="notAllowed"
        text={localize(SelfOnboardingCopy.self_onboarding_profile_photo_direction_3)}
      />
      <ProfilePhotoDirectionItem
        permission="notAllowed"
        text={localize(SelfOnboardingCopy.self_onboarding_profile_photo_direction_4)}
      />
    </div>
  );
}
