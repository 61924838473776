import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownButton, MenuItemProps, TabConfig } from '@odin-labs/components';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { AuthContext } from 'auth';
import { AuthUser, to } from 'acl';
import { BriefcaseSolidIcon, BriefcaseIcon, PlusIcon } from 'components/icons';
import { Container } from 'components/container';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { NewHeader } from 'components/header/NewHeader';
import { AddJobsiteModal, AddJobsiteTemplateModal } from './modals';
import { JobsitesTabApi, JobsitesTabProps } from './types';
import { JobsitesTab } from './jobsitesTab';

const baseRoute = `/jobsites`;

export enum JobsiteTabName {
  Jobsites = 'jobsites',
  Templates = 'templates',
}

const getTabsConfig = (user: AuthUser): TabConfig<JobsitesTabProps>[] =>
  ensureNonEmptyItems<TabConfig<JobsitesTabProps>>([
    {
      name: JobsiteTabName.Jobsites,
      text: `Jobsites`,
      relativePath: '',
      component: JobsitesTab,
    },
    user.isAllowed(to.seeJobsiteTemplates) && {
      name: JobsiteTabName.Templates,
      text: `Jobsite Templates`,
      relativePath: '/templates',
      component: JobsitesTab,
    },
  ]);

export function JobsitesContainer(): ReactElement {
  const history = useHistory();
  const { currentUser: user } = React.useContext(AuthContext);
  const {
    value: isAddJobsiteModalOpen,
    setTrue: openAddJobsiteModal,
    setFalse: closeAddJobsiteModal,
  } = useBoolean(false);
  const {
    value: isAddTemplateModalOpen,
    setTrue: openAddTemplateModal,
    setFalse: closeAddTemplateModal,
  } = useBoolean(false);

  const [tabApi, setTabApi] = React.useState<JobsitesTabApi>(null);

  const tabsCount: Record<string, string> = {
    [JobsiteTabName.Jobsites]: tabApi?.jobsitesCount?.toString() ?? '...',
    [JobsiteTabName.Templates]: tabApi?.templatesCount?.toString() ?? '...',
  };

  const tabsConfig = getTabsConfig(user).map((tab) => ({ ...tab, badge: tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig, baseRoute });

  const navigateToTabIfNeeded = (tabName: JobsiteTabName): void => {
    if (currentTab.name !== tabName) {
      const tab = tabsConfig.find((t) => t.name === tabName);
      history.push(`${baseRoute}${tab.relativePath}`);
    }
  };

  const menuItems = ensureNonEmptyItems<MenuItemProps>([
    user.isAllowed(to.addJobsites) && {
      text: 'Jobsite',
      icon: BriefcaseIcon,
      onClick: openAddJobsiteModal,
    },
    user.isAllowed(to.addJobsiteTemplates) && {
      text: 'Jobsite Template',
      icon: BriefcaseSolidIcon,
      onClick: openAddTemplateModal,
    },
  ]);

  const tabsPageProps: JobsitesTabProps = {
    onTabApiChange: setTabApi,
    isTemplate: currentTab.name === JobsiteTabName.Templates,
  };

  return (
    <Container className="jobsites-container">
      <NewHeader
        title="Jobsites"
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          children: !!menuItems.length && <DropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      <AddJobsiteModal
        isOpen={isAddJobsiteModalOpen}
        onCancel={closeAddJobsiteModal}
        onConfirm={(): void => {
          closeAddJobsiteModal();
          navigateToTabIfNeeded(JobsiteTabName.Jobsites);
        }}
      />
      <AddJobsiteTemplateModal
        isOpen={isAddTemplateModalOpen}
        onCancel={closeAddTemplateModal}
        onConfirm={(): void => {
          closeAddTemplateModal();
          navigateToTabIfNeeded(JobsiteTabName.Templates);
        }}
      />
    </Container>
  );
}
