import React, { ReactElement } from 'react';
import { Badge, Checkbox, Modal } from '@odin-labs/components';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { Card, CardBody, CardTitle } from 'components/reactstrap';
import { WorkersFoundModalProps } from './types';

export function WorkersFoundModal({
  isOpen = false,
  workers,
  subtitle,
  setWorkerMatch,
  workerMatch,
  cancelText,
  actionText,
  toggle,
  onCancel,
  onAction,
}: WorkersFoundModalProps): ReactElement {
  return (
    <Modal
      onCancel={onCancel}
      onAction={onAction}
      open={isOpen}
      setOpen={toggle}
      cancelText={cancelText || 'Create new worker'}
      actionText={actionText || 'View worker profile'}
      actionButtonEnabled={!!workerMatch}
    >
      <>
        <h2 className="odin-font-semibold odin-mb-3 odin-text-center">
          This person may already have a profile in ODIN!
        </h2>
        {subtitle ? <p className="odin-text-center">{subtitle}</p> : null}
        <div className="odin-flex odin-flex-wrap">
          {workers.filter(Boolean).map((worker) => {
            return (
              <div
                key={worker.workerId}
                onClick={(): void => {
                  setWorkerMatch(worker.workerId === workerMatch?.workerId ? null : worker);
                }}
                className="odin-p-3 odin-basis-1/2 odin-max-w-1/2"
              >
                <div className="odin-relative odin-z-10">
                  <span className="odin-absolute odin-top-2.5 odin-left-auto odin-right-0 odin-opacity-100">
                    <Checkbox checked={worker.workerId === workerMatch?.workerId} />
                  </span>
                </div>

                <Card className="odin-text-center odin-cursor-pointer hover:odin-shadow-xl">
                  <CardBody>
                    <CardTitle as="h3">
                      {worker.firstName} {worker.lastName}
                    </CardTitle>
                    <p>
                      {worker.email}
                      <br />
                      {getISODateTimeToFormattedDate(convertUTCtoLocalDate(worker.birthDate))}
                    </p>
                    <div>
                      {worker.title && <Badge type="rounded" color="gray" className="odin-mr-3" text={worker.title} />}
                      {worker.trade && <Badge type="rounded" color="gray" text={worker.trade} />}
                    </div>
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>
      </>
    </Modal>
  );
}
