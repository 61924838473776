import React from 'react';
import cn from 'classnames';

const classes = {
  cardFooter: (extraClasses: string): string =>
    cn(extraClasses, 'reactstrap-card-footer odin-border-t odin-border-gray-200 odin-py-4 odin-px-6'),
};

export type CardFooterProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function CardFooter(props: CardFooterProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.cardFooter(className)}>{children}</div>;
}
