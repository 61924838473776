import React from 'react';
import { useDidUpdateEffect } from '@odin-labs/components';
import { FormInputTypes, TypedFormInputs, GridColSpan, UseInputs, UseFormMethods } from 'components/form';
import {
  toFancySelectOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
} from 'utils/constants';
import {
  FormInputArgs,
  Localize,
  SelfOnboardingContractorSelectionFormData,
  SelfOnboardingState,
} from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';

export const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);

type GetFormInputsArgs = FormInputArgs & Pick<SelfOnboardingState, 'contractorOptions'> & { localize: Localize };

const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<SelfOnboardingContractorSelectionFormData> => {
  const { contractorOptions, localize } = args;
  return {
    contractorId: {
      element: FormInputTypes.Select,
      label: localize(Copy.self_onboarding_contractor_selector_label),
      elementProps: {
        options: contractorOptions,
      },
      validation: { required: true },
      layout: GridColSpan.SpanFull,
    },
  };
};

type GetFormInputsHookArgs = GetFormInputsArgs & {
  setIsContinueActionEnabled: (value: boolean) => void;
};

export const getFormInputsHook =
  (args: GetFormInputsHookArgs): UseInputs<SelfOnboardingContractorSelectionFormData> =>
  (
    form: UseFormMethods<SelfOnboardingContractorSelectionFormData>,
  ): TypedFormInputs<SelfOnboardingContractorSelectionFormData> => {
    const { setIsContinueActionEnabled, contractorOptions, localize } = args;
    const { watch } = form;

    const selectedContractor = watch('contractorId');
    const isContinueActionEnabled = !!selectedContractor;
    useDidUpdateEffect(() => {
      setIsContinueActionEnabled(isContinueActionEnabled);
    }, [isContinueActionEnabled]);

    return React.useMemo(() => getFormInputs({ contractorOptions, localize }), [contractorOptions, localize]);
  };
