import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { CreateAnnouncementData, DraftAnnouncementFormData, AnnouncementType } from 'containers/announcements/types';

export const getFormInputs = (announcement: CreateAnnouncementData): TypedFormInputs<DraftAnnouncementFormData> => {
  const messageMaxLength = announcement.announcementType === AnnouncementType.Email ? 2000 : 255;
  const subjectMaxLength = announcement.announcementType === AnnouncementType.Email ? 255 : 40;
  return {
    subject: {
      element: FormInputTypes.Field,
      elementProps: {
        label: 'Subject',
        maxLength: subjectMaxLength,
        note: `${announcement?.subject?.length || 0} of max ${subjectMaxLength} characters`,
      },
      validation: { required: true },
      layout: GridColSpan.SpanFull,
    },
    message: {
      element: FormInputTypes.TextAreaField,
      elementProps: {
        label: 'Message',
        size: 'lg',
        maxLength: messageMaxLength,
        autosize: true,
        maxRows: 20,
        note: `${announcement?.message?.length || 0} of max ${messageMaxLength} characters`,
      },
      validation: { required: true },
      layout: GridColSpan.SpanFull,
    },
  };
};

export const getDefaultValues = (announcement: CreateAnnouncementData): DraftAnnouncementFormData => ({
  subject: announcement.subject ?? '',
  message: announcement.message ?? '',
});
