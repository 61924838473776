import { to } from 'acl';
import { AuthContext } from 'auth';
import classNames from 'classnames';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { getFormattedDate } from 'utils';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { JobsiteWorker, Worker } from 'containers/worker/types';
import { Button } from '@odin-labs/components';
import { ExclamationTriangleIcon } from 'components/icons';

export interface JobsiteBan {
  jobsiteWorker: JobsiteWorker;
  jobsiteName: string;
  workerName: string;
}

export interface WorkerProfileBannerActionProps {
  jobsiteBan: JobsiteBan;
  openChangeWorkerAccessModal: (jobsiteWorker: JobsiteWorker) => void;
}

function WorkerProfileBannerAction(props: WorkerProfileBannerActionProps): ReactElement {
  const { jobsiteBan, openChangeWorkerAccessModal } = props;
  const { jobsiteWorker } = jobsiteBan ?? {};
  const { jobsiteId } = jobsiteWorker?.jobsiteContractor?.jobsite ?? {};
  const isSuspension = !!jobsiteWorker?.banExpiresAt;

  const { currentUser: user } = React.useContext(AuthContext);

  if (
    (isSuspension && user.isAllowed(to.unsuspendWorkers, jobsiteId)) ||
    (!isSuspension && user.isAllowed(to.unbanWorkers, jobsiteId))
  ) {
    return (
      <Button
        theme="white"
        size="xs"
        onClick={(): void => {
          openChangeWorkerAccessModal(jobsiteWorker);
        }}
        className="odin-py-2"
        text={`${isSuspension ? 'Unsuspend' : 'Unban'} worker`}
      />
    );
  }

  return null;
}

export interface WorkerProfileBannerProps {
  worker: Worker;
  jobsiteBans: JobsiteBan[];
  openChangeWorkerAccessModal: (jobsiteWorker: JobsiteWorker) => void;
  openBanReasonModal: (jobsiteBan: JobsiteBan) => void;
  jobsiteDocumentConditionalPasses: string[];
  jobsiteMedicalConditionalPasses: string[];
}

export function WorkerProfileBanner({
  worker,
  jobsiteBans,
  openChangeWorkerAccessModal,
  openBanReasonModal,
  jobsiteDocumentConditionalPasses,
  jobsiteMedicalConditionalPasses,
}: WorkerProfileBannerProps): ReactElement {
  return (
    <>
      {worker?.isArchived && (
        <div
          className="odin-bg-odin-warning odin-border-2 
        odin-h-full odin-p-3 odin-text-white odin-flex odin-items-center"
        >
          <ExclamationTriangleIcon className="odin-pr-3" />
          <span className="odin-grow">
            <span>This worker&apos;s profile is archived</span>
          </span>
        </div>
      )}
      {jobsiteBans.map((jobsiteBan) => {
        const { banExpiresAt, jobsiteWorkerId } = jobsiteBan?.jobsiteWorker ?? {};
        if (banExpiresAt && moment(banExpiresAt).isBefore(moment().format())) {
          return null;
        }
        return (
          <div
            key={jobsiteWorkerId}
            className={classNames(
              banExpiresAt ? 'odin-bg-odin-warning' : 'odin-bg-odin-danger',
              'odin-border-2 odin-h-full odin-p-3 odin-text-white odin-flex odin-items-center',
            )}
          >
            <ExclamationTriangleIcon className="odin-pr-3" />
            <span className="odin-grow">
              {banExpiresAt ? (
                <span>
                  This worker is suspended from&nbsp;
                  <span
                    className="odin-underline odin-cursor-pointer"
                    onClick={(): void => openBanReasonModal(jobsiteBan)}
                  >
                    {jobsiteBan?.jobsiteName} until {getISODateTimeToFormattedDate(banExpiresAt)}.
                  </span>
                </span>
              ) : (
                <span>
                  This worker is permanently banned from&nbsp;
                  <span
                    className="odin-underline odin-cursor-pointer"
                    onClick={(): void => openBanReasonModal(jobsiteBan)}
                  >
                    {jobsiteBan?.jobsiteName}.
                  </span>
                </span>
              )}
            </span>
            <WorkerProfileBannerAction
              jobsiteBan={jobsiteBan}
              openChangeWorkerAccessModal={openChangeWorkerAccessModal}
            />
          </div>
        );
      })}
      {jobsiteDocumentConditionalPasses.map((jobsiteConditionalPass) => {
        return (
          <div
            key={jobsiteConditionalPass}
            className="odin-bg-odin-warning odin-border odin-h-full odin-p-3 odin-text-white"
          >
            <ExclamationTriangleIcon className="odin-pr-3" />
            The worker has a conditional pass for documents that is valid until{' '}
            {getFormattedDate(jobsiteConditionalPass)}.
          </div>
        );
      })}
      {jobsiteMedicalConditionalPasses.map((jobsiteConditionalPass) => {
        return (
          <div
            key={jobsiteConditionalPass}
            className="odin-bg-odin-warning odin-border odin-h-full odin-p-3 odin-text-white"
          >
            <ExclamationTriangleIcon className="odin-pr-3" />
            The worker has a conditional pass for medical tests that is valid until{' '}
            {getFormattedDate(jobsiteConditionalPass)}.
          </div>
        );
      })}
    </>
  );
}
