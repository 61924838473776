import React from 'react';
import { EditType, MergeUnionType } from 'types';
import { Field } from '@odin-labs/components';
import { EditableContractor } from './types';

export type FormSubmissionEditableContractorProps =
  | {
      jfsContractor: EditableContractor;
      updateValue: (jfsWorker: EditableContractor, editType: EditType) => void;
    }
  | { loading: true };

export function FormSubmissionEditableContractor(props: FormSubmissionEditableContractorProps): React.ReactElement {
  const { loading, jfsContractor, updateValue } = props as MergeUnionType<FormSubmissionEditableContractorProps>;
  const { jobsiteContractor, extraData } = jfsContractor ?? {};
  const { contractor, id: jobsiteContractorId } = jobsiteContractor ?? {};
  const { name: contractorName } = contractor?.organization ?? {};
  const { workersCount } = extraData ?? {};

  const updateState = React.useCallback(
    (extraDataValue: Partial<typeof extraData>): void =>
      updateValue(
        {
          changeType: jfsContractor.id ? 'updated' : 'created',
          ...jfsContractor,
          extraData: { ...jfsContractor.extraData, ...extraDataValue },
        },
        'update',
      ),
    [updateValue, jfsContractor],
  );

  return (
    <div className="odin-gap-2 odin-grid odin-grid-cols-4">
      <div className="odin-flex odin-items-center odin-col-span-full sm:odin-col-span-2">
        <label
          htmlFor={`workersCount-${jobsiteContractorId}`}
          className="odin-block odin-text-sm odin-font-medium odin-text-gray-900"
        >
          {contractorName}
        </label>
      </div>
      <div className="odin-col-span-full sm:odin-col-span-2">
        <Field
          loading={loading}
          name={`workersCount-${jobsiteContractorId}`}
          value={workersCount?.toString() ?? ''}
          type="number"
          className="odin-text-right"
          onChange={(changedValue): void => updateState({ workersCount: Number(changedValue) || null })}
        />
      </div>
    </div>
  );
}
