import { JobsiteOnboardingFieldInput, JobsiteOnboardingStepInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { getOnboardingModule } from 'containers/jobsiteConfiguration/helpers/utils';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { DeepMap } from 'react-hook-form';
import { toggleBorderClasses } from './utils';

export const siteSpecificOrientationSectionInputs: TypedFormInputs<
  EditJobsiteConfigurationFormData['siteSpecificOrientation']
> = {
  stickerNumber: {
    element: FormInputTypes.Toggle,
    label: 'Helmet sticker number',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
};

export const getSiteSpecificOrientationSectionDefaultValues = (
  jobsite: Jobsite,
): EditJobsiteConfigurationFormData['siteSpecificOrientation'] => {
  const onboardingModule = getOnboardingModule(jobsite?.modules);
  const siteSpecificOrientationStep = onboardingModule?.steps?.find(
    (s) => s.key === OnboardingStepKey.SiteSpecificOrientation,
  );
  const { fields } = siteSpecificOrientationStep ?? {};

  const isFieldRequired = (fieldKey: keyof EditJobsiteConfigurationFormData['siteSpecificOrientation']): boolean => {
    return fields?.find((f) => f.key === fieldKey)?.isRequired ?? false;
  };

  return {
    stickerNumber: isFieldRequired('stickerNumber'),
  };
};

type SiteSpecificOrientationSectionUpdateInput = {
  siteSpecificOrientationStep: JobsiteOnboardingStepInput;
};

export const getSiteSpecificOrientationSectionUpdateInput = (
  siteSpecificOrientation: EditJobsiteConfigurationFormData['siteSpecificOrientation'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['siteSpecificOrientation'], true>,
): SiteSpecificOrientationSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(siteSpecificOrientation, dirtyFields);

  const fields: JobsiteOnboardingFieldInput[] = Object.keys(siteSpecificOrientation)
    .map((key) => ({
      key,
      isRequired: getUpdateInputValue(key as keyof typeof siteSpecificOrientation),
    }))
    .filter(({ isRequired }) => isRequired !== undefined);

  return {
    siteSpecificOrientationStep: fields.length
      ? {
          key: OnboardingStepKey.SiteSpecificOrientation,
          fields,
        }
      : undefined,
  };
};
