import { useQuery } from '@apollo/client';
import { FileType, JobsiteWorkerDocument, QueryGetWorkerDocumentsArgs } from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { LoadingError } from 'components/loadingError';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import React, { ReactElement } from 'react';
import noSurveysImage from 'images/checklist.png';
import { convertUTCtoLocalDate, getISODateTimeToFormattedDate } from 'utils/dates';
import { Card, CardBody, CardHeader, CardHeaderTitle, CardTitle } from 'components/reactstrap';
import { NoResults } from 'components/noResults';
import moment from 'moment';
import { GetWorkerDocumentsResponse, WorkerTabProps } from 'containers/print/types';
import { DocumentKey } from 'containers/worker/utils';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { kebabToTitleCase } from 'utils';

export function WorkerDocuments({ worker }: WorkerTabProps): ReactElement {
  const {
    data: workerDocumentsData,
    error: workerDocumentsError,
    loading: workerDocumentsLoading,
  } = useQuery<GetWorkerDocumentsResponse, QueryGetWorkerDocumentsArgs>(GET_WORKER_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      workerId: worker?.workerId,
      includePhi: false,
    },
  });

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();

  const { workerDocuments } = workerDocumentsData?.getWorkerDocuments ?? {};
  const filteredDocuments = React.useMemo(() => {
    const jobsiteIds = jobsites?.map((j) => j.jobsiteId);
    return workerDocuments?.filter((jobsiteDocument) => {
      const { workerDocumentType, jobsite } = jobsiteDocument?.jobsiteWorkerDocumentType ?? {};
      const { jobsiteId } = jobsite ?? {};
      const workerDocumentTypeKey = workerDocumentType?.key;
      return (
        !workerDocumentTypeKey?.includes('medical') &&
        !workerDocumentTypeKey?.includes(DocumentKey.Signature) &&
        (workerDocumentType?.isGlobal || jobsiteIds?.includes(jobsiteId))
      );
    });
  }, [workerDocuments, jobsites]);

  const getDocumentName = (jobsiteDocument: JobsiteWorkerDocument): string => {
    const { key, name } = jobsiteDocument?.jobsiteWorkerDocumentType?.workerDocumentType ?? {};
    return key === DocumentKey.Generic ? 'Other' : name;
  };

  const error = workerDocumentsError ?? userJobsitesError;
  const loading = workerDocumentsLoading || userJobsitesLoading;

  if (error || loading) {
    return <LoadingError loading={loading} error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle as="h3">Documents</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {filteredDocuments?.length > 0 ? (
          <ul className="odin-flex odin-flex-col -odin-mt-6 odin-divide-y">
            {filteredDocuments?.map((jobsiteDocument: JobsiteWorkerDocument): ReactElement => {
              const jWDocumentVersionCount = jobsiteDocument?.jobsiteWorkerDocumentVersions.length;
              const jwDocumentLatestVersion =
                jobsiteDocument?.jobsiteWorkerDocumentVersions[jWDocumentVersionCount - 1];
              const { isGlobal } = jobsiteDocument?.jobsiteWorkerDocumentType?.workerDocumentType ?? {};
              const jobsiteName = jobsiteDocument?.jobsiteWorkerDocumentType?.jobsite?.name;

              return (
                <li className="odin-py-6" key={jobsiteDocument?.jobsiteWorkerDocumentId}>
                  <div>
                    <CardTitle as="h3" className="odin-mb-1">
                      {getDocumentName(jobsiteDocument)}
                    </CardTitle>
                    <div className="odin-flex odin-mb-1">
                      {jobsiteName && <p className="odin-mr-4">{isGlobal ? 'Global' : `Jobsite ${jobsiteName}`}</p>}
                      {jwDocumentLatestVersion && (
                        <p>
                          Uploaded on:&nbsp;
                          {getISODateTimeToFormattedDate(
                            convertUTCtoLocalDate(jwDocumentLatestVersion.objectHistory.createdAt),
                          )}
                        </p>
                      )}
                    </div>
                    <div className="odin-flex odin-mb-1">
                      {jwDocumentLatestVersion?.additionalFieldValues?.map((additionalField) => {
                        return (
                          <p className="odin-mr-4" key={additionalField.key}>
                            {kebabToTitleCase(additionalField.key)}
                            :&nbsp;
                            {Date.parse(additionalField.value) &&
                            moment(additionalField.value, moment.ISO_8601, true).isValid()
                              ? getISODateTimeToFormattedDate(additionalField.value)
                              : additionalField.value}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="odin-flex odin-gap-x-6 odin-mt-2">
                    {jwDocumentLatestVersion.files?.map((document: ServerFile) => {
                      return document.fileType === FileType.Pdf ? (
                        <PdfPreview
                          key={document.fileId}
                          fileURL={document.downloadUrl}
                          alt={document.originalFileName}
                          className="odin-w-full"
                        />
                      ) : (
                        <a
                          href={document?.downloadUrl}
                          target="_blank"
                          download
                          className="odin-w-1/2"
                          rel="noreferrer"
                          key={document?.downloadUrl}
                        >
                          <img
                            src={document?.downloadUrl || noSurveysImage}
                            alt={document?.downloadUrl}
                            className="odin-w-full odin-rounded-md"
                          />
                        </a>
                      );
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <NoResults message="No worker documents to show" image={noSurveysImage} />
        )}
      </CardBody>
    </Card>
  );
}
