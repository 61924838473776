import React from 'react';
import { ServerFile } from 'types';
import { getUserFullName } from 'utils';
import { DocumentFilePreview } from 'components/documentFilePreview';
import { JobsiteFormSubmissionDocument } from 'containers/jobsiteFormSubmission/print/types';
import { getPrettyFormattedDateTime } from 'utils/dates';

export type JobsiteIncidentReportDocumentProps = {
  document: JobsiteFormSubmissionDocument;
  singleItemPerRow: boolean;
};

export function JobsiteIncidentReportDocument(props: JobsiteIncidentReportDocumentProps): React.ReactElement {
  const { document, singleItemPerRow } = props;
  const { description, files, objectHistory } = document ?? {};
  const { createdBy, createdAt } = objectHistory ?? {};

  return (
    <div className="odin-h-full odin-border odin-border-gray-200 odin-rounded-md odin-p-6 odin-space-y-6">
      <div className="odin-grid odin-grid-cols-1 odin-gap-y-3 sm:odin-grid-cols-2 sm:odin-gap-x-3">
        {files?.map((file: ServerFile) => (
          <DocumentFilePreview key={file.fileId} file={file} singleItemPerRow={singleItemPerRow} />
        ))}
      </div>
      <div className="odin-grow odin-text-sm odin-text-gray-900">{description}</div>
      {createdBy && (
        <span className="odin-text-xs odin-text-gray-600">
          Uploaded by {getUserFullName(createdBy)}, {getPrettyFormattedDateTime(createdAt)}
        </span>
      )}
    </div>
  );
}
