import { JobsiteOnboardingFieldInput, JobsiteOnboardingStepInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { getOnboardingModule } from 'containers/jobsiteConfiguration/helpers/utils';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { DeepMap } from 'react-hook-form';
import { toggleBorderClasses } from './utils';

export const onboardingSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['onboarding']> = {
  documentCollection: {
    element: FormInputTypes.Toggle,
    label: 'Document Collection',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  drugTesting: {
    element: FormInputTypes.Toggle,
    label: 'Medical',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  training: {
    element: FormInputTypes.Toggle,
    label: 'Onboarding Training',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  signatureCollection: {
    element: FormInputTypes.Toggle,
    label: 'Signature Collection',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  badging: {
    element: FormInputTypes.Toggle,
    label: 'Badging',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  siteSpecificOrientation: {
    element: FormInputTypes.Toggle,
    label: 'Site-Specific Orientation',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
};

export const getOnboardingSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['onboarding'] => {
  const onboardingModule = getOnboardingModule(jobsite?.modules);
  const { steps } = onboardingModule ?? {};

  const isStepVisible = (stepKey: string): boolean => {
    return steps?.find((s) => s.key === stepKey)?.isVisible ?? false;
  };

  return {
    documentCollection: isStepVisible(OnboardingStepKey.WorkDocuments),
    drugTesting: isStepVisible(OnboardingStepKey.MedicalInfo),
    training: isStepVisible(OnboardingStepKey.OnboardingTraining),
    signatureCollection: isStepVisible(OnboardingStepKey.Signature),
    badging: isStepVisible(OnboardingStepKey.Badging),
    siteSpecificOrientation: isStepVisible(OnboardingStepKey.SiteSpecificOrientation),
  };
};

type ConfiguredOnboardingStepKey =
  | OnboardingStepKey.WorkDocuments
  | OnboardingStepKey.MedicalInfo
  | OnboardingStepKey.OnboardingTraining
  | OnboardingStepKey.Signature
  | OnboardingStepKey.Badging
  | OnboardingStepKey.SiteSpecificOrientation;

const fieldByOnboardingStepKey: Record<
  ConfiguredOnboardingStepKey,
  keyof EditJobsiteConfigurationFormData['onboarding']
> = {
  [OnboardingStepKey.WorkDocuments]: 'documentCollection',
  [OnboardingStepKey.MedicalInfo]: 'drugTesting',
  [OnboardingStepKey.OnboardingTraining]: 'training',
  [OnboardingStepKey.Signature]: 'signatureCollection',
  [OnboardingStepKey.Badging]: 'badging',
  [OnboardingStepKey.SiteSpecificOrientation]: 'siteSpecificOrientation',
};

type OnboardingSectionUpdateInput = { steps: JobsiteOnboardingStepInput[] };

export const getOnboardingSectionUpdateInput = (
  onboarding: EditJobsiteConfigurationFormData['onboarding'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['onboarding'], true>,
  fieldsByStepKey: Record<string, JobsiteOnboardingFieldInput[]>,
): OnboardingSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(onboarding, dirtyFields);

  return {
    steps: Object.entries(fieldByOnboardingStepKey)
      .map(([key, field]) => ({
        key,
        isVisible: getUpdateInputValue(field),
        fields: fieldsByStepKey[key],
      }))
      .filter(({ isVisible, fields }) => isVisible !== undefined || fields || undefined),
  };
};
