import React, { useState, ReactElement } from 'react';
import { FormOnSubmit, ModalForm } from 'components/form';
import {
  GetJobsitesContainerCurrentSessionDocument,
  useJobsiteCloseOutMutation,
} from 'apollo/generated/client-operations';
import { BusinessTimeIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getFormInputs, getDefaultValues, getCloseOutInput } from './CloseOutJobsiteModal.forms';
import { CloseOutJobsiteModalProps, CloseOutJobsiteFormData } from './types';

export function CloseOutJobsiteModal(props: CloseOutJobsiteModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, onClosed, jobsiteId } = props;

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const [jobsiteCloseOut] = useJobsiteCloseOutMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsitesContainerCurrentSessionDocument],
  });

  const onSubmit: FormOnSubmit<CloseOutJobsiteFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isFetching) return;

    setFetching(true);

    try {
      const input = getCloseOutInput(jobsiteId, data, dirtyFields);
      const result = await jobsiteCloseOut({ variables: { input } });

      onConfirm(result?.data?.jobsiteCloseOut.jobsite);
      AlertService.alert('success', 'Success', 'Jobsite successfully closed out!');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setFetching(false);
      }
    }
  };

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(), []);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      afterLeave={onClosed}
      title="Close Out Jobsite"
      size="xs"
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Close Out Jobsite"
      actionIcon={BusinessTimeIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
