import React, { ReactElement } from 'react';

export function AlertBody(props: { title: string; message: string }): ReactElement {
  const { title, message } = props;
  return (
    <div className="alert-text">
      <div className="alert-title" data-notify="title">
        <b>{title}</b>
      </div>
      <div data-notify="message">{message}</div>
    </div>
  );
}
