import QRCode from 'react-qr-code';
import { Badge } from '@odin-labs/components';
import { Container } from 'components/reactstrap';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import { FullLayoutNavbarContainer } from 'containers/fullLayoutNavbar';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useGetPrintWorkerDataQuery } from 'apollo/generated/client-operations';
import { getFullNameForUser, getCurrentDomain } from 'utils';
import { WorkerDocuments } from './tabs/WorkerDocuments';
import { WorkerOverview } from './tabs/WorkerOverview';

export function WorkerPrintContainer(): ReactElement {
  const { workerId } = useParams<{ workerId: string }>();

  const {
    data: workerData,
    error: workerError,
    loading: workerLoading,
  } = useGetPrintWorkerDataQuery({
    fetchPolicy: 'no-cache',
    variables: { workerId },
  });

  const worker = workerData?.getWorker;
  const { jobsiteWorkers, contractorWorkers, workerComments } = React.useMemo(
    () => ({
      jobsiteWorkers: worker?.jobsiteWorkers.edges.map(({ node }) => node) ?? [],
      contractorWorkers: worker?.contractorWorkers.edges.map(({ node }) => node) ?? [],
      workerComments: worker?.workerComments.edges.map(({ node }) => node) ?? [],
    }),
    [worker],
  );

  if (workerError || workerLoading) {
    return <LoadingError loading={workerLoading} error={workerError} />;
  }

  const leftContent = (): ReactElement => {
    return (
      <div className="odin-flex odin-items-center odin-mb-2 odin-mt-3">
        <Badge size="xl" color="blue" type="rounded" className="" text={worker?.quickCode} />
        <QRCode className="odin-ml-4" value={`${getCurrentDomain()}/worker/${worker?.workerId}`} size={100} />
      </div>
    );
  };

  return (
    <>
      <FullLayoutNavbarContainer />
      <Container className="odin-pb-6">
        <Header pretitle="Worker" title={getFullNameForUser(worker?.user)} leftContent={leftContent()} />
        <Helmet title={`ODIN - ${getFullNameForUser(worker?.user)} Worker Profile`} />
        <WorkerOverview
          loading={workerLoading}
          worker={worker}
          workerComments={workerComments}
          jobsiteWorkers={jobsiteWorkers}
          contractorWorkers={contractorWorkers}
        />
        <WorkerDocuments worker={worker} jobsiteWorkers={jobsiteWorkers} contractorWorkers={contractorWorkers} />
      </Container>
    </>
  );
}
