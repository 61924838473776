import React from 'react';
import cn from 'classnames';

const classes = {
  cardHeader: (extraClasses: string): string =>
    cn(
      extraClasses,
      'reactstrap-card-header',
      'odin-py-2 odin-px-6 odin-bg-transparent odin-border-b odin-border-gray-200',
      'odin-flex odin-flex-row odin-items-center odin-min-h-15',
    ),
};

export type CardHeaderProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function CardHeader(props: CardHeaderProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.cardHeader(className)}>{children}</div>;
}
