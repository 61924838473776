import moment from 'moment';
import cn from 'classnames';
import i18n from 'i18n-js';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import {
  UserUpdateIdentityInput,
  WorkerCitizenshipStatus,
  WorkerOnboardingUpdateWorkerMutationVariables,
} from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import { EditFormDataType, OnboardingStepKey, JobsiteWorker } from 'containers/workerOnboarding/types';
import { getFields } from 'types';
import { getStepFieldsConfig } from 'containers/workerOnboarding/helpers/utils';
import { Copy } from 'containers/workerOnboarding/helpers/languages';
import {
  ageValidation,
  dateValidation,
  emailValidation,
  phoneNumberValidation,
  ssnValidation,
  zipCodeValidation,
} from 'utils/validation';
import {
  toFancySelectOptions,
  statesOptions,
  emergencyContactRelationshipOptions as emergencyContactRelationshipOptionsValues,
  genderOptions as genderOptionsValues,
  jobTitleOptions as jobTitleOptionsValues,
  primaryLanguageOptions as primaryLanguageOptionsValues,
  tradeOptions as tradeOptionsValues,
  unionAffiliationOptions as unionAffiliationOptionsValues,
  raceOptions as raceOptionsValues,
} from 'utils/constants';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import { getPrettyFormattedUtcDate, momentFormatter } from 'utils/dates';

const unionAffiliationOptions = toFancySelectOptions(unionAffiliationOptionsValues);
const jobTitleOptions = toFancySelectOptions(jobTitleOptionsValues);
const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const emergencyContactRelationshipOptions = toFancySelectOptions(emergencyContactRelationshipOptionsValues);
const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
const genderOptions = toFancySelectOptions(genderOptionsValues);

export const getRaceOptions = (language: string): SelectOptionElement[] => {
  return toFancySelectOptions(raceOptionsValues).map((option) => ({
    ...option,
    label: i18n.t(`race_option_${option.key}`, { locale: language }),
  }));
};
export const getVeteranOptions = (language: string): SelectOptionElement[] => [
  {
    label: i18n.t(Copy.VETERAN, { locale: language }),
    value: 'Veteran',
  },
  {
    label: i18n.t(Copy.NON_VETERAN, { locale: language }),
    value: 'Non veteran',
  },
];
export const getCitizenshipStatusOptions = (language: string): SelectOptionElement<WorkerCitizenshipStatus>[] => [
  {
    label: i18n.t(Copy.CITIZEN, { locale: language }),
    value: WorkerCitizenshipStatus.Citizen,
  },
  {
    label: i18n.t(Copy.NON_CITIZEN, { locale: language }),
    value: WorkerCitizenshipStatus.NonCitizen,
  },
];

type GetFormInputsArgs = {
  contractorOptions: SelectOptionElement[];
  jobsiteWorker: JobsiteWorker;
  canAddUnions: boolean;
  language?: string;
  raceOptions: SelectOptionElement[];
  citizenshipStatusOptions: SelectOptionElement<WorkerCitizenshipStatus>[];
  veteranOptions: SelectOptionElement[];
  isEmailEditable: boolean;
  isPhoneEditable: boolean;
};

const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();

export const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<EditFormDataType> => {
  const {
    contractorOptions,
    jobsiteWorker,
    canAddUnions,
    language,
    raceOptions,
    citizenshipStatusOptions,
    veteranOptions,
    isEmailEditable,
    isPhoneEditable,
  } = args;

  const fieldsConfig = getStepFieldsConfig(OnboardingStepKey.PersonalInfo, jobsiteWorker);

  const getFieldConfig = (
    configFieldKey: keyof typeof jobsiteConfigWorkerInfo,
  ): { isRequired: boolean; isHidden: boolean } => {
    const { isRequired, isHidden } = fieldsConfig[configFieldKey] ?? {};
    return { isRequired: !!isRequired, isHidden: !!isHidden };
  };

  const phone = getFieldConfig(jobsiteConfigWorkerInfo.phone);
  const email = getFieldConfig(jobsiteConfigWorkerInfo.email);
  const ssnLastFour = getFieldConfig(jobsiteConfigWorkerInfo.ssnLastFour);
  const race = getFieldConfig(jobsiteConfigWorkerInfo.race);
  const gender = getFieldConfig(jobsiteConfigWorkerInfo.gender);
  const trade = getFieldConfig(jobsiteConfigWorkerInfo.trade);
  const title = getFieldConfig(jobsiteConfigWorkerInfo.title);
  const emergencyContact = getFieldConfig(jobsiteConfigWorkerInfo.emergencyContact);
  const address = getFieldConfig(jobsiteConfigWorkerInfo.address);
  const addressZipCode = getFieldConfig(jobsiteConfigWorkerInfo.addressZipCode);
  const citizenshipStatus = getFieldConfig(jobsiteConfigWorkerInfo.citizenshipStatus);
  const veteranStatus = getFieldConfig(jobsiteConfigWorkerInfo.veteranStatus);
  const unionAffiliation = getFieldConfig(jobsiteConfigWorkerInfo.unionAffiliation);

  const getLabel = (copy: Copy): string => i18n.t(copy, { locale: language });

  const getRequiredValidationMessage = (copy: Copy): string =>
    `${i18n.t(copy, { locale: language })} ${i18n.t(Copy.REQUIRED, { locale: language })}`;

  const getInvalidValidationMessage = (copy: Copy): string => i18n.t(copy, { locale: language });

  return {
    firstName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.FIRST_NAME),
      elementProps: {},
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.FIRST_NAME),
        },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    middleInitial: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.MIDDLE_INITIAL),
      elementProps: {
        fieldType: 'custom',
        cleaveOptions: { blocks: [1] },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    lastName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.LAST_NAME),
      elementProps: {},
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.LAST_NAME),
        },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    suffix: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.SUFFIX),
      elementProps: {},
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    birthDate: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.BIRTH_DATE),
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.BIRTH_DATE),
        },
        pattern: {
          value: dateValidation,
          message: getInvalidValidationMessage(Copy.INVALID_DATE),
        },
        validate: ageValidation(Copy.INVALID_DATE, 14, 100),
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    ssnLastFour: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.LAST_FOUR_SSN),
      isHidden: ssnLastFour.isHidden,
      elementProps: {
        fieldType: 'ssn4',
      },
      validation: {
        pattern: {
          value: ssnValidation,
          message: getInvalidValidationMessage(Copy.SSN_4_DIGIT),
        },
        required: {
          value: ssnLastFour.isRequired,
          message: getRequiredValidationMessage(Copy.LAST_FOUR_SSN),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    race: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.RACE),
      isHidden: race.isHidden,
      elementProps: {
        options: raceOptions,
      },
      validation: {
        required: {
          value: race.isRequired,
          message: getRequiredValidationMessage(Copy.RACE),
        },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    gender: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.GENDER),
      isHidden: gender.isHidden,
      elementProps: {
        options: genderOptions,
      },
      validation: {
        required: {
          value: gender.isRequired,
          message: getRequiredValidationMessage(Copy.GENDER),
        },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    primaryLanguage: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.CHOOSE_LANGUAGE),
      elementProps: {
        options: primaryLanguageOptions,
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    citizenshipStatus: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.CITIZENSHIP_STATUS),
      isHidden: citizenshipStatus.isHidden,
      elementProps: {
        options: citizenshipStatusOptions,
      },
      validation: {
        required: {
          value: citizenshipStatus.isRequired,
          message: getRequiredValidationMessage(Copy.CITIZENSHIP_STATUS),
        },
      },
      layout: [!veteranStatus.isHidden && cn('odin-col-start-1'), GridColSpan.Span6, GridColSpan.XlSpan3],
    },
    isVeteran: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.VETERAN_STATUS),
      isHidden: veteranStatus.isHidden,
      elementProps: {
        options: veteranOptions,
      },
      validation: {
        required: {
          value: veteranStatus.isRequired,
          message: getRequiredValidationMessage(Copy.VETERAN_STATUS),
        },
      },
      layout: citizenshipStatus.isHidden
        ? [GridColSpan.SpanFull, GridColSpan.SmSpan6]
        : [GridColSpan.Span6, GridColSpan.XlSpan3],
    },
    addressLine1: {
      element: FormInputTypes.PlacesAutocomplete,
      label: getLabel(Copy.ADDRESS),
      isHidden: address.isHidden,
      elementProps: {
        onCommit: fillAddressDetails,
      },
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.ADDRESS),
        },
      },
      layout: [cn('sm:odin-col-start-1'), GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    addressLine2: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.ADDRESS_2),
      isHidden: address.isHidden,
      elementProps: {},
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    addressCity: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.CITY),
      isHidden: address.isHidden,
      elementProps: {},
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.CITY),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    addressState: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.STATE),
      isHidden: address.isHidden,
      elementProps: {
        options: statesOptions,
      },
      validation: {
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.STATE),
        },
      },
      layout: addressZipCode.isHidden
        ? [GridColSpan.SpanFull, GridColSpan.SmSpan6]
        : [GridColSpan.Span6, GridColSpan.SmSpan3],
    },
    addressZipCode: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.ZIP),
      isHidden: addressZipCode.isHidden,
      elementProps: {
        fieldType: 'zipcode',
      },
      validation: {
        pattern: {
          value: zipCodeValidation,
          message: getInvalidValidationMessage(Copy.ZIP_CODE_5_DIGITS),
        },
        required: {
          value: address.isRequired,
          message: getRequiredValidationMessage(Copy.ZIP),
        },
      },
      layout: address.isHidden ? [GridColSpan.SpanFull, GridColSpan.SmSpan6] : [GridColSpan.Span6, GridColSpan.SmSpan3],
    },
    phoneNumber: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.PHONE_NUMBER),
      isHidden: phone.isHidden,
      elementProps: {
        fieldType: 'phone',
        disabled: !isPhoneEditable,
      },
      validation: {
        pattern: {
          value: phoneNumberValidation,
          message: getInvalidValidationMessage(Copy.INVALID_PHONE),
        },
        required: {
          value: phone.isRequired,
          message: getRequiredValidationMessage(Copy.PHONE_NUMBER),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    email: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.EMAIL_ADDRESS),
      isHidden: email.isHidden,
      elementProps: {
        disabled: !isEmailEditable,
      },
      validation: {
        pattern: {
          value: emailValidation,
          message: getInvalidValidationMessage(Copy.INVALID_EMAIL),
        },
        required: {
          value: email.isRequired,
          message: getRequiredValidationMessage(Copy.EMAIL_ADDRESS),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    emergencyContactName: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.EMERGENCY_CONTACT),
      isHidden: emergencyContact.isHidden,
      elementProps: {},
      validation: {
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.EMERGENCY_CONTACT),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    emergencyContactRelationship: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.RELATIONSHIP),
      isHidden: emergencyContact.isHidden,
      elementProps: {
        isCreateable: true,
        options: emergencyContactRelationshipOptions,
      },
      validation: {
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.RELATIONSHIP),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    emergencyContactPhone: {
      element: FormInputTypes.Field,
      label: getLabel(Copy.EMERGENCY_CONTACT_PHONE_NUMBER),
      isHidden: emergencyContact.isHidden,
      elementProps: {
        fieldType: 'phone',
      },
      validation: {
        pattern: {
          value: phoneNumberValidation,
          message: getInvalidValidationMessage(Copy.INVALID_PHONE),
        },
        required: {
          value: emergencyContact.isRequired,
          message: getRequiredValidationMessage(Copy.EMERGENCY_CONTACT_PHONE_NUMBER),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    trade: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.TRADE),
      isHidden: trade.isHidden,
      elementProps: {
        isCreateable: false,
        options: tradeOptions,
      },
      validation: {
        required: {
          value: trade.isRequired,
          message: getRequiredValidationMessage(Copy.TRADE),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    jobTitle: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.JOB_TITLE),
      isHidden: title.isHidden,
      elementProps: {
        isCreateable: false,
        options: jobTitleOptions,
      },
      validation: {
        required: {
          value: title.isRequired,
          message: getRequiredValidationMessage(Copy.JOB_TITLE),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    unionAffiliation: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.UNION_AFFILIATION),
      isHidden: unionAffiliation.isHidden,
      elementProps: {
        isCreateable: canAddUnions,
        menuPlacement: 'top',
        options: unionAffiliationOptions,
      },
      validation: {
        required: {
          value: unionAffiliation.isRequired,
          message: getRequiredValidationMessage(Copy.UNION_AFFILIATION),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    contractorId: {
      element: FormInputTypes.Select,
      label: getLabel(Copy.CONTRACTOR),
      elementProps: {
        options: contractorOptions,
        menuPlacement: 'top',
      },
      validation: {
        required: {
          value: true,
          message: getRequiredValidationMessage(Copy.CONTRACTOR),
        },
      },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
  };
};

export const getDefaultValues = ({
  jobsiteWorker,
  raceOptions,
  citizenshipStatusOptions,
  veteranOptions,
  contractorOptions,
}: {
  jobsiteWorker: JobsiteWorker;
  raceOptions: SelectOptionElement[];
  veteranOptions: SelectOptionElement[];
  contractorOptions: SelectOptionElement[];
  citizenshipStatusOptions: SelectOptionElement<WorkerCitizenshipStatus>[];
}): EditFormDataType => {
  const { contractorWorker } = jobsiteWorker ?? {};
  const { worker, contractor } = contractorWorker ?? {};
  const { contractorId } = contractor ?? {};
  const {
    user,
    middleInitial,
    suffix,
    birthDate,
    ssnLastFour,
    race,
    gender,
    primaryLanguage,
    citizenshipStatus,
    isVeteran,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    jobTitle,
    emergencyContactName,
    emergencyContactRelationship,
    emergencyContactPhone,
    trade,
    unionAffiliation,
  } = worker ?? {};
  const { identity } = user ?? {};
  const { firstName, lastName, email, phoneNumber } = identity ?? {};

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    email: email ?? '',
    middleInitial: middleInitial ?? '',
    suffix: suffix ?? '',
    birthDate: getPrettyFormattedUtcDate(birthDate) ?? '',
    ssnLastFour: ssnLastFour ?? '',
    addressLine1: addressLine1 ?? '',
    addressLine2: addressLine2 ?? '',
    addressCity: addressCity ?? '',
    addressZipCode: addressZipCode ?? '',
    phoneNumber: phoneNumber ?? '',
    emergencyContactName: emergencyContactName ?? '',
    emergencyContactPhone: emergencyContactPhone ?? '',
    contractorId: contractorOptions.find((opt) => opt.value === contractorId) ?? null,
    race: raceOptions.find((opt) => opt.value === race) ?? null,
    gender: genderOptions.find((opt) => opt.value === gender) ?? null,
    primaryLanguage: primaryLanguageOptions.find((opt) => opt.value === primaryLanguage) ?? null,
    citizenshipStatus: citizenshipStatusOptions.find((opt) => opt.value === citizenshipStatus) ?? null,
    isVeteran: veteranOptions.find((opt) => opt.value === (isVeteran ? 'Veteran' : 'Non veteran')) ?? null,
    addressState: statesOptions.find((opt) => opt.value === addressState) ?? null,
    jobTitle: jobTitleOptions.find((opt) => opt.value === jobTitle) ?? null,
    emergencyContactRelationship:
      emergencyContactRelationshipOptions.find((opt) => opt.value === emergencyContactRelationship) ?? null,
    trade: tradeOptions.find((opt) => opt.value === trade) ?? null,
    unionAffiliation: unionAffiliationOptions.find((opt) => opt.value === unionAffiliation) ?? null,
  };
};

export type PersonalInfoUpdateInput = {
  userUpdateIdentityInput: UserUpdateIdentityInput;
} & WorkerOnboardingUpdateWorkerMutationVariables;

export const getUpdateInputs = (
  jobsiteWorker: JobsiteWorker,
  data: EditFormDataType,
  dirtyFields: DeepMap<EditFormDataType, true>,
): PersonalInfoUpdateInput => {
  const { contractorWorker } = jobsiteWorker ?? {};
  const { worker } = contractorWorker ?? {};
  const { workerId, user } = worker ?? {};

  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const isVeteranInputValue = dirtyFields.isVeteran ? data.isVeteran?.value === 'Veteran' : undefined;
  const birthDateInputValue = dirtyFields.birthDate
    ? moment.utc(data.birthDate, momentFormatter).toDate() || null
    : undefined;
  const phoneNumberInputValue = getPhoneNumberAsE164(getUpdateInputValue('phoneNumber'));

  return ensureNonUndefinedFields<PersonalInfoUpdateInput>({
    workerId,
    workerInput: {
      middleInitial: getUpdateInputValue('middleInitial'),
      suffix: getUpdateInputValue('suffix'),
      race: getUpdateInputValue('race'),
      gender: getUpdateInputValue('gender'),
      primaryLanguage: getUpdateInputValue('primaryLanguage'),
      citizenshipStatus: getUpdateInputValue('citizenshipStatus'),
      isVeteran: isVeteranInputValue,
      addressLine1: getUpdateInputValue('addressLine1'),
      addressLine2: getUpdateInputValue('addressLine2'),
      addressCity: getUpdateInputValue('addressCity'),
      addressState: getUpdateInputValue('addressState'),
      addressZipCode: getUpdateInputValue('addressZipCode'),
      emergencyContactName: getUpdateInputValue('emergencyContactName'),
      emergencyContactRelationship: getUpdateInputValue('emergencyContactRelationship'),
      emergencyContactPhone: getPhoneNumberAsE164(getUpdateInputValue('emergencyContactPhone')),
      trade: getUpdateInputValue('trade'),
      jobTitle: getUpdateInputValue('jobTitle'),
      unionAffiliation: getUpdateInputValue('unionAffiliation'),
      workerBaseInfoInput: {
        birthDate: birthDateInputValue,
        ssnLastFour: getUpdateInputValue('ssnLastFour'),
      },
    },
    userUpdateIdentityInput: {
      userAccountId: user?.userAccountId,
      userIdentityInput: {
        firstName: getUpdateInputValue('firstName'),
        lastName: getUpdateInputValue('lastName'),
        email: getUpdateInputValue('email'),
        phoneNumber: phoneNumberInputValue,
      },
    },
  });
};
