import moment from 'moment';
import { ValidateResult } from 'react-hook-form';

export const zipCodeValidation = /^\d{5}$/;
export const ssnValidation = /^\d{4}$/;
export const phoneNumberValidation = /^(\+1)?(\d{10})$/; // /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
export const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const dateValidation = /^\d{2}\/\d{2}\/\d{4}$/;
export const timeValidation = /^([0-1][0-9]|[2][0-3]):([0-5][0-9]) (AM|PM)$/;
export const webURLValidation =
  // eslint-disable-next-line max-len
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;

export const validations = {
  zipCodeValidation,
  ssnValidation,
  phoneNumberValidation,
  emailValidation,
  dateValidation,
  timeValidation,
  webURLValidation,
};

function removeTrailingNotes(text: string): string {
  return text?.replace(/\s*\((optional|required)\)$/i, '');
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
export function InvalidField(strings: TemplateStringsArray, fieldAsVariable?: string): string {
  const field = removeTrailingNotes(fieldAsVariable ?? strings?.[0]) ?? 'field';
  return `Please enter a valid ${field}`;
}

export function RequiredField(strings: TemplateStringsArray, fieldAsVariable?: string): string {
  const field = removeTrailingNotes(fieldAsVariable ?? strings?.[0]) ?? 'Field';
  return `${field} is required`;
}

export const FILE_CHANGED_ERROR_MESSAGE =
  'It looks like the file you were uploading was modified or deleted after selection. Please upload the file again.';

export const ageValidation =
  (fieldName: string, minAge: number, maxAge: number) =>
  (date: string): ValidateResult => {
    if (date) {
      const age = Math.max(moment().diff(date, 'years'), 0);
      return age < minAge || maxAge < age ? InvalidField`${fieldName}` : true;
    }
    return true;
  };

export const cleaveAgeValidation =
  (invalidValidationMessage: string, minAge: number, maxAge: number) =>
  (cleaveDate: string): ValidateResult => {
    if (cleaveDate) {
      const date = moment(cleaveDate, 'MMDDYYYY');
      const age = Math.max(moment().diff(date, 'years'), 0);
      return age < minAge || maxAge < age ? invalidValidationMessage : true;
    }
    return true;
  };
