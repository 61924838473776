import React from 'react';
import moment, { Moment } from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { useGetJobsiteAndContractorsQuery } from 'apollo/generated/client-operations';

import { Jobsite, JobsiteWorker } from 'containers/worker/types';
import { JobsiteContractor } from 'containers/contractor/types';

import { FormInputTypes, GridColSpan, UseInputs, TypedFormInputs, UseFormMethods } from 'components/form';
import { getJobsiteContractorsOptions } from 'containers/contractor/helpers';
import { BriefcaseIcon, WrenchIcon } from 'components/icons';
import { AssignWorkerFormData, ContractorOptionElement, JobsiteOptionElement } from './types';

export type AssignWorkerInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  contractorId: string;
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
};

const getFormInputs = ({
  contractorsOptions,
  jobsitesOptions,
  minOrientationDate,
}: {
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  minOrientationDate: Moment;
}): TypedFormInputs<AssignWorkerFormData> => ({
  jobsiteId: {
    element: FormInputTypes.Select,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsitesOptions,
      icon: BriefcaseIcon,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  contractorId: {
    element: FormInputTypes.Select,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      icon: WrenchIcon,
      disabled: (contractorsOptions?.length ?? 0) < 1,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  orientationDate: {
    element: FormInputTypes.DatePicker,
    label: 'Orientation Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
      minDate: minOrientationDate,
      disabled: !minOrientationDate,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export type JobsiteForOption = Pick<Jobsite, 'jobsiteId' | 'name' | 'parentId'>;
export const getJobsitesOptions = (jobsites: JobsiteForOption[]): JobsiteOptionElement[] =>
  jobsites
    ?.map(({ jobsiteId: value, name: label, parentId }) => ({ value, label, parentId }))
    .sort((a, b) => a.label.localeCompare(b.label));

export type JobsiteContractorForOption = {
  contractor: { contractorId: string; organization: { name: string } };
  startDate: Date;
};

export const useContractorsOptions = (
  jobsiteId: string,
  jobsiteWorkers: JobsiteWorker[],
): ContractorOptionElement[] => {
  const { data: jobsiteContractorsData, loading } = useGetJobsiteAndContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];

    const alreadyAssignedContractorIds = jobsiteWorkers
      ?.filter((jw) => jw.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
      .map((jw) => jw.jobsiteContractor.contractor.contractorId);

    const jobsiteContractors = jobsiteContractorsData?.getJobsite.jobsiteContractors.edges
      .map(({ node }) => node)
      .filter((jc) => !alreadyAssignedContractorIds?.includes(jc.contractor.contractorId));

    return getJobsiteContractorsOptions(jobsiteContractors);
  }, [jobsiteContractorsData]);
};

export const getFormInputsHook =
  (args: {
    jobsitesOptions: JobsiteOptionElement[];
    jobsiteWorkers: JobsiteWorker[];
  }): UseInputs<AssignWorkerFormData> =>
  ({ watch, setValue }: UseFormMethods<AssignWorkerFormData>): TypedFormInputs<AssignWorkerFormData> => {
    const { jobsitesOptions, jobsiteWorkers } = args;
    const selectedJobsiteOption = watch('jobsiteId');
    const selectedJobsiteId = selectedJobsiteOption?.value;

    const selectedContractorOption = watch('contractorId');
    const { startDate } = selectedContractorOption ?? {};

    const contractorsOptions = useContractorsOptions(selectedJobsiteId, jobsiteWorkers);

    useDidUpdateEffect(() => {
      setValue('contractorId', contractorsOptions?.length === 1 ? contractorsOptions[0] : null);
      setValue('orientationDate', moment().startOf('day'));
    }, [contractorsOptions]);

    return React.useMemo(
      () => getFormInputs({ jobsitesOptions, contractorsOptions, minOrientationDate: startDate && moment(startDate) }),
      [contractorsOptions, jobsitesOptions, startDate],
    );
  };

export const getDefaultValues = (args: {
  jobsiteId: string;
  jobsitesOptions: JobsiteOptionElement[];
  contractorsOptions: ContractorOptionElement[];
}): AssignWorkerFormData => {
  const { jobsiteId, jobsitesOptions, contractorsOptions } = args;

  return {
    jobsiteId: jobsitesOptions?.find((option) => option.value === jobsiteId) ?? null,
    contractorId: contractorsOptions?.length === 1 ? contractorsOptions[0] : null,
    orientationDate: moment().startOf('day'),
  };
};
