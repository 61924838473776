import React from 'react';
import cn from 'classnames';

const classes = {
  col: (extraClasses: string): string =>
    cn(extraClasses, 'reactstrap-col odin-basis-0 odin-grow odin-max-w-full odin-px-3'),
};

export type ColProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function Col(props: ColProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.col(className)}>{children}</div>;
}
