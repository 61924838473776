import React, { ReactElement } from 'react';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getGraphQLError, hasGraphQLErrorCode } from 'utils/error';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import {
  getDefaultValues,
  getFormInputsHook,
  useContractorsOptions,
  getJobsitesOptions,
} from './AssignWorkerModalContent.forms';
import { AssignWorkerFormData, AssignWorkerModalContentProps, AssignWorkerStep } from './types';
import { useAssignContractorWorkerToJobsite } from './useAssignContractorWorkerToJobsite';

export function AssignWorkerModalContent(props: AssignWorkerModalContentProps): ReactElement {
  const {
    closeModal,
    onConfirm,
    selectedWorker: { workerId },
    jobsiteWorkers,
  } = props;
  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);

  const { jobsites, loading: userJobsitesLoading, error: userJobsitesError } = useAvailableJobsites();
  const { assignJobsiteAndGoToPersonalInformation } = useAssignContractorWorkerToJobsite({ workerId });

  const onSubmit: FormOnSubmit<AssignWorkerFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    const { jobsiteId } = data;

    // check to see if the new jobsite the worker is getting assigned to has a parent contractor
    const parentId = jobsiteId?.parentId;

    // get the common jobsites for parent
    const siblingJobsiteWorkerAccesses =
      parentId && jobsiteWorkers?.filter((jw) => jw.jobsiteContractor?.jobsite?.parentId === parentId);

    // user is already assigned to a jobsite with a parent jobsite, lets check to make sure its
    // not the wrong one and they actually want to move jobsite
    if (siblingJobsiteWorkerAccesses?.length) {
      onConfirm(
        { ...data, siblingJobsiteWorkers: siblingJobsiteWorkerAccesses },
        AssignWorkerStep.CompleteJobsiteAssignment,
      );
      return;
    }

    try {
      await assignJobsiteAndGoToPersonalInformation(data);
    } catch (error) {
      if (hasGraphQLErrorCode(error, 'BAD_USER_INPUT')) {
        onConfirm(data, AssignWorkerStep.AlreadyAssignedWorker);
      } else {
        event.preventDefault();
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const jobsitesOptions = React.useMemo(() => {
    const alreadyAssignedJobsiteIds = jobsiteWorkers?.map((jw) => jw?.jobsiteContractor?.jobsite?.jobsiteId);
    return getJobsitesOptions(jobsites.filter((j) => !alreadyAssignedJobsiteIds?.includes(j.jobsiteId)));
  }, [jobsites]);
  const defaultJobsiteId = jobsitesOptions?.length === 1 ? jobsitesOptions[0].value : null;
  const defaultContractorsOptions = useContractorsOptions(defaultJobsiteId, jobsiteWorkers);

  const formInputs = getFormInputsHook({ jobsitesOptions, jobsiteWorkers });

  const defaultValues = React.useMemo(
    () =>
      getDefaultValues({
        jobsitesOptions,
        jobsiteId: defaultJobsiteId,
        contractorsOptions: defaultContractorsOptions,
      }),
    [defaultJobsiteId, defaultContractorsOptions, jobsitesOptions],
  );

  return (
    <ModalFormContent
      setOpen={closeModal}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Continue"
      actionButtonWithSpinner={userJobsitesLoading || isFetching}
      error={userJobsitesError}
    />
  );
}
