import React, { ReactElement } from 'react';
import cn from 'classnames/bind';
import { getFullNameForUser } from 'utils';
import { getPrettyFormattedDateTime } from 'utils/dates';
import { ObjectHistoryFormat, ObjectHistoryProps, ObjectHistorySource } from './types';

export function ObjectHistory(props: ObjectHistoryProps): ReactElement {
  const { object, format = ObjectHistoryFormat.FullText, className, source } = props;
  const { objectHistory } = object ?? {};
  if (!objectHistory) {
    return null;
  }
  const lastUpdated = objectHistory.updatedAt ?? objectHistory.createdAt;
  const lastUpdatedBy = objectHistory.updatedBy ?? objectHistory.createdBy;

  const name = lastUpdatedBy ? getFullNameForUser(lastUpdatedBy) : null;
  const timeStamp = getPrettyFormattedDateTime(lastUpdated);

  let sourcePrefix = '';
  if (source === ObjectHistorySource.Worker) {
    sourcePrefix = 'Worker profile data';
  } else if (source === ObjectHistorySource.JobsiteWorker) {
    sourcePrefix = 'Worker jobsite specific data';
  }
  let message = `${sourcePrefix} ${sourcePrefix ? 'l' : 'L'}ast updated ${name ? `by ${name}` : ''} on ${timeStamp}`;
  if (format === ObjectHistoryFormat.TimeName) {
    message = `${timeStamp}${name ? ` by ${name}` : ''}`;
  } else if (format === ObjectHistoryFormat.NameTime) {
    message = `${name ? `${name} ` : ''} on ${timeStamp}`;
  } else if (format === ObjectHistoryFormat.Time) {
    message = timeStamp;
  } else if (format === ObjectHistoryFormat.Name) {
    message = name;
  }
  return <div className={cn('object-history', className)}>{message}</div>;
}
