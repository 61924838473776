import { Moment } from 'moment';
import { DeepMap } from 'react-hook-form';
import { ApolloError, QueryResult } from '@apollo/client';
import { SelectOptionElement } from '@odin-labs/components';
import {
  CardPrintJob,
  ContractorWorker,
  GetAllContractorsResponse,
  JobsiteWorker as GqlJobsiteWorker,
  JobsiteWorkerDocument,
  JobsiteWorkerDocumentType,
  QueryGetAllDocumentsForJobsiteWorkerArgs,
  StateAbbreviation,
  WorkerCard,
  GetJobsiteWorkerQuery,
  WorkerCitizenshipStatus,
  useGetJobsiteWorkerQuery,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { InputFile, Maybe, ServerFile } from 'types';
import { User } from 'auth';
import { DeferredPromise } from 'utils';
import { FormDefaultValue } from 'components/form';
import { DocumentKey } from 'containers/worker/utils';
import { ContractorOptionElement } from 'containers/contractor/helpers';

export type JobsiteWorker = GetJobsiteWorkerQuery['getJobsiteWorker'];
export type Jobsite = JobsiteWorker['jobsiteContractor']['jobsite'];
export type JobsiteModule = Jobsite['modules'][number];
export type JobsiteOnboardingModule = Extract<JobsiteModule, { __typename?: 'JobsiteOnboardingModule' }>;
export type JobsiteOnboardingStep = JobsiteOnboardingModule['steps'][number];
export type JobsiteOnboardingDocument = JobsiteOnboardingModule['documents'][number];
export type JobsiteOnboardingField = JobsiteOnboardingDocument['fields'][number];

export interface OnboardingUrlParams {
  jobsiteWorkerId?: string;
  step?: string;
}

export interface GetAllContractorsQueryResponse {
  getAllContractors: GetAllContractorsResponse;
}

export type WorkerDocumentsDataType = {
  [documentKey: string]:
    | File[]
    | ServerFile[]
    | File
    | ServerFile
    | string
    | Pick<SelectOptionElement, 'label' | 'value'>
    | undefined;
  // stickerNumber: string;
  layout?: undefined;
};

export interface UploadFileResponse {
  uploadSingleFile: ServerFile;
}

export type SiteSafetyTrainingDataType = WorkerDocumentsDataType & {
  stickerNumber: string;
};

export type SignatureDataType = WorkerDocumentsDataType;

export type MedicalConditionsDataType = WorkerDocumentsDataType & {
  medicalNotes?: string;
};

export interface GetJobsiteWorkerDocumentTypesResponse {
  getAllJobsiteWorkerDocumentTypes: Array<JobsiteWorkerDocumentType>;
}
export type EditFormDataType = {
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  birthDate: string;
  ssnLastFour: string;
  race: SelectOptionElement;
  gender: SelectOptionElement;
  primaryLanguage: SelectOptionElement;
  citizenshipStatus: SelectOptionElement<WorkerCitizenshipStatus>;
  isVeteran: SelectOptionElement;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: SelectOptionElement<StateAbbreviation>;
  addressZipCode: string;
  phoneNumber: string;
  email: string;
  jobTitle: SelectOptionElement;
  emergencyContactName: string;
  emergencyContactRelationship: SelectOptionElement;
  emergencyContactPhone: string;
  trade: SelectOptionElement;
  unionAffiliation: SelectOptionElement;
  contractorId: ContractorOptionElement;
};

export interface GetAllContractorsForWorkerResponse {
  getAllContractorsForWorker: Array<ContractorWorker>;
}

export type MatchedWorkerCard = WorkerCard & { matchingWorkerCard: Maybe<WorkerCard> };

export interface JobsiteWorkerOnboardingNavbarProps {
  step: string;
  jobsiteWorker: JobsiteWorker;
}

export interface JobsiteWorkerOnboardingActionsProps {
  step: string;
  jobsiteWorker: JobsiteWorker;
}

export interface GetAllDocumentsForJobsiteWorker {
  getAllDocumentsForJobsiteWorker: JobsiteWorkerDocument[];
}

export interface JobsiteWorkerOnboardingFooterNavbarProps {
  jobsiteWorker: JobsiteWorker;
  isFormDirty: boolean;
  error?: string;
  onSave?: () => Promise<void> | Promise<boolean>;
  onOverride?: () => void;
  hideLockSession?: boolean;
  hideSkipOptions?: boolean;
  hideSave?: boolean;
  onResetComplete?: () => void;
  onForceComplete?: (reason: string, expirationDate: Moment, exemptionReason: string) => void;
  showConditionalPassExpiration?: boolean;
  showExemptionReason?: boolean;
  hasNoWorkerCards?: boolean;
}

export interface CardPrintJobTableRowData {
  id?: string;
  startedAt?: string;
  hidPrintJobId?: string;
  statusMessage?: string;
  completedAt?: string;
  cardNumber?: string;
  facilityCode?: string;
  cardPrintJob?: CardPrintJob;
}

export interface WorkerCardTableRowData {
  id?: string;
  cardNumber?: string;
  status?: string;
  bluetoothCardNumber?: string;
  bluetoothStatus?: string;
  isTemporary?: string;
  issuedAt?: string;
  expiredAt?: string;
  workerCard?: WorkerCard;
}

type JobsiteWorkerQueryResult = ReturnType<typeof useGetJobsiteWorkerQuery>;
type AllDocumentsForJobsiteWorkerQueryResult = QueryResult<
  GetAllDocumentsForJobsiteWorker,
  QueryGetAllDocumentsForJobsiteWorkerArgs
>;

export type UseJobsiteWorkerOnboardingDataArgs = {
  jobsiteWorkerId: string;
};

type OnboardingDocumentsJobsiteWorker = Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
  jobsiteContractor?: {
    jobsite?: Pick<JobsiteWorker['jobsiteContractor']['jobsite'], 'jobsiteId'>;
  };
};
export type UseJobsiteWorkerOnboardingDocumentsArgs = {
  jobsiteWorker: OnboardingDocumentsJobsiteWorker;
};

export type JobsiteWorkerOnboardingData = {
  loading: boolean;
  error: ApolloError;
  jobsiteWorker: JobsiteWorker | undefined;
  jobsiteWorkerDocumentTypes: JobsiteWorkerDocumentType[];
  jobsiteWorkerDocuments: JobsiteWorkerDocument[];
  filteredDocumentTypeIds: Record<string, string>;
  workerContractors: ContractorWorker[];
  refetchJobsiteWorker: JobsiteWorkerQueryResult['refetch'];
  refetchJobsiteWorkerDocuments: AllDocumentsForJobsiteWorkerQueryResult['refetch'];
};

export type JobsiteWorkerOnboardingDocuments = Pick<
  JobsiteWorkerOnboardingData,
  | 'loading'
  | 'error'
  | 'jobsiteWorkerDocumentTypes'
  | 'jobsiteWorkerDocuments'
  | 'filteredDocumentTypeIds'
  | 'refetchJobsiteWorkerDocuments'
>;

export type JobsiteWorkerDocumentTypes = Pick<
  JobsiteWorkerOnboardingDocuments,
  'loading' | 'error' | 'jobsiteWorkerDocumentTypes'
>;

export type JwDocument = { key: string; id: string };

export type OnboardingStepProps = Pick<
  JobsiteWorkerOnboardingData,
  'loading' | 'refetchJobsiteWorker' | 'refetchJobsiteWorkerDocuments'
> & {
  jobsiteWorker?: JobsiteWorker;
  jobsiteWorkerId?: string;
  contractorWorkerData?: ContractorWorker;
  defaultFormValues?: FormDefaultValue<WorkerDocumentsDataType>[];
  filteredDocumentTypeIds: Record<string, string>;
  workerContractors: ContractorWorker[];
  documents?: JwDocument[];
};

export enum OnboardingStepName {
  PersonalInfo = 'Personal information',
  WorkDocuments = 'Work documents',
  MedicalInfo = 'Medical',
  OnboardingTraining = 'Onboarding training',
  Signature = 'Signature',
  Badging = 'Badging',
  SiteSpecificOrientation = 'Site Specific Orientation',
}

export enum OnboardingStepKey {
  PersonalInfo = 'personal-information',
  WorkDocuments = 'work-documents',
  MedicalInfo = 'medical-testing',
  OnboardingTraining = 'onboarding-training',
  Signature = 'signature',
  Badging = 'badging',
  SiteSpecificOrientation = 'site-specific-orientation',
}

export interface UseMedicalDocumentsUploadArgs {
  jobsiteWorker: Pick<GqlJobsiteWorker, 'jobsiteWorkerId'>;
  documentTypes: readonly DocumentKey[];
  filteredDocumentTypeIds: Record<string, string>;
  user: User;
  documents?: JwDocument[];
  refetchJobsiteWorkerDocuments: AllDocumentsForJobsiteWorkerQueryResult['refetch'];
  onUploadCompleted?: () => void;
  deferredSubmission?: DeferredPromise<boolean>;
}

export type UploadDocumentsArgs<T extends WorkerDocumentsDataType> = {
  data: T;
  dirtyFields: DeepMap<T, true>;
};

export interface MedicalDocumentsUpload {
  uploadDocuments: (args: UploadDocumentsArgs<MedicalConditionsDataType>) => Promise<void>;
  updateJobsiteWorker: ReturnType<typeof useUpdateJobsiteWorkerMutation>[0];
  fetching: boolean;
  setFetching: (value: boolean) => void;
}

export type WorkerDocumentFormData = Partial<{
  versionId: string;
  key: DocumentKey;
  frontFile: InputFile;
  backFile: InputFile;
  type: SelectOptionElement;
  stateIssued: SelectOptionElement;
  number: string;
  issueDate: string;
  expirationDate: string;
  isTrainingConnectCard: boolean;
}>;
