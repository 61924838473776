import React, { ReactElement } from 'react';
import { LoadingError } from 'components/loadingError';
import { useHistory, useParams } from 'react-router';
import { AppErrorCode } from 'apollo/generated/client-operations';
import { hasGraphQLErrorCode } from 'utils/error';
import { Container } from 'components/container';
import { getJobsiteWorkerDocumentsData } from './helpers/forms';
import { useJobsiteWorkerOnboardingData } from './helpers/useJobsiteWorkerOnboardingData';
import { OnboardingStepKey, OnboardingStepProps, OnboardingUrlParams } from './types';
import {
  BadgingStep,
  MedicalTestingStep,
  OnboardingTrainingStep,
  PersonalInfoOverviewStep,
  SignatureStep,
  SiteSpecificTrainingStep,
  WorkDocumentsStep,
} from './steps';

const componentsByStep: Record<OnboardingStepKey, (props: OnboardingStepProps) => React.ReactElement> = {
  [OnboardingStepKey.PersonalInfo]: PersonalInfoOverviewStep,
  [OnboardingStepKey.WorkDocuments]: WorkDocumentsStep,
  [OnboardingStepKey.MedicalInfo]: MedicalTestingStep,
  [OnboardingStepKey.OnboardingTraining]: OnboardingTrainingStep,
  [OnboardingStepKey.Signature]: SignatureStep,
  [OnboardingStepKey.Badging]: BadgingStep,
  [OnboardingStepKey.SiteSpecificOrientation]: SiteSpecificTrainingStep,
};

export function WorkerOnboardingContainer(): ReactElement {
  const history = useHistory();
  const { jobsiteWorkerId, step } = useParams<OnboardingUrlParams>();

  const {
    loading,
    error,
    jobsiteWorker,
    jobsiteWorkerDocuments,
    filteredDocumentTypeIds,
    workerContractors,
    refetchJobsiteWorker: refetchJobsiteWorkers,
    refetchJobsiteWorkerDocuments,
  } = useJobsiteWorkerOnboardingData({ jobsiteWorkerId });

  React.useEffect(() => {
    if (hasGraphQLErrorCode(error, AppErrorCode.ResourceNotFound)) {
      history.push('/onboardings');
    }
  }, [error]);

  if (error) {
    return <LoadingError error={error} />;
  }

  const { defaultFormValues, documents } = getJobsiteWorkerDocumentsData(jobsiteWorkerDocuments);

  const commonProps: OnboardingStepProps = {
    loading,
    jobsiteWorkerId,
    jobsiteWorker,
    filteredDocumentTypeIds,
    defaultFormValues,
    documents,
    workerContractors,
    refetchJobsiteWorkerDocuments,
    refetchJobsiteWorker: refetchJobsiteWorkers,
  };

  const OnboardingStepComponent = componentsByStep[step as OnboardingStepKey] ?? ((): React.ReactElement => null);
  return (
    <Container className="worker-onboarding-container odin-pb-20">
      <div className="justify-content-center odin-px-6 md:odin-px-3 xl:odin-px-5">
        <OnboardingStepComponent {...commonProps} />
      </div>
    </Container>
  );
}
