import React from 'react';
import { useParams } from 'react-router';
import { TabConfig } from '@odin-labs/components';
import { useGetJobsiteConfigurationQuery } from 'apollo/generated/client-operations';
import { CardTitle } from 'components/reactstrap';
import { CheckIcon } from 'components/icons';
import { LoadingError } from 'components/loadingError';
import { ScrollSpyTabsPages, useScrollSpyTabs } from 'components/tabs';
import { NewHeader, classes as headerClasses } from 'components/header/NewHeader';
import { NewHeaderActionsProps } from 'components/header/types';
import { RouterPrompt } from 'components/routerPrompt';
import { useDeferredFormSubmission, useIsMounted } from 'utils';
import { Container } from 'components/container';
import { JobsiteConfigurationDataTabProps, JobsiteConfigurationDataTabWithRefProps } from './types';
import { JobsiteConfigurationEdit } from './tabs/JobsiteConfigurationEdit';

export const classes = {
  container: 'odin-h-full',
  tabPage: 'odin-overflow-y-scroll odin-bg-white',
  headerTitleContainer: 'odin-flex odin-space-x-5 odin-items-center',
  headerTitleBadgesContainer: 'odin-space-x-2',
};

const tabsConfig: TabConfig<JobsiteConfigurationDataTabProps>[] = [
  { name: 'jobsiteInfo', text: 'Jobsite Info', relativePath: '', component: JobsiteConfigurationEdit },
  { name: 'features', text: 'Features', relativePath: '#features' },
  { name: 'onboarding', text: 'Onboarding', relativePath: '#onboarding' },
  { name: 'workerInfo', text: 'Worker Info', relativePath: '#worker-info' },
  { name: 'documents', text: 'Documents', relativePath: '#documents' },
  { name: 'siteAccessRules', text: 'Site Access Rules', relativePath: '#site-access-rules' },
  { name: 'notifications', text: 'Notifications', relativePath: '#notifications' },
  { name: 'geofencing', text: 'Geofencing', relativePath: '#geofencing' },
  { name: 'hidFargoConnect', text: 'HID Fargo Connect', relativePath: '#hid-fargo-connect' },
  { name: 'cCURE', text: 'C-CURE', relativePath: '#c-cure' },
  { name: 'cameras', text: 'Cameras', relativePath: '#cameras' },
  { name: 'procore', text: 'Procore', relativePath: '#procore' },
];

export function JobsiteConfigurationContainer(): React.ReactElement {
  const { jobsiteId } = useParams<{ jobsiteId: string }>();
  const baseRoute = `/jobsite/${jobsiteId}/settings`;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission();

  const {
    data,
    loading,
    error,
    refetch: refetchJobsite,
  } = useGetJobsiteConfigurationQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      jobsiteId,
      documentTypesInput: {
        includeArchived: true,
      },
      userFormsInput: {
        includeAllForms: true,
      },
    },
  });

  const headerActionsProps = React.useMemo(
    (): NewHeaderActionsProps => ({
      menuItems: [
        {
          onClick: submitForm,
          text: 'Save Changes',
          hideTextOnMobile: false,
          withSpinner: isSaving,
          icon: CheckIcon,
          disabled: !isFormDirty,
        },
      ],
      baseRoute,
      headerActions: ['back'],
    }),
    [submitForm, isFormDirty, isSaving],
  );

  const jobsite = data?.getJobsite;
  const userData = data?.getCurrentSession.user;

  const tabsPageProps: JobsiteConfigurationDataTabWithRefProps = {
    loading,
    jobsite,
    userData,
    onIsDirtyChange: setIsFormDirty,
    ref: formRef,
    deferredSubmission,
    refetchJobsite,
    isSaving,
    setIsSaving: (value: boolean, resetIsDirty: boolean): void => {
      if (isMounted()) {
        setIsSaving(value);
        if (resetIsDirty) setIsFormDirty(false);
      }
    },
  };

  const headerTitle = (
    <div className={classes.headerTitleContainer}>
      <CardTitle as="h1" className={headerClasses.title}>
        {jobsite?.name}
      </CardTitle>
    </div>
  );

  const { tabs, currentTab } = useScrollSpyTabs({ tabsConfig, baseRoute });

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Container className={classes.container}>
      <RouterPrompt when={isFormDirty} onConfirm={submitForm} />
      <NewHeader title={headerTitle} tabsProps={{ tabs, currentTab }} actionsProps={headerActionsProps} />
      <ScrollSpyTabsPages
        className={classes.tabPage}
        baseRoute={baseRoute}
        tabs={tabs}
        componentProps={tabsPageProps}
      />
    </Container>
  );
}
