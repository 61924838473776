import React, { ReactElement } from 'react';
import { AuthContext } from 'auth';
import { Form } from 'components/form';
import { Header } from 'components/header';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { MedicalConditionsDataType, OnboardingStepProps } from 'containers/workerOnboarding/types';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { DocumentKey } from 'containers/worker/utils';
import { useMedicalDocumentsUpload } from 'containers/workerOnboarding/helpers/useMedicalDocumentsUpload';
import { FormOnSubmit } from 'components/form/types';
import { to } from 'acl';
import { useDeferredFormSubmission } from 'utils';
import { StepLoading } from './StepLoading';
import { getDefaultValues, getFormInputsHook } from './MedicalTestingStep.forms';
import { commonClasses } from './common.style';

const medicalDocumentsKeys = [
  DocumentKey.MedicalDrugTestingConsentForm,
  DocumentKey.MedicalBreathAlcoholTest,
  DocumentKey.MedicalUrineDrugTest,
  DocumentKey.MedicalCovid19OrientationAndProcedures,
] as const;

export function MedicalTestingStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);

  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<MedicalConditionsDataType>();

  const { uploadDocuments, updateJobsiteWorker, fetching, setFetching } = useMedicalDocumentsUpload({
    user,
    jobsiteWorker,
    documentTypes: medicalDocumentsKeys,
    filteredDocumentTypeIds,
    documents,
    deferredSubmission,
    refetchJobsiteWorkerDocuments,
  });

  const inputs = getFormInputsHook({
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
  });

  const defaultValues = React.useMemo(
    () => getDefaultValues({ jobsiteWorker, defaultFormValues }),
    [jobsiteWorker, defaultFormValues],
  );

  const onSubmit: FormOnSubmit<MedicalConditionsDataType> = (data, _event, dirtyFields): void => {
    uploadDocuments({ data, dirtyFields });
  };

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          passedMedicalExamAt: getCurrentISOFormattedDateTime(),
          medicalExamSkipReason: reason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          passedMedicalExamAt: null,
          medicalExamSkipReason: null,
        },
      },
    });
  };

  if (!user.isAllowed(to.seeMedicalTestingStepContent, jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId)) {
    return (
      <>
        <div>
          <h2 className="odin-mt-4">You don&lsquo;t have permission to view this page</h2>
        </div>
        <JobsiteWorkerOnboardingFooterNavbar
          jobsiteWorker={jobsiteWorker}
          isFormDirty={isFormDirty}
          hideLockSession
          hideSave
          hideSkipOptions={user.isContractor}
          onResetComplete={(): void => resetComplete()}
          onForceComplete={(reason: string): void => overrideComplete(reason)}
        />
      </>
    );
  }

  const loading = parentLoading || fetching;

  return (
    <div className="odin-relative">
      <div data-hj-suppress>
        <Header title="Medical" />
        <StepLoading loading={loading} hasTransparentBackground={!inputs().length} />
        <Form
          inputs={inputs}
          ref={formRef}
          defaultValues={defaultValues}
          onIsDirtyChange={setIsFormDirty}
          onSubmit={onSubmit}
          className={commonClasses.form}
          inputsContainerClassName={commonClasses.formInputsContainer}
        />
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        hideLockSession
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
      />
    </div>
  );
}
