import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import { Select, SelectOptionElement } from '@odin-labs/components';
import { to } from 'acl';
import {
  JobsiteWorkerOrientationStatus,
  QueryGetJobsiteContractorsArgs,
  UserErrorCode,
  useChangeContractorForJobsiteWorkersMutation,
  useUpdateJobsiteWorkerMutation,
  useWorkerOnboardingUpdateUserIdentityMutation,
  useWorkerOnboardingUpdateWorkerMutation,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { AlertService } from 'components/alertService';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { QueryGetJobsiteContractorsResponse } from 'containers/jobsiteContractors/types';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { EditFormDataType, OnboardingStepProps } from 'containers/workerOnboarding/types';
import { GET_JOBSITE_CONTRACTORS } from 'graphql/server/queries/jobsiteContractor';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { useDeferredFormSubmission, useIsMounted } from 'utils';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { StepLoading } from './StepLoading';
import {
  getCitizenshipStatusOptions,
  getDefaultValues,
  getFormInputs,
  getRaceOptions,
  getUpdateInputs,
  getVeteranOptions,
} from './PersonalInfoOverviewStep.forms';

const languages = {
  en: 'en',
  es: 'es',
};

const languageOptions: SelectOptionElement[] = [
  {
    label: 'English',
    value: languages.en,
  },
  {
    label: 'Spanish',
    value: languages.es,
  },
];

export function PersonalInfoOverviewStep(props: OnboardingStepProps): ReactElement {
  const { jobsiteWorkerId, jobsiteWorker, loading: parentLoading } = props;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<EditFormDataType>();
  const [language, setLanguage] = useState<SelectOptionElement>(languageOptions[0]);

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;
  const canAddUnions = user.isAllowed(to.addUnions);

  const jobsiteId = jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId;
  const { data: jobsiteContractorsData, loading: jobsiteContractorsLoading } = useQuery<
    QueryGetJobsiteContractorsResponse,
    QueryGetJobsiteContractorsArgs
  >(GET_JOBSITE_CONTRACTORS, {
    fetchPolicy: 'no-cache',
    variables: {
      jobsiteIds: [jobsiteId],
    },
    skip: !jobsiteId,
  });

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [updateUserIdentity] = useWorkerOnboardingUpdateUserIdentityMutation();
  const [updateWorker] = useWorkerOnboardingUpdateWorkerMutation();

  const [changeContractorForJobsiteWorkers] = useChangeContractorForJobsiteWorkersMutation({
    fetchPolicy: 'no-cache',
  });

  const overrideComplete = (reason: string): void => {
    if (fetching) return;
    setFetching(true);

    try {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            profileCompletedAt: getCurrentISOFormattedDateTime(),
            profileCompleteSkipReason: reason,
            orientationStatus: JobsiteWorkerOrientationStatus.InProgress,
          },
        },
      });
    } finally {
      if (isMounted()) setFetching(false);
    }
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    try {
      updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            profileCompletedAt: null,
            profileCompleteSkipReason: null,
          },
        },
      });
    } finally {
      if (isMounted()) setFetching(false);
    }
  };

  const onSubmit: FormOnSubmit<EditFormDataType> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (fetching) return;

    const { contractorId: contractor } = data;
    if (contractor.isDefaultContractor) {
      formApi.setError('contractorId', {
        message: 'Please select a contractor to proceed.',
        shouldFocus: true,
      });
      return;
    }

    setFetching(true);

    try {
      const { userUpdateIdentityInput, workerId, workerInput } = getUpdateInputs(jobsiteWorker, data, dirtyFields);

      const contractorChanged = dirtyFields.contractorId;

      if (userUpdateIdentityInput.userIdentityInput) {
        await updateUserIdentity({ variables: { input: userUpdateIdentityInput } });
      }

      if (workerInput) {
        await updateWorker({ variables: { workerId, workerInput } });
      }

      if (contractorChanged) {
        await changeContractorForJobsiteWorkers({
          variables: {
            input: {
              jobsiteWorkerIds: [jobsiteWorkerId],
              newContractorId: contractor.value,
              startDate: null,
            },
          },
        });
      }

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            profileCompletedAt: getCurrentISOFormattedDateTime(),
            profileCompleteSkipReason: null,
          },
        },
      });

      AlertService.alert('success', 'Successfully updated', 'Updated worker info');
      deferredSubmission.resolve(true);
    } catch (error) {
      const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.EmailAlreadyExists,
      );
      if (emailAlreadyExistsError) {
        formApi.setError('email', {
          message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        deferredSubmission.resolve(false);
        return;
      }
      const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.PhoneAlreadyExists,
      );
      if (phoneAlreadyExistsError) {
        formApi.setError('phoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        deferredSubmission.resolve(false);
        return;
      }

      deferredSubmission.reject(error);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setFetching(false);
    }
  };

  const jobsiteContractors = jobsiteContractorsData?.getJobsiteContractors?.results;
  const contractorOptions = React.useMemo(() => {
    return getContractorsOptions(jobsiteContractors?.map((jc) => jc.contractor)) ?? [];
  }, [jobsiteContractors]);

  const languageValue = language?.value;
  const { raceOptions, veteranOptions, citizenshipStatusOptions } = React.useMemo(
    () => ({
      raceOptions: getRaceOptions(languageValue),
      citizenshipStatusOptions: getCitizenshipStatusOptions(languageValue),
      veteranOptions: getVeteranOptions(languageValue),
    }),
    [languageValue],
  );
  const firstVeteranOptionsValue = veteranOptions?.[0]?.value;
  // `defaultValues` should be recalculated based on `firstVeteranOptionsValue` rather than on `veteranOptions`
  const defaultValues = React.useMemo(() => {
    return getDefaultValues({
      jobsiteWorker,
      raceOptions,
      citizenshipStatusOptions,
      veteranOptions,
      contractorOptions,
    });
  }, [jobsiteWorker, raceOptions, citizenshipStatusOptions, contractorOptions, firstVeteranOptionsValue]);

  const inputs = React.useMemo(() => {
    const { editableFields } = jobsiteWorker?.contractorWorker?.worker ?? {};
    const isEmailEditable = !defaultValues.email || !!editableFields?.email;
    const isPhoneEditable = !defaultValues.phoneNumber || !!editableFields?.phone;
    return getFormInputs({
      jobsiteWorker,
      contractorOptions,
      canAddUnions,
      raceOptions,
      citizenshipStatusOptions,
      veteranOptions,
      language: languageValue,
      isEmailEditable,
      isPhoneEditable,
    });
  }, [
    jobsiteWorker,
    raceOptions,
    citizenshipStatusOptions,
    veteranOptions,
    contractorOptions,
    canAddUnions,
    languageValue,
  ]);

  const loading = parentLoading || jobsiteContractorsLoading || fetching;

  return (
    <>
      <div className="odin-relative">
        <fieldset disabled={isContractorMember}>
          <Header
            title="Personal information"
            rightContent={
              <div style={{ width: '150px' }}>
                <Select
                  name="language"
                  value={language}
                  onChange={setLanguage}
                  label="Form language"
                  options={languageOptions}
                  isClearable={false}
                />
              </div>
            }
          />
          <StepLoading loading={loading} />
          <Form
            ref={formRef}
            inputs={inputs}
            defaultValues={defaultValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
            validationTriggers={[language]}
            inputsContainerClassName={cn('odin-grid odin-grid-cols-12 odin-gap-6')}
          />
        </fieldset>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
