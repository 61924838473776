import { EditJobsiteConfigurationFormData } from 'containers/jobsiteConfiguration/types';
import {
  JobsiteModule,
  SelfOnboardingState,
  SelfOnboardingStepConfig,
  SelfOnboardingStepKey,
} from 'containers/selfOnboarding/types';
import { getFields } from 'types';

export type SequentialStepArgs = Pick<SelfOnboardingState, 'shouldSelectContractor'> & {
  modules: JobsiteModule[];
  worker: Pick<SelfOnboardingState['worker'], 'isAssignedToMultipleJobsites'>;
  getStepConfig: () => SelfOnboardingStepConfig;
};
export type SequentialStep =
  | SelfOnboardingStepKey
  | {
      key: SelfOnboardingStepKey;
      isVisible: (args: SequentialStepArgs) => boolean;
    };

export const sequentialSteps: SequentialStep[] = [
  SelfOnboardingStepKey.LanguageSelection,
  {
    key: SelfOnboardingStepKey.ContractorSelection,
    isVisible: (args): boolean => !!args?.shouldSelectContractor,
  },
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.Profile,
  {
    key: SelfOnboardingStepKey.EmergencyContactInfo,
    isVisible: (args): boolean => {
      const jobsiteConfigWorkerInfo = getFields<EditJobsiteConfigurationFormData['workerInfo']>();
      const stepConfig = args.getStepConfig();
      return !stepConfig.fields?.[jobsiteConfigWorkerInfo.emergencyContact]?.isHidden;
    },
  },
  SelfOnboardingStepKey.GovernmentIssuedId,
  SelfOnboardingStepKey.NycSiteSafetyTrainingCard,
  SelfOnboardingStepKey.OshaCard,
  SelfOnboardingStepKey.JobsiteSafetyVideo,
  SelfOnboardingStepKey.JobsiteSafetyDocument,
  SelfOnboardingStepKey.LIRRBlueTraining,
  SelfOnboardingStepKey.ConfinedSpacesTraining,
  SelfOnboardingStepKey.ProfilePhoto,
  {
    key: SelfOnboardingStepKey.VerifyMobileDevice,
    isVisible: (state): boolean => !state?.worker?.isAssignedToMultipleJobsites,
  },
  SelfOnboardingStepKey.ReviewCompletedProfile,
];

const lastSequentialStep = sequentialSteps[sequentialSteps.length - 1];
export const lastStep = typeof lastSequentialStep === 'string' ? lastSequentialStep : lastSequentialStep.key;

export const noOrderInfoSteps: SelfOnboardingStepKey[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.ContractorSelection,
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.VerifyMobileDeviceToSignIn,
  SelfOnboardingStepKey.ConfirmMobileDevice,
  SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn,
  SelfOnboardingStepKey.ReviewCompletedProfile,
];

export const getNoGoBackSteps = (args: Omit<SequentialStepArgs, 'getStepConfig'>): SelfOnboardingStepKey[] => [
  args.shouldSelectContractor ? SelfOnboardingStepKey.ContractorSelection : SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.Closed,
];

type WhiteListedStepState = Pick<SelfOnboardingState, 'didWorkerMatch'>;
export type WhiteListedStep =
  | SelfOnboardingStepKey
  | {
      key: SelfOnboardingStepKey;
      isWhiteListed: (state: WhiteListedStepState) => boolean;
    };

function isWhiteListedIfWorkerMatched(state: WhiteListedStepState): boolean {
  return state?.didWorkerMatch;
}

export const whiteListedSteps: WhiteListedStep[] = [
  SelfOnboardingStepKey.LanguageSelection,
  SelfOnboardingStepKey.ContractorSelection,
  SelfOnboardingStepKey.BasicInfo,
  SelfOnboardingStepKey.WorkerConsentDocument,
  { key: SelfOnboardingStepKey.AlreadyCompleted, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.VerifyMobileDeviceToSignIn, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn, isWhiteListed: isWhiteListedIfWorkerMatched },
  { key: SelfOnboardingStepKey.UnableToVerifyAccount, isWhiteListed: isWhiteListedIfWorkerMatched },
];
