import React, { ReactElement } from 'react';
import cn from 'classnames';
import { Badge, Icon, Tooltip, TooltipPanel } from '@odin-labs/components';
import {
  CommentMedicalIcon,
  FileAltIcon,
  FileSignatureIcon,
  HardHatIcon,
  IdBadgeIcon,
  NewspaperIcon,
  UserIcon,
} from 'components/icons';
import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { getVisibleSteps } from 'containers/workerOnboardings/helpers/utils';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { OnboardingProgressProps } from './types';

type IconAndStatusProps = {
  completed: boolean;
  skipReason: string;
  icon: Icon;
  stepKey: string;
  bannedReason: string;
  workerId: string;
};

const getIconColorClass = (completed: boolean, skipReason: string, bannedReason: string): string => {
  if (bannedReason) {
    return cn('odin-text-odin-danger');
  }
  if (skipReason) {
    return cn('odin-text-[#ffaa29]');
  }
  return cn(completed ? 'odin-text-[#00d97e]' : 'odin-text-gray-600');
};

function IconAndStatus(props: IconAndStatusProps): ReactElement {
  const { completed, skipReason, icon: OnboardingStepIcon, bannedReason } = props;
  return (
    <Tooltip placement="top">
      <span>
        <OnboardingStepIcon
          className={cn('odin-w-5 odin-h-5', getIconColorClass(completed, skipReason, bannedReason))}
        />
      </span>
      <TooltipPanel>{bannedReason || skipReason}</TooltipPanel>
    </Tooltip>
  );
}

export function OnboardingProgress({ jobsiteWorker, className = {} }: OnboardingProgressProps): ReactElement {
  const { workerId } = jobsiteWorker?.contractorWorker?.worker ?? {};
  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const visibleSteps = getVisibleSteps(jobsite);

  const bannedReason = [jobsiteWorker?.banCategory, jobsiteWorker?.bannedReason].filter(Boolean).join(': ');

  return (
    <h1 className={cn(className)}>
      <Badge
        type="rounded"
        className={cn(
          'odin-flex odin-gap-x-3 !odin-py-1.5 !odin-px-2.5 !odin-rounded-md',
          bannedReason
            ? 'odin-color-odin-danger odin-bg-[#fad7dd]'
            : [
                jobsiteWorker?.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding
                  ? '!odin-bg-amber-100'
                  : '!odin-bg-gray-100',
              ],
        )}
      >
        <IconAndStatus
          completed={!!jobsiteWorker?.profileCompletedAt}
          skipReason={jobsiteWorker?.profileCompleteSkipReason}
          icon={UserIcon}
          stepKey="personal-info"
          bannedReason={bannedReason}
          workerId={workerId}
        />
        {ensureNonEmptyItems([
          visibleSteps?.includes(OnboardingStepKey.WorkDocuments) && (
            <IconAndStatus
              key={OnboardingStepKey.WorkDocuments}
              completed={!!jobsiteWorker?.documentsCompletedAt}
              skipReason={jobsiteWorker?.documentsCompletedSkipReason}
              icon={FileAltIcon}
              stepKey="documents"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.MedicalInfo) && (
            <IconAndStatus
              key={OnboardingStepKey.MedicalInfo}
              completed={!!jobsiteWorker?.passedMedicalExamAt}
              skipReason={jobsiteWorker?.medicalExamSkipReason}
              icon={CommentMedicalIcon}
              stepKey="medical"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.OnboardingTraining) && (
            <IconAndStatus
              key={OnboardingStepKey.OnboardingTraining}
              completed={!!jobsiteWorker?.onboardingSignedAt}
              skipReason={jobsiteWorker?.onboardingSkipReason}
              icon={NewspaperIcon}
              stepKey="onboarding"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.Signature) && (
            <IconAndStatus
              key={OnboardingStepKey.Signature}
              completed={!!jobsiteWorker?.signatureCompletedAt}
              skipReason={jobsiteWorker?.signatureSkipReason}
              icon={FileSignatureIcon}
              stepKey="signature"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.Badging) && (
            <IconAndStatus
              key={OnboardingStepKey.Badging}
              completed={!!jobsiteWorker?.badgingCompletedAt}
              skipReason={jobsiteWorker?.badgingSkipReason}
              icon={IdBadgeIcon}
              stepKey="badging"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
          visibleSteps?.includes(OnboardingStepKey.SiteSpecificOrientation) && (
            <IconAndStatus
              key={OnboardingStepKey.SiteSpecificOrientation}
              completed={!!jobsiteWorker?.siteSpecificTrainingCompletedAt}
              skipReason={jobsiteWorker?.siteSpecificTrainingSkipReason}
              icon={HardHatIcon}
              stepKey="site-specific"
              bannedReason={bannedReason}
              workerId={workerId}
            />
          ),
        ])}
      </Badge>
    </h1>
  );
}
