import cn from 'classnames';
import { FormDefaultValue, FormInputTypes, TypedFormInputs } from 'components/form';
import { MapMarkerIcon } from 'components/icons';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { EditPersonalInfoFormData, EditPersonalInfoFormDefaultData } from 'containers/worker/beta/types';
import {
  toFancySelectOptions,
  statesOptions,
  genderOptions as genderOptionsValues,
  jobTitleOptions as jobTitleOptionsValues,
  primaryLanguageOptions as primaryLanguageOptionsValues,
  raceOptions as raceOptionsValues,
  tradeOptions as tradeOptionsValues,
  unionAffiliationOptions as unionAffiliationOptionsValues,
  veteranOptions as veteranOptionsValues,
} from 'utils/constants';
import {
  ageValidation,
  dateValidation,
  phoneNumberValidation,
  ssnValidation,
  zipCodeValidation,
} from 'utils/validation';

const unionAffiliationOptions = toFancySelectOptions(unionAffiliationOptionsValues);
const jobTitleOptions = toFancySelectOptions(jobTitleOptionsValues);
const tradeOptions = toFancySelectOptions(tradeOptionsValues);
const primaryLanguageOptions = toFancySelectOptions(primaryLanguageOptionsValues);
const genderOptions = toFancySelectOptions(genderOptionsValues);
const raceOptions = toFancySelectOptions(raceOptionsValues);
const veteranOptions = toFancySelectOptions(veteranOptionsValues);

export const personalFormInput = (canAddUnions?: boolean): TypedFormInputs<EditPersonalInfoFormData> => ({
  firstName: {
    element: FormInputTypes.Field,
    label: 'First name*',
    validation: { required: true },
    layout: cn('odin-col-span-10', 'odin-text-sm'),
  },
  middleInitial: {
    element: FormInputTypes.Field,
    label: 'Middle initial',
    elementProps: {
      fieldType: 'custom',
      cleaveOptions: { blocks: [1] },
    },
    layout: cn('odin-col-span-2', 'odin-text-sm'),
  },
  lastName: {
    element: FormInputTypes.Field,
    label: 'Last name*',
    elementProps: {
      placeholder: 'Last name',
    },
    validation: { required: true },
    layout: cn('odin-col-span-10', 'odin-text-sm'),
  },
  suffix: {
    element: FormInputTypes.Field,
    label: 'Suffix',
    elementProps: {
      placeholder: 'Suffix',
    },
    layout: cn('odin-col-span-2', 'odin-text-sm'),
  },
  birthDate: {
    element: FormInputTypes.Field,
    label: 'Date of birth',
    elementProps: {
      placeholder: 'MM/DD/YYYY',
      fieldType: 'pastDate',
    },
    validation: {
      pattern: dateValidation,
      validate: ageValidation('Date of birth', 14, 100),
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  ssnLastFour: {
    element: FormInputTypes.Field,
    label: 'Last 4 of social security number',
    elementProps: {
      fieldType: 'ssn4',
    },
    validation: { pattern: ssnValidation },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  primaryLanguage: {
    element: FormInputTypes.Select,
    label: 'Primary language',
    elementProps: {
      options: primaryLanguageOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  addressLine1: {
    element: FormInputTypes.PlacesAutocomplete,
    label: 'Address',
    elementProps: {
      icon: MapMarkerIcon,
      onCommit: fillAddressDetails,
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  addressLine2: {
    element: FormInputTypes.Field,
    label: 'Address line 2',
    elementProps: {
      placeholder: 'Address line 2',
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  addressCity: {
    element: FormInputTypes.Field,
    label: 'City',
    elementProps: {
      placeholder: 'City',
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  addressState: {
    element: FormInputTypes.Select,
    label: 'State',
    elementProps: {
      placeholder: '',
      options: statesOptions,
    },
    layout: cn('odin-col-span-3', 'odin-text-sm'),
  },
  addressZipCode: {
    element: FormInputTypes.Field,
    label: 'Zip code',
    elementProps: {
      fieldType: 'zipcode',
    },
    validation: { pattern: zipCodeValidation },
    layout: cn('odin-col-span-3', 'odin-text-sm'),
  },
  phoneNumber: {
    element: FormInputTypes.Field,
    label: 'Phone number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
      // disabled: !isPhoneEditable,
    },
    validation: { pattern: phoneNumberValidation },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  email: {
    element: FormInputTypes.Field,
    label: 'Email address',
    elementProps: {
      placeholder: 'Email address',
      disabled: true,
    },
    layout: cn('odin-col-span-6', 'odin-text-sm'),
  },
  trade: {
    element: FormInputTypes.Select,
    label: 'Trade',
    elementProps: {
      options: tradeOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  jobTitle: {
    element: FormInputTypes.Select,
    label: 'Job Title',
    elementProps: {
      options: jobTitleOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  unionAffiliation: {
    element: FormInputTypes.Select,
    label: 'Union Affiliation',
    elementProps: {
      isCreateable: canAddUnions,
      options: unionAffiliationOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  gender: {
    element: FormInputTypes.Select,
    label: 'Gender',
    elementProps: {
      options: genderOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  race: {
    element: FormInputTypes.Select,
    label: 'Race',
    elementProps: {
      options: raceOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
  isVeteran: {
    element: FormInputTypes.Select,
    label: 'Veteran status',
    elementProps: {
      options: veteranOptions,
    },
    layout: cn('odin-col-span-4', 'odin-text-sm'),
  },
});

export const createEditPersonalInfoDefaultData = (
  keys: Array<keyof EditPersonalInfoFormData>,
  formData: EditPersonalInfoFormDefaultData,
): FormDefaultValue<EditPersonalInfoFormData>[] => {
  return keys
    .filter((key) => key !== 'cancelButton' && key !== 'submitButton')
    .map((key) => key as keyof EditPersonalInfoFormDefaultData)
    .map((key) => {
      if (key === 'isVeteran') {
        return {
          name: key,
          value: {
            label: formData[key] ? 'Veteran' : 'Non veteran',
            value: formData[key] ? 'Veteran' : 'Non veteran',
          },
        };
      }

      const fancySelectKeys = [
        'race',
        'gender',
        'primaryLanguage',
        'isVeteran',
        'addressState',
        'trade',
        'jobTitle',
        'unionAffiliation',
      ];

      if (fancySelectKeys.includes(key) && formData[key]) {
        return {
          name: key,
          value: {
            label: formData[key],
            value: formData[key],
          },
        };
      }

      return {
        name: key,
        value: formData[key],
      };
    });
};
