import React from 'react';
import { UserPendingChangeType } from 'apollo/generated/client-operations';
import { Button } from '@odin-labs/components';
import { PencilIcon } from 'components/icons';

type SubmitUserChangeActionProps = {
  type: UserPendingChangeType;
  isHidden: boolean;
  hasPendingChange: boolean;
  onClick: (value: { type: UserPendingChangeType }) => void;
};

export function SubmitUserChangeAction(props: SubmitUserChangeActionProps): React.ReactElement {
  const { type, isHidden, onClick, hasPendingChange } = props;

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault();
      onClick?.({ type });
    },
    [onClick],
  );

  if (isHidden) return null;

  if (hasPendingChange) {
    return (
      <a href="" onClick={onLinkClick} className="odin-underline">
        View pending change
      </a>
    );
  }

  return <Button theme="naked" onClick={onLinkClick} icon={PencilIcon} className="!odin-py-0" />;
}
