import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { ApolloError, useMutation } from '@apollo/client';
import { StateAbbreviation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { ModalForm } from 'components/form';
import { UPDATE_WORKER_AND_IDENTITY } from 'containers/worker/helpers/queries';
import { MutationUpdateWorkerAndIdentityArgs, UpdateWorkerAndIdentityResponse } from 'containers/worker/types';
import { createEditPersonalInfoDefaultData, personalFormInput } from 'containers/worker/beta/helpers/forms';
import { EditPersonalInfoFormData, EditPersonalInfoFormDefaultData } from 'containers/worker/beta/types';
import { getGraphQLError } from 'utils/error';
import { getPrettyFormattedUtcDate } from 'utils/dates';

import { EditPersonalInfoModalProps } from './types';

const personalKeys = Object.keys(personalFormInput()) as Array<keyof EditPersonalInfoFormData>;

export function EditPersonalInfoModal({
  isOpen = false,
  onCancel,
  canAddUnions,
  worker,
  refetch,
}: EditPersonalInfoModalProps): ReactElement {
  const [fetching, setFetching] = useState(false);

  const [updateJobsiteWorker] = useMutation<UpdateWorkerAndIdentityResponse, MutationUpdateWorkerAndIdentityArgs>(
    UPDATE_WORKER_AND_IDENTITY,
    {
      onCompleted: (): void => {
        onCancel();
        setFetching(false);
        refetch();
      },
      onError: (responseError: ApolloError): void => {
        setFetching(false);
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
      },
    },
  );

  const defaultData: EditPersonalInfoFormDefaultData = {
    firstName: worker?.user?.identity?.firstName || '',
    lastName: worker?.user?.identity?.lastName || '',
    email: worker?.user?.identity?.email || '',
    middleInitial: worker?.middleInitial || '',
    suffix: worker?.suffix || '',
    // Cleave input values don't inlude slashes
    birthDate: getPrettyFormattedUtcDate(worker?.birthDate) || '',
    ssnLastFour: worker?.ssnLastFour || '',
    race: worker?.race || '',
    gender: worker?.gender || '',
    primaryLanguage: worker?.primaryLanguage || '',
    isVeteran: worker?.isVeteran || '',
    addressLine1: worker?.addressLine1 || '',
    addressLine2: worker?.addressLine2 || '',
    addressCity: worker?.addressCity || '',
    addressState: worker?.addressState,
    addressZipCode: worker?.addressZipCode || '',
    phoneNumber: worker?.user?.identity?.phoneNumber || '',
    jobTitle: worker?.jobTitle || '',
    trade: worker?.trade || '',
    unionAffiliation: worker?.unionAffiliation || '',
  };

  const defaultPersonalData = createEditPersonalInfoDefaultData(personalKeys, defaultData);

  const submitPersonalInfoForm = (formData: EditPersonalInfoFormData): void => {
    if (fetching) {
      return;
    }
    setFetching(true);
    updateJobsiteWorker({
      variables: {
        workerId: worker?.workerId,
        workerInput: {
          middleInitial: formData?.middleInitial,
          suffix: formData?.suffix,
          race: formData?.race?.value || null,
          gender: formData?.gender?.value || null,
          primaryLanguage: formData?.primaryLanguage?.value || null,
          isVeteran: formData?.isVeteran?.value === 'Veteran',
          addressLine1: formData?.addressLine1,
          addressLine2: formData?.addressLine2,
          addressCity: formData?.addressCity,
          addressState: (formData?.addressState?.value as StateAbbreviation) || null,
          addressZipCode: formData?.addressZipCode,
          jobTitle: formData?.jobTitle?.value || null,
          trade: formData?.trade?.value || null,
          unionAffiliation: formData?.unionAffiliation?.value || null,
          workerBaseInfoInput: {
            birthDate: moment(formData?.birthDate, 'MMDDYYYY').toISOString(),
            ssnLastFour: formData?.ssnLastFour,
          },
        },
        userUpdateIdentityInput: {
          userAccountId: worker?.user?.userAccountId,
          userIdentityInput: {
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            phoneNumber: formData?.phoneNumber,
          },
        },
      },
    });
  };
  return (
    <ModalForm
      size="sm"
      open={isOpen}
      setOpen={onCancel}
      title="Edit personal information"
      actionButtonWithSpinner={fetching}
      actionText="Save"
      inputs={personalFormInput(canAddUnions)}
      defaultValues={defaultPersonalData}
      onSubmit={submitPersonalInfoForm}
      inputsContainerClassName="odin--mb-7 odin-grid odin-grid-cols-12 odin-gap-x-3"
    />
  );
}
