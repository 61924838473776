import React from 'react';
import toast from 'react-hot-toast';
import { Alert, AlertType } from '@odin-labs/components';
import { AlertBody } from './AlertBody';

export class AlertService {
  static alert(type: AlertType, title: string, message: string, duration = 3000): void {
    toast.custom(
      (t) => (
        <div className={t.visible ? 'odin-animate-enter' : 'odin-animate-leave'}>
          <Alert
            type={type}
            text={<AlertBody title={title} message={message} />}
            onDismiss={(): void => toast.dismiss(t.id)}
          />
        </div>
      ),
      { duration },
    );
  }
}
