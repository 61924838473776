import React, { ReactElement } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DropdownButton, MenuItemProps, TabConfig } from '@odin-labs/components';
import {
  GetJobsiteContainerMembersDocument,
  UserAssignmentType,
  UserRoleKey,
  useGetContractorsQuery,
  useGetJobsiteContainerQuery,
  useGetRolesQuery,
} from 'apollo/generated/client-operations';
import { LoadingError } from 'components/loadingError';
import { NewHeader } from 'components/header/NewHeader';
import { Container } from 'components/container';
import { WrenchIcon, PlusIcon, UserIcon } from 'components/icons';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { HeaderAction } from 'components/header/types';
import { AuthUser } from 'auth/types';
import { AddUserModal } from 'containers/users/modals';
import { JobsiteTitle } from './components/JobsiteTitle';
import { JobsiteTabApi, JobsiteTabProps } from './types';
import {
  JobsiteEquipmentTabContainer,
  JobsiteEquipmentHistoryTabContainer,
  JobsiteInfoTabContainer,
  JobsiteUsersTabContainer,
  JobsiteWorkersTabContainer,
  JobsiteContractorsTabContainer,
} from './tabs';
import { AddContractorAssignmentModal } from './modals';

const refetchQueries = [GetJobsiteContainerMembersDocument];

const getTabsConfig = (user: AuthUser, jobsiteId: string): TabConfig<JobsiteTabProps>[] => {
  const canSeeAdvancedTabs = !user.isContractor;
  return ensureNonEmptyItems<TabConfig<JobsiteTabProps>>([
    { name: 'info', text: 'Jobsite Info', relativePath: '', component: JobsiteInfoTabContainer },
    {
      name: 'contractors',
      text: 'Contractors',
      relativePath: '/contractors',
      component: JobsiteContractorsTabContainer,
    },
    { name: 'workers', text: 'Workers', relativePath: '/workers', component: JobsiteWorkersTabContainer },
    canSeeAdvancedTabs &&
      user.isAllowed(to.seeJobsiteUsers, jobsiteId) && {
        name: 'users',
        text: 'Users',
        relativePath: '/users',
        component: JobsiteUsersTabContainer,
      },
    canSeeAdvancedTabs && {
      name: 'equipment',
      text: 'Equipment',
      relativePath: '/equipment',
      component: JobsiteEquipmentTabContainer,
    },
    canSeeAdvancedTabs && {
      name: 'equipmentHistory',
      text: 'Equipment History',
      relativePath: '/equipment-history',
      component: JobsiteEquipmentHistoryTabContainer,
    },
  ]);
};

export function JobsiteContainer(): ReactElement {
  const history = useHistory();
  const location = useLocation();

  const { jobsiteId } = useParams<{ jobsiteId: string }>();
  const baseRoute = `/jobsite/${jobsiteId}`;
  const usersRoute = `${baseRoute}/users`;
  const contractorsRoute = `${baseRoute}/contractors`;

  const {
    value: isAddJobsiteUserModalOpen,
    setTrue: openAddJobsiteUserModal,
    setFalse: closeAddJobsiteUserModal,
  } = useBoolean(false);

  const {
    value: isAddContractorAssignmentModalOpen,
    setTrue: openAddContractorAssignmentModal,
    setFalse: closeAddContractorAssignmentModal,
  } = useBoolean(false);

  const { currentUser: user } = React.useContext(AuthContext);

  const {
    data: jobsiteData,
    loading: jobsiteLoading,
    error: jobsiteError,
  } = useGetJobsiteContainerQuery({
    fetchPolicy: 'no-cache',
    variables: {
      jobsiteId,
    },
  });

  const {
    data: contractorsData,
    loading: contractorsLoading,
    error: contractorsError,
  } = useGetContractorsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const jobsite = jobsiteData?.getJobsite;
  const contractorsCount = jobsite?.jobsiteContractors.count.toString() ?? '';
  const userCount = jobsite?.jobsiteMembers.count.toString() ?? '';
  const workerCount = jobsite?.jobsiteWorkers.count.toString() ?? '';
  const equipmentCount = '';

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const availableContractorsToAssign = React.useMemo(() => {
    const contractors = contractorsData?.getCurrentSession.user.contractors.edges.map(({ node }) => node);
    const assignedContractorIds = jobsite?.jobsiteContractors.edges.map(({ node }) => node.contractor.contractorId);
    return contractors?.filter((c) => !assignedContractorIds?.includes(c.contractorId));
  }, [jobsite, contractorsData]);

  const tabsCount: Record<string, string> = {
    equipment: equipmentCount,
    workers: workerCount,
    contractors: contractorsCount,
    users: userCount,
  };

  const tabsConfigWithBadges = getTabsConfig(user, jobsiteId).map((tab) => ({ ...tab, badge: tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  const closeModalAndNavigateToJobsiteUsersIfNeeded = React.useCallback((): void => {
    if (location.pathname !== usersRoute) {
      history.push(usersRoute);
    }
    closeAddJobsiteUserModal();
  }, [closeAddJobsiteUserModal, history, location]);

  const closeModalAndNavigateToContractorsIfNeeded = React.useCallback((): void => {
    if (location.pathname !== contractorsRoute) {
      history.push(contractorsRoute);
    }
    closeAddJobsiteUserModal();
  }, [closeAddJobsiteUserModal, history, location]);

  const menuItems = ensureNonEmptyItems<MenuItemProps>([
    user.isAllowed(to.addContractorJobsiteAssignments) && {
      onClick: openAddContractorAssignmentModal,
      text: 'Contractor Assignment',
      icon: WrenchIcon,
    },
    user.isAllowed(to.addJobsiteUsers, jobsiteId) && {
      onClick: openAddJobsiteUserModal,
      text: 'User',
      icon: UserIcon,
    },
  ]);

  const [tabApi, setTabApi] = React.useState<JobsiteTabApi>(null);

  const tabsPageProps: JobsiteTabProps = {
    jobsiteId,
    loading: jobsiteLoading || contractorsLoading,
    error: jobsiteError,
    onTabApiChange: setTabApi,
  };

  const error = jobsiteError || contractorsError;
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Container className="jobsite-container">
      <NewHeader
        title={
          <JobsiteTitle
            name={jobsite?.name}
            addressCity={jobsite?.addressCity}
            addressState={jobsite?.addressState}
            organizationName={jobsite?.developer?.organization?.name}
          />
        }
        actionsProps={{
          baseRoute,
          onReloadPressed: tabApi?.refetchData,
          headerActions: ensureNonEmptyItems<HeaderAction>([
            'back',
            'reload',
            user.isAllowed(to.editJobsiteConfiguration) ? 'settings' : undefined,
          ]),
          children: menuItems.length > 0 && <DropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
        tabsProps={{ tabs, currentTab }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      {jobsite && (
        <>
          <AddUserModal
            isOpen={isAddJobsiteUserModalOpen}
            onCancel={closeAddJobsiteUserModal}
            onConfirm={closeModalAndNavigateToJobsiteUsersIfNeeded}
            jobsite={jobsite}
            refetchQueries={refetchQueries}
            userRoleType={UserAssignmentType.Jobsite}
            userRoles={userRoles}
          />
          <AddContractorAssignmentModal
            isOpen={isAddContractorAssignmentModalOpen}
            closeModal={closeAddContractorAssignmentModal}
            onConfirm={closeModalAndNavigateToContractorsIfNeeded}
            jobsite={jobsite}
            contractors={availableContractorsToAssign}
          />
        </>
      )}
    </Container>
  );
}
