import cn from 'classnames';

export const classes = {
  itemContainer: (isClickable: boolean, extraClasses: string): string =>
    cn(
      'search-result-item-container',
      'odin-flex',
      'odin-flex-col',
      'odin-p-6',
      'odin-space-y-2',
      isClickable && 'odin-cursor-pointer',
      extraClasses,
    ),

  workerInfoRow: (showInfoOnNewRow: boolean): string =>
    cn(
      'search-result-worker-info-row',
      'odin-flex',
      'odin-flex-col',
      'odin-gap-y-1.5',
      !showInfoOnNewRow && 'sm:odin-flex-row',
      'sm:odin-gap-x-4',
    ),
  workerNameContainer: cn('search-result-worker-name-container odin-flex'),
  workerName: cn(
    'search-result-worker-name',
    'odin-py-1',
    'odin-ml-3',
    'odin-text-base',
    'odin-font-medium',
    'odin-text-gray-900',
    'odin-truncate',
  ),
  workerInfoContainer: cn(
    'search-result-worker-info',
    'odin-grid',
    'odin-grid-cols-2',
    'odin-gap-y-1.5',
    'odin-ml-11',
    'sm:odin-flex',
    'sm:odin-flex-wrap',
    'sm:odin-gap-x-4',
  ),
  workerInfoItem: cn(
    'worker-info-item-container',
    'odin-flex',
    'odin-items-center',
    'odin-text-gray-900 odin-text-1.5xs',
    'odin-truncate',
  ),
  workerInfoItemIcon: cn(
    'worker-info-item-icon',
    'odin-text-odin-primary',
    'odin-text-sm',
    'odin-font-normal',
    'odin-mr-1.5',
  ),

  jobsiteInfoRow: (isHidden: boolean): string =>
    cn(
      'search-result-jobsite-info-row',
      'odin-ml-7',
      'odin-flex',
      'odin-flex-col',
      'sm:odin-flex-row',
      'sm:odin-flex-wrap',
      isHidden && 'odin-hidden',
    ),

  jobsiteLabel: cn(
    'search-result-item-jobsite-label',
    'odin-w-fit',
    'odin-border',
    'odin-border-gray-200',
    'odin-rounded-md',
    'odin-mt-1.5',
    'odin-ml-4',
    'odin-px-3',
    'odin-py-2',
    'odin-text-xs',
    'odin-text-gray-900',
    'odin-space-x-2',
  ),

  jobsiteAccessStatus: (isAllowed: boolean): string =>
    cn(isAllowed ? 'odin-text-odin-success' : 'odin-text-odin-danger', 'odin-fill-current', 'odin-w-2', 'odin-h-2'),

  jobsiteNameLabel: cn('odin-font-medium'),
  jobsiteConstructorNameLabel: cn(''),
};
