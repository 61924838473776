import React, { ReactElement, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { AlertService } from 'components/alertService';
import { ARCHIVE_WORKER } from 'containers/worker/helpers/queries';
import { Modal } from '@odin-labs/components';
import { getGraphQLError } from 'utils/error';
import { ArchiveWorkerModalProps } from './types';

export function ArchiveWorkerModal({
  workerId,
  isOpen = false,
  onCancel,
  onAction,
}: ArchiveWorkerModalProps): ReactElement {
  const [fetching, setFetching] = useState<boolean>(false);
  const [archiveWorkerMutation] = useMutation(ARCHIVE_WORKER, {
    onCompleted: () => {
      setFetching(false);
      onAction();
      AlertService.alert('success', 'Worker successfully archived', 'This worker has been archived');
    },
    onError: (archiveError: ApolloError) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(archiveError));
    },
  });

  const archiveWorker = (): void => {
    if (fetching) {
      return;
    }
    setFetching(true);
    archiveWorkerMutation({
      variables: {
        workerId,
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      onCancel={onCancel}
      onAction={archiveWorker}
      size="xs"
      cancelText="Cancel"
      actionText="Archive"
      title="Are you sure?"
      actionButtonEnabled={!fetching}
      actionButtonTheme="danger"
    >
      <div>
        <h3>Are you sure you want to archive this worker? This action cannot be undone!</h3>
      </div>
    </Modal>
  );
}
