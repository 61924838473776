import React from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  WorkerCard,
  WorkerCardType,
  useAddAndIssueFacialRecognitionBadgeMutation,
  AppErrorCode,
  useUpdateWorkerMutation,
  MutationRemoveCardFromWorkerArgs,
  Success,
} from 'apollo/generated/client-operations';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError, hasGraphQLErrorName, hasGraphQLErrorCode } from 'utils/error';
import { REMOVE_CARD_FROM_WORKER } from 'containers/worker/helpers/queries';
import { CheckIcon, ExclamationTriangleIcon } from 'components/icons';
import { Card } from 'components/reactstrap';
import { FormOnSubmit, ModalFormContent } from 'components/form';
import { AlertService } from 'components/alertService';
import { Worker } from 'containers/worker/types';
import workerImage from 'images/worker.png';
import { getFormDefaultValues, getFormInputs } from './IssueFacialRecognitionBadgeModalContent.forms';
import { IssueFacialRecognitionBadgeFormData } from './types';

export type IssueFacialRecognitionBadgeModalContentProps = {
  setOpen: () => void;
  onAction: (issueFacialRecognitionBadgeFormData?: IssueFacialRecognitionBadgeFormData) => void;
  refetchWorkerCards: () => Promise<unknown>;
  jobsiteId: string;
  workerId: string;
  workerCardFormatId: string;
  workerCards: WorkerCard[];
  worker: Worker;
};

export function IssueFacialRecognitionBadgeModalContent(
  props: IssueFacialRecognitionBadgeModalContentProps,
): React.ReactElement {
  const { setOpen, onAction, workerId, jobsiteId, workerCardFormatId, workerCards, worker, refetchWorkerCards } = props;

  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [notifyWorker, setNotifyWorker] = React.useState<boolean>(!worker?.profilePictureCropped);

  const [addAndIssueFacialRecognitionBadge] = useAddAndIssueFacialRecognitionBadgeMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateWorker] = useUpdateWorkerMutation();

  const [removeCardFromWorker] = useMutation<Success, MutationRemoveCardFromWorkerArgs>(REMOVE_CARD_FROM_WORKER, {
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      setIsFetching(false);
    },
  });

  const onSubmit: FormOnSubmit<IssueFacialRecognitionBadgeFormData> = async (data, event): Promise<void> => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    try {
      const { phoneNumber, badges } = nullifyEmptyFields(data);

      const badgesToDeactivate = badges.map((bs) => bs.workerCard);
      if (badgesToDeactivate.length > 0) {
        const badgeIds = badgesToDeactivate.map((wc) => wc.workerCardId);
        await removeCardFromWorker({ variables: { workerCardIds: badgeIds, deactivateCardReason: 'issue new' } });
      }

      await addAndIssueFacialRecognitionBadge({
        variables: {
          jobsiteId,
          workerId,
          workerCardInput: {
            isTemporary: false,
            startDate: moment().toDate(),
            type: WorkerCardType.FacialRecognition,
            workerCardFormatId,
          },
          newPhoneNumber: notifyWorker ? phoneNumber : null,
        },
      });

      onAction();
      AlertService.alert('success', 'Success', 'Facial Recognition badge issued successfully');
    } catch (error) {
      event.preventDefault();
      if (hasGraphQLErrorCode(AppErrorCode.AlcatrazImageRejected)) {
        // await updateWorker({ variables: { workerId, workerInput: { profilePictureFileId: null } } });
        setNotifyWorker(true);
        await refetchWorkerCards();
        return;
      }
      if (hasGraphQLErrorCode(AppErrorCode.AlcatrazProfileAlreadyAssociated)) {
        onAction();
        return;
      }
      if (hasGraphQLErrorName(error, 'ResourceAlreadyExistsError')) {
        onAction(data);
      } else {
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      }
    } finally {
      if (isMounted()) {
        setIsFetching(false);
      }
    }
  };

  const formInputs = getFormInputs({ notifyWorker });
  const defaultValues = React.useMemo(
    () => getFormDefaultValues(worker, workerCards),
    [worker, workerCards, notifyWorker],
  );

  return (
    <>
      {!notifyWorker && (
        <Card className="odin-pt-4">
          <img
            className="odin-m-auto odin-w-1/4 !odin-rounded-full"
            src={worker?.profilePictureCropped?.downloadUrl || workerImage}
            alt="Worker Profile"
          />
          <br />
        </Card>
      )}
      {notifyWorker && worker?.profilePictureCropped?.downloadUrl && (
        <div className="odin-p-7.5 sm:odin-col-span-3">
          <div className="odin-p-6 odin-rounded-xl odin-bg-odin-light-yellow odin-text-center">
            <ExclamationTriangleIcon className="odin-text-3xl odin-text-odin-black" />
            <h2 className="odin-my-3 odin-text-1.5xl odin-text-gray-900 odin-font-medium">
              Profile photo not accepted
            </h2>
            <div className="odin-text-sm odin-text-gray-600">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Please confirm the worker's mobile phone number and click Start Enrollment to have the worker complete the
              enrollment process.
            </div>
          </div>
        </div>
      )}
      {notifyWorker && !worker?.profilePictureCropped?.downloadUrl && (
        <div className="odin-p-7.5 sm:odin-col-span-3">
          <div className="odin-p-6 odin-rounded-xl odin-bg-odin-highlight odin-text-center">
            <ExclamationTriangleIcon className="odin-text-3xl odin-text-odin-primary" />
            <h2 className="odin-my-3 odin-text-1.5xl odin-text-gray-900 odin-font-medium">No worker photo on file</h2>
            <div className="odin-text-sm odin-text-gray-600">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Please confirm the worker's mobile phone number and click Start Enrollment to have the worker complete the
              enrollment process.
            </div>
          </div>
        </div>
      )}

      <ModalFormContent
        setOpen={setOpen}
        inputs={formInputs}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
        actionText="Start Enrollment"
        actionIcon={CheckIcon}
        actionButtonWithSpinner={isFetching}
      />
    </>
  );
}
