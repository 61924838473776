import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { getISODateTimeToFormattedDate } from 'utils/dates';
import { Status } from 'components/status/Status';
import { JobsiteWorkerCardStatusProps } from 'components/jobsiteWorkerCard/types';
import { classes } from 'components/jobsiteWorkerCard/JobsiteWorkerCard.style';

export function JobsiteWorkerMedicalStatus(props: JobsiteWorkerCardStatusProps): ReactElement {
  const { worker, jobsiteWorker, disableActions, confirmSelfOnboardingWorkerEdit } = props;
  const {
    passedMedicalExamAt: medicalExamCompletedAt,
    medicalExamSkipExpiresAt,
    medicalExamSkipReason,
  } = jobsiteWorker;
  const history = useHistory();

  const goToWorkerMedical = React.useCallback((): void => {
    history.push(`/worker/${worker.workerId}/medical`);
  }, [history, worker]);

  const goToOnboardingMedical = React.useCallback((): void => {
    history.push(`/onboarding/${jobsiteWorker?.jobsiteWorkerId}/medical-testing`);
  }, [history, jobsiteWorker]);

  const getSubLabel = ({
    text,
    onClick,
  }: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
  }): ReactElement => (
    <span className="odin-underline odin-cursor-pointer odin-text-1.5xs odin-text-odin-primary" onClick={onClick}>
      {text}
    </span>
  );

  let label: ReactElement;
  let status: 'warning' | 'success' | 'default' | 'danger';
  let subLabel: ReactElement;

  if (jobsiteWorker.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding) {
    label = <p>Self-Onboarding</p>;
    status = 'warning';
    subLabel = getSubLabel({
      text: 'Start medical',
      onClick: () => confirmSelfOnboardingWorkerEdit({ then: goToOnboardingMedical }),
    });
  } else if (medicalExamCompletedAt && medicalExamSkipReason == null) {
    label = <p>Complete</p>;
    subLabel = getSubLabel({ text: 'View results', onClick: goToWorkerMedical });
    status = 'success';
  } else if (medicalExamSkipExpiresAt) {
    label = <p>Expired</p>;
    subLabel = <span>Expired {getISODateTimeToFormattedDate(medicalExamSkipExpiresAt)}</span>;
    status = 'danger';
  } else if (medicalExamSkipReason) {
    label = <p>Exempt</p>;
    subLabel = <span>{medicalExamSkipReason}</span>;
    status = 'warning';
  } else {
    label = <p>Not started</p>;
    status = 'danger';
    subLabel = getSubLabel({ text: 'Start medical', onClick: goToOnboardingMedical });
  }

  return (
    <>
      <Status label={label} status={status} className={classes.footerLabel} />
      {!disableActions && subLabel}
    </>
  );
}
