import React, { ReactElement } from 'react';
import { useMutation } from '@apollo/client';
import { GetWorkerDataDocument, MutationUpdateJobsiteWorkerArgs } from 'apollo/generated/client-operations';
import { JobsiteWorkerCard } from 'components/jobsiteWorkerCard/JobsiteWorkerCard';
import { AlertService } from 'components/alertService';
import { UPDATE_JOBSITE_WORKER } from 'containers/workerOnboardings/helpers/queries';
import { WorkerTabProps, JobsiteWorker } from 'containers/worker/types';
import { useModalState } from 'utils';
import { getGraphQLError } from 'utils/error';
import { JsonTreeModal } from 'components/modals';
import { AuthContext } from 'auth';
import { to } from 'acl';
import {
  EditJobsiteClearanceAssignmentsModal,
  EditJobsiteWorkerModal,
  ChangeContractorModal,
  CorrectJobsiteAssignmentModal,
  EditSelfOnboardingWorkerWarningModal,
  EnableJobsiteWorkerSelfOnboardingModal,
  DocumentExemptionModal,
} from 'containers/worker/modals';
import { LockedWorkerAlert } from './LockedWorkerAlert';

export function WorkerAssignments(props: WorkerTabProps): ReactElement {
  const { jobsiteWorkers, worker, isLocked, refetchWorkerData, onTabApiChange, openChangeWorkerAccessModal } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: { then: onEditSelfOnboardingWorkerConfirm },
    isModalOpen: isEditSelfOnboardingWorkerWarningModalOpen,
    openModal: confirmSelfOnboardingWorkerEdit,
    closeModal: closeEditSelfOnboardingWorkerWarningModal,
    resetModalValue: resetEditSelfOnboardingWorkerWarningModalValue,
  } = useModalState<{ then: () => void }>({ then: null });
  const {
    value: jobsiteWorkerToEdit,
    isModalOpen: isEditJobsiteWorkerModalOpen,
    openModal: openEditJobsiteWorkerModal,
    closeModal: closeEditJobsiteWorkerModal,
    resetModalValue: resetJobsiteWorkerToEdit,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToEnableSelfOnboardingFor,
    isModalOpen: isEnableJobsiteWorkerSelfOnboardingModalOpen,
    openModal: openEnableJobsiteWorkerSelfOnboardingModal,
    closeModal: closeEnableJobsiteWorkerSelfOnboardingModal,
    resetModalValue: resetJobsiteWorkerToEnableSelfOnboardingFor,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToChangeContractorFor,
    isModalOpen: isChangeContractorModalOpen,
    openModal: openChangeContractorModal,
    closeModal: closeChangeContractorModal,
    resetModalValue: resetJobsiteWorkerToChangeContractorFor,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToCorrectJobsiteAssignmentFor,
    isModalOpen: isCorrectJobsiteAssignmentModalOpen,
    openModal: openCorrectJobsiteAssignmentModal,
    closeModal: closeCorrectJobsiteAssignmentModal,
    resetModalValue: resetJobsiteWorkerToCorrectJobsiteAssignmentFor,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToExemptDocumentFor,
    isModalOpen: isDocumentExemptModalOpen,
    openModal: openDocumentExemptModal,
    closeModal: closeDocumentExemptModal,
    resetModalValue: resetJobsiteWorkerToExemptDocumentFor,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToDebugAccessFor,
    isModalOpen: isAccessDebugModalOpen,
    openModal: openAccessDebugModal,
    closeModal: closeAccessDebugModal,
    resetModalValue: resetJobsiteWorkerToDebugAccessFor,
  } = useModalState<JobsiteWorker>(null);
  const {
    value: jobsiteWorkerToEditClearancesFor,
    isModalOpen: isEditJobsiteClearancesModalOpen,
    openModal: openEditJobsiteClearancesModal,
    closeModal: closeEditJobsiteClearancesModal,
    resetModalValue: resetJobsiteWorkerToEditClearancesFor,
  } = useModalState<JobsiteWorker>(null);

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onCompleted: () => {
      AlertService.alert('success', 'Success', 'Access control synced!');
      refetchWorkerData();
    },
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  const { jobsiteContractor } = jobsiteWorkerToChangeContractorFor ?? {};
  const { jobsiteId } = jobsiteContractor?.jobsite ?? {};
  const { contractorId } = jobsiteContractor?.contractor ?? {};

  return (
    <>
      <div className="odin-flex odin-flex-col odin-gap-y-6 odin-pb-6">
        {jobsiteWorkers?.length ? (
          jobsiteWorkers.map(
            (jobsiteWorker): ReactElement => (
              <JobsiteWorkerCard
                worker={worker}
                jobsiteWorker={jobsiteWorker}
                key={`${jobsiteWorker?.jobsiteWorkerId}-worker-card`}
                openChangeWorkerAccessModal={openChangeWorkerAccessModal}
                openDocumentExemptModal={openDocumentExemptModal}
                openAccessDebugModal={openAccessDebugModal}
                openEditJobsiteClearancesModal={openEditJobsiteClearancesModal}
                syncWorkerAccess={(): void => {
                  updateJobsiteWorker({
                    variables: {
                      jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
                      jobsiteWorkerInput: {},
                    },
                  });
                }}
                confirmSelfOnboardingWorkerEdit={confirmSelfOnboardingWorkerEdit}
                openEditJobsiteWorkerModal={openEditJobsiteWorkerModal}
                openEnableJobsiteWorkerSelfOnboardingModal={openEnableJobsiteWorkerSelfOnboardingModal}
                openChangeContractorModal={openChangeContractorModal}
                openCorrectJobsiteAssignmentModal={openCorrectJobsiteAssignmentModal}
                disableActions={user.isContractor || !user.isAllowed(to.seeWorkerJobsiteActions)}
              />
            ),
          )
        ) : (
          <p>
            No matching jobsite assignments found. This worker may be assigned to a jobsite where you do not have access
            to.
          </p>
        )}
      </div>
      <EditSelfOnboardingWorkerWarningModal
        isOpen={isEditSelfOnboardingWorkerWarningModalOpen}
        closeModal={closeEditSelfOnboardingWorkerWarningModal}
        onConfirm={(): void => {
          onEditSelfOnboardingWorkerConfirm();
          closeEditSelfOnboardingWorkerWarningModal();
        }}
        onClosed={resetEditSelfOnboardingWorkerWarningModalValue}
      />
      <EditJobsiteWorkerModal
        isOpen={isEditJobsiteWorkerModalOpen}
        closeModal={closeEditJobsiteWorkerModal}
        onConfirm={closeEditJobsiteWorkerModal}
        onClosed={resetJobsiteWorkerToEdit}
        worker={worker}
        jobsiteWorker={jobsiteWorkerToEdit}
      />
      <EnableJobsiteWorkerSelfOnboardingModal
        isOpen={isEnableJobsiteWorkerSelfOnboardingModalOpen}
        closeModal={closeEnableJobsiteWorkerSelfOnboardingModal}
        onConfirm={closeEnableJobsiteWorkerSelfOnboardingModal}
        onClosed={resetJobsiteWorkerToEnableSelfOnboardingFor}
        jobsiteWorker={jobsiteWorkerToEnableSelfOnboardingFor}
        refetchQueries={[GetWorkerDataDocument]}
      />
      <ChangeContractorModal
        isOpen={isChangeContractorModalOpen}
        closeModal={closeChangeContractorModal}
        onClosed={resetJobsiteWorkerToChangeContractorFor}
        refetchQueries={[GetWorkerDataDocument]}
        jobsiteId={jobsiteId}
        contractorId={contractorId}
        jobsiteWorkers={[jobsiteWorkerToChangeContractorFor]}
      />
      <CorrectJobsiteAssignmentModal
        isOpen={isCorrectJobsiteAssignmentModalOpen}
        closeModal={closeCorrectJobsiteAssignmentModal}
        onClosed={resetJobsiteWorkerToCorrectJobsiteAssignmentFor}
        jobsiteWorker={jobsiteWorkerToCorrectJobsiteAssignmentFor}
        jobsiteWorkers={jobsiteWorkers}
      />

      <DocumentExemptionModal
        isOpen={isDocumentExemptModalOpen}
        closeModal={closeDocumentExemptModal}
        onClosed={resetJobsiteWorkerToExemptDocumentFor}
        jobsiteWorker={jobsiteWorkerToExemptDocumentFor}
      />
      <JsonTreeModal
        title={`Access for ${jobsiteWorkerToDebugAccessFor?.jobsiteContractor?.jobsite?.name}`}
        isOpen={isAccessDebugModalOpen}
        closeModal={closeAccessDebugModal}
        onClosed={resetJobsiteWorkerToDebugAccessFor}
        data={jobsiteWorkerToDebugAccessFor}
      />
      <EditJobsiteClearanceAssignmentsModal
        isOpen={isEditJobsiteClearancesModalOpen}
        closeModal={closeEditJobsiteClearancesModal}
        onClosed={resetJobsiteWorkerToEditClearancesFor}
        onConfirm={refetchWorkerData}
        jobsiteWorker={jobsiteWorkerToEditClearancesFor}
        worker={worker}
      />
    </>
  );
}
