import React, { ReactElement } from 'react';
import { Modal, Select } from '@odin-labs/components';
import { RequiredField } from 'utils/validation';
import { ArrowRightIcon } from 'components/icons';
import { SelectDocumentTypeModalProps } from './types';
import { useSelectDocumentTypeModalData } from './helpers/useSelectDocumentTypeModalData';

export function SelectDocumentTypeModal(props: SelectDocumentTypeModalProps): ReactElement {
  const { isOpen = false, onCancel, onConfirm, jobsiteWorkers, documentTypes } = props;

  const { jobsiteOptions, documentTypeOptions, jobsiteWorker, state, updateState, resetState } =
    useSelectDocumentTypeModalData({ jobsiteWorkers, documentTypes });

  const { selectedJobsite, selectedJobsiteError, selectedDocumentType, selectedDocumentTypeError } = state;

  const onActionHandler = (): void => {
    if (!selectedJobsite || !selectedDocumentType) {
      updateState({
        selectedJobsiteError: !selectedJobsite ? RequiredField`Jobsite` : '',
        selectedDocumentTypeError: !selectedDocumentType ? RequiredField`Document type` : '',
      });
      return;
    }

    updateState({ selectedJobsiteError: '', selectedDocumentTypeError: '' });
    onConfirm({ jobsiteId: selectedJobsite.value, documentType: selectedDocumentType.value, jobsiteWorker });
    resetState();
  };

  const onCancelHandler = (): void => {
    onCancel();
    resetState();
  };

  return (
    <Modal
      open={isOpen}
      title="Choose document type"
      onAction={onActionHandler}
      setOpen={onCancelHandler}
      actionText="Continue"
      actionIcon={ArrowRightIcon}
    >
      <div className="odin-space-y-5">
        <Select
          name="jobsite"
          label="Jobsite"
          value={selectedJobsite}
          options={jobsiteOptions}
          placeholder="Select a jobsite"
          isDisabled={!jobsiteOptions?.length || (jobsiteOptions.length === 1 && !!selectedJobsite)}
          isSearchable={false}
          onChange={(value): void =>
            updateState({
              selectedJobsite: value,
              selectedJobsiteError: '',
              selectedDocumentType: null,
              selectedDocumentTypeError: '',
            })
          }
          error={selectedJobsiteError}
        />
        <Select
          name="documentType"
          label="Document type"
          value={selectedDocumentType}
          options={documentTypeOptions}
          isDisabled={!documentTypeOptions.length}
          isSearchable={false}
          onChange={(value): void => updateState({ selectedDocumentType: value, selectedDocumentTypeError: '' })}
          error={selectedDocumentTypeError}
        />
      </div>
    </Modal>
  );
}
