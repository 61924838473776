import React, { ReactElement, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Button, Slideover, Table, TableContainer } from '@odin-labs/components';
import { to } from 'acl';
import {
  JobsiteWorkerDocumentVersion,
  QueryGetWorkerDocumentsArgs,
  GetWorkerDataDocument,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { EditIcon, PlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { CardTitle } from 'components/reactstrap';
import { MedicalDocumentCard } from 'components/cards/MedicalDocumentCard';
import { SelectDocumentTypeModal } from 'components/modals/SelectDocumentTypeModal';
import { UpdateWorkerMedicalNotesModal } from 'containers/worker/modals/updateWorkerMedicalNotesModal/UpdateWorkerMedicalNotesModal';
import { UploadMedicalDocumentModalContainer } from 'containers/modals';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import { GetWorkerDocumentsResponse, JobsiteWorker, WorkerTabProps } from 'containers/worker/types';
import { getFullNameForUser, useBoolean, useResettableState } from 'utils';
import { DocumentKey, getMedicalDocType } from 'containers/worker/utils';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { DocumentData, getColumns, toDocumentData } from './WorkerMedical.tables';

const uploadDocumentTypes: Record<string, string> = {
  [DocumentKey.MedicalDrugTestingConsentForm]: 'Drug Testing Consent Form',
  [DocumentKey.MedicalCovid19OrientationAndProcedures]: 'COVID-19 Orientation and Procedures',
  [DocumentKey.PostEmploymentBreathAlcoholTest]: 'Breath alcohol test',
  [DocumentKey.PostEmploymentUrineDrugTest]: 'Urine drug test',
};

const refetchQueries = [GET_WORKER_DOCUMENTS, GetWorkerDataDocument];

export function WorkerMedical(props: WorkerTabProps): ReactElement {
  const { worker, jobsiteWorkers, jobsiteIds, isLocked, refetchWorkerData, onTabApiChange } = props;
  const { currentUser: user } = React.useContext(AuthContext);

  const {
    value: isDocumentTypeModalVisible,
    setTrue: openDocumentTypeModal,
    setFalse: closeDocumentTypeModal,
  } = useBoolean(false);

  const [selectedJobsiteWorker, setSelectedJobsiteWorker] = useState<JobsiteWorker>(null);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const {
    value: jobsiteWorkerWithMedicalNotesToUpdate,
    setValue: openUpdateWorkerMedicalNotesModal,
    resetValue: closeUpdateWorkerMedicalNotesModal,
  } = useResettableState<JobsiteWorker>(null, null);
  const {
    value: selectedDocumentData,
    setValue: openSlideover,
    resetValue: closeSlideover,
  } = useResettableState<DocumentData>(null, null);
  const selectedDocument = selectedDocumentData?.document;

  const [jobsiteWorkerDocumentTypeKey, setJobsiteWorkerDocumentTypeKey] = useState<string>('');

  const { data: workerDocumentsData, loading: workerDocumentsLoading } = useQuery<
    GetWorkerDocumentsResponse,
    QueryGetWorkerDocumentsArgs
  >(GET_WORKER_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    skip: !worker?.workerId || isLocked,
    notifyOnNetworkStatusChange: true,
    variables: {
      workerId: worker?.workerId,
      includePhi: true,
      includeArchived: worker?.isArchived,
    },
  });

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  const columns = React.useMemo(() => getColumns(), []);

  const medicalDocuments = React.useMemo(() => {
    const filteredDocuments = workerDocumentsData?.getWorkerDocuments?.workerDocuments?.filter((jobsiteDocument) => {
      const { workerDocumentType, jobsite } = jobsiteDocument.jobsiteWorkerDocumentType ?? {};
      const { key: documentTypeKey } = workerDocumentType ?? {};
      const { jobsiteId: documentJobsiteId } = jobsite ?? {};
      return (
        (documentTypeKey?.includes('medical') || documentTypeKey?.includes('post-employment')) &&
        jobsiteIds.includes(documentJobsiteId) &&
        user.isAllowed(to.accessWorkerMedicalDocuments, documentJobsiteId)
      );
    });
    return filteredDocuments?.map(toDocumentData) ?? [];
  }, [workerDocumentsData, jobsiteIds, user]);

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  if (jobsiteIds?.length === 0) {
    return <p>You do not have access to this worker’s medical history.</p>;
  }

  const jobsiteWorkersWithMedicalNotes = jobsiteWorkers?.filter(
    (jw) => jw.medicalNotes && user.isAllowed(to.accessWorkerMedicalDocuments, jw.jobsiteContractor.jobsite.jobsiteId),
  );

  const onRowClickHandler = ({ data: documentData }: { data: DocumentData }): void => {
    openSlideover(documentData);
  };

  return (
    <>
      {!!jobsiteWorkersWithMedicalNotes.length && (
        <div className="odin-mb-3">
          {jobsiteWorkersWithMedicalNotes.map((jw) => (
            <Alert
              key={jw.jobsiteWorkerId}
              text={`Pre-existing condition: ${jw.medicalNotes}`}
              onAction={(): void => openUpdateWorkerMedicalNotesModal(jw)}
              actionText="Edit"
              actionIcon={EditIcon}
            />
          ))}
        </div>
      )}
      <TableContainer>
        <NewHeader
          size="md"
          title="Documents"
          titleInfo={medicalDocuments?.length ?? '...'}
          actionsProps={{
            headerActions: null,
            children: user.isAllowed(to.addWorkerMedicalDocuments) && !!jobsiteIds.length && (
              <Button
                size={['base', 'md:xs']}
                text="Add Document"
                hideTextOnMobile
                icon={PlusIcon}
                onClick={openDocumentTypeModal}
              />
            ),
          }}
        />
        <Table
          loading={workerDocumentsLoading}
          columns={columns}
          data={medicalDocuments}
          disableGlobalFilter
          disableSortBy
          initialState={{ pageSize: 50 }}
          // cellClassName="!odin-pl-5"
          onRowClick={onRowClickHandler}
          noResultsText="No medical documents to show"
        />
      </TableContainer>
      <Slideover open={!!selectedDocument} overlayCloseEnabled setOpen={closeSlideover}>
        <>
          <CardTitle as="h2" className="odin-mb-4.5">
            {getFullNameForUser(worker?.user)}
          </CardTitle>
          <CardTitle as="h3" className="odin-mb-3.5">
            {getMedicalDocType(selectedDocument?.jobsiteWorkerDocumentType?.workerDocumentType?.key)}
          </CardTitle>
          <hr className="odin-my-4" />
          <CardTitle as="h4" className="odin-mb-2.5">
            File history
          </CardTitle>
          <div className="odin-flex  odin-flex-col-reverse">
            {selectedDocument?.jobsiteWorkerDocumentVersions?.map(
              (documentVersion: JobsiteWorkerDocumentVersion): ReactElement => (
                <MedicalDocumentCard
                  key={documentVersion?.jobsiteWorkerDocumentVersionId}
                  medicalDocumentVersion={documentVersion}
                  documentTypeKey={selectedDocument?.jobsiteWorkerDocumentType.workerDocumentType.key}
                />
              ),
            )}
          </div>
        </>
      </Slideover>
      <UploadMedicalDocumentModalContainer
        isModalOpen={isUploadModalOpen}
        setModalOpen={setIsUploadModalOpen}
        jobsiteWorkerDocumentTypeKey={jobsiteWorkerDocumentTypeKey}
        jobsiteWorker={selectedJobsiteWorker}
      />
      <UpdateWorkerMedicalNotesModal
        isOpen={!!jobsiteWorkerWithMedicalNotesToUpdate}
        closeModal={closeUpdateWorkerMedicalNotesModal}
        onConfirm={closeUpdateWorkerMedicalNotesModal}
        refetchQueries={refetchQueries}
        jobsiteWorker={jobsiteWorkerWithMedicalNotesToUpdate}
      />
      <SelectDocumentTypeModal
        isOpen={isDocumentTypeModalVisible}
        jobsiteWorkers={jobsiteWorkers}
        documentTypes={uploadDocumentTypes}
        onCancel={closeDocumentTypeModal}
        onConfirm={({ jobsiteWorker: confirmedJobsiteWorker, documentType }): void => {
          setJobsiteWorkerDocumentTypeKey(documentType);
          setIsUploadModalOpen(true);
          setSelectedJobsiteWorker(confirmedJobsiteWorker);
          closeDocumentTypeModal();
        }}
      />
    </>
  );
}
