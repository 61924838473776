import React from 'react';
import { ApolloError } from '@apollo/client';
import { SendPasswordResetEmailMutation, useSendPasswordResetEmailMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { Card, CardBody, CardHeader, CardHeaderTitle } from 'components/reactstrap';
import { AlertService } from 'components/alertService';
import { ArchiveWorkerModal } from 'components/modals/ArchiveWorkerModal';
import { WorkerTabProps } from 'containers/worker/types';
import { useBoolean } from 'utils';
import { getGraphQLError } from 'utils/error';
import { CopyLink } from 'components/copyLink';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { WorkerCCureDataContainer } from './WorkerCCureDataContainer';
import { WorkerAdminAction } from './WorkerAdminAction';

export function WorkerAdminActions(props: WorkerTabProps): React.ReactElement {
  const { worker, refetchWorkerData, isLocked, onTabApiChange } = props;

  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [passwordResetUrl, setPasswordResetUrl] = React.useState<string>(null);
  const {
    value: isArchiveWorkerModalOpen,
    setTrue: openArchiveWorkerModal,
    setFalse: closeArchiveWorkerModal,
  } = useBoolean();

  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation({
    variables: { email: worker?.user?.identity?.email },
    onCompleted: async () => {
      setIsFetching(false);
      AlertService.alert(
        'success',
        'Password reset',
        'Please tell the user to check their email for a password reset link.',
      );
    },
    onError: async (resetError: ApolloError) => {
      setIsFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(resetError));
    },
  });

  const [acquirePasswordResetLink] = useSendPasswordResetEmailMutation({
    variables: { email: worker?.user?.identity?.email, sendForgotPasswordEmail: false },
    onCompleted: async (result: SendPasswordResetEmailMutation) => {
      setIsFetching(false);
      setPasswordResetUrl(result.sendPasswordResetEmail);
      AlertService.alert(
        'success',
        'Password reset link generated',
        'Use the Copy link to copy the link to your clipboard.',
      );
    },
    onError: async (resetError: ApolloError) => {
      setIsFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(resetError));
    },
  });

  const resetPassword = (): void => {
    if (!worker?.user?.identity?.email) {
      AlertService.alert('danger', 'Worker does not have an email address!', '');
      return;
    }

    if (isFetching) return;
    setIsFetching(true);
    sendPasswordResetEmail();
  };

  const generatePasswordResetLink = async (): Promise<void> => {
    if (!worker?.user?.identity?.email) {
      AlertService.alert('danger', 'Worker does not have an email address!', '');
      return;
    }

    if (isFetching) return;
    setIsFetching(true);
    await acquirePasswordResetLink();
  };

  const { currentUser: user } = React.useContext(AuthContext);
  const adminAccess = user.isAllowed(to.accessWorkerAdminSection);

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  if (!adminAccess) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderTitle as="h3">Admin actions</CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <div className="lg:odin-flex odin-gap-6">
            <div className="lg:odin-w-1/2 odin-space-y-8">
              <WorkerAdminAction label="Forgot password" text="Send reset" onClick={resetPassword} />
              <WorkerAdminAction
                label="Generate password reset link"
                text="Generate link"
                onClick={generatePasswordResetLink}
              >
                {passwordResetUrl && (
                  <CopyLink className="odin-ml-4 odin-block" link={passwordResetUrl} label="Copy password reset link" />
                )}
              </WorkerAdminAction>
              <WorkerAdminAction
                label="Archive worker"
                text="Archive"
                theme="danger"
                onClick={openArchiveWorkerModal}
              />
            </div>
            <div className="lg:odin-w-1/2 odin-space-y-8 odin-mt-8 lg:odin-mt-0">
              {user.isAllowed(to.seeWorkerCCureData) && <WorkerCCureDataContainer workerId={worker?.workerId} />}
            </div>
          </div>
        </CardBody>
      </Card>
      <ArchiveWorkerModal
        workerId={worker?.workerId}
        isOpen={isArchiveWorkerModalOpen}
        onCancel={closeArchiveWorkerModal}
        toggle={closeArchiveWorkerModal}
        onAction={(): void => {
          refetchWorkerData();
          closeArchiveWorkerModal();
        }}
      />
    </>
  );
}
