import React from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { useBoolean } from 'utils';
import { DefaultSidebarContainer } from 'containers/sidebar';
import { NavbarContainer, DefaultNavbarContent } from 'containers/navbar';
import { AppLayoutContentProps } from './types';

export function AppLayoutContent(props: AppLayoutContentProps): React.ReactElement {
  const { routes, currentRoute, pathName, currentSession } = props;
  const { value: isSidebarOpen, setTrue: openSidebar, setFalse: closeSidebar } = useBoolean();
  const {
    sidebarContainer: SidebarContainer = DefaultSidebarContainer,
    navbarContent: NavbarContent = DefaultNavbarContent,
    fullLayout,
    hideNavbar,
  } = currentRoute ?? {};

  return (
    <div className="odin-absolute odin-inset-0 md:odin-flex odin-bg-white">
      {!fullLayout && SidebarContainer && (
        <SidebarContainer
          routes={routes}
          currentRoute={currentRoute}
          pathname={pathName}
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />
      )}
      <div className="odin-flex odin-flex-col odin-w-full odin-relative odin-z-0 odin-overflow-y-auto">
        {!fullLayout && !hideNavbar && (
          <NavbarContainer openSidebar={openSidebar}>
            {NavbarContent && <NavbarContent currentRoute={currentRoute} pathname={pathName} />}
          </NavbarContainer>
        )}
        <main className="odin-h-full odin-w-full">{renderRoutes(routes as RouteConfig[], { currentSession })}</main>
      </div>
    </div>
  );
}
