import React from 'react';
import { AlertService } from 'components/alertService';
import { FormOnSubmit, ModalForm } from 'components/form';
import { getGraphQLError, getGraphQLErrorByCode } from 'utils/error';
import {
  UserErrorCode,
  UserPendingChangeType,
  useUserUpdateIdentityMutation,
} from 'apollo/generated/client-operations';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { SendIcon } from 'components/icons';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { getDefaultValues, getFormInputs, getPendingChangeInput } from './SubmitUserChangeModal.forms';
import { SubmitUserChangeModalProps, SubmitUserChangeFormData } from './types';

const changeTypeText: Record<UserPendingChangeType, string> = {
  phone: 'phone number',
  email: 'email address',
};

const alertChangeTypeText: Record<UserPendingChangeType, string> = {
  phone: 'Phone number',
  email: 'Email address',
};

const subtitleText: Record<UserPendingChangeType, string> = {
  phone: 'The worker will receive a text message asking them to validate the phone number.',
  email: 'The worker will receive an email asking them to validate the email address.',
};

export function SubmitUserChangeModal(props: SubmitUserChangeModalProps): React.ReactElement {
  const { isOpen = false, onCancel, onClosed, worker, refetch, type } = props;
  const { pendingChanges } = worker?.user.identity ?? {};

  const [isSaving, setIsSaving] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const [updateUserIdentity] = useUserUpdateIdentityMutation();

  const onSubmit: FormOnSubmit<SubmitUserChangeFormData> = async (data, event, dirtyFields, formApi): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    const input = getPendingChangeInput(worker, data, dirtyFields);

    try {
      if (input.userIdentityInput) {
        await updateUserIdentity({ variables: { input } });
      }

      AlertService.alert('success', 'Success', `${alertChangeTypeText[type]} change successfully submitted`);
      setIsSaving(false);
      onCancel();
      refetch();
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);

      const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.EmailAlreadyExists,
      );
      if (emailAlreadyExistsError) {
        formApi.setError('newEmail', {
          message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }
      const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
        error,
        UserErrorCode.PhoneAlreadyExists,
      );
      if (phoneAlreadyExistsError) {
        formApi.setError('newPhoneNumber', {
          message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
          shouldFocus: true,
        });
        return;
      }
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const formInputs = React.useMemo(() => getFormInputs({ type, pendingChanges }), [type, pendingChanges]);
  const defaultValues = React.useMemo(() => getDefaultValues(worker), [worker]);

  const getCancelText = (): string => {
    if (
      (type === UserPendingChangeType.Email && pendingChanges?.email) ||
      (type === UserPendingChangeType.Phone && pendingChanges?.phoneNumber)
    ) {
      return 'Close';
    }
    return undefined;
  };

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      afterLeave={onClosed}
      onIsDirtyChange={setIsFormDirty}
      title={`Change ${changeTypeText[type]}`}
      subtitle={subtitleText[type]}
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-12 odin-gap-6"
      actionText="Send confirmation"
      actionIcon={SendIcon}
      actionButtonEnabled={isFormDirty}
      actionButtonWithSpinner={isSaving}
      cancelText={getCancelText()}
    />
  );
}
