import React from 'react';
import cn from 'classnames';

const classes = {
  container: (extraClasses: string): string =>
    cn(
      extraClasses,
      'reactstrap-container',
      'odin-w-full md:odin-mx-auto odin-px-3',
      'md:odin-max-w-[720px] lg:odin-max-w-[960px] xl:odin-max-w-7xl',
      'print:odin-max-w-full',
    ),
};

export type ContainerProps = React.PropsWithChildren<Partial<Pick<HTMLDivElement, 'className'>>>;

export function Container(props: ContainerProps): React.ReactElement {
  const { children, className } = props;
  return <div className={classes.container(className)}>{children}</div>;
}
