import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import { TrashIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { useContractorRemoveWorkerMutation } from 'apollo/generated/client-operations';
import { RemoveContractorWorkerModalProps } from './types';

export function RemoveContractorWorkerModal(props: RemoveContractorWorkerModalProps): ReactElement {
  const { isOpen, contractorWorkerId, onCancel, onConfirm, refetchQueries } = props;

  const [contractorRemoveWorker, { loading }] = useContractorRemoveWorkerMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onConfirm();
      AlertService.alert('success', 'Success', 'Contractor user successfully removed');
    },
    onError: (responseError: ApolloError) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: { input: { contractorWorkerId } },
    refetchQueries,
  });

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title="Delete this Contractor Assignment?"
      titleAlignment="center"
      text={
        "Deleting this worker's contractor assignment will mean the worker " +
        'is no longer affiliated with this contractor, and the worker may no longer be visible to you in Odin. ' +
        "Are you sure you want to delete this worker's contractor assignment?"
      }
      textAlignment="center"
      onAction={contractorRemoveWorker}
      actionText="Delete Assignment"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={loading}
    />
  );
}
