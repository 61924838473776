import { WorkerDocumentFormModalContainer } from './WorkerDocumentFormModalContainer';
import { UploadMedicalDocumentModalContainer } from './UploadMedicalDocumentModalContainer';
import { UpdateProfilePictureModalContainer } from './UpdateProfilePictureModalContainer';

const name = 'ModalContainers';

export {
  WorkerDocumentFormModalContainer,
  UploadMedicalDocumentModalContainer,
  UpdateProfilePictureModalContainer,
  name,
};
