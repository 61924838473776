import React from 'react';
import { Button, Spinner } from '@odin-labs/components';
import {
  InputFile,
  SelfOnboardingServerFile,
  ReviewCompletedProfiledTabProps,
  SelfOnboardingDocumentFormData,
  SelfOnboardingStepKey,
} from 'containers/selfOnboarding/types';
import { PencilIcon } from 'components/icons';
import { FilePreview, useFilePreview } from 'components/filePreview';
import { getDocumentInputsConfig } from 'containers/selfOnboarding/steps/documentStep';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { FileType } from 'apollo/generated/client-operations';
import { PdfPreview } from 'components/thumbnail/PdfPreview';
import { getInfoRows } from './utils';

export const isServerFile = (file: InputFile): file is SelfOnboardingServerFile => file && !(file instanceof File);

export type ReviewCompletedProfileDocumentProps = {
  document: SelfOnboardingDocumentFormData;
  loading: boolean;
} & Pick<ReviewCompletedProfiledTabProps, 'navigation' | 'localize'>;

export function ReviewCompletedProfileDocument(props: ReviewCompletedProfileDocumentProps): React.ReactElement {
  const { document, loading, navigation, localize } = props;
  const { goToStep } = navigation;
  const { titleCopy } = getDocumentInputsConfig(document.key);

  const documentStepKey = document.key as string as SelfOnboardingStepKey;
  const documentDetailsRows = getInfoRows(document, localize);

  const file = document.frontFile;
  const { fileUrl, isLoading } = useFilePreview(file);

  const getPreviewContent = (): React.ReactNode => {
    if (isLoading) {
      return (
        <div className="odin-h-52 odin-flex odin-items-center odin-justify-center">
          <Spinner size="base" className="odin-text-odin-primary" />
        </div>
      );
    }

    const isPdf = isServerFile(file) ? file.fileType === FileType.Pdf : file?.type.includes('pdf');
    if (isPdf) {
      return <PdfPreview isThumbnail fileURL={fileUrl} alt={document.key} />;
    }

    return (
      <FilePreview
        className="odin-w-full odin-h-52 odin-rounded-md odin-object-contain"
        file={file}
        alt={document.key}
      />
    );
  };

  return (
    <div className="odin-rounded-md odin-shadow-odin-normal odin-p-6 odin-space-y-6">
      <div className="odin-flex odin-justify-between">
        <h3 className="odin-text-base odin-font-medium odin-text-gray-900">{localize(titleCopy)}</h3>
        <Button theme="naked" size="sm" onClick={(): void => goToStep(documentStepKey)}>
          <PencilIcon className="odin-h-4 odin-w-4" />
        </Button>
      </div>
      {getPreviewContent()}
      <InfoList className="odin-space-y-3" loading={loading} noEdit noPadding noBorders noLgResponsiveness>
        {documentDetailsRows.map((row) => (
          <InfoRow key={row.label} {...row} />
        ))}
      </InfoList>
    </div>
  );
}
