import React from 'react';
import cn from 'classnames';
import { Logo, MenuItemProps, DropdownButton } from '@odin-labs/components';
import { ArrowLeftIcon, GlobeIcon } from 'components/icons';
import { SelfOnboardingStepProps } from './types';
import { Copy } from './helpers/languages';
import { languageOptions } from './utils';

export type SelfOnboardingHeaderProps = Pick<
  SelfOnboardingStepProps,
  'language' | 'onLanguageChange' | 'localize' | 'navigation'
> & {
  canGoToPreviousStep: boolean;
};
export type LanguageMenuItem = MenuItemProps & Pick<SelfOnboardingHeaderProps, 'language'>;

const classes = {
  container: cn(
    'odin-sticky odin-top-0 odin-z-40',
    'odin-bg-odin-primary',
    'odin-flex odin-justify-between odin-items-center',
    'odin-px-7.5 odin-py-6.5',
    'print:odin-hidden',
  ),
  innerContainer: 'odin-mx-auto odin-w-full odin-max-w-7xl odin-flex odin-justify-between',
  leftContainer: 'odin-flex odin-items-center',
  logoHolder: cn('odin-flex odin-justify-start'),
};

export function SelfOnboardingHeader(props: SelfOnboardingHeaderProps): React.ReactElement {
  const { language, onLanguageChange, localize, navigation, canGoToPreviousStep } = props;
  const { goToPreviousStep } = navigation;

  const menuItems: LanguageMenuItem[] = languageOptions.map((opt) => ({
    onClick: (): void => onLanguageChange(opt),
    text: localize(Copy.language, { locale: opt.value }),
    language: opt.value,
  }));

  const languageText = menuItems.find((mi) => mi.language === language)?.text;

  return (
    <header className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          {canGoToPreviousStep && (
            <button onClick={goToPreviousStep}>
              <ArrowLeftIcon className="odin-text-white odin-text-2.33xl odin-mr-5" />
            </button>
          )}
          <div className={classes.logoHolder}>
            <span className="sr-only">ODIN</span>
            <Logo logoStyle="textOnly" size="md" />
          </div>
        </div>
        <DropdownButton
          menuItems={menuItems}
          icon={GlobeIcon}
          text={languageText}
          size="sm"
          theme="twoTone"
          hideFocusBorder
        />
      </div>
    </header>
  );
}
