import React from 'react';
import { Modal } from '@odin-labs/components';
import { UserAssignmentType, useUserRemoveAssignmentMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { TrashIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { RemoveContractorUserModalProps } from './types';

export function RemoveUserRoleModal(props: RemoveContractorUserModalProps): React.ReactElement {
  const { isOpen, onCancel, onConfirm, refetchQueries, userRoleType, memberUserAssignmentId, memberUserAccountId } =
    props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [removeUserAssignment] = useUserRemoveAssignmentMutation({
    fetchPolicy: 'no-cache',
    refetchQueries,
  });

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await removeUserAssignment({
        variables: {
          input: { userAssignmentType: userRoleType, userAssignmentId: memberUserAssignmentId },
        },
      });

      if (userRoleType === UserAssignmentType.Jobsite && user.userAccountId === memberUserAccountId) {
        useAvailableJobsites.resetCacheOnNextCall();
      }
      onConfirm();
      AlertService.alert('success', 'Success', 'User role successfully removed');
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      size="xs"
      title={"Are you sure you want \nto remove this user's role?"}
      titleAlignment="center"
      text={`This will remove access to this ${userRoleType} for this user.`}
      textAlignment="center"
      onAction={onAction}
      actionText="Remove User Role"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionButtonWithSpinner={isSaving}
    />
  );
}
