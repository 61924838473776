import React from 'react';
import cn from 'classnames';
import { Form, FormOnSubmit } from 'components/form';
import { useStartPhoneNumberVerificationMutation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
  SelfOnboardingVerificationPhoneNumberFormData,
} from 'containers/selfOnboarding/steps/types';
import { StepInfo, StepActions, StepJobsiteInfo, GradientCard } from 'containers/selfOnboarding/steps/components';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { getPhoneNumberAsE164 } from 'utils';
import { MobileSignalIcon } from 'components/icons';

export function VerifyMobileDeviceToSignInStartStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, jobsiteInvitation, navigation, stepConfig, localize, language } = props;
  const { matchedWorker, verificationPhoneNumber, basicInfo, contractorId } = state;
  const { workerId: matchedWorkerId } = matchedWorker ?? {};
  const phoneNumber = verificationPhoneNumber ?? basicInfo?.phoneNumber;
  const { goToStep, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;

  const [isSaving, setIsSaving] = React.useState(false);
  const [startPhoneNumberVerification] = useStartPhoneNumberVerificationMutation();

  const onSubmit: FormOnSubmit<SelfOnboardingVerificationPhoneNumberFormData> = async (data, event): Promise<void> => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    try {
      const result = await startPhoneNumberVerification({
        variables: { input: { workerId: matchedWorkerId, phoneNumber: getPhoneNumberAsE164(phoneNumber) } },
      });
      updateState({ verificationPhoneNumber: phoneNumber });

      setIsSaving(false);
      if (result.data.startPhoneNumberVerification.success) {
        goToStep(SelfOnboardingStepKey.ConfirmMobileDeviceToSignIn);
      }
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);

      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    }
  };

  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);
  const phoneLast4Digits = phoneNumber?.slice(-4);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo showOnMobileOnly>
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} contractorName={contractorId?.label} />
      </StepInfo>
      <div className={commonClasses.stepHeader}>
        <GradientCard
          headerText={localize(Copy.self_onboarding_verify_phone_to_sign_in_header)}
          text={localize(Copy.self_onboarding_verify_phone_to_sign_in_instructions)}
        />
      </div>
      <Form
        inputs={null}
        defaultValues={null}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingVerificationPhoneNumberFormData) ?? true}
        renderBelow={
          <StepActions
            jobsiteInvitation={jobsiteInvitation}
            localize={localize}
            isReview={isReview}
            continueActionWithSpinner={isSaving}
            continueActionText={`${localize(Copy.self_onboarding_verify_phone_to_sign_in_action)} ${phoneLast4Digits}`}
            continueActionIcon={MobileSignalIcon}
            continueActionEnabled={!!phoneNumber}
            onSkip={(): void => goToStep(SelfOnboardingStepKey.UnableToVerifyAccount)}
            skipActionCopy={Copy.self_onboarding_verify_phone_to_sign_in_skip}
            skipActionIcon={null}
            ignoreJobsiteConfigSkipFlag
          />
        }
        validationTriggers={[language]}
        className={cn(commonClasses.form, 'odin-col-start-2')}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
