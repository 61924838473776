import React from 'react';
import { InfoList } from 'components/infoList';
import { InfoRow } from 'components/infoRow';
import { Dropzone } from 'components/dropzone';
import { ReviewCompletedProfiledTabProps } from 'containers/selfOnboarding/steps/types';
import { ReviewProfilePhotoContainer } from 'containers/selfOnboarding/steps/reviewCompletedProfileStep/components';
import { getInfoRows } from './utils';

export function ReviewCompletedProfileWorkerInfoTab(props: ReviewCompletedProfiledTabProps): React.ReactElement {
  const { state, loading, navigation, localize, jobsiteInvitation } = props;
  const { file } = state.profilePhoto ?? {};
  const { goToStep } = navigation;

  const infoRows = React.useMemo(
    () => getInfoRows({ state, localize, jobsiteInvitation }),
    [state, localize, jobsiteInvitation],
  );

  return (
    <div className="odin-flex odin-gap-x-10">
      <div className="odin-hidden sm:odin-block odin-shrink-0">
        <ReviewProfilePhotoContainer navigation={navigation}>
          <Dropzone
            name="profilePicture"
            value={file}
            isAvatar
            avatarSize="lg"
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
            hideAvatarChangePhotoButton
          />
        </ReviewProfilePhotoContainer>
      </div>
      <InfoList withBottomBorder loading={loading}>
        {infoRows.map((row) => (
          <InfoRow
            key={row.label}
            allowEdit
            {...row}
            onEditClick={(): void => goToStep(row.editStep, { state: { autoFocusField: row.editField } })}
          />
        ))}
      </InfoList>
    </div>
  );
}
