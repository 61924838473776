import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { stringifyEmptyFields } from 'utils';
import { EditSiteAreaFormData } from 'containers/jobsiteConfiguration/types';

export const getAsNumber = (value: string | number): number => {
  if (!value) return null;
  if (typeof value === 'number') return value;
  return Number(value.replace('\u00B0', ''));
};

export const getFormInputs = (): TypedFormInputs<EditSiteAreaFormData> => ({
  name: {
    element: FormInputTypes.Field,
    label: 'Name',
    layout: [GridColSpan.SpanFull],
    validation: { required: true },
  },
  radius: {
    element: FormInputTypes.Field,
    label: 'Radius in Meters',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    elementProps: { fieldType: 'number' },
    validation: { required: true },
  },
  latitude: {
    element: FormInputTypes.Field,
    label: 'Latitude',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
  },
  longitude: {
    element: FormInputTypes.Field,
    label: 'Longitude',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
  },
});

export const getDefaultValues = (siteArea?: EditSiteAreaFormData): EditSiteAreaFormData => {
  const { name, radius, latitude, longitude } = siteArea ?? {};
  return stringifyEmptyFields({ name, radius, latitude, longitude });
};
