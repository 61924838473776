import React from 'react';
import { Badge, Tooltip, TooltipPanel } from '@odin-labs/components';
import { getFullNameForUser } from 'utils';
import { MobileIcon } from 'components/icons';
import { Worker } from 'containers/worker/types';

export function WorkerTitle({ worker }: { worker: Worker }): React.ReactElement {
  return (
    <div>
      <h1 className="odin-text-xl odin-font-bold odin-text-odin-black sm:odin-text-2xl sm:odin-truncate">
        {getFullNameForUser(worker?.user)}
      </h1>
      <div className="odin-flex odin-gap-x-1.5 odin-mb-1.5 odin-mt-3">
        <Badge className="odin-font-normal" color="indigo" type="rounded" text={worker?.quickCode} size="xl" />
        {worker?.user?.isMobileUser && (
          <Tooltip placement="right">
            <span
              className={`odin-inline-flex odin-items-center odin-p-2 odin-rounded-md 
              odin-font-normal odin-text-xl odin-text-gray-800 odin-bg-gray-200`}
            >
              <MobileIcon />
            </span>
            <TooltipPanel>Worker app installed</TooltipPanel>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
