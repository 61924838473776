import cn from 'classnames';

export const classes = {
  jobsiteWorkerField: 'odin-flex',
  jobsiteWorkerFieldLabel: cn(
    'jobsite-worker-field-label',
    'odin-w-5/12',
    'odin-text-sm',
    'odin-font-medium',
    'odin-mb-2.5',
  ),
  jobsiteWorkerFieldValue: cn('jobsite-worker-field-value', 'odin-w-7/12', 'odin-text-sm', 'odin-ml-4'),
  siteAccessText: cn('odin-text-1.5xs odin-text-odin-dark-gray'),
  footerStatus: cn('odin-text-sm odin-font-medium'),
  footerLabel: cn('odin-text-sm odin-font-normal !odin-text-[#12263f]'),
};
