import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { JobsiteWorkerOrientationStatus } from 'apollo/generated/client-operations';
import { classes } from 'components/jobsiteWorkerCard/JobsiteWorkerCard.style';
import { JobsiteWorkerCardStatusProps } from 'components/jobsiteWorkerCard/types';
import { Status } from 'components/status/Status';
import { getISODateTimeToFormattedDate } from 'utils/dates';

export function JobsiteWorkerDocumentStatus(props: JobsiteWorkerCardStatusProps): ReactElement {
  const { worker, jobsiteWorker, disableActions, showLabel, confirmSelfOnboardingWorkerEdit } = props;
  const { nycSstExemptionReason, documentsCompletedAt, documentsCompletedSkipExpiresAt, documentsCompletedSkipReason } =
    jobsiteWorker;
  const history = useHistory();

  const goToWorkerDocuments = React.useCallback((): void => {
    history.push(`/worker/${worker.workerId}/documents`);
  }, [history, worker]);

  const goToOnboardingWorkDocuments = React.useCallback((): void => {
    history.push(`/onboarding/${jobsiteWorker?.jobsiteWorkerId}/work-documents`);
  }, [history, jobsiteWorker]);

  const getSubLabel = ({
    text,
    onClick,
  }: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
  }): ReactElement => (
    <span className="odin-underline odin-cursor-pointer odin-text-1.5xs odin-text-odin-primary" onClick={onClick}>
      {text}
    </span>
  );

  let label: ReactElement;
  let status: 'warning' | 'success' | 'default' | 'danger';
  let subLabel: ReactElement;

  if (jobsiteWorker.orientationStatus === JobsiteWorkerOrientationStatus.SelfOnboarding) {
    label = <p>Self-Onboarding</p>;
    status = 'warning';
    subLabel =
      showLabel &&
      getSubLabel({
        text: 'Add documents',
        onClick: () => confirmSelfOnboardingWorkerEdit({ then: goToOnboardingWorkDocuments }),
      });
  } else if (documentsCompletedAt && documentsCompletedSkipReason == null) {
    label = <p>Complete</p>;
    subLabel = showLabel && getSubLabel({ text: 'View documents', onClick: goToWorkerDocuments });
    status = 'success';
  } else if (documentsCompletedSkipExpiresAt) {
    label = <p>Expired</p>;
    subLabel = showLabel && <span>Expired {getISODateTimeToFormattedDate(documentsCompletedSkipExpiresAt)}</span>;
    status = 'danger';
  } else if (documentsCompletedSkipReason || nycSstExemptionReason) {
    label = <p>Exempt</p>;
    subLabel = showLabel && <span>{documentsCompletedSkipReason ?? nycSstExemptionReason}</span>;
    status = 'warning';
  } else {
    label = <p>Not started</p>;
    status = 'danger';
    subLabel = showLabel && getSubLabel({ text: 'Add documents', onClick: goToOnboardingWorkDocuments });
  }

  return (
    <>
      <Status label={label} status={status} className={classes.footerLabel} />
      {!disableActions && subLabel}
    </>
  );
}
