import React from 'react';
import { Button, Table } from '@odin-labs/components';
import { PlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import { languageOptions } from 'containers/jobsiteConfiguration/helpers/utils';
import { DeleteDocumentFileModal, EditDocumentFileModal, EditableDocumentFile, AddDocumentFileModal } from './modals';
import { DocumentFilesProps } from './types';
import { getColumns, DocumentFileColumn } from './DocumentFiles.tables';

export const DocumentFiles = React.forwardRef(
  (props: DocumentFilesProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { value, onChange, loading, accept } = props;

    const { change, closeModal, openAddModal, openEditModal, openDeleteModal, updateValueAndCloseModal } =
      useModalChangeActions<EditableDocumentFile>({ items: value, onChange, idField: 'id' });

    const columns = React.useMemo<DocumentFileColumn[]>(
      () => getColumns({ openEditModal, openDeleteModal }),
      [openEditModal, openDeleteModal],
    );

    const items = React.useMemo(() => {
      return value?.filter((item) => item.changeType !== 'removed');
    }, [value]);

    const availableLanguageOptions = React.useMemo(() => {
      if (change?.type === 'create') {
        return languageOptions.filter((opt) => !items?.some((item) => item.language === opt.value));
      }
      return languageOptions;
    }, [items, change?.type]);

    return (
      <>
        <div ref={ref} className="odin-flex odin-flex-col odin-gap-y-6">
          <NewHeader
            title="Files"
            size="md"
            actionsProps={{
              headerActions: null,
              children: <Button icon={PlusIcon} text="Add File" size="sm" onClick={openAddModal} />,
            }}
          />
          <Table loading={loading} columns={columns} data={items} remote disableGlobalFilter disableSortBy />
        </div>
        <AddDocumentFileModal
          isOpen={change?.type === 'create'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          languageOptions={availableLanguageOptions}
          accept={accept}
        />
        <EditDocumentFileModal
          isOpen={change?.type === 'update'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          documentFile={change?.item}
          languageOptions={availableLanguageOptions}
          accept={accept}
        />
        <DeleteDocumentFileModal
          isOpen={change?.type === 'remove'}
          onCancel={closeModal}
          onConfirm={updateValueAndCloseModal}
          documentFile={change?.item}
        />
      </>
    );
  },
);
