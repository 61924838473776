import React, { ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import { Modal } from '@odin-labs/components';
import { useDeleteOrganizationMutation } from 'apollo/generated/client-operations';
import { useHistory } from 'react-router';
import { AlertService } from 'components/alertService';
import { getGraphQLError } from 'utils/error';
import { TrashIcon } from 'components/icons';
import { DeleteOrganizationModalProps } from './types';

export function DeleteOrganizationModal(props: DeleteOrganizationModalProps): ReactElement {
  const { isOpen, closeModal, organization } = props;
  const { organizationId, name } = organization ?? {};
  const history = useHistory();

  const [deleteOrganization, { loading }] = useDeleteOrganizationMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      closeModal();
      history.push(`/organizations`);
      AlertService.alert('success', 'Success', 'Organization / Contractor deleted successfully');
    },
    onError: (responseError: ApolloError) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(responseError));
    },
    variables: { organizationId },
  });

  return (
    <Modal
      open={isOpen}
      setOpen={closeModal}
      size="xs"
      title={`Delete "${name}" Organization / Contractor?`}
      titleAlignment="center"
      textAlignment="center"
      onAction={deleteOrganization}
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      actionText="Delete Organization"
      actionButtonWithSpinner={loading}
    />
  );
}
