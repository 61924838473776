import React from 'react';
import { Modal } from '@odin-labs/components';
import {
  UserPendingChangeType,
  UserIdentityPendingChangesInput,
  useUserUpdateIdentityMutation,
} from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { ensureNonUndefinedFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { TrashIcon } from 'components/icons';
import { CancelUserChangeModalProps } from './types';

const alertChangeTypeText: Record<UserPendingChangeType, string> = {
  phone: 'Phone number',
  email: 'Email address',
};

export function CancelUserChangeModal(props: CancelUserChangeModalProps): React.ReactElement {
  const { isOpen, onCancel, onClosed, onConfirm, type, worker, refetch } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [updateUserIdentity] = useUserUpdateIdentityMutation({
    fetchPolicy: 'no-cache',
  });

  const onAction = async (): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await updateUserIdentity({
        variables: {
          input: {
            userAccountId: worker.user.userAccountId,
            userIdentityInput: {
              pendingChanges: ensureNonUndefinedFields<UserIdentityPendingChangesInput>({
                phoneNumber: type === UserPendingChangeType.Phone ? null : undefined,
                email: type === UserPendingChangeType.Email ? null : undefined,
              }),
            },
          },
        },
      });

      onConfirm();
      refetch();
      AlertService.alert('success', 'Success', `${alertChangeTypeText[type]} pending change successfully cancelled`);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={onCancel}
      afterLeave={onClosed}
      size="xs"
      title="Are you sure you want to cancel this change?"
      titleAlignment="center"
      textAlignment="center"
      onAction={onAction}
      actionText="Yes, Cancel change"
      actionButtonTheme="danger"
      actionIcon={TrashIcon}
      cancelText="Close"
      actionButtonWithSpinner={isSaving}
    />
  );
}
