import React, { useState, ReactElement } from 'react';
import { UserAssignmentType, useUserAssignMutation } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { isEmpty, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { UserPlusIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { getAddInput, getDefaultValues, getFormInputsHook } from './AddUserRoleModal.forms';
import { EditUserRoleFormData, AddUserRoleModalProps } from './types';
import { useUserModalOptions } from './useUserModalOptions';

export function AddUserRoleModal(props: AddUserRoleModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm, workerId, memberUserAccountId, userRoleType, userRoles, assignedObjectIds } =
    props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const [assignMember] = useUserAssignMutation();

  const onSubmit: FormOnSubmit<EditUserRoleFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      if (isEmpty(dirtyFields)) return;
      const input = getAddInput(memberUserAccountId, data, dirtyFields);
      await assignMember({ variables: { input } });

      if (userRoleType === UserAssignmentType.Jobsite && user.userAccountId === memberUserAccountId) {
        useAvailableJobsites.resetCacheOnNextCall();
      }
      onConfirm();
      AlertService.alert('success', 'Success', 'User role successfully added');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) setIsSaving(false);
    }
  };

  const { userRolesOptions, contractorsOptions, jobsitesOptions, developersOptions, error } = useUserModalOptions({
    userRoleType,
    userRoles,
    workerId,
    assignedObjectIds,
  });

  const formInputs = getFormInputsHook({
    editType: 'create',
    userRoleType,
    userRolesOptions,
    jobsitesOptions,
    contractorsOptions,
    developersOptions,
  });
  const defaultValues = React.useMemo(
    () => getDefaultValues({ userRolesOptions, jobsitesOptions, contractorsOptions, developersOptions }),
    [userRolesOptions, jobsitesOptions, contractorsOptions, developersOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add User Role"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add User Role"
      actionIcon={UserPlusIcon}
      actionButtonWithSpinner={isSaving}
      error={error}
    />
  );
}
