import React, { useState, ReactElement } from 'react';
import {
  GetContractorContainerContractorJobsitesDocument,
  GetContractorContainerContractorSubcontractorsDocument,
  useContractorAddJobsitesMutation,
} from 'apollo/generated/client-operations';
import { PlusIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { getJobsitesOptions } from './utils';
import {
  getDefaultValues,
  getFormInputsHook,
  EditJobsiteAssignmentFormData,
  getAddInput,
} from './AddJobsiteAssignmentModal.forms';
import { AddJobsiteAssignmentModalProps } from './types';

export function AddJobsiteAssignmentModal(props: AddJobsiteAssignmentModalProps): ReactElement {
  const { isOpen, contractor, jobsites, onCancel, onConfirm } = props;

  const isMounted = useIsMounted();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [contractorAddJobsites] = useContractorAddJobsitesMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [
      GetContractorContainerContractorSubcontractorsDocument,
      GetContractorContainerContractorJobsitesDocument,
    ],
  });

  const onSubmit: FormOnSubmit<EditJobsiteAssignmentFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const input = getAddInput(data, dirtyFields);
      await contractorAddJobsites({ variables: { input } });

      onConfirm();
      AlertService.alert('success', 'Success', 'Jobsite Assignment successfully added');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const { contractorsOptions, jobsitesOptions } = React.useMemo(
    () => ({
      contractorsOptions: getContractorsOptions([contractor]),
      jobsitesOptions: getJobsitesOptions(jobsites),
    }),
    [contractor, jobsites],
  );

  const formInputs = getFormInputsHook({
    editType: 'create',
    contractorId: contractor.contractorId,
    contractorsOptions,
    jobsitesOptions,
    isModalOpen: isOpen,
  });

  const defaultValues = React.useMemo(
    () => getDefaultValues({ contractorsOptions, jobsitesOptions }),
    [contractorsOptions, jobsitesOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Jobsite Assignment"
      inputs={formInputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-6 odin-gap-6"
      actionText="Add Assignment"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isSaving}
    />
  );
}
