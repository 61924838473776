import { Button } from '@odin-labs/components';
import { InfoRowProps } from 'components/infoRow/types';
import { OrganizationTabProps } from 'containers/organization/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { getPrettyFormattedDate } from 'utils/dates';
import { ensureNonEmptyItems } from 'utils';
import { DeveloperIcon } from 'containers/developer/types';
import { ContractorIcon } from 'containers/contractor/types';
import { LandmarkIcon, PlusIcon, WrenchIcon } from 'components/icons';

export type OrganizationInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

const getName = ({ firstName, lastName }: { firstName?: string; lastName?: string } = {}): string =>
  [firstName, lastName].filter(Boolean).join(' ');

export function getInfoRows(props: OrganizationTabProps): OrganizationInfoRowConfig[] {
  const { organization, openAddDeveloperModal, openAddContractorModal } = props;
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    email,
    phoneNumber,
    name,
    legalName,
    slug,
    objectHistory,
    contractors,
    developers,
  } = organization ?? {};

  const createdByIdentity = objectHistory?.createdBy?.identity;
  const { count: contractorCount, edges: contractorEdge } = contractors ?? {};
  const { count: developerCount, edges: developerEdge } = developers ?? {};
  const contractorLink =
    contractorCount && contractorEdge?.length ? (
      <Link to={`/contractor/${contractorEdge[0].node.contractorId}`}>
        <ContractorIcon className="odin-mr-2" />
        Details...
      </Link>
    ) : (
      <Button text="Add Contractor" icon={PlusIcon} onClick={openAddContractorModal} rightIcon={WrenchIcon} />
    );
  const developerLink =
    developerCount && developerEdge?.length ? (
      <Link to={`/client/${developerEdge[0].node.developerId}`}>
        <DeveloperIcon className="odin-mr-2" />
        Details...
      </Link>
    ) : (
      <Button text="Add Client" icon={PlusIcon} onClick={openAddDeveloperModal} rightIcon={LandmarkIcon} />
    );
  const {} = organization ?? {};
  const organizationAddress = organization && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  return ensureNonEmptyItems([
    contractorLink ? { label: 'Contractor', value: contractorLink } : null,
    developerLink ? { label: 'Client', value: developerLink } : null,
    { label: 'Display Name', value: name },
    { label: 'Legal Name', value: legalName },
    { label: 'Slug', value: slug },
    { label: 'Email', value: email },
    { label: 'Phone', value: phoneNumber },
    { label: 'Address', value: organizationAddress },
    { label: 'Created By', value: getName(createdByIdentity) },
    { label: 'Created On', value: getPrettyFormattedDate(objectHistory?.createdAt), withBottomBorder: true },
  ]);
}
