import { LoadingError } from 'components/loadingError';

import { DeveloperJobsiteCard } from 'containers/developer/tabs/DeveloperJobsitesTabContainer/developerJobsiteCard/DeveloperJobsiteCard';
import { DeveloperTabProps } from 'containers/developer/types';
import React, { ReactElement } from 'react';

export function DeveloperJobsitesTabContainer(props: DeveloperTabProps): ReactElement {
  const { developer, jobsites, onTabApiChange, loading, error, refetchDeveloperData } = props;

  React.useEffect(() => onTabApiChange({ refetchData: refetchDeveloperData }), [refetchDeveloperData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <div className="client-jobsites-container odin-flex odin-flex-col odin-gap-y-7">
      {loading ? (
        <DeveloperJobsiteCard loading />
      ) : (
        jobsites?.map(
          (jobsite): ReactElement => (
            <DeveloperJobsiteCard key={`${jobsite.jobsiteId}-jobsite-card`} developer={developer} jobsite={jobsite} />
          ),
        )
      )}
    </div>
  );
}
